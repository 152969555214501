import React, { useContext, useEffect, useState } from "react"
import { Button, Calendar, Modal, notification, Popconfirm, Select, Spin, Tag } from "antd"
import moment from "moment"
import API from "@aws-amplify/api"

import { listAppointmentAvailability, listPatient } from "../backend/graphql/queries"
import { AppContext } from "../contexts/AppContext"
import { createAppointment, updateAppointment } from "../backend/graphql/mutations"

const PatientAppointment = (props) => {
    const { appState } = useContext(AppContext);
    const patientID = props?.match?.params?.id;
    const { Option } = Select
    const [patient, setPatient] = useState({
        // name: "Diego McLaughlin",
        // nric: "*****123A"
    })
    const [visibilityAppointment, setVisibilityAppointment] = useState(false)
    const [monthYear, setMonthYear] = useState({
        month: "",
        year: ""
    })
    const [loading, setLoading] = useState(false)
    const disabledDate = (current) => {
        // console.log(moment().endOf("day"));
        // return current < moment().startOf("day");
    }
    const firstDayOfWeek = 7;
    const days = [1, 2, 3, 4, 5, 6, 7];
    days.splice(days.indexOf(firstDayOfWeek), 1)
    const lastDayOfWeek = days[days.length - 1];
    moment.updateLocale("en", {
        week: {
            dow: firstDayOfWeek,
        }
    });
    const [filter, setFilter] = useState({
        // to be used for API query, onChange would update values here
        session: "M",
        clinic: 1
    })
    const [appointmentAvailability, setAppointmentAvailability] = useState([]);
    const [patientAppointment, setPatientAppointment] = useState([]);
    const [appointmentFiltered, setAppointmentFiltered] = useState([]);
    const [clinicList, setClinicList] = useState([]);
    const [modalDisplay, setModalDisplay] = useState({})
    // console.log("modalDisplay", modalDisplay);
    // console.log("clinicList", clinicList);

    useEffect(() => {
        setMonthYear({
            month: moment().format("MMMM"),
            year: moment().format("YYYY")
        })

        return () => { }
    }, [])

    useEffect(() => {
        if (Object.keys(appState.clinicList).length !== 0) {
            _listPatient(patientID)
            _listAppointmentAvailability(
                firstDayOfWeek,
                lastDayOfWeek,
                moment().local().format("yyyy-MM-01"),
                moment(moment().local().format("yyyy-MM-01")).add(1, "months").add(-1, "days").format("yyyy-MM-DD"),
                patientID,
            )
            setFilter({
                ...filter,
                clinic: parseInt(appState.selectedClinicID)
            })
        }
        return () => { }
    }, [appState.clinicList])

    useEffect(() => {
        // console.log("filter", filter);
        if (appointmentAvailability.length == 0)
            return;

        let filtered = [];
        if (filter.clinic) {
            setClinicList(appState.clinicList.filter(s => filter.clinic == s.id));
            filtered = appointmentAvailability.filter(s => filter.clinic == s.clinicID);
        }

        if (filter.session) {
            let clinicIDs = filtered.map(s => s.clinicID);
            let _filtered = filtered.filter(s => s.shift == filter.session || s.shift == null);
            // console.log('_filtered', _filtered);

            if (_filtered.map(s => s.clinicID).length != clinicIDs.length) {
                for (let _clinicID of clinicIDs) {
                    if (!_filtered.find(s => s.clinicID == _clinicID)) {
                        let firstClinicIDRecord = appointmentAvailability.find(s => s.clinicID == _clinicID);
                        _filtered.push({
                            clinicID: _clinicID,
                            noOfActiveSeat: firstClinicIDRecord.noOfActiveSeat,
                            date: null,
                            noOfAppointment: null,
                            shift: null,
                        })
                    }
                }
            }

            // console.log('_filtered', _filtered);
            setAppointmentFiltered(_filtered.length > 0 ? _filtered : appointmentAvailability);
        } else {
            setAppointmentFiltered(appointmentAvailability);
        }

        // updateAppointmentFilteredList();
    }, [filter, appointmentAvailability])

    useEffect(() => {
        // console.log('modalDisplay', modalDisplay);

    }, [modalDisplay])


    const _listAppointmentAvailability = async (calendarStartDay, calenderEndDay, start, end, patientID = 0) => {
        setLoading(true);
        // console.log(filter);
        try {
            const variables = {
                calendarStartDay,
                calenderEndDay,
                start,
                end
            }

            if (patientID > 0) {
                variables.patientID = patientID;
            }

            // variables.clinicIDs = [filter.clinic];
            variables.clinicIDs = appState.clinicList.map(s => s.id);

            // console.log('PatientAppointment', variables);

            const result = await API.graphql({
                query: listAppointmentAvailability,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientAppointment listAppointmentAvailability", result);
            const data = result.data.result
            const array = result.data.result.result

            // process data
            let parseData = JSON.parse(data);
            // console.log(parseData);
            setAppointmentAvailability(parseData.data.appointmentAvailability);
            setPatientAppointment(parseData.data.patientAppointments);

            setLoading(false);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to list Appointment availability"
            })
        }
        finally { }
    }

    const _createAppointment = async (patientID, values) => {
        setLoading(true);
        try {
            const variables = {
                create: {
                    patientID: parseInt(patientID),
                    // clinicID: parseInt(appState.selectedClinicID),
                    clinicID: parseInt(clinicList[0].id),
                    date: values.date.local().format("yyyy-MM-DD"),
                    shift: values.shift,
                    time: values.shift == 'M' ? '09:00:00' : (values.shift == 'N' ? '12:00:00' : values.shift == 'M' ? '15:00:00' : '')
                }
            };

            variables.create = Object.entries(variables.create).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: createAppointment,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result createAppointment", result);

            const data = result?.data?.result
            const array = result?.data?.result?.result

            // console.log('createAppointment', data, array);

            setLoading(false);
            return data;

            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to book Appointment"
            })
        }
        finally { }
    }

    const _updateAppointment = async (patientID, values) => {
        setLoading(true);
        try {
            const variables = {
                update: {
                    id: values.appointmentID,
                    deleted: 1
                }
            };

            variables.update = Object.entries(variables.update).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: updateAppointment,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result updateAppointment", result);

            const data = result.data.result
            const array = result.data.result.result

            // console.log('updateAppointment', data, array);

            setLoading(false);
            return data;

            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to update Appointment"
            })
        }
        finally { }
    }

    const _listPatient = async (patientID) => {
        if (patientID == 0) {
            return;
        }

        try {
            const variables = {
                filter: {
                    id: { eq: patientID }
                },
                pagination: {
                    limit: 1,
                    // orderby: 'startTime ASC'
                },
            }

            // console.log('listPatient', variables);

            const result = await API.graphql({
                query: listPatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientDetailsSummary", result);
            const data = result.data.result
            if (data.result.length !== 0) {
                let nricDisplay = data.result[0].nric ?? ""
                nricDisplay = nricDisplay?.substring(0, nricDisplay.length - 4).replace(/\d|\w/g, "*") + nricDisplay?.substring(nricDisplay.length - 4)
                setPatient({
                    ...data.result[0],
                    nric: nricDisplay
                })
            }
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Patient Details"
            })
        }
        finally { }
    }

    const handleBookCancelClick = (values) => {
        // console.log("values", values);
        // API CALL HERE, call handleModalVisibility("appointment") when successful to close modal
        let appointment = undefined;
        if (values.displayBookCancel == 'book') {
            appointment = _createAppointment(patientID, values);
            if (appointment) {
                notification.success({ message: `Appointment Booked: ${values.date.format('DD MMM YYYY')}` })
            }
        } else if (values.displayBookCancel == 'cancel') {
            appointment = _updateAppointment(patientID, values);
            if (appointment) {
                notification.success({ message: `Appointment Cancelled: ${values.date.format('DD MMM YYYY')}` })
            }
        }
        if (appointment) {
            setVisibilityAppointment(false);
            _listAppointmentAvailability(
                firstDayOfWeek,
                lastDayOfWeek,
                modalDisplay.date.local().format("yyyy-MM-01"),
                moment(modalDisplay.date.local().format("yyyy-MM-01")).add(1, "months").add(-1, "days").format("yyyy-MM-DD"),
                patientID,
            )
        }
    }

    // const updateAppointmentFilteredList = () => {
    //     setAppointmentFiltered(appointmentAvailability.filter(s => (s.shift == filter.session || s.shift == null) && filter.clinic == s.clinicID));
    // }

    const handleCalendarDateChange = (date) => {
        // console.log("handleCalendarDateChange", date);
        // console.log("appointmentAvailability", appointmentAvailability);
        // console.log("patientAppointment", patientAppointment);
    }

    const handleCalendarDateSelect = (date) => {
        // console.log("handleCalendarDateSelect", date);
        // console.log("handleCalendarDateSelect month", moment(date).format("MMMM"));
        // console.log("current display month", monthYear.month);
        // console.log("handleCalendarDateSelect formatted", date.format("yyyy-MM-DDT00:00:00.000") + "Z");
        // console.log("appointmentAvailability", appointmentAvailability); // sessions that have bookings
        // console.log("patientAppointment", patientAppointment);
        // console.log("appointmentFiltered", appointmentFiltered);
        // console.log("filter", filter);

        let booked = false
        let available = -1
        let displayBookCancel = ""
        let bookedSession = ""
        let appointmentID = ""
        let appointment = {};

        // check if selected date and shift exist in patient"s appointment 
        for (let i = 0; i < patientAppointment?.length; i++) {
            // if (appointmentFiltered[i].date === date.format("yyyy-MM-DDT00:00:00.000") + "Z" && appointmentFiltered[i].shift === filter.session) {
            if (
                patientAppointment[i].date === date.format("yyyy-MM-DDT00:00:00.000") + "Z"
                // &&
                // patientAppointment[i].clinicID == filter.clinic
            ) {
                booked = true
                // console.log("booked session", patientAppointment[i].date, patientAppointment[i].shift);
                bookedSession = patientAppointment[i].shift;
                appointmentID = patientAppointment[i].id;
                appointment = patientAppointment[i];
                break
            }
        }
        if (booked === false) {
            //  check if with filtered date range's booking availability
            let availabilityClinicDateSession = null;

            // Filter for selected clinic
            let appointmentAvailabilityForClinic = appointmentFiltered.filter(s => s.clinicID == filter.clinic);
            // console.log('appointmentAvailabilityForClinic', appointmentAvailabilityForClinic, date, date.format("yyyy-MM-DDT00:00:00.000") + "Z");

            if (appointmentAvailabilityForClinic.length > 0) {

                // If clinic got no book for that month at all...
                if (
                    appointmentAvailabilityForClinic.length == 1 &&
                    appointmentAvailabilityForClinic[0].date == null &&
                    appointmentAvailabilityForClinic[0].shift == null
                ) {
                    availabilityClinicDateSession = appointmentAvailabilityForClinic[0];
                } else {
                    // filter for selected date
                    let appointmentAvailabilityForSelectedDate = appointmentAvailabilityForClinic.filter(s => s.date === date.format("yyyy-MM-DDT00:00:00.000") + "Z");
                    // console.log('appointmentAvailabilityForSelectedDate', appointmentAvailabilityForSelectedDate);

                    // found record for selected clinic, date only
                    if (appointmentAvailabilityForSelectedDate.length > 0) {
                        // filter for selected session
                        let appointmentAvailabilityForSession = appointmentAvailabilityForSelectedDate.filter(s => s.shift === filter.session);
                        // console.log('appointmentAvailabilityForSession', appointmentAvailabilityForSession);

                        // found record for slected clinic, date, session 
                        if (appointmentAvailabilityForSession.length > 0) {
                            availabilityClinicDateSession = appointmentAvailabilityForSession[0];
                        }
                        // found record for selected clinic, date only
                        else {
                            availabilityClinicDateSession = {
                                ...appointmentAvailabilityForSelectedDate[0],
                                noOfAppointment: 0,
                            }
                        }
                    }
                    // found record for selected clinic only
                    else {
                        // let appointmentAvailabilityForSession = appointmentAvailabilityForClinic.filter(s => s.shift === filter.session);
                        // // console.log('appointmentAvailabilityForSession', appointmentAvailabilityForSession);
                        // availabilityClinicDateSession = appointmentAvailabilityForSession[0];

                        availabilityClinicDateSession = {
                            ...appointmentAvailabilityForClinic[0],
                            noOfAppointment: 0,
                        }
                    }
                }
            }
            // console.log(availabilityClinicDateSession);

            if (availabilityClinicDateSession)
                available = ((availabilityClinicDateSession.noOfActiveSeat - availabilityClinicDateSession.noOfAppointment) > 0)

            // for (let i = 0; i < appointmentAvailability.length; i++) {
            //     if (
            //         appointmentAvailability[i].date === date.format("yyyy-MM-DDT00:00:00.000") + "Z" &&
            //         appointmentFiltered[i]?.shift === filter.session
            //     ) {
            //         if (appointmentAvailability[i].noOfActiveSeat - appointmentAvailability[i].noOfAppointment <= 0) {
            //             available = false
            //             break
            //         } else {
            //             available = true;
            //             break
            //         }
            //     }
            // }
            // console.log(availabilityClinicDateSession, available);
            if (available == -1) {

            } else if (available === true) {
                displayBookCancel = "book"
            } else if (available == false) {
                displayBookCancel = "full"
            }
        }
        else {
            // Check if can cancel
            if (appointment.endedBy)
                displayBookCancel = "ended"
            else if (appointment.startedBy)
                displayBookCancel = "started"
            else
                displayBookCancel = "cancel"
        }
        // console.log("bookedSession", bookedSession, appointment.clinicID, appState?.clinicList, appState?.clinicList?.find(s => s.id == appointment.clinicID));

        setModalDisplay({
            date,
            shift: bookedSession !== "" ? bookedSession : filter.session,
            displayBookCancel,
            appointmentID,
            appointment,
            isPermitted: ((appState?.clinicList?.find(s => s.id == appointment?.clinicID) !== undefined && appointment) || Object.keys(appointment).length == 0) ?? false,
        })

        // check current and previous date, if month the same display modal, else do nothing (compare month will do)
        if (moment(date).format("MMMM") === monthYear.month) {
            handleModalVisibility("appointment")
        }
    }

    const handleCalendarPanelChange = (date, mode) => {
        // console.log("=== handleCalendarPanelChange date", date);
        // console.log("=== handleCalendarPanelChange mode", mode);
        setMonthYear({
            dayOfWeek: date.format("dddd"),
            dayOfMonth: date.format("D"),
            month: date.format("MMMM"),
            year: date.format("YYYY")
        });

        if (mode === 'month') {
            _listAppointmentAvailability(
                firstDayOfWeek,
                lastDayOfWeek,
                moment(date).local().format('yyyy-MM-01'),
                moment(moment(date).local().format('yyyy-MM-01')).add(1, 'months').add(-1, 'days').format('yyyy-MM-DD'),
                patientID,
            )
        }
    }

    const handleModalVisibility = (modal) => {
        // console.log("modal", modal);
        switch (modal) {
            case "appointment":
                if (visibilityAppointment === true) {
                    setModalDisplay({})
                }
                setVisibilityAppointment(!visibilityAppointment)
                break
            default:
        }
    }

    const getListData = (value) => {
        let listData = [];
        if (clinicList.length == 0)
            return []

        let selectedDate = value.format("yyyy-MM-DDT00:00:00.000") + "Z";

        for (let clinic of clinicList) {
            // let clinicAvailability = appointmentFiltered.filter(s => s.clinicID == clinic.id);
            let clinicAvailability = appointmentAvailability.filter(s => s.clinicID == clinic.id);
            // console.log(clinic.id, clinicAvailability);

            // render availability
            if (clinicAvailability.length == 1 || (clinicAvailability.length > 1 && clinicAvailability.filter(s => s.date == selectedDate).length == 0)) {
                listData.push({
                    color: "green",
                    text: `Available ${clinicAvailability[0].noOfActiveSeat}`
                })
            } else if (clinicAvailability.length > 0) {
                // console.log(clinic.id, selectedDate, filter, appointmentAvailability, appointmentFiltered, clinicAvailability);

                let clinicAvailabilityMorning = clinicAvailability.filter(s => s.shift == 'M' && s.date == selectedDate);
                // console.log(clinicAvailabilityMorning, filter.session && filter.session == 'M' && clinicAvailabilityMorning.length > 0);
                let morningAvail = clinicAvailabilityMorning.length > 0 ? clinicAvailabilityMorning?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0] : clinicAvailability.filter(s => s.date == selectedDate)[0].noOfActiveSeat;

                let clinicAvailabilityNoon = clinicAvailability.filter(s => s.shift == 'N' && s.date == selectedDate);
                // console.log(clinicAvailabilityNoon);
                let noonAvail = clinicAvailabilityNoon.length > 0 ? clinicAvailabilityNoon?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0] : clinicAvailability.filter(s => s.date == selectedDate)[0].noOfActiveSeat;
                // let noonAvail = filter.session && filter.session != 'N' ? true : clinicAvailability.filter(s => s.shift == 'N' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0];

                let clinicAvailabilityEvening = clinicAvailability.filter(s => s.shift == 'E' && s.date == selectedDate);
                // console.log(clinicAvailabilityEvening);
                let eveningAvail = clinicAvailabilityEvening.length > 0 ? clinicAvailabilityEvening?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0] : clinicAvailability.filter(s => s.date == selectedDate)[0].noOfActiveSeat;
                // let eveningAvail = filter.session && filter.session != 'E' ? true : clinicAvailability.filter(s => s.shift == 'E' && s.date == selectedDate)?.map(s => s.noOfActiveSeat - s.noOfAppointment)[0];

                // console.log(selectedDate, clinic.id, appointmentFiltered, filter.session, clinicAvailability, morningAvail, noonAvail, eveningAvail);

                if (filter.session == "M") {
                    if (morningAvail > 0)
                        listData.push({
                            color: "green",
                            text: `Available ${morningAvail}`
                        })
                    else
                        listData.push({
                            color: "red",
                            text: `Full`
                        })
                } else if (filter.session == "N") {
                    if (noonAvail > 0)
                        listData.push({
                            color: "green",
                            text: `Available ${noonAvail}`
                        })
                    else
                        listData.push({
                            color: "red",
                            text: `Full`
                        })
                } else if (filter.session == "E") {
                    if (eveningAvail > 0)
                        listData.push({
                            color: "green",
                            text: `Available ${eveningAvail}`
                        })
                    else
                        listData.push({
                            color: "red",
                            text: `Full`
                        })
                }
            }

            if (patientAppointment?.length > 0) {
                // render patient Appointment
                let patientAppointmentClinicDate = patientAppointment?.filter(s => //s.clinicID == clinic.id && 
                    s.date == selectedDate);
                // console.log(selectedDate, patientAppointmentClinicDate);
                if (patientAppointmentClinicDate?.length > 0) {
                    // console.log(patientAppointmentClinicDate);
                    if (patientAppointmentClinicDate[0].endedBy) {
                        listData.push({
                            color: "blue",
                            text: `Completed - ${patientAppointmentClinicDate[0].shift} (${patientAppointmentClinicDate[0].uid})`
                        })
                    } else if (patientAppointmentClinicDate[0].startedBy) {
                        listData.push({
                            color: "blue",
                            text: `In Progress - ${patientAppointmentClinicDate[0].shift} (${patientAppointmentClinicDate[0].uid})`
                        })
                    } else {
                        listData.push({
                            color: "blue",
                            text: `Booked - ${patientAppointmentClinicDate[0].shift} (${patientAppointmentClinicDate[0].uid})`
                        })
                    }
                }
            }
        }

        return listData || []
    }

    const dateCellRender = (value) => {
        // console.log("dateCellRender", value);
        const listData = getListData(value)
        // console.log("listData", listData);
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.content}>
                        <Tag color={item.color} style={{ minWidth: "80%", textAlign: "center" }}>{item.text}</Tag>
                    </li>
                ))}
            </ul>
        )
    }

    const renderListOptions = (array, key = "id", value = "id", name = "name") => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="content-content">
            <div className="container-row">
                <Spin spinning={loading}>
                    <div className="patientappointment-header">
                        <div className="column1 title">
                            Appointment - <span className="patient">{patient.name} ({patient.nric})</span>
                        </div>
                    </div>
                    <div className="patientappointment-content">
                        <div className="filter">
                            <Select
                                showSearch
                                placeholder="Select"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                                defaultValue={1} // get from appState
                                onSelect={value => {
                                    setFilter({
                                        ...filter,
                                        clinic: value
                                    })
                                }}
                                value={filter.clinic}
                            >
                                {/* {renderListOptions([
                                    { id: 1, name: "Clinic A" },
                                    { id: 2, name: "Clinic B" },
                                ])} */}
                                {renderListOptions(appState.clinicList)}
                            </Select>
                            <Select
                                showSearch
                                placeholder="Select"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                                defaultValue={"M"}
                                onSelect={value => {
                                    setFilter({
                                        ...filter,
                                        session: value
                                    })
                                }}
                            >
                                {renderListOptions([
                                    { id: "M", name: "Morning Session" },
                                    { id: "N", name: "Noon Session" },
                                    { id: "E", name: "Evening Session" },
                                ])}
                            </Select>
                        </div>
                        <div className="calendar-header">
                            <div className="month-year">
                                {monthYear.month} {monthYear.year}
                            </div>
                            <div className="legend">
                                <Tag color="" style={{ minWidth: "80px", textAlign: "center" }}>Available</Tag>
                                <Tag color="red" style={{ minWidth: "80px", textAlign: "center" }}>Full</Tag>
                                <Tag color="blue" style={{ minWidth: "80px", textAlign: "center" }}>Booked</Tag>
                            </div>
                        </div>
                        <Calendar
                            onChange={handleCalendarDateChange}
                            onPanelChange={handleCalendarPanelChange}
                            dateCellRender={dateCellRender}
                            disabledDate={disabledDate}
                            onSelect={handleCalendarDateSelect}
                        />
                        <Modal
                            title={
                                <div className="modal-title">
                                    {modalDisplay.date?.format("DD MMM YYYY (ddd)")} - {modalDisplay.shift === "M" ? "Morning" : (modalDisplay.shift === "N" ? "Noon" : (modalDisplay.shift === "E" ? "Evening" : ""))}
                                </div>
                            }
                            visible={visibilityAppointment}
                            onCancel={() => handleModalVisibility("appointment")}
                            footer={
                                (['book', 'cancel'].includes(modalDisplay.displayBookCancel)) && modalDisplay.isPermitted?
                                    [
                                        <Button key="back" onClick={() => handleModalVisibility("appointment")}>
                                            No
                                        </Button>,
                                        <Popconfirm title="Are you sure?" onConfirm={() => handleBookCancelClick(modalDisplay)} okText="Yes" cancelText="No">
                                            <Button key="yes" type="primary">
                                                Yes
                                            </Button>
                                        </Popconfirm>
                                        ,
                                    ]
                                    :
                                    [
                                        <Button key="back" onClick={() => handleModalVisibility("appointment")}>
                                            Close
                                        </Button>,
                                    ]
                            }
                            className="modal-patientappointment"
                        >
                            <div className="modal-content">
                                {
                                    (modalDisplay.isPermitted) ?
                                        (
                                            (['book', 'cancel'].includes(modalDisplay.displayBookCancel)) ?
                                                (
                                                    <div>
                                                        <div>
                                                            Are you sure you want to <b>{modalDisplay.displayBookCancel}</b> appointment?
                                                        </div>
                                                        <br />
                                                        <ul>
                                                            <li>{modalDisplay.date?.format("DD MMM YYYY (ddd)")}</li>
                                                            <li>{modalDisplay.shift === "M" ? "Morning" : (modalDisplay.shift === "N" ? "Noon" : (modalDisplay.shift === "E" ? "Evening" : ""))}</li>
                                                            {modalDisplay.displayBookCancel == 'book' ?
                                                                <li>{clinicList.length !== 0 ? clinicList[0].name : ""}</li> :
                                                                <li>{modalDisplay.appointment.clinicID ? appState.fixedClinicList.find(s => s.id == modalDisplay.appointment.clinicID)?.name : ""}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                ) :
                                                (
                                                    (['started', 'ended'].includes(modalDisplay.displayBookCancel)) ?
                                                        (
                                                            <div>
                                                                Session has already <b>{modalDisplay.displayBookCancel}</b>, cannot be cancelled.
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                There are no available slots for the selected session.
                                                            </div>
                                                        )
                                                )
                                        ) :
                                        (
                                            (modalDisplay.appointment) ?
                                                (
                                                    <div>Session been <b>{modalDisplay.displayBookCancel == 'cancel' ? 'booked' : 'cancelled'}</b>.</div>
                                                ) :
                                                (
                                                    <></>
                                                )
                                        )
                                }

                            </div>
                        </Modal>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default PatientAppointment