import React, { useContext, useEffect, useState } from "react"
import { Table } from "antd"
import { AppContext } from "../contexts/AppContext"
import API from "@aws-amplify/api";
import moment from "moment";

import { listCalculatedLabResult } from "../backend/graphql/queries"
import { onUpdateCalculatedLabResultSubscription } from "../backend/graphql/subscriptions"

const PatientRecordCalculatedLabResults = (props) => {
    const { appState } = useContext(AppContext);
    const { patientID } = props
    const [table, setTable] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    })
    let subscriptionUpdateCalculatedLabResult

    const columns = [
        {
            title: "Year",
            dataIndex: "date"
        },
        {
            title: "URRD",
            dataIndex: "URRD"
        },
        {
            title: "URR",
            dataIndex: "URR",
            // align: 'center',
            render: (text, record) => {
                return {
                    // props: { style: { textAlign: 'right' }},
                    children: text && `${text}`.length > 0 ? parseFloat(text)?.toFixed(1) : text
                }
            }
        },
        {
            title: "KTV online",
            dataIndex: "KTVonline",
            // align: 'right',
            render: (text, record) => {
                return {
                    // props: { style: { textAlign: 'right' }},
                    children: text && `${text}`.length > 0 ? parseFloat(text)?.toFixed(1) : text
                }
            }
        },
        {
            title: "KTVe",
            dataIndex: "KTVe",
            render: (text, record) => {
                return {
                    // props: { style: { textAlign: 'right' }},
                    children: text && `${text}`.length > 0 ? parseFloat(text)?.toFixed(1) : text
                }
            }
        },
        {
            title: "Duration",
            dataIndex: "duration"
        },
        {
            title: "Urea Post",
            dataIndex: "ureaPost"
        },
        {
            title: "Urea Pre",
            dataIndex: "ureaPre"
        },
        {
            title: "DW",
            dataIndex: "dryWeight",
            render: (text, record) => {
                return {
                    // props: { style: { textAlign: 'right' }},
                    children: text && `${text}`.length > 0 ? parseFloat(text)?.toFixed(1) : text
                }
            }
        },
        {
            title: "UFV (ℓ)",
            dataIndex: "UF",
            render: (text, record) => {
                return {
                    // props: { style: { textAlign: 'right' }},
                    children: text && `${text}`.length > 0 ? Number(text) / 1000?.toFixed(1) : text
                }
            }
        },
        {
            title: "MSA",
            dataIndex: "MSA"
        },
        {
            title: "Dialyser",
            dataIndex: "dialyser"
        },
        {
            title: "QB",
            dataIndex: "QB"
        },
        {
            title: "QD",
            dataIndex: "QD"
        },
        {
            title: "QA",
            dataIndex: "QA"
        },
    ]

    const _listCalculatedLabResult = async (patientID, props) => {
        // setTableDialysisHistory({
        //     ...tableDialysisHistory,
        //     loading: true,
        // })
        setTable({
            ...table,
            loading: true,
        });

        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: '(`latest` = 1) DESC, `timestamp` DESC',
                },
                filter: {
                    patientID: {
                        eq: parseInt(patientID)
                    }
                }
            }

            // console.log('PatientRecordCalculatedLabResults', variables);

            const result = await API.graphql({
                query: listCalculatedLabResult,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordCalculatedLabResults listCalculatedLabResult", result);
            const data = result.data?.result
            const array = result.data?.result?.result

            if (array) {
                let _calculatedLabResult = []

                for (let v of array) {

                    // let duration = filteredDialysisInterval.length > 0 && d.dischargeTime ? (moment(filteredDialysisInterval[0].timestamp).diff(moment(d.startTime), "minutes")) : (d.dischargeTime ? moment(d.dischargeTime).diff(moment(d.startTime), "minutes") : '');
                    let duration = (v.dialysisDischargeTime ? moment(v.dialysisDischargeTime).diff(moment(v.dialysisStartTime), "minutes") : '');

                    _calculatedLabResult.push({
                        ...v,
                        // date: v.latest === 1 ? 'Latest' : moment.utc(v.timestamp).local().format('DD MMM YYYY HH:mm'),
                        date: v.latest === 1 ? 'Latest' : moment.utc(v.timestamp).local().format('YYYY'),
                        URRD: v.URRD ? moment.utc(v.URRD).local().format('DD MMM YYYY HH:mm:ss') : '',
                        QA: `${v.minQA} to ${v.maxQA}`,
                        dryWeight: v.dryWeight ? v.dryWeight / 1000.0 : '',
                        KTVonline: v.KTVonline != null && v.KTVonline >= 0 ? (parseFloat(v.KTVonline).toFixed(1)) : '',
                        duration: duration || '',
                    })
                }
                // console.log(_calculatedLabResult);
                _calculatedLabResult = _calculatedLabResult?.sort((a, b) => a.latest > b.latest ? -1 : 1);

                setTable({
                    ...table,
                    loading: false,
                    data: _calculatedLabResult,
                    pagination: {
                        ...table.pagination,
                        current: props.current,
                        offset: props.offset,
                        total: props.offset >= data?.count ? data?.count + 1 : data?.count // keeps the last pagination if it is the last record
                    }
                })
            }
        }
        catch (error) {
            console.log("error: ", error);
        }
        finally { }
    }

    const getCalculatedLabResult = async (filter, pagination) => {
        setTable({
            ...table,
            loading: true,
            data: -1
        });

        _listCalculatedLabResult(patientID, pagination)
    }

    const subscriptionUpdateCalculatedLabResultSetup = () => {
        subscriptionUpdateCalculatedLabResult = API.graphql({
            query: onUpdateCalculatedLabResultSubscription,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
                patientID: parseInt(patientID)
            }
        }).subscribe({
            next: async (_calculatedlabresults) => {
                // console.log("calculatedlabresults submit", _calculatedlabresults);
                const item = _calculatedlabresults.value.data.onUpdateCalculatedLabResultSubscription
                // console.log("item", item);
                // _listDialysisInterval(dialysisID)

                getCalculatedLabResult(null, {
                    ...table.pagination,
                    offset: table.pagination.current * table.pagination.pageSize - table.pagination.pageSize,
                })
            }
        })
    }

    useEffect(() => {
        subscriptionUpdateCalculatedLabResultSetup()

        return () => {
            subscriptionUpdateCalculatedLabResult.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0) {
            // _listDialysis(props.patientID, tableDialysisHistory.pagination);
            // console.log('PatientRecordCalculatedLabResults', props);
            getCalculatedLabResult(null, {
                ...table.pagination,
                offset: table.pagination.current * table.pagination.pageSize - table.pagination.pageSize,
            })
        }
        return () => { }
    }, [appState.cognitoUser])

    return (
        <div className="patientrecord-calculatedlabresults">
            <div className="title">
                Calculated Lab Results
            </div>
            <div>
                <Table
                    columns={columns}
                    loading={table.loading}
                    dataSource={table.data}
                    scroll={{ x: 1500, y: 500 }}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : ""
                    }}
                    pagination={table.data.length > 0 ? { ...table.pagination, position: ["bottomLeft"] } : false}
                />
            </div>
        </div>
    )
}

export default PatientRecordCalculatedLabResults