// import React from "react";
import API from "@aws-amplify/api";
import useSWR from "swr";

export const fetcher = async (queryOption) => {
    const result = await API.graphql(
        queryOption
        // {
        //     query: listLastNonNullLab,
        //     authMode: "AMAZON_COGNITO_USER_POOLS",
        //     variables
        // }
    )
    // console.log('useSWR result', result)

    return result;
}

export const SWR = (queryOption) => {
    
    const { data, error } = useSWR(queryOption, fetcher);

    return data;
}
