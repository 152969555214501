import React, { useContext, useEffect, useState } from "react"
import { Avatar, Button, Checkbox, Divider, Form, Input, notification, Select, Spin, Tabs } from "antd"
import { UserOutlined } from "@ant-design/icons"

import Logo from "../media/Logo-AEGISMedicalCare.png"
import { AppContext } from "../contexts/AppContext"
import Storage from "@aws-amplify/storage"
import { useHistory } from 'react-router-dom'

const SettingsCentreSelection = (props) => {
    const { appState, actionSetClinicID } = useContext(AppContext)
    const { Option } = Select
    const [form] = Form.useForm();
    const { from } = props.location.state || { from: "/" }
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const validateMessages = {
        required: "Required.",
        types: {
            email: 'This is not a valid email.',
        },
    }

    useEffect(() => {
        // console.log("appState", appState);
        if (appState.validLogin === false) {
            history.push(from)
        }
    }, [])

    useEffect(() => {
        if (appState.selectedClinicID !== "") {
            history.push(from)
        }
    }, [appState.selectedClinicID])

    const onFinish = async (values) => {
        // console.log("onFinish", values);
        setLoading(true)
        actionSetClinicID(values.centre);
    }

    const renderListOptions = (array, key = 'id', value = 'id', name = 'name') => {
        if (typeof(array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="login">
            <div className="container-login">
                <div className="login-body">
                    <div className="login-header">
                        <img src={Logo} />
                    </div>
                    <div className="login-content">
                        <Spin active spinning={loading}>
                            <div className="container-centreselection">
                                <div className="column1">
                                    <div className="welcome">
                                        Welcome,
                                    </div>
                                    <div className="name">
                                        {appState?.cognitoUser?.attributes?.name}
                                    </div>
                                    <Avatar className="avatar" icon={<UserOutlined />} shape="square" src={
                                        appState?.profilePicture ?? ''
                                    } />
                                </div>
                                <div className="column2">
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        name="normal_login"
                                        className="login-form"
                                        onFinish={onFinish}
                                        validateMessages={validateMessages}
                                        requiredMark={false}
                                    >
                                        <Form.Item
                                            label="Please select a centre"
                                            name="centre"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select"
                                                filterOption={(input, option) =>
                                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                }
                                                // defaultValue={0}
                                            >
                                                {renderListOptions(
                                                    appState.clinicList
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="login-form-button">
                                                Enter
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsCentreSelection