import React, { useContext, useEffect, useState } from "react"
import { Button, Divider, Form, Input, Modal, Select, Space, Spin, TreeSelect, Popconfirm, notification } from "antd"
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import moment from "moment"
import API from "@aws-amplify/api"
import { useHistory } from 'react-router-dom'

import { listDialysisInterval } from "../backend/graphql/queries"
import { submitDialysisInterval } from "../backend/graphql/mutations"
import { onSubmitDialysisInterval } from "../backend/graphql/custom_subscriptions"
import { AppContext } from '../contexts/AppContext'
import iconClock from "../media/Icon-Clock.png"
import path from "../utils/pathSettings"

import SummaryIntraDialysis from "./Component-Summary-IntraDialysis"
import { freqList, routeList } from "../utils/constants"

const PatientRecordIntraDialysisInput = (props) => {
    const { appState } = useContext(AppContext)
    const {
        patientID,
        dialysisID,
        lastDialysisIntervalNum,
        setLastDialysisIntervalNum,
        lastDialysisInterval,
        setLastDialysisInterval,
        lastDialysis,
        setLastDialysis,
    } = props;
    const [form] = Form.useForm()
    const { TextArea } = Input
    const { TreeNode } = TreeSelect
    const { Option } = Select
    const validateMessages = {
        required: "Required.",
    }
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    // const [lastDialysisIntervalNum, setLastDialysisIntervalNum] = useState(1);
    // console.log("lastDialysis", lastDialysis);
    // console.log("lastDialysisIntervalNum", lastDialysisIntervalNum);
    // console.log("lastDialysisInterval", lastDialysisInterval);
    let subscriptionDialysisIntervalOnSubmit
    const [dialysisIntervalDesc, setDialysisIntervalDesc] = useState([])
    const [visibilityMobileIntraDialysisSummary, setVisibilityMobileIntraDialysisSummary] = useState(false)

    const columns1 = [
        {
            title: "SBP",
            dataIndex: "sbp"
        },
        {
            title: "DBP",
            dataIndex: "dbp"
        },
        {
            title: "HR",
            dataIndex: "hr"
        },
        {
            title: "QB",
            dataIndex: "qb"
        },
        {
            title: "AP",
            dataIndex: "ap"
        },
        {
            title: "VP",
            dataIndex: "vp"
        },
        {
            title: "TMP",
            dataIndex: "tmp"
        },
        {
            title: "UFR (ℓ)",
            dataIndex: "ufr"
        },
        {
            // title: "cUF",
            title: "UFV (ℓ)",
            dataIndex: "cuf"
        },
        {
            // title: "DFLOW",
            title: "FLOW",
            dataIndex: "dflow"
        },
        {
            // title: "CDT",
            title: "COND",
            dataIndex: "cdt"
        },
        {
            title: "Temp",
            dataIndex: "temp"
        },
        {
            title: "HepRate",
            dataIndex: "hepRate"
        },
    ]

    const getLitersToMilliliters = (liters) => {
        if (!liters)
            return null;

        let litersString = liters.toString();
        let litersResult;

        // check if is decimal
        if (litersString.includes('.')) {
            litersResult = parseFloat(litersString) * 1000;
        }
        // // check if within 999 (kg)
        // else if (litersString.length <= 2) {
        //     litersResult = parseInt(litersString) * 1000;
        // }
        // otherwise more than 3 character
        else {
            litersResult = parseInt(litersString);
        }

        return parseInt(litersResult);
    }

    const subscriptionDialysisIntervalOnSubmitSetup = () => {
        subscriptionDialysisIntervalOnSubmit = API.graphql({
            query: onSubmitDialysisInterval,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
                clinicID: localStorage.getItem("ircs_clinicid"),
                dialysisID: dialysisID
            }
        }).subscribe({
            next: async (_dialysisInterval) => {
                // console.log("dialysisInterval submit", _dialysisInterval);
                const item = _dialysisInterval.value.data.onSubmitDialysisInterval
                // console.log("item", item);
                _listDialysisInterval(dialysisID)
            }
        })
    }

    const _listDialysisInterval = async (dialysisID) => {
        setLoading(true)
        try {
            const variables = {
                filter: {
                    dialysisID: {
                        eq: dialysisID
                    },
                    deleted: { eq: 0 },
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listDialysisInterval,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordIntraDialysisInput", result);

            const data = result.data.result
            const array = result.data.result.result
            // console.log("array", array);
            // const arrayReverse = array.reverse()
            // const arrayReverse = array
            // console.log("arrayReverse", arrayReverse);
            // setDialysisIntervalDesc(arrayReverse)

            if (array && array.length) {
                // console.log("array", array);
                setLastDialysisIntervalNum(array.length)
                setLastDialysisInterval(array[array.length - 1]);
                form.setFieldsValue({
                    sbp: array[array.length - 1].SBP,
                    dbp: array[array.length - 1].DBP,
                    hr: array[array.length - 1].HR,
                    qb: array[array.length - 1].QB,
                    ap: array[array.length - 1].AP,
                    vp: array[array.length - 1].VP,
                    tmp: array[array.length - 1].tmp,
                    ufr: array[array.length - 1].UFR/1000,
                    // cuf: array[array.length - 1].cUF,
                    dflow: array[array.length - 1].DFLOW,
                    cdt: array[array.length - 1].CDT,
                    temp: array[array.length - 1].temp,
                    hepRate: array[array.length - 1].hepRate
                })

                if (array.length > 0) {
                    form.setFieldsValue({
                        sbp_prev1: array[array.length - 1].SBP,
                        dbp_prev1: array[array.length - 1].DBP,
                        hr_prev1: array[array.length - 1].HR,
                        qb_prev1: array[array.length - 1].QB,
                        ap_prev1: array[array.length - 1].AP,
                        vp_prev1: array[array.length - 1].VP,
                        tmp_prev1: array[array.length - 1].tmp,
                        ufr_prev1: array[array.length - 1].UFR/1000,
                        cuf_prev1: array[array.length - 1].cUF/1000,
                        dflow_prev1: array[array.length - 1].DFLOW,
                        cdt_prev1: array[array.length - 1].CDT,
                        temp_prev1: array[array.length - 1].temp,
                        hepRate_prev1: array[array.length - 1].hepRate
                    })
                }
                // if (arrayReverse.length > 0) {
                //     form.setFieldsValue({
                //         sbp_prev1: arrayReverse[0].SBP,
                //         dbp_prev1: arrayReverse[0].DBP,
                //         hr_prev1: arrayReverse[0].HR,
                //         qb_prev1: arrayReverse[0].QB,
                //         ap_prev1: arrayReverse[0].AP,
                //         vp_prev1: arrayReverse[0].VP,
                //         tmp_prev1: arrayReverse[0].tmp,
                //         ufr_prev1: arrayReverse[0].UFR,
                //         cuf_prev1: arrayReverse[0].cUF,
                //         dflow_prev1: arrayReverse[0].DFLOW,
                //         cdt_prev1: arrayReverse[0].CDT,
                //         temp_prev1: arrayReverse[0].temp,
                //         hepRate_prev1: arrayReverse[0].hepRate
                //     })
                // }

                if (array.length > 1) {
                    form.setFieldsValue({
                        sbp_prev2: array[array.length - 2].SBP,
                        dbp_prev2: array[array.length - 2].DBP,
                        hr_prev2: array[array.length - 2].HR,
                        qb_prev2: array[array.length - 2].QB,
                        ap_prev2: array[array.length - 2].AP,
                        vp_prev2: array[array.length - 2].VP,
                        tmp_prev2: array[array.length - 2].tmp,
                        ufr_prev2: array[array.length - 2].UFR/1000,
                        cuf_prev2: array[array.length - 2].cUF/1000,
                        dflow_prev2: array[array.length - 2].DFLOW,
                        cdt_prev2: array[array.length - 2].CDT,
                        temp_prev2: array[array.length - 2].temp,
                        hepRate_prev2: array[array.length - 2].hepRate
                    })
                }
                // if (arrayReverse.length > 1) {
                //     form.setFieldsValue({
                //         sbp_prev2: arrayReverse[1].SBP,
                //         dbp_prev2: arrayReverse[1].DBP,
                //         hr_prev2: arrayReverse[1].HR,
                //         qb_prev2: arrayReverse[1].QB,
                //         ap_prev2: arrayReverse[1].AP,
                //         vp_prev2: arrayReverse[1].VP,
                //         tmp_prev2: arrayReverse[1].tmp,
                //         ufr_prev2: arrayReverse[1].UFR,
                //         cuf_prev2: arrayReverse[1].cUF,
                //         dflow_prev2: arrayReverse[1].DFLOW,
                //         cdt_prev2: arrayReverse[1].CDT,
                //         temp_prev2: arrayReverse[1].temp,
                //         hepRate_prev2: arrayReverse[1].hepRate
                //     })
                // }
            }
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Dialysis Interval"
            })
        }
        finally {
            setLoading(false)
        }
    }

    const _submitDialysisInterval = async (values) => {
        setLoading(true);
        // setTable({
        //     ...table,
        //     loading: true,
        // });
        try {
            if (values.cuf.toString().startsWith('.')) {
                values.cuf = '0' + values.cuf;
            }

            let variables = {
                create: {
                    dialysisID: dialysisID,

                    AP: parseInt(values.ap),
                    VP: parseInt(values.vp),
                    SBP: parseInt(values.sbp),
                    DBP: parseInt(values.dbp),
                    HR: parseInt(values.hr),
                    CDT: parseFloat(values.cdt),
                    // KTV: values. ,
                    // cUF: getLitersToMilliliters(parseFloat(values.cuf)),
                    cUF: parseInt(values.cuf),
                    QB: parseInt(values.qb),
                    DFLOW: parseInt(values.dflow),
                    UFR: parseFloat(values.ufr),
                    // UFR: getLitersToMilliliters(parseFloat(values.ufr)),
                    temp: parseFloat(values.temp),
                    tmp: parseInt(values.tmp),
                    hepRate: parseFloat(values.hepRate),

                    note: values?.note,
                    symptoms: JSON.stringify(values.symptoms),
                }
            }

            if (values.administeredMedication) {
                variables.createMedicationAdministered = [];
                for (let medicationAdmin of values.administeredMedication) {
                    variables.createMedicationAdministered.push({
                        patientID: parseInt(patientID),
                        dialysisID: parseInt(dialysisID),
                        medication: medicationAdmin.medication,
                        dose: medicationAdmin.dose,
                        route: medicationAdmin.route,
                        freq: medicationAdmin.frequency,
                        givenBy: medicationAdmin.givenBy,
                        witnessedBy: medicationAdmin.witnessedBy,
                    })
                }
            }

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(JSON.stringify(variables, null, 2));

            const result = await API.graphql({
                query: submitDialysisInterval,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: variables
            })
            // console.log("API result PatientRecordIntraDialysisInput submitDialysisInterval", result);

            if (result?.data?.result) {
                _listDialysisInterval(dialysisID);
                notification.success({
                    message: "Created successfully"
                })
                form.resetFields();
                history.push(path("dashboard"))
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create new record"
            })
            setLoading(false)
        } finally { }
    }

    const onFinish = async (values) => {
        // console.log(onFinish clicked", values);
        values = {...values, ufr: Number(values.ufr)*1000, cuf: Number(values.cuf)*1000 }
        // console.log(onFinish after formatting", values);
        const v = await form.validateFields(columns1.map(s => s.dataIndex));
        // console.log(v);
        if (v.administeredMedication !== undefined && v.administeredMedication.length !== 0) {
            let same = false
            for (let i = 0; i < v.administeredMedication.length; i++) {
                if (v.administeredMedication[i].givenBy === v.administeredMedication[i].witnessedBy) {
                    // console.log(`same user can't be witness`);
                    // console.log(v.administeredMedication[i].givenBy);
                    // console.log(v.administeredMedication[i].witnessedBy);
                    same = true
                    notification.error({
                        message: "Same user can't be witness"
                    });
                    break
                }
            }
            if (same === false) {
                _submitDialysisInterval(values)
            }
        }
        else {
            _submitDialysisInterval(values)
        }
        // if (v.givenBy == v.witnessed) {
        //     console.log(`same user can't be witness`);
        //     notification.error({
        //         message: "Same user can't be witness"
        //     });
        // } else {
        //     _submitDialysisInterval(values);
        // }
    }

    const handleFocus = (event) => {
        event.target.select()
    }

    const renderInputItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item width", item.width);
            const width = item.width !== undefined ? item.width : null
            if (item.renderInput !== undefined) {
                return item.renderInput
            }
            else {
                return (
                    <div className="item">
                        {
                            ((lastDialysisIntervalNum - 1) > 0) ? (
                                <Form.Item
                                    label={item.title + " (Int " + (lastDialysisIntervalNum - 1) + ")"}
                                    name={item.dataIndex + "_prev2"}
                                    style={{ width: width }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            message: "Digits only",
                                            pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                        },
                                    ]}
                                >
                                    <Input disabled autoComplete="off" />
                                </Form.Item>
                            ) : ("")
                        }
                        {
                            lastDialysisIntervalNum > 0 ? (
                                <Form.Item
                                    label={item.title + " (Int " + lastDialysisIntervalNum + ")"}
                                    name={item.dataIndex + "_prev1"}
                                    style={{ width: width }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            message: "Digits only",
                                            pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                        },
                                    ]}
                                >
                                    <Input disabled autoComplete="off" />
                                </Form.Item>
                            ) : ("")
                        }
                        <Form.Item
                            label={item.title}
                            name={item.dataIndex}
                            style={{ width: width }}
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    message: "Digits only",
                                    pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                },
                            ]}
                        >
                            <Input autoComplete="off" onFocus={handleFocus} />
                        </Form.Item>
                    </div>
                )
                // if (width !== null) {
                //     return (
                //         <Form.Item
                //             label={item.title}
                //             name={item.dataIndex}
                //             style={{ width: width }}
                //             rules={[
                //                 {
                //                     required: true,
                //                 },
                //                 {
                //                     message: "Digits only",
                //                     pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                //                 },
                //             ]}
                //         >
                //             <Input autoComplete="off" />
                //         </Form.Item>
                //     )
                // }
                // else {
                //     // if (item.dataIndex === "action") {
                //     //     return (
                //     //         <Form.Item>
                //     //             <Popconfirm title="Are you sure?" onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No" placement="topRight">
                //     //                 <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} />
                //     //             </Popconfirm>
                //     //         </Form.Item>
                //     //     )
                //     // }
                //     // else {
                //     return (
                //         <Form.Item
                //             label={item.title}
                //             name={item.dataIndex}
                //             rules={[
                //                 {
                //                     required: true,
                //                 },
                //                 {
                //                     message: "Digits only",
                //                     pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                //                 },
                //             ]}
                //         >
                //             <Input autoComplete="off" />
                //         </Form.Item>
                //     )
                //     // }
                // }
            }
        })
        return items
    }

    const renderMachineInputRow = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderInputItems(array)
            )
        )
    }

    const renderTreeSelectOptions = (array) => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                )
            })

            return listOptions
        }
        else {
            return (
                ""
            )
        }
    }

    const renderListOptions = (array, key = 'id', value = 'id', name = 'name') => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 0 },
        },
    };

    useEffect(() => {
        subscriptionDialysisIntervalOnSubmitSetup()

        return () => {
            subscriptionDialysisIntervalOnSubmit.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0 && dialysisID !== "undefined") {
            _listDialysisInterval(dialysisID);
        }
    }, [appState.cognitoUser, dialysisID])

    return (
        <div className="patientrecord-intradialysisinput">
            <div className="title">
                <div className="interval">Interval {loading === false ? lastDialysisIntervalNum + 1 : "..."}</div>
                <div className="container-date">
                    <div className="date start">
                        <div className="column1">
                            <img src={iconClock} />
                        </div>
                        <div className="column2">
                            <div className="row1">
                                Start Time
                            </div>
                            <div className="row2">
                                {/* {moment("2021-04-10 06:00:00").format("DD MMM YYYY, HH:mm")} */}
                                {/* {moment.utc(lastDialysis.startTime).local().add(30 * (lastDialysisIntervalNum), 'minutes').format("DD MMM YYYY, HH:mm")} */}
                                {moment.utc(lastDialysisInterval?.timestamp ? lastDialysisInterval.timestamp : lastDialysis.startTime).local().format('DD MMM YYYY, HH:mm')}
                            </div>
                        </div>
                    </div>
                    <div className="date end">
                        <div className="column1">
                            <img src={iconClock} />
                        </div>
                        <div className="column2">
                            <div className="row1">
                                End Time
                            </div>
                            <div className="row2">
                                {/* {moment("2021-04-10 06:00:00").format("DD MMM YYYY, HH:mm")} */}
                                {/* {moment.utc(lastDialysis.startTime).local().add(30 * (lastDialysisIntervalNum + 1), 'minutes').format("DD MMM YYYY, HH:mm")} */}
                                {moment.utc(lastDialysisInterval?.timestamp ? lastDialysisInterval.timestamp : lastDialysis.startTime).local().add(parseInt(process.env.REACT_APP_DIALYSIS_INTERVAL_PERIOD), 'minutes').format('DD MMM YYYY, HH:mm')}
                                {/* {' ' + JSON.stringify(process.env)}
                                {process.env.NODE_ENV} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    layout="horizontal"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    requiredMark={false}
                >
                    <div className="content">
                        <div className="column1">
                            <div className="row1">
                                {renderMachineInputRow(columns1)}
                            </div>
                            <Divider />
                            <div>
                                <Form.Item
                                    label="Dialysis Note"
                                    name="note"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                                <Form.Item
                                    label="Symptoms"
                                    name="symptoms"
                                >
                                    <TreeSelect
                                        showSearch
                                        allowClear
                                        multiple
                                        treeDefaultExpandAll
                                        treeCheckable
                                        placeholder="Select symptoms"
                                        filterTreeNode={(input, treenode) =>
                                            treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {/* {renderTreeSelectOptions([
                                            {
                                                id: 1,
                                                name: "Fever"
                                            },
                                            {
                                                id: 2,
                                                name: "Flu"
                                            },
                                            {
                                                id: 3,
                                                name: "Muscle soreness"
                                            },
                                        ])} */}
                                        {renderTreeSelectOptions(appState.symptomList)}
                                    </TreeSelect>
                                </Form.Item>
                            </div>
                            <Divider />
                            <Form.Item
                                label="Administered Medication"
                            />
                            <Form.List
                                name="administeredMedication"
                            // rules={[
                            //     {
                            //         validator: async (_, names) => {
                            //             if (!names || names.length < 2) {
                            //                 return Promise.reject(new Error('At least 2 passengers'));
                            //             }
                            //         },
                            //     },
                            // ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {/* {fields.map((field, index) => {
                                            // console.log("field", field);
                                            // console.log("fields", fields);
                                            // console.log("fields length", fields.length);
                                            return (
                                                <Form.Item
                                                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                    // label={index === 0 ? 'Administered Medication' : ''}
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Space key={field.key} align="baseline">
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: "Please input variable or delete this field.",
                                                                },
                                                            ]}
                                                            noStyle
                                                        >
                                                            <Input placeholder="Variable" style={{ width: '60%' }} />
                                                        </Form.Item>
                                                        {fields.length >= 1 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </Space>
                                                </Form.Item>
                                            )
                                        })} */}
                                        {fields.map(field => (
                                            <div key={field.key} className="administeredmedication-input">
                                                {/* <Space key={field.key} align="baseline"> */}
                                                <Form.Item
                                                    {...field}
                                                    label="Medication"
                                                    name={[field.name, 'medication']}
                                                    fieldKey={[field.fieldKey, 'medication']}
                                                    rules={[{ required: true, message: 'Missing medication' }]}
                                                >
                                                    <Select
                                                        style={{ width: 180 }}
                                                        showSearch={true}
                                                        placeholder="Select a medication"
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onSearch={onSearch}
                                                        filterOption={(inputValue, option) =>
                                                            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }>
                                                        {renderListOptions(appState.drugList, 'name', 'name', 'name')}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="Dose"
                                                    name={[field.name, 'dose']}
                                                    fieldKey={[field.fieldKey, 'dose']}
                                                    rules={[{ required: true, message: 'Missing dose' }]}
                                                >
                                                    <Input style={{ width: 150 }} autoComplete="off" placeholder="Enter dose" allowClear />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="Route"
                                                    name={[field.name, 'route']}
                                                    fieldKey={[field.fieldKey, 'route']}
                                                    rules={[{ required: true, message: 'Missing route' }]}
                                                >
                                                    <Select
                                                        style={{ width: 150 }}
                                                        showSearch={true}
                                                        placeholder="Select a route"
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                        }>
                                                        {renderListOptions(routeList, 'id', 'id', 'value')}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="Frequency"
                                                    name={[field.name, 'frequency']}
                                                    fieldKey={[field.fieldKey, 'frequency']}
                                                    rules={[{ required: true, message: 'Missing frequency' }]}
                                                >
                                                    <Select
                                                        style={{ width: 100 }}
                                                        showSearch={true}
                                                        placeholder="Select a frequency"
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                        }>
                                                        {renderListOptions(freqList, 'id', 'id', 'value')}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="Given By"
                                                    name={[field.name, 'givenBy']}
                                                    fieldKey={[field.fieldKey, 'givenBy']}
                                                    rules={[{ required: true, message: 'Missing given by' }]}
                                                >
                                                    <Select
                                                        style={{ width: 150 }}
                                                        showSearch={true}
                                                        placeholder="Select a staff"
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                        }>
                                                        {renderListOptions(appState.staffList, 'accountID', 'accountID', 'name')}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="Witness"
                                                    name={[field.name, 'witnessedBy']}
                                                    fieldKey={[field.fieldKey, 'witnessedBy']}
                                                    rules={[{ required: true, message: 'Missing witness' }]}
                                                >
                                                    <Select
                                                        style={{ width: 150 }}
                                                        showSearch={true}
                                                        placeholder="Select a staff"
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                        }>
                                                        {renderListOptions(appState.staffList, 'accountID', 'accountID', 'name')}
                                                    </Select>
                                                </Form.Item>
                                                <MinusCircleOutlined className="btn-minus" onClick={() => remove(field.name)} />
                                                {/* </Space> */}
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                style={{ width: '60%' }}
                                                icon={<PlusOutlined />}
                                            >
                                                Add Medication
                                            </Button>
                                            {/* <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add('The head item', 0);
                                                }}
                                                style={{ width: '60%', marginTop: '20px' }}
                                                icon={<PlusOutlined />}
                                            >
                                                Add field at head
                                            </Button> */}
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                        <div className="column2 above-md">
                            {/* <Button className="btn-fetchdata">
                                Fetch Data
                            </Button> */}
                            <div />
                            <Popconfirm title="Are you sure?" onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No">
                                <Button type="primary">Submit</Button>
                            </Popconfirm>
                        </div>
                        <div className="column2 below-md">
                            {/* <Button className="btn-fetchdata">
                                Fetch Data
                            </Button> */}
                            <div />
                            {/* <Popconfirm title="Are you sure?" onConfirm={() => {
                                // console.log(mobile submit", form.getFieldsValue());
                                // onFinish(form.getFieldsValue())
                            }} okText="Yes" cancelText="No">
                            </Popconfirm> */}
                            <Button type="primary" onClick={() => {
                                // console.log(mobile submit", form.getFieldsValue());
                                setVisibilityMobileIntraDialysisSummary(true)
                                // onFinish(form.getFieldsValue())
                            }}>Submit</Button>
                        </div>
                    </div>
                </Form>
            </Spin>
            <Modal
                visible={visibilityMobileIntraDialysisSummary}
                onCancel={() => { setVisibilityMobileIntraDialysisSummary(false) }}
                footer={[
                    <Button key="back" onClick={() => setVisibilityMobileIntraDialysisSummary(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        setVisibilityMobileIntraDialysisSummary(false)
                        onFinish(form.getFieldsValue())
                    }}>
                        Submit
                    </Button>
                ]}
            >
                <SummaryIntraDialysis page="checkin" values={form.getFieldValue()} />
            </Modal>
        </div>
    )
}

export default PatientRecordIntraDialysisInput