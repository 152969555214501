import React, { useContext, useEffect, useState } from "react"
import { Avatar, Button, Descriptions, Form, Modal, notification, Space, Spin, Upload } from "antd"
import { UploadOutlined, UserOutlined } from "@ant-design/icons"
import { Link, useHistory } from "react-router-dom"
import API from "@aws-amplify/api"
import { Storage } from "@aws-amplify/storage"
import moment from "moment"

import { listStaff } from "../backend/graphql/queries"
import { updateStaff } from "../backend/graphql/mutations"
import path from "../utils/pathSettings"
// import menu from "../utils/navigationMenuSettingsArchive"
import renderInputItems from "../utils/renderFormInputItems"
import { checkImageTypeValidity } from "../utils/fileTypeCheck"
import { compressImage } from "../utils/compressImage"
import { AppContext } from "../contexts/AppContext"
import { Auth } from "@aws-amplify/auth"

const SettingsManageAccountEdit = () => {
    const { appState, getS3, actionLogin } = useContext(AppContext);
    const [form] = Form.useForm()
    const validateMessages = {
        required: "Required.",
        types: {
            email: 'This is not a valid email.',
        },
    }
    const [accountDetails, setAccountDetails] = useState(-1)
    const [profilePic, setProfilePic] = useState("")
    const [loading, setLoading] = useState()
    const history = useHistory()

    const uploadFile = async (file, accountID = '') => {
        // console.log(file);

        const fileName = "s_" + accountID + "_" + moment().utc().format("YYYY-MM-DD_HHmmss") + "_" + file.name
        const fileType = file.type

        try {
            const result = await Storage.put("staff/" + fileName, file, {
                contentType: fileType
            })
            // console.log(result, result.key);
            return result.key
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: error
            })
        }
    }

    const getAccountDetails = async () => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem("ircs_cognitouser"))
        // console.log("user", user);
        try {
            let variables = {
                filter: {
                    accountID: {
                        eq: user.username
                    }
                }
            }
            const result = await API.graphql({
                query: listStaff,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("result", result);
            const data = result.data.result.result[0]

            let _staff = data;

            // let imageKey = _staff.profilePictureKey ?? ""
            // if (imageKey !== "") {
            //     _staff.profilePic = await Storage.get(imageKey)
            // }
            // else {
            //     _staff.profilePic = ""
            // }

            setAccountDetails(_staff)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve account details"
            })
            setLoading(false)
        }
    }

    const getProfilePicture = async (key) => {
        setProfilePic(await Storage.get(key))
    }

    useEffect(() => {
        getAccountDetails()
        return () => { }
    }, [])

    useEffect(() => {
        if (accountDetails !== -1) {
            // let imageKey = accountDetails.profilePictureKey ?? ""
            // if (imageKey !== "") {
            //     getProfilePicture(imageKey)
            // }
            form.setFieldsValue({
                name: accountDetails.name,
                designation: accountDetails.designation,
                mobileNumber: accountDetails.contactNo,
                email: accountDetails.email
            })
            setLoading(false)
        }
    }, [accountDetails])

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const uploadButton = (
        <div>
            <Button icon={<UploadOutlined />}>Upload</Button>
        </div>
    )
    const [state, setState] = useState({
        fileList: []
    })

    const handleCancel = () => {
        setState({ ...state, previewVisible: false })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    const handleChange = (file) => {
        // console.log(file);
        // console.log(optionNum);
        let { fileList } = file
        // fileList = fileList.slice(-1)
        setState({ ...state, fileList: fileList })
    }

    const onFinishUpdateStaff = async (values) => {
        const user = await Auth.currentAuthenticatedUser({bypassCache: true});
        let imageKey = ""

        // check for image upload
        if (state.fileList.length > 0) {
            const file = state.fileList[0].originFileObj
            const typeType = file.type

            if (checkImageTypeValidity(typeType) === true) {

                let _file = await compressImage(file);
                imageKey = await uploadFile(_file, user.username) ?? '';
                await updateAccount(values, imageKey);
            }
            else {
                notification.error({
                    message: "Invalid image type. Please reattach image."
                })
            }
        }
    }

    const updateAccount = async (values, imageKey) => {
        // console.log("Update account", values);
        setLoading(true)
        const user = JSON.parse(localStorage.getItem("ircs_cognitouser"))
        // console.log("user", user);

        const variables = {
            update: {
                accountID: user.username,
                name: values.name,
                profilePictureKey: accountDetails.profilePictureKey,
                profilePictureBucket: accountDetails.profilePictureBucket,
                profilePictureRegion: accountDetails.profilePictureRegion,
            }
        }
        if (imageKey !== "") {
            let s3 = getS3()
            variables.update = {
                ...variables.update,
                profilePictureKey: imageKey,
                profilePictureBucket: s3?.bucket,
                profilePictureRegion: s3?.region,
            }
        }
        // console.log("variables", variables);
        try {
            const result = await API.graphql({
                query: updateStaff,
                variables: variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);

            // Refresh Cognito token
            let user = await Auth.currentAuthenticatedUser({
                bypassCache: true
            });
            actionLogin(user);

            notification.success({
                message: "Updated successfully"
            })
            history.push(path("settingsManageAccount"))
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            })
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <div className="content-content">
            <div className="settingsmanageaccount-header">
                <div className="title">
                    Manage Account - Edit
                </div>
            </div>
            <div className="settingsmanageaccount-account">
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        layout="horizontal"
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="row">
                            <div className="container-profilepic">
                                <div>
                                    <Avatar className="avatar" icon={<UserOutlined />} shape="square" src={
                                        // profilePic
                                        appState?.profilePicture ?? ''
                                    } />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop: "16px" }}>
                                    <Form.Item>
                                        <Upload
                                            action={getBase64}
                                            listType="picture"
                                            fileList={state.fileList}
                                            onPreview={handlePreview}
                                            onChange={(file) => handleChange(file)}
                                        >
                                            {state.fileList.length >= 1 ? null : uploadButton}
                                        </Upload>
                                        <Modal
                                            visible={state.previewVisible}
                                            title={state.previewTitle}
                                            footer={null}
                                            onCancel={handleCancel}
                                        >
                                            <img alt="upload preview" style={{ width: '100%' }} src={state.previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="container-accountdetails">
                                <Descriptions
                                    title={"My Account Details"}
                                    extra={
                                        <Space>
                                            <Button>
                                                <Link to={path("settingsManageAccount")}>Cancel</Link>
                                            </Button>
                                            <Button type="primary" onClick={() => { onFinishUpdateStaff(form.getFieldsValue()) }}>
                                                Save
                                            </Button>
                                        </Space>
                                    }
                                />
                                {renderInputItems([
                                    {
                                        label: "Full Name",
                                        name: "name",
                                        type: "input",
                                        placeholder: "Enter full name",
                                    },
                                    {
                                        label: "Designation",
                                        name: "designation",
                                        type: "input",
                                        placeholder: "Designation",
                                        disabled: true,
                                    },
                                    // {
                                    //     label: "Designation",
                                    //     name: "designation",
                                    //     type: "select",
                                    //     placeholder: "Select designation",
                                    //     optionList: [
                                    //         { id: 1, name: "Option 1" },
                                    //         { id: 2, name: "Option 2" },
                                    //         { id: 3, name: "Option 3" },
                                    //         { id: 4, name: "Option 4" },
                                    //     ]
                                    // },
                                    // {
                                    //     label: "Centre",
                                    //     name: "centre",
                                    //     type: "select",
                                    //     placeholder: "Select centre",
                                    //     optionList: [
                                    //         { id: 1, name: "Option 1" },
                                    //         { id: 2, name: "Option 2" },
                                    //         { id: 3, name: "Option 3" },
                                    //         { id: 4, name: "Option 4" },
                                    //     ]
                                    // },
                                    {
                                        label: "Mobile Number",
                                        name: "mobileNumber",
                                        type: "input",
                                        placeholder: "Enter mobile number",
                                        required: true,
                                        disabled: true,
                                    },
                                    {
                                        label: "Email",
                                        name: "email",
                                        type: "email",
                                        placeholder: "Enter email",
                                        required: true,
                                        disabled: true,
                                    },
                                ])}
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    )
}

export default SettingsManageAccountEdit