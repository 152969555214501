import React, { useContext, useEffect, useState } from "react"
import { Button, Form, DatePicker, Input, Modal, notification, Popconfirm, Space, Spin, Table, TreeSelect } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import API from "@aws-amplify/api"
import moment from "moment"

import { listStaffStatusWithMaintenanceStatusCount } from "../backend/graphql/custom_queries"
import { createStaffStatus, updateStaffStatus } from "../backend/graphql/mutations"
import { AppContext } from "../contexts/AppContext"
import renderInputItems from "../utils/renderFormInputItems"
import iconCalendar from "../media/Icon-Calendar.png"
import iconMinus from "../media/Icon-Minus.png"

const ManageCertification = () => {
    const { appState } = useContext(AppContext)
    const [formAdd] = Form.useForm()
    const [formUpdate] = Form.useForm()
    const [formSearch] = Form.useForm()
    const dateFormatList = ["DD MMM YYYY"]
    const { TreeNode, SHOW_PARENT } = TreeSelect
    const [filter, setFilter] = useState({
        centre: [0],
        search: "",
    })
    const validateMessages = {
        required: "Required.",
    }
    const [totalCertification, setTotalCertification] = useState(0)
    const [expiredCertification, setExpiredCertification] = useState(0)
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            showSizeChanger: true,
        },
        loading: false
    })
    const [display, setDisplay] = useState([])
    const [staffList, setStaffList] = useState(-1)
    const [clinicList, setClinicList] = useState(-1)
    const [visibilityUpdateCertification, setVisibilityUpdateCertification] = useState(false)
    const [editCertification, setEditCertification] = useState({}) // current edit modal does not display any certification details
    const [visibilityAddCertification, setVisibilityAddCertification] = useState(false)
    const [addCertification, setAddCertification] = useState({})
    const [loadingModal, setLoadingModal] = useState(false)

    useEffect(() => {
        getCertificationList(table.pagination, filter)
        formSearch.setFieldsValue(filter)
    }, [])

    useEffect(() => {
        if (table.data !== -1) {
            // console.log("table", table.data);
            setDisplay(table.data)
            setTable( {
                ...table,
                loading: false
            })
        }
    }, [table.data])

    useEffect(() => {
        if (appState.staffList !== -1) {
            let array = []
            appState.staffList.forEach((element) => {
                array.push({
                    ...element,
                    id: element.accountID
                })
            })
            setStaffList(array)
        }
        if (appState.clinicList !== -1) {
            setClinicList(appState.clinicList)
        }
    }, [appState.fixedListLoaded])

    useEffect(() => {
      formUpdate.setFieldsValue({
        expiryDate: moment(editCertification.expiry)
      });
    }, [editCertification])
    

    const getCertificationList = async (props, values) => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true,
        })
        try {
            let filter = {
                deleted: { eq: 0 },
            }

            let filter2 = {
                deleted: { eq: 0 },
                status: {
                    contains: "EXPIRED"
                }
            }

            if (values.search != "") {
                filter = {
                    ...filter,
                    or: [
                        { staffName: { contains: values.search } },
                    ]
                }

                filter2 = {
                    ...filter
                }
            }

            // console.log("values.centre[0]", values.centre[0]);
            if (values.centre[0] !== 0) {
                let array = []
                for (let i = 0; i < values.centre.length; i++) {
                    let object = {
                        clinicID: { eq: values.centre[i] }
                    }
                    array.push(object)
                }
                // console.log("array", array);
                filter = {
                    ...filter,
                    or: filter.or ? [
                        ...filter.or,
                        ...array
                    ] : array
                }

                filter2 = {
                    ...filter
                }
            }

            const staffStatusDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "FIELD(status, 'EXPIRED', 'PASS') ASC, expiry ASC, staffName ASC, name ASC"
                },
                filter: filter,
                filter2: filter2
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                // query: listStaffStatus,
                query: listStaffStatusWithMaintenanceStatusCount,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("certification", result);
            const data = result.data.result
            // console.log("data", data);
            const data2  = result.data.result2
            setTotalCertification(data.count)
            setExpiredCertification(data2.count)
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    pageSize: props.pageSize,
                    current: props.current,
                    offset: props.offset,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            notification.error({
                message: "Unable to retrieve records"
            })
            setTable({
                ...table,
                loading: false
            })
        }
    }

    const createStaffStatusRecord = async (values) => {
        setLoadingModal(true)
        // console.log("values", values);
        try {
            const current = moment()
            let expiryStatus = "ACTIVE"
            if (values.expiryDate < current) {
                expiryStatus = "EXPIRED"
            }
            const staffStatusDetails = {
                create: {
                    accountID: values.staff,
                    name: values.certificationName,
                    status: expiryStatus,
                    expiry: values.expiryDate ? moment(values.expiryDate).format('YYYY-MM-DD') : null
                }
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                query: createStaffStatus,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Created successfully"
            })
            getCertificationList(table.pagination, filter)
            formAdd.resetFields()
            handleModalVisibility("addCertification")
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to create record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const updateStaffStatusRecord = async (values, id) => {
        setLoadingModal(true)
        try {
            const current = moment()
            let expiryStatus = "PASS"
            if (values.expiryDate < current) {
                expiryStatus = "EXPIRED"
            }
            const staffStatusDetails = {
                update: {
                    id: id,
                    status: expiryStatus,
                    expiry: values.expiryDate ? moment(values.expiryDate).format('YYYY-MM-DD') : null
                }
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                query: updateStaffStatus,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            notification.success({
                message: "Updated successfully"
            })
            getCertificationList(table.pagination, filter)
            handleModalVisibility("updateCertification")
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const deleteStaffStatusRecord = async (id) => {
        setLoadingModal(true)
        try {
            const staffStatusDetails = {
                update: {
                    id: id,
                    deleted: 1
                }
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                query: updateStaffStatus,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            notification.success({
                message: "Deleted successfully"
            })
            getCertificationList(table.pagination, filter)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to delete record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const onFinishSearch = (values) => {
        // console.log("submit formSearch", values);
        // console.log(values);
        setFilter(values)
        getCertificationList(table.pagination, values)
    }

    const onFinishAddCertification = async (values) => {
        // console.log("onFinishAddCertification", values);
        await formAdd.validateFields()
        createStaffStatusRecord(values)
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("addCertification")
        // }, 1000)
    }

    const onFinishUpdateCertification = async (values, id) => {
        // console.log("onFinishUpdateCertification", values);
        await formUpdate.validateFields()
        updateStaffStatusRecord(values, id)
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateCertification")
        // }, 1000)
    }

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        switch (modal) {
            case "addCertification":
                setVisibilityAddCertification(!visibilityAddCertification)
                break
            case "updateCertification":
                setEditCertification(record ? record : {})
                setVisibilityUpdateCertification(!visibilityUpdateCertification)
                break
            default:
        }
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    }

    const handleTreeSelectChange = (value, label, extra) => {
        // console.log("value", value);
        // console.log("label", label);
        // console.log("extra", extra);
    }

    const handleDelete = async (id) => {
        // console.log("handle delete", id);
        deleteStaffStatusRecord(id)
        // setTable({
        //     ...table,
        //     loading: true
        // })
        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         loading: false
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    const handleTableChange = (paginate) => {
        getCertificationList({
            ...table.pagination,
            pageSize: paginate.pageSize,
            current: paginate.current,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        }, filter)
    }

    const renderTreeSelectOptions = (array) => {
        if (typeof(array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const columns = [
        {
            title: "Staff Name",
            dataIndex: "staffName"
        },
        {
            title: "Certification Name",
            dataIndex: "name"
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => {
                return {
                    props: {
                        style: {color: text == 'EXPIRED' ? "red" : ""}  
                    },
                    children: text
                }
            }
        },
        {
            title: "Expiry Date",
            dataIndex: "expiry",
            render: (text, record) => {
                return text && text.length > 0 ? moment(text).format('DD MMM YYYY') : ''
            }
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <Space size="large">
                        <div className="table-row-action clickable" onClick={() => handleModalVisibility("updateCertification", record)}>
                            <div className="icon">
                                <img src={iconCalendar} />
                            </div>
                            <div className="text">
                                Update Status
                            </div>
                        </div>
                        <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
                            <div className="table-row-action clickable red">
                                <div className="icon">
                                    <img src={iconMinus} />
                                </div>
                                <div className="text">
                                    Delete
                                </div>
                            </div>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ]

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="container-title">
                            <div className="title">
                                Manage Certfications
                            </div>
                            <Space className="row">
                                <div className="statscard">
                                    <div className="column1">
                                        Total<br />Certifications
                                    </div>
                                    <div className="column2">
                                        {totalCertification}
                                    </div>
                                </div>
                                {
                                    expiredCertification !== 0 ? (
                                        <div className="statscard red">
                                            <div className="column1">
                                                Expired<br />Certifications
                                            </div>
                                            <div className="column2">
                                                {expiredCertification}
                                            </div>
                                        </div>
                                    ) : ""
                                }
                            </Space>
                        </div>
                        <Button type="primary" onClick={() => handleModalVisibility("addCertification")}>
                            Add Certification
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    <Form.Item
                                        key="centre"
                                        name="centre"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        style={{ minWidth: 430, width: "50%" }}
                                    >
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            multiple
                                            treeDefaultExpandAll
                                            treeCheckable
                                            placeholder="Select clinics"
                                            showCheckedStrategy={SHOW_PARENT}
                                            filterTreeNode={(input, treenode) =>
                                                treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            // defaultValue={0}
                                            onChange={handleTreeSelectChange}
                                        >
                                            <TreeNode value={0} title="All">
                                                {clinicList !== -1 ? renderTreeSelectOptions(clinicList) : ""}
                                                {/* {renderTreeSelectOptions([
                                                    {
                                                        id: 1,
                                                        name: "AMC Centre (AMC)"
                                                    },
                                                    {
                                                        id: 2,
                                                        name: "Thomson Clinic (TMC)"
                                                    },
                                                    {
                                                        id: 3,
                                                        name: "Yishun Clinic (YSC)"
                                                    },
                                                    {
                                                        id: 4,
                                                        name: "Hougang Clinic (HGC)"
                                                    },
                                                    {
                                                        id: 5,
                                                        name: "Mayflower Clinic (MFC)"
                                                    },
                                                ])} */}
                                            </TreeNode>
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item
                                        className="search"
                                        name="search"
                                    >
                                        <Input autoComplete="off" prefix={<SearchOutlined />} placeholder="Search staff name" value={filter.search} allowClear />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : ""
                    }}
                    loading={table.loading}
                    dataSource={display}
                    pagination={table.pagination}
                    onChange={handleTableChange}
                    // dataSource={[
                    //     {
                    //         id: "1",
                    //         staffName: "Dr. ABC",
                    //         certificationName: "Cert name A",
                    //         status: "Active",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    //     {
                    //         id: "2",
                    //         staffName: "Dr. ABC",
                    //         certificationName: "Cert name B",
                    //         status: "Expired",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    //     {
                    //         id: "3",
                    //         staffName: "Dr. ABC",
                    //         certificationName: "Cert name C",
                    //         status: "Active",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    // ]}
                />
            </div>
            <Modal
                title={
                    <div className="modal-title">
                        Update Status
                    </div>
                }
                visible={visibilityUpdateCertification}
                onCancel={() => handleModalVisibility("updateCertification")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("updateCertification")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure?" onConfirm={() => onFinishUpdateCertification(formUpdate.getFieldsValue(), editCertification.id)} okText="Yes" cancelText="No">
                        <Button key="submit" type="primary" loading={loadingModal}>
                            Update
                        </Button>
                    </Popconfirm>
                    ,
                ]}
                className="modal-manage"
            width={600}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishUpdateCertification}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="New Expiry Date"
                                name="expiryDate"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">
                        Add Certification
                    </div>
                }
                visible={visibilityAddCertification}
                onCancel={() => handleModalVisibility("addCertification")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("addCertification")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure" onConfirm={() => onFinishAddCertification(formAdd.getFieldsValue())} okText="Yes" cancelText="No">
                        <Button key="submit" type="primary" loading={loadingModal}>
                            Add
                        </Button>
                    </Popconfirm>
                    ,
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishAddCertification}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Name",
                                    name: "staff",
                                    type: "select",
                                    placeholder: "Select staff",
                                    required: true,
                                    optionList: staffList !== -1 ? staffList : []
                                    // optionList: [
                                    //     { id: 1, name: "Dr. ABC" },
                                    //     { id: 2, name: "Dr. CDE" },
                                    //     { id: 3, name: "Dr. HIJ" },
                                    //     { id: 4, name: "Dr. KLM" },
                                    // ]
                                },
                                {
                                    label: "Certification Name",
                                    name: "certificationName",
                                    type: "input",
                                    placeholder: "Enter certification name",
                                    required: true
                                },
                                {
                                    label: "Expiry Date",
                                    name: "expiryDate",
                                    type: "datepicker",
                                    disabledDate: false,
                                    required: true
                                },
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    )
}

export default ManageCertification