import React, { useContext, useEffect, useState } from "react"
import { Button, DatePicker, Empty, Form, Input, message, Modal, notification, Popconfirm, Spin, Table, Pagination } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { CopyToClipboard } from "react-copy-to-clipboard"
import API from "@aws-amplify/api"
import moment from "moment"

import { listMedicalMilestone } from "../backend/graphql/queries"
import { createMedicalMilestone } from "../backend/graphql/mutations"
import { AppContext } from "../contexts/AppContext"
import iconFile from "../media/Icon-File2.png"
import iconEye from "../media/Icon-Eye2.png"

const { TextArea } = Input

const PatientRecordMedicalMilestone = (props) => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm()
    const dateFormatList = ["DD MMM YYYY"]
    const validateMessages = {
        required: "Required.",
    }
    const [visibilityMedicationMileStone, setVisibilityMedicationMilestone] = useState(0);
    const [visibilityAddMedicationMileStone, setVisibilityAddMedicationMilestone] = useState(false);
    // const [medicalMilestone, setMedicalMilestone] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [table, setTable] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    });

    useEffect(() => {
        // console.log('PatientRecordMedicalMilestone', props?.patientID);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            _listMedicalMilestone(props?.patientID);
        }
        return () => { }
    }, [appState.cognitoUser])

    const _listMedicalMilestone = async (patientID, pagination = table.pagination, filter = null) => {
        // setLoading(true);
        setTable({
            ...table,
            loading: true,
        });
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `timestamp DESC`
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    deleted: { eq: 0 }
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedicalMilestone,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordMedicalMilestone", result);

            const data = result.data.result
            const array = result.data.result.result

            // process data
            let _medicalMilestone = [];
            for (let v of array) {
                _medicalMilestone.push({
                    id: v.id,
                    patientID: v.patientID,
                    date: v.timestamp,
                    medicalMilestone: v.milestone,
                    note: v.note,
                })
            }

            // setMedicalMilestone(_medicalMilestone);
            setTable({
                ...table,
                data: _medicalMilestone,
                loading: false,
                pagination: {
                    ...table.pagination,
                    current: pagination.current,
                    offset: pagination.offset,
                    total: pagination.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })

            // console.log(_medicalMilestone);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Medical Milestone"
            })
        }
        finally { }
    }

    const onFinish = async (values) => {
        // setLoading(true);
        setTable({
            ...table,
            loading: false,
        });

        // console.log("onFinish clicked", values);
        const v = await form.validateFields()
        try {
            let variables = {
                patientID: props?.patientID,
                timestamp: v.date.toISOString().split('.')[0],
                milestone: v.medicalMilestone,
                note: v.note
            }

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: createMedicalMilestone,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            })
            // console.log("API result PatientRecordMedicalMilestone createMedicalMilestone", result);

            if (result?.data?.result) {
                _listMedicalMilestone(props?.patientID);
                notification.success({
                    message: "Created successfully"
                })
            }

            // setLoading(false);
            setTable({
                ...table,
                loading: false,
            });
            form.resetFields();
            setVisibilityAddMedicationMilestone(false);
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create new record"
            })
        } finally { }
    }

    const getMedicalMilestone = (filter, pagination = table.pagination) => {
        setTable({
            ...table,
            loading: true,
        });

        _listMedicalMilestone(props?.patientID, pagination);
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    }

    const handleModalVisibility = (modal, id = 0) => {
        // console.log("modal", modal, id);
        switch (modal) {
            case "medicalMilestone":
                setVisibilityMedicationMilestone(id);
                break
            case "addMedicalMilestone":
                if (visibilityAddMedicationMileStone) {
                    form.resetFields();
                }
                setVisibilityAddMedicationMilestone(!visibilityAddMedicationMileStone)
                break
            default:
        }
    }

    const handleTableChange = (paginate) => {
        getMedicalMilestone(null, {
            ...table.pagination,
            current: paginate.current,
            offset: paginate.current * table.pagination.pageSize - table.pagination.pageSize,
        })
    }

    const handleTableShowSizeChange = (current, size) => {
        // console.log('handleTableShowSizeChange', current, size);
    }

    // const columns = [
    //     {
    //         title: "Date",
    //         dataIndex: "date"
    //     },
    //     {
    //         title: "Medical Milestone",
    //         dataIndex: "medication"
    //     },
    //     {
    //         title: "Action",
    //         dataIndex: "action"
    //     },
    // ]

    const renderItems = (array) => {
        if (Object.keys(array).length > 0) {
            const items = array.map((item, index) => {
                // console.log("item", item);
                return (
                    <div key={item.id} className={index % 2 === 0 ? "record highlight" : "record"}>
                        <div>
                            <span className="clickable">
                                <CopyToClipboard
                                    text={moment.utc(item.date).local().format("DD MMM YYYY") + ": " + item.medicalMilestone}
                                    onCopy={() => { message.success("Copied!") }}
                                >
                                    <img src={iconFile} />
                                </CopyToClipboard>
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            {moment.utc(item.date).local().format("DD MMM YYYY")}
                        </div>
                        <div className="value">
                            &nbsp;&nbsp;&nbsp;
                            <div className="milestone">
                                {item.medicalMilestone}
                            </div>
                        </div>
                        <div className="value">
                            &nbsp;&nbsp;&nbsp;
                            <div className="note">
                                {item.note}
                            </div>
                        </div>
                        {/* <Button className="circle" htmlType="submit" shape="circle" icon={<MinusOutlined />} /> */}
                        <div className="clickable" onClick={() => { handleModalVisibility("medicalMilestone", item.id) }}>
                            <img src={iconEye} />
                        </div>
                    </div>
                )
            })
            return items
        }
    }

    const renderMedicationMilestone = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderItems(array)
            )
        )
    }

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            width: 150,
            render: (text, record) => {
                return (
                    <div>
                        <span className="clickable">
                            <CopyToClipboard
                                text={moment.utc(text).local().format("DD MMM YYYY") + ": " + record.medicalMilestone}
                                onCopy={() => { message.success("Copied!") }}
                            >
                                <img src={iconFile} />
                            </CopyToClipboard>
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {moment.utc(text).local().format("DD MMM YYYY")}
                    </div>
                )
            }
        },
        {
            title: "Milestone",
            dataIndex: "medicalMilestone",
            // width: 500,
            // ellipsis: true,
        },
        {
            title: "Note",
            dataIndex: "note",
            ellipsis: true,
        },
        {
            title: "",
            dataIndex: "action",
            width: 50,
            render: (_, record) => {
                return (
                    <div className="clickable" onClick={() => { handleModalVisibility("medicalMilestone", record.id) }}>
                        <img src={iconEye} alt="" />
                    </div>
                )
            }
        }
    ]

    return (
        <div className="patientrecord-medicalmilestone">
            <div className="container-title">
                <div className="title">
                    Medical Milestone
                </div>
                <Button type="primary" onClick={() => handleModalVisibility("addMedicalMilestone")}>
                    <PlusOutlined /> Add Milestone
                </Button>
            </div>
            {/* <div>
                <Table
                    columns={columns}
                />
            </div> */}
            <Spin spinning={table.loading}>
                {/* <div className="card">
                    <div className="card-header medicalmilestone">
                        <div className="date">
                            Date
                        </div>
                        <div className="milestone">
                            Milestone
                        </div>
                        <div className="note">
                            Note
                        </div>
                    </div>
                    <div className="card-content">
                        <div className="record-content">
                            {renderMedicationMilestone(table.data)}
                        </div>
                        <Pagination
                            current={table.pagination.current}
                            pageSize={table.pagination.pageSize}
                            total={table.pagination.total}
                            onChange={handleTableChange}
                            showSizeChanger={false}
                            onShowSizeChange={handleTableShowSizeChange}
                        />
                    </div>
                </div> */}
                <Table
                    // className="card"
                    columns={columns}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => {
                        return index % 2 === 0 ? "highlight" : ""
                    }}
                    dataSource={table.data}
                    loading={table.loading}
                    pagination={table.data.length > 0 ? { ...table.pagination, position: ["bottomLeft"] } : false}
                    onChange={handleTableChange}
                />
            </Spin>
            <Modal
                title={
                    <div className="modal-title">
                        Medical Milestone
                    </div>
                }
                visible={visibilityMedicationMileStone}
                onCancel={() => handleModalVisibility("medicalMilestone", 0)}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("medicalMilestone")}>
                        Cancel
                    </Button>,
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={800}
            >
                <div className="modal-content">
                    <div className="medicalmilestone">
                        <div className="row1">
                            <div className="label">Date:</div>
                            <div className="value">{moment(table.data?.filter(s => s.id == visibilityMedicationMileStone)[0]?.date).format("DD MMM YYYY")}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Medical Milestone:</div>
                            <div className="value">{table.data?.filter(s => s.id == visibilityMedicationMileStone)[0]?.medicalMilestone}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Note:</div>
                            <div className="value">{table.data?.filter(s => s.id == visibilityMedicationMileStone)[0]?.note}</div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">
                        Add Medical Milestone
                    </div>
                }
                visible={visibilityAddMedicationMileStone}
                onCancel={() => handleModalVisibility("addMedicalMilestone")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("addMedicalMilestone")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure?" onConfirm={() => {
                        form.submit()
                    }}>
                        <Button key="submit" type="primary">
                            Submit
                        </Button>
                    </Popconfirm>,
                ]}
                className="modal-patientrecord-medlabmilestone"
                width={800}
            >
                <Spin spinning={table.loading}>
                    <div className="modal-content">
                        <Form
                            className="addmedicalmilestone"
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                            </Form.Item>
                            <Form.Item
                                label="Medical Milestone"
                                name="medicalMilestone"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                className="row2"
                                label="Note"
                                name="note"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                        </Form>
                    </div>
                </Spin>
            </Modal>
        </div>
    )
}

export default PatientRecordMedicalMilestone