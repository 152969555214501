import React, { useContext, useEffect, useState } from "react"
import { Avatar, Button, DatePicker, Descriptions, Form, Input, Modal, notification, Popconfirm, Space, Spin, Table, Tooltip } from "antd"
import { InfoCircleOutlined, LockOutlined, UserOutlined, } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { Auth } from "@aws-amplify/auth"
import API from "@aws-amplify/api"
import moment from "moment"
import { Storage } from "@aws-amplify/storage"

import { listStaffStatusWithMaintenanceStatusCount } from "../backend/graphql/custom_queries"
import { listStaff, listStaffStatus } from "../backend/graphql/queries"
import { createStaffStatus, updateStaffStatus } from "../backend/graphql/mutations"
import path from "../utils/pathSettings"
// import menu from "../utils/navigationMenuSettingsArchive"
import renderInputItems from "../utils/renderFormInputItems"
import iconCalendar from "../media/Icon-Calendar.png"
import iconMinus from "../media/Icon-Minus.png"
import iconKey from "../media/Icon-Key.png"
import { AppContext } from "../contexts/AppContext"

const SettingsManageAccount = () => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm()
    const [formAdd] = Form.useForm()
    const [formUpdate] = Form.useForm()
    const dateFormatList = ["DD MMM YYYY"]
    const validateMessages = {
        required: "Required.",
        string: {
            min: "This field must be at least ${min} characters.",
        },
    }
    const [totalCertification, setTotalCertification] = useState(0)
    const [expiredCertification, setExpiredCertification] = useState(0)
    const [accountDetails, setAccountDetails] = useState(-1)
    const [profilePic, setProfilePic] = useState("")
    const [displayAccountDetails, setDisplayAccountDetails] = useState({})
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    })
    const [display, setDisplay] = useState([])
    const [visibilityUpdateCertification, setVisibilityUpdateCertification] = useState(false)
    const [editCertification, setEditCertification] = useState({}) // current edit modal does not display any certification details
    const [visibilityAddCertification, setVisibilityAddCertification] = useState(false)
    const [addCertification, setAddCertification] = useState({})
    const [visibilityChangePassword, setVisibilityChangePassword] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)

    const changePassword = async (values) => {
        await form.validateFields()
        setLoadingModal(true)
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
            const result = await Auth.changePassword(user, values.password, values.newPassword)
            // console.log(result);
            handleModalVisibility("changePassword")
            form.resetFields()
            notification.success({
                message: "Password has been updated successfully"
            })
        }
        catch (error) {
            console.log("error : ", error);
            if (error.code === "NotAuthorizedException") {
                notification.error({
                    message: "Incorrect current password"
                })
            }
            else {
                notification.error({
                    message: error.message
                })
            }
        }
        finally {
            setLoadingModal(false)
        }
    }

    const getAccountDetails = async () => {
        const user = JSON.parse(localStorage.getItem("ircs_cognitouser"))
        // console.log("user", user);
        try {
            let variables = {
                filter: {
                    accountID: {
                        eq: user.username
                    }
                }
            }
            const result = await API.graphql({
                query: listStaff,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("result", result);
            const data = result.data.result.result[0]

            let _staff = data;

            // let imageKey = _staff.profilePictureKey ?? ""
            // if (imageKey !== "") {
            //     _staff.profilePic = await Storage.get(imageKey)
            // }
            // else {
            //     _staff.profilePic = ""
            // }

            setAccountDetails(_staff)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve account details"
            })
        }
    }

    const getCertificationList = async (props) => {
        const user = JSON.parse(localStorage.getItem("ircs_cognitouser"))
        // console.log("user", user);
        setTable({
            ...table,
            loading: true,
        })
        try {
            let filter = {
                deleted: { eq: 0 },
                accountID: { eq: user.username }
            }

            let filter2 = {
                deleted: { eq: 0 },
                accountID: { eq: user.username },
                status: {
                    contains: "EXPIRED"
                }
            }

            const staffStatusDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "name ASC"
                },
                filter: filter,
                filter2: filter2
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                // query: listStaffStatus,
                query: listStaffStatusWithMaintenanceStatusCount,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);
            const data = result.data.result
            // console.log("data", data);
            const data2 = result.data.result2
            setTotalCertification(data.count)
            setExpiredCertification(data2.count)
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve certifcation records"
            })
            setTable({
                ...table,
                loading: false
            })
        }
    }

    const createStaffStatusRecord = async (values) => {
        setLoadingModal(true)
        // console.log("values", values);
        try {
            const user = JSON.parse(localStorage.getItem("ircs_cognitouser"))
            const current = moment()
            let expiryStatus = "ACTIVE"
            if (values.expiryDate < current) {
                expiryStatus = "EXPIRED"
            }
            const staffStatusDetails = {
                create: {
                    accountID: user.username,
                    name: values.certificationName,
                    status: expiryStatus,
                    expiry: values.expiryDate ? moment(values.expiryDate).toISOString().split(".")[0] : null
                }
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                query: createStaffStatus,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Created successfully"
            })
            getCertificationList(table.pagination)
            formAdd.resetFields()
            handleModalVisibility("addCertification")
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to create record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const updateStaffStatusRecord = async (values, id) => {
        setLoadingModal(true)
        try {
            const current = moment()
            let expiryStatus = "ACTIVE"
            if (values.expiryDate < current) {
                expiryStatus = "EXPIRED"
            }
            const staffStatusDetails = {
                update: {
                    id: id,
                    status: expiryStatus,
                    expiry: values.expiryDate ? moment(values.expiryDate).toISOString().split(".")[0] : null
                }
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                query: updateStaffStatus,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            notification.success({
                message: "Updated successfully"
            })
            getCertificationList(table.pagination)
            handleModalVisibility("updateCertification")
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const deleteStaffStatusRecord = async (id) => {
        setLoadingModal(true)
        try {
            const staffStatusDetails = {
                update: {
                    id: id,
                    deleted: 1
                }
            }
            // console.log("staffStatusDetails", staffStatusDetails);
            const result = await API.graphql({
                query: updateStaffStatus,
                variables: staffStatusDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            notification.success({
                message: "Deleted successfully"
            })
            getCertificationList(table.pagination)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to delete record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const getProfilePicture = async (key) => {
        setProfilePic(await Storage.get(key))
    }

    useEffect(() => {
        getAccountDetails()
        getCertificationList(table.pagination)
        return () => { }
    }, [])

    useEffect(() => {
        if (accountDetails !== -1) {
            let imageKey = accountDetails.profilePictureKey ?? ""
            if (imageKey !== "") {
                getProfilePicture(imageKey)
            }
            setDisplayAccountDetails(accountDetails)
        }
    }, [accountDetails])

    useEffect(() => {
        if (table.data !== -1) {
            setDisplay(table.data)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data])

    const handleDelete = async (id) => {
        // console.log("handle delete", id);
        deleteStaffStatusRecord(id)
        // setTable({
        //     ...table,
        //     loading: true
        // })
        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         loading: false
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        switch (modal) {
            case "addCertification":
                setVisibilityAddCertification(!visibilityAddCertification)
                break
            case "updateCertification":
                setEditCertification(record ? record : {})
                setVisibilityUpdateCertification(!visibilityUpdateCertification)
                break
            case "changePassword":
                setVisibilityChangePassword(!visibilityChangePassword)
                break
            default:
        }
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    }

    const onFinishAddCertification = async (values) => {
        // console.log("onFinishAddCertification", values);
        await formAdd.validateFields()
        createStaffStatusRecord(values)
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("addCertification")
        // }, 1000)
    }

    const onFinishUpdateCertification = async (values, id) => {
        // console.log("onFinishUpdateCertification", values);
        await formAdd.validateFields()
        updateStaffStatusRecord(values, id)
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateCertification")
        // }, 1000)
    }

    const columns = [
        {
            title: "Certification Name",
            dataIndex: "name"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
        {
            title: "Expiry Date",
            dataIndex: "expiry"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <Space size="large">
                        <div className="table-row-action clickable" onClick={() => handleModalVisibility("updateCertification", record)}>
                            <div className="icon">
                                <img src={iconCalendar} />
                            </div>
                            <div className="text">
                                Update Status
                            </div>
                        </div>
                        <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
                            <div className="table-row-action clickable red">
                                <div className="icon">
                                    <img src={iconMinus} />
                                </div>
                                <div className="text">
                                    Delete
                                </div>
                            </div>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ]

    return (
        <div className="content-content">
            <div className="settingsmanageaccount-header">
                <div className="title">
                    Manage Account
                </div>
            </div>
            <div className="settingsmanageaccount-account">
                <Form
                    form={form}
                    layout="horizontal"
                    requiredMark={false}
                >
                    <div className="row">
                        <div className="container-profilepic">
                            <div>
                                <Avatar className="avatar" icon={<UserOutlined />} shape="square" src={
                                    // profilePic
                                    appState?.profilePicture ?? ''
                                } />
                            </div>
                        </div>
                        <div className="container-accountdetails">
                            <Descriptions
                                title={"My Account Details"}
                                extra={
                                    <div className="column">
                                        {/* <Button>
                                            <Link to={path("settingsChangePassword")}>Change Password</Link>
                                        </Button> */}
                                        <Button type="primary">
                                            <Link to={path("settingsManageAccountEdit")}>Edit</Link>
                                        </Button>
                                        {/* <Button style={{ float: "right" }} onClick={() => handleModalVisibility("changePassword")}>Change Password</Button> */}
                                    </div>
                                }
                            />
                            <div className="container-changepassword" onClick={() => handleModalVisibility("changePassword")}>
                                <div className="icon">
                                    <img src={iconKey} />
                                </div>
                                <div className="text">
                                    Change Password
                                </div>
                            </div>
                            {renderInputItems([
                                {
                                    label: "Full Name",
                                    name: "fullName",
                                    type: "displayText",
                                    value: displayAccountDetails.name,
                                },
                                {
                                    label: "Designation",
                                    name: "designation",
                                    type: "displayText",
                                    value: displayAccountDetails.designation,
                                },
                                // {
                                //     label: "Centre",
                                //     name: "centre",
                                //     type: "displayText",
                                //     value: displayAccountDetails.clinicDisplay,
                                // },
                                {
                                    label: "Mobile Number",
                                    name: "mobileNumber",
                                    type: "displayText",
                                    value: displayAccountDetails.contactNo,
                                },
                                {
                                    label: "Email",
                                    name: "email",
                                    type: "displayText",
                                    value: displayAccountDetails.email,
                                },
                            ])}
                        </div>
                    </div>
                </Form>
            </div>
            <div className="settingsmanageaccount-certification">
                <div className="settingsmanageaccount-certification-header">
                    <div className="column">
                        <div className="title">
                            Certification
                        </div>
                        <Space className="row">
                            <div className="statscard">
                                <div className="column1">
                                    Total<br />Certifications
                                </div>
                                <div className="column2">
                                    {totalCertification}
                                </div>
                            </div>
                            {
                                expiredCertification !== 0 ? (
                                    <div className="statscard red">
                                        <div className="column1">
                                            Expired<br />Certifications
                                        </div>
                                        <div className="column2">
                                            {expiredCertification}
                                        </div>
                                    </div>
                                ) : ""
                            }
                        </Space>
                    </div>
                    <Button type="primary" onClick={() => handleModalVisibility("addCertification")}>
                        Add Certification
                    </Button>
                </div>
                <div className="settingsmanageaccount-certification-content">
                    <Table
                        columns={columns}
                        rowKey={record => record.id}
                        rowClassName={(record, index) => {
                            // console.log("index", index);
                            return index % 2 === 0 ? "highlight" : ""
                        }}
                        loading={table.loading}
                        dataSource={display}
                    // dataSource={[
                    //     {
                    //         id: "1",
                    //         certificationName: "Cert name A",
                    //         status: "Active",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    //     {
                    //         id: "2",
                    //         certificationName: "Cert name B",
                    //         status: "Expired",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    //     {
                    //         id: "3",
                    //         certificationName: "Cert name C",
                    //         status: "Active",
                    //         expiryDate: "2021-04-10 06:00:00",
                    //     },
                    // ]}
                    />
                </div>
                <Modal
                    title={
                        <div className="modal-title">
                            Update Status
                        </div>
                    }
                    visible={visibilityUpdateCertification}
                    onCancel={() => handleModalVisibility("updateCertification")}
                    footer={[
                        <Button key="back" onClick={() => handleModalVisibility("updateCertification")}>
                            Cancel
                        </Button>,
                        <Popconfirm title="Are you sure?" onConfirm={() => onFinishUpdateCertification(formUpdate.getFieldsValue(), editCertification.id)} okText="Yes" cancelText="No">
                            <Button key="submit" type="primary" loading={loadingModal}>
                                Update
                            </Button>
                        </Popconfirm>
                        ,
                    ]}
                    className="modal-manageaccount-updatecertification"
                // width={800}
                >
                    <div className="modal-content">
                        <Spin spinning={loadingModal}>
                            <Form
                                className="updatecertification"
                                form={formUpdate}
                                layout="horizontal"
                                onFinish={onFinishUpdateCertification}
                                validateMessages={validateMessages}
                                requiredMark={false}
                            >
                                <Form.Item
                                    label="New Expiry Date"
                                    name="expiryDate"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                                </Form.Item>
                            </Form>
                        </Spin>
                    </div>
                </Modal>
                <Modal
                    title={
                        <div className="modal-title">
                            Add Certification
                        </div>
                    }
                    visible={visibilityAddCertification}
                    onCancel={() => handleModalVisibility("addCertification")}
                    footer={[
                        <Button key="back" onClick={() => handleModalVisibility("addCertification")}>
                            Cancel
                        </Button>,
                        <Popconfirm title="Are you sure" onConfirm={() => onFinishAddCertification(formAdd.getFieldsValue())} okText="Yes" cancelText="No">
                            <Button key="submit" type="primary" loading={loadingModal}>
                                Add
                            </Button>
                        </Popconfirm>
                        ,
                    ]}
                    className="modal-manageaccount-updatecertification"
                    width={800}
                >
                    <div className="modal-content">
                        <Spin spinning={loadingModal}>
                            <Form
                                className="updatecertification"
                                form={formAdd}
                                layout="horizontal"
                                onFinish={onFinishUpdateCertification}
                                validateMessages={validateMessages}
                                requiredMark={false}
                            >
                                {renderInputItems([
                                    {
                                        label: "Certification Name",
                                        name: "certificationName",
                                        type: "input",
                                        placeholder: "Enter certification name",
                                        required: true,
                                    },
                                    {
                                        label: "Expiry Date",
                                        name: "expiryDate",
                                        type: "datepicker",
                                        required: true,
                                        disabledDate: false,
                                    },
                                ])}
                            </Form>
                        </Spin>
                    </div>
                </Modal>
            </div>
            <Modal
                title={
                    <div className="modal-title">
                        Change Password
                    </div>
                }
                visible={visibilityChangePassword}
                onCancel={() => handleModalVisibility("changePassword")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("changePassword")}>
                        Cancel
                    </Button>,
                    <Button type="primary" onClick={() => { changePassword(form.getFieldsValue()) }}>
                        Change Password
                    </Button>
                ]}
                className="modal-manageaccount-changepassword"
            >
                <Spin spinning={loadingModal}>
                    <Form
                        form={form}
                        layout="vertical"
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="">
                            <div className="container-accountdetails">
                                <Form.Item
                                    label="Current Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    extra={
                                        <Tooltip placement="bottomLeft" title={"Kindly log out of your account and click on \"Forgot password\" at the login page."} trigger={["click"]}>
                                            <div className="hint">
                                                <InfoCircleOutlined /> Forget your password?
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="Current Password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="New Password"
                                    name="newPassword"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 8,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="New Password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Confirm New Password"
                                    name="confirmNewPassword"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("newPassword") === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error("The two passwords that you entered do not match."));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="Confirm New Password"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    )
}

export default SettingsManageAccount