import React, { useContext, useEffect, useReducer, useState } from "react"
import { Button, DatePicker, Form, notification, Progress } from "antd"
import { PrinterOutlined } from '@ant-design/icons'
import { BlobProvider, Page, Text, View, Document, PDFDownloadLink, PDFViewer, StyleSheet, usePDF, pdf } from "@react-pdf/renderer"
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from "@david.kucsai/react-pdf-table"
import JSZip from "jszip"
import { saveAs } from "save-as"

import { AppContext } from "../contexts/AppContext"
import renderInputItems from "../utils/renderFormInputItems"
import moment from "moment"
import API from "@aws-amplify/api"
import { listFunderReport, listPatient } from "../backend/graphql/queries"

const ManageFunderReport = () => {
    const { appState } = useContext(AppContext)
    const reducer = (state, action) => {
        // console.log("action", action);
        // console.log("state", state);
        switch (action.type) {
            case "SET_REPORT":
                // console.log(state', state, action);
                let array = [...state]
                array.push(action.payload)
                // return array;
                // console.log("!!array", array);
                var setObj = new Set(); // create key value pair from array of array
                var result = array.reduce((acc, item) => {
                    // console.log(filtering', acc, item);
                    if (!setObj.has(item.patientId)) {
                        setObj.add(item.patientId, item)
                        acc.push(item)
                    }
                    // console.log("acc", acc);
                    return acc;
                }, [])
                // console.log("!!result", result);
                return result
            case "RESET_REPORT":
                // console.log(state', state, action);
                return []
            default:
        }
    }
    const [zipFolder, dispatch] = useReducer(reducer, [])
    const [numRecords, setNumRecords] = useState(-1) // default -1
    const [enabledZipDownload, setEnabledZipDownload] = useState(false)
    const [formSearch] = Form.useForm()
    const [filter, setFilter] = useState({
        clinic: -1,
        monthYear: null,
    })
    const validateMessages = {
        required: "Required.",
    }
    const [tableDialysis, setTableDialysis] = useState({
        data: -1,
        // pagination: {
        //     current: 1,
        //     pageSize: 20,
        //     offset: 0
        // },
        loading: false,
    })
    const [tablePatient, setTablePatient] = useState({
        data: -1,
        // pagination: {
        //     current: 1,
        //     pageSize: 20,
        //     offset: 0
        // },
        loading: false,
    })
    const [displayFunderReport, setDisplayFunderReport] = useState(-1)

    const styles = StyleSheet.create({
        page: {
            fontSize: "12px",
            padding: "16px",
        },
        row: {
            display: "flex",
            flexDirection: "row"
        },
        highlight: {
            backgroundColor: "yellow"
        }
    })

    const MyDocument = (data) => {
        // console.log(data)
        let report = data.funderReport
        return (
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <Text>Clinic: {report?.clinicName}</Text>
                    <View style={styles.row}>
                        <Text>Patient: </Text>
                        <Text style={styles.highlight}>{report?.patientName}</Text>
                        <Text>&nbsp;&nbsp;&nbsp;Last 4 alphanumeric NRIC: </Text>
                        <Text style={styles.highlight}>{report?.patientNRIC}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>Total Sessions: </Text>
                        <Text style={styles.highlight}>{report?.dialysis?.length}</Text>
                    </View>
                    <Table
                        data={report?.dialysis
                            // [
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "12", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "11", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "10", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "9", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "8", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "7", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "6", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "5", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "4", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "3", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "2", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            //     { name: "Mudkip ABC", date: "17 Jul 2022", sess: "1", bsa: "1.7", memb: "Theranova 400", dw: "74", lw: "72.2", prw: "75.9", wg: "3.2", psw: "73.6", prbp: "142", psbp: "134", hr: "72", notes: "3 hrs HD due hopistal Appt", offwt: "1.6", month: "5" },
                            // ]
                        }
                    >
                        <TableHeader fontSize={"9px"} textAlign="center">
                            {/* <TableCell>PATIENT</TableCell>  */}
                            <TableCell style={{ flex: "0 0 55px" }} >DATE</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >SESS</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >MSA</TableCell>
                            <TableCell style={{ flex: "0.3", textAlign: "left", padding: "0 3px" }} >DIALYSER</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >DW</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >LW</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >PrW</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >WG</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >PsW</TableCell>
                            <TableCell style={{ flex: "0 0 40px" }} >PrBP</TableCell>
                            <TableCell style={{ flex: "0 0 40px" }} >PsBP</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >HR</TableCell>
                            <TableCell style={{ flex: "1", textAlign: "left", padding: "0 3px" }} >NOTES</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >OFFWT</TableCell>
                            <TableCell style={{ flex: "0 0 35px" }} >MONTH</TableCell>
                        </TableHeader>
                        <TableBody textAlign={"center"} fontSize={"9px"}>
                            {/* <DataTableCell getContent={(r) => r.name} /> */}
                            <DataTableCell style={{ flex: "0 0 55px" }} getContent={(r) => moment.utc(r.startTime).local().format('DD MMM YYYY')} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.sessionMonth} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.MSA} />
                            <DataTableCell style={{ flex: "0.3", textAlign: "left", padding: "0 3px" }} getContent={(r) => r.dialyser} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.dryWeight} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.lastWeight} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.preWeight} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.ufTargetStart} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.postWeight} />
                            <DataTableCell style={{ flex: "0 0 40px" }} getContent={(r) => `${r.preSBP}/${r.preDBP}`} />
                            <DataTableCell style={{ flex: "0 0 40px" }} getContent={(r) => `${r.postSBP}/${r.postDBP}`} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => `${r.preHRT}/${r.postHRT}`} />
                            <DataTableCell style={{ flex: "1", textAlign: "left", padding: "0 3px" }} getContent={(r) => r.notes} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => r.offTarget} />
                            <DataTableCell style={{ flex: "0 0 35px" }} getContent={(r) => moment.utc(r.startTime).local().format("M")} />
                        </TableBody>
                    </Table>
                </Page>
            </Document>
        )
    }

    // const [instance, updateInstance] = usePDF({ document: MyDocument });

    // useEffect(() => {
    //     if (instance.blob !== null) {

    //         console.log("instance", instance);
    //     }
    // }, [instance])

    useEffect(() => {
        // dispatch({
        //     type: "SET_REPORT", payload: {
        //         blob: "hello",
        //         fileName: "Funder Report - Centre - Patient A.pdf"
        //     }
        // })
    }, [])

    useEffect(() => {
        // console.log(numRecords", numRecords, zipFolder?.length, zipFolder)
        if (numRecords !== -1 && numRecords === zipFolder?.length) {
            setEnabledZipDownload(true)
        }
    }, [numRecords, zipFolder])

    useEffect(() => {
        if (tableDialysis.data !== -1) {

            let patientIDs = [... new Set(tableDialysis.data.map(s => s.patientID))];
            if (patientIDs.length) {
                // console.log(atientIDs)
                getPatient(patientIDs);
                setNumRecords(patientIDs.length);
            }
        }
    }, [tableDialysis.data])

    useEffect(() => {
        if (tableDialysis.data != -1 && tablePatient.data != -1) {
            let funderReports = []
            for (let p of tablePatient.data) {
                let dialysis = tableDialysis.data.filter(s => s.patientID == p.id)


                for (let d of dialysis) {
                    d.lastWeight = d.lastWeight != null && d.lastWeight >= 0 ? (parseInt(d.lastWeight) / 1000.0).toFixed(1) : '';
                    d.preWeight = d.preWeight != null && d.preWeight >= 0 ? (parseInt(d.preWeight) / 1000.0).toFixed(1) : '';
                    d.postWeight = d.postWeight != null && d.postWeight >= 0 ? (parseInt(d.postWeight) / 1000.0).toFixed(1) : '';
                    d.dryWeight = d.dryWeight != null && d.dryWeight >= 0 ? (parseInt(d.dryWeight) / 1000.0).toFixed(1) : '';

                    d.preTemp = d.preTemp != null && d.preTemp >= 0 ? (parseFloat(d.preTemp).toFixed(1)) : '';
                    d.postTemp = d.postTemp != null && d.postTemp >= 0 ? (parseFloat(d.postTemp).toFixed(1)) : '';

                    d.offTarget = d.postWeight && d.dryWeight ? (d.postWeight - d.dryWeight)?.toFixed(1) : '0';

                    d.ufTargetStart = d.ufTargetStart ? Number(d.ufTargetStart)?.toFixed(1) : '';
                }

                funderReports.push({
                    patientID: p.id,
                    patientName: p.name,
                    patientNRIC: p.nric,
                    clinicName: appState.fixedClinicList.find(s => s.id == dialysis[0]?.clinicID)?.name,
                    monthYear: moment.utc(dialysis[0]?.arriveTime).local().format('MMM YYYY'),
                    dialysis
                })
            }
            setDisplayFunderReport(funderReports);
            // console.log('funderReports', funderReports);
        }
    }, [tableDialysis.data, tablePatient.data])

    // useEffect(() => {
    //     if (numRecords === -1 && enabledZipDownload === false, displayFunderReport === -1) {
    //         let values = formSearch.getFieldsValue(['monthYear', 'clinic'])
    //         if (values.monthYear && values.clinic) {
    //             let monthYear = moment.utc(values.monthYear.format('YYYY-MM-01T00:00:00'));
    //             getFunderReport(monthYear, values.clinic)
    //         }
    //     }
    // }, [numRecords, enabledZipDownload, displayFunderReport, filter])

    // useEffect(() => {
    //     console.log("=== zipFolder ===", zipFolder);
    // }, [zipFolder])

    const getFunderReport = async (startDate, clinicID) => {
        // console.log(tartDate, clinicID)
        setTableDialysis({
            ...tableDialysis,
            loading: true,
        })
        try {
            const variables = {
                clinicID: parseInt(clinicID),
                startDate: startDate.local().format('yyyy-MM-01T00:00:00'),
                endDate: moment(startDate).add(1, "months").local().format('yyyy-MM-01T00:00:00')
            }

            // console.log(ManageFunderReport listFunderReport', variables);

            const result = await API.graphql({
                query: listFunderReport,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log(API result ManageFunderReport listFunderReport", result);
            let data = result.data.result

            setTableDialysis({
                ...tableDialysis,
                loading: data.length !== 0 ? true : false,
                data: data,
                // pagination: {
                //     ...tableDialysisHistory.pagination,
                //     current: props.current,
                //     offset: props.offset,
                //     total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                // }
            })
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve funder report"
            })
            setTableDialysis({
                ...tableDialysis,
                loading: false
            })
        }
    }

    const getPatient = async (patientIDs) => {
        setTablePatient({
            ...tablePatient,
            loading: true,
        })
        try {
            const variables = {
                filter: {
                    or: []
                },
                pagination: {
                    limit: patientIDs.length
                }
            }
            patientIDs.forEach(id => variables.filter.or.push({ id: { eq: id } }))

            // console.log('ManageFunderReport listPatient', variables);

            const result = await API.graphql({
                query: listPatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log(API result ManageFunderReport listPatient", result);
            let data = result.data.result.result
            // if (manageDialysis === true) {
            //     data.result = data.result.filter(item => item.endTime !== null && item.endTime !== undefined && item.endTime !== "")
            // }

            setTablePatient({
                ...tablePatient,
                loading: data.length !== 0 ? true : false,
                data: data,
            })
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve patient"
            })
            setTablePatient({
                ...tablePatient,
                loading: false
            })
        }
    }

    const onFinishSearch = () => {
        // console.log(onFinish");
    }

    const onFormChange = (newValue, existingValue) => {
        if (existingValue.monthYear && existingValue.clinic) {
            // console.log("onChange", newValue, existingValue);
            let monthYear = moment.utc(existingValue.monthYear.format('YYYY-MM-01T00:00:00'));
            setFilter({
                monthYear,
                clinic: existingValue.clinic,
            })
            // console.log(onthYear, existingValue)
            getFunderReport(monthYear, existingValue.clinic)
        }
    }

    const onChange = (date, dateString) => {
        // console.log(ate, dateString);
    }

    const handleDownload = async () => {
        // console.log(download files", zipFolder, filter, filter.monthYear.toString());
        const zip = new JSZip()
        for (let i = 0; i < zipFolder.length; i++) {
            zip.file(zipFolder[i].fileName.replace('/', ''), zipFolder[i].blob)
        }
        return zip.generateAsync({ type: "blob" }).then(blob => { saveAs(blob, `Funder Report - ${appState.fixedClinicList?.find(s => s.id == filter.clinic)?.name} - ${filter.monthYear.local().format('MMM YYYY')}.zip`) })
    }

    const handleClear = async () => {
        setNumRecords(-1);
        setEnabledZipDownload(false);
        setDisplayFunderReport(-1);
        dispatch({
            type: "RESET_REPORT", payload: []
        });
        setFilter({
            monthYear: null,
            clinic: -1,
        });
        setTableDialysis({ data: -1, loading: false })
        setTablePatient({ data: -1, loading: false })
        formSearch.resetFields();
    }

    // const renderBlobProvider = () => {
    //     let blobProviders = [];
    //     // for (let i of displayFunderReport) {
    //     blobProviders.push(
    //         <BlobProvider document={<MyDocument />} fileName="Funder Report - Centre - Jan 2022 - Patient A.pdf">
    //             {({ blob, url, loading, error }) => {
    //                 // console.log("blob 1", blob);
    //                 // console.log("working blob", pdf(<MyDocument />).toBlob());
    //                 if (blob !== null) {
    //                     // console.log("zipFolder", zipFolder);
    //                     let patientId = "1"
    //                     let dispatchAction = true
    //                     for (let i = 0; i < zipFolder.length; i++) {
    //                         if (zipFolder[i].patientId === patientId) {
    //                             dispatchAction = false
    //                             break
    //                         }
    //                     }
    //                     // console.log("dispatchAction", dispatchAction);
    //                     if (dispatchAction === true) {
    //                         // console.log("dispatch for blob 1");
    //                         dispatch({
    //                             type: "SET_REPORT", payload: {
    //                                 blob: blob,
    //                                 fileName: "Funder Report - Centre - Jan 2022 - Patient A.pdf",
    //                                 patientId,
    //                             }
    //                         })
    //                     }
    //                 }
    //                 return null
    //                 // return (
    //                 //     <Button type="primary" loading={loading} disabled={loading}><PrinterOutlined />Print - Funder Report</Button>
    //                 // )
    //             }
    //             }
    //         </BlobProvider>
    //     )
    //     // <BlobProvider document={<MyDocument />} fileName="Funder Report - Centre - Jan 2022 - Patient B.pdf">
    //     //     {({ blob, url, loading, error }) => {
    //     //         // console.log("blob 2", blob);
    //     //         if (blob !== null) {
    //     //             // console.log("zipFolder", zipFolder);
    //     //             let patientId = "2"
    //     //             let dispatchAction = true
    //     //             for (let i = 0; i < zipFolder.length; i++) {
    //     //                 if (zipFolder[i].patientId === patientId) {
    //     //                     dispatchAction = false
    //     //                     break
    //     //                 }
    //     //             }
    //     //             // console.log("dispatchAction", dispatchAction);
    //     //             if (dispatchAction === true) {
    //     //                 // console.log("dispatch for blob 2");
    //     //                 dispatch({
    //     //                     type: "SET_REPORT", payload: {
    //     //                         blob: blob,
    //     //                         fileName: "Funder Report - Centre - Jan 2022 - Patient B.pdf",
    //     //                         patientId: "2"
    //     //                     }
    //     //                 })
    //     //             }
    //     //         }
    //     //         return null
    //     //         // return (
    //     //         //     <Button type="primary" loading={loading} disabled={loading}><PrinterOutlined />Print - Funder Report</Button>
    //     //         // )
    //     //     }
    //     //     }
    //     // </BlobProvider>
    //     // }
    //     return (<div>
    //         {blobProviders}
    //     </div>)
    // }

    // const renderPDFViewer = () => {
    //     if (displayFunderReport?.length > 0) {
    //         let PDFViewer = [];
    //         for (let i of displayFunderReport) {
    //             PDFViewer.push(
    //                 <PDFViewer width="1000" height="600">
    //                     <MyDocument funderReport={i} />
    //                 </PDFViewer>
    //                 // <PDFViewer width="1000" height="600">
    //                 //     <MyDocument />
    //                 // </PDFViewer>
    //             )
    //         }

    //         return <div>
    //             {PDFViewer}
    //         </div>
    //     } else {
    //         <></>
    //     }
    // }

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="row1">
                    <div className="title">
                        Manage Funder Report
                    </div>
                </div>
            </div>
            <div className="row">

                <Form
                    form={formSearch}
                    layout="inline"
                    onFinish={onFinishSearch}
                    onValuesChange={onFormChange}
                    validateMessages={validateMessages}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Month"
                        name="monthYear"
                    >
                        <DatePicker onChange={onChange} picker="month" format={"MMM YYYY"} disabled={filter?.monthYear && filter.clinic > 0} />
                    </Form.Item>
                    {renderInputItems([
                        {
                            label: "Clinic",
                            name: "clinic",
                            type: "select",
                            placeholder: "Select clinic",
                            required: true,
                            optionList: appState.clinicList,
                            width: "250px",
                            disabled: filter?.monthYear && filter.clinic > 0
                        },
                    ])}
                </Form>
                {filter?.monthYear && filter.clinic > 0 ?
                    <Button type="primary" onClick={handleClear}>Clear</Button> : <></>}
            </div>
            <br />
            <div className="column">
                <div>
                    {
                        numRecords !== -1 && zipFolder && numRecords !== zipFolder?.length ? (
                            <Progress percent={(zipFolder?.length / numRecords) * 100} status="active" />
                        ) : ""
                    }
                    {
                        enabledZipDownload === true && numRecords === zipFolder?.length ? (
                            <Button onClick={handleDownload} type="primary" loading={!enabledZipDownload} disabled={!enabledZipDownload}><PrinterOutlined />Print - Funder Report</Button>
                        ) : ""
                    }
                    {/* for loop method to render records */}
                    {/* {renderBlobProvider()} */}
                    {(() => {
                        // console.log(displayFunderReport);
                        if (displayFunderReport?.length > 0) {

                            return displayFunderReport.map((data, i) => <BlobProvider document={<MyDocument funderReport={data} />} fileName="Funder Report - Centre - Jan 2022 - Patient A.pdf">
                                {({ blob, url, loading, error }) => {
                                    // console.log("blob 1", blob);
                                    // console.log("working blob", pdf(<MyDocument />).toBlob());
                                    if (blob !== null) {
                                        // console.log("zipFolder", zipFolder);
                                        let patientId = data.patientID
                                        // let dispatchAction = true
                                        // for (let i = 0; i < zipFolder?.length; i++) {
                                        //     if (zipFolder[i].patientId === patientId) {
                                        //         console.log('skip')
                                        //         dispatchAction = false
                                        //         break
                                        //     }
                                        // }
                                        let dispatchAction = zipFolder?.find(s => s.patientId === patientId) ? false : true;

                                        // console.log("dispatchAction", dispatchAction);
                                        if (dispatchAction === true) {
                                            // console.log("dispatch for blob 1");
                                            dispatch({
                                                type: "SET_REPORT", payload: {
                                                    blob: blob,
                                                    fileName: `${data.patientName} - ${data.monthYear} - Funder Report - ${data.clinicName}.pdf`,
                                                    patientId,
                                                }
                                            })
                                        }
                                    }
                                    return null
                                    // return (
                                    //     <Button type="primary" loading={loading} disabled={loading}><PrinterOutlined />Print - Funder Report</Button>
                                    // )
                                }
                                }
                            </BlobProvider>)
                        } else return <div></div>
                    })()}
                    {/* <BlobProvider document={<MyDocument />} fileName="Funder Report - Centre - Jan 2022 - Patient A.pdf">
                        {({ blob, url, loading, error }) => {
                            // console.log("blob 1", blob);
                            // console.log("working blob", pdf(<MyDocument />).toBlob());
                            if (blob !== null) {
                                // console.log("zipFolder", zipFolder);
                                let patientId = "1"
                                let dispatchAction = true
                                for (let i = 0; i < zipFolder.length; i++) {
                                    if (zipFolder[i].patientId === patientId) {
                                        dispatchAction = false
                                        break
                                    }
                                }
                                // console.log("dispatchAction", dispatchAction);
                                if (dispatchAction === true) {
                                    // console.log("dispatch for blob 1");
                                    dispatch({
                                        type: "SET_REPORT", payload: {
                                            blob: blob,
                                            fileName: "Funder Report - Centre - Jan 2022 - Patient A.pdf",
                                            patientId: "1"
                                        }
                                    })
                                }
                            }
                            return null
                            // return (
                            //     <Button type="primary" loading={loading} disabled={loading}><PrinterOutlined />Print - Funder Report</Button>
                            // )
                        }
                        }
                    </BlobProvider> */}
                </div>
                <br />
                {/* for loop method to render records */}
                {/* {renderPDFViewer()} */}
                {(() => {
                    // console.log(displayFunderReport);
                    if (displayFunderReport?.length > 0) {
                        return displayFunderReport.map((data, i) => <PDFViewer width="1000" height="600">
                            <MyDocument funderReport={data} />
                        </PDFViewer>)
                    } else return <div></div>
                })()}
                {/* <PDFViewer width="1000" height="600">
                    <MyDocument />
                </PDFViewer> */}
                {/* <PDFViewer width="1000" height="600">
                    <MyDocument />
                </PDFViewer> */}
            </div>
        </div>
    )
}

export default ManageFunderReport