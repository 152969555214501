import React, { forwardRef, useContext, useEffect, useRef, useState } from "react"
import { Button, Empty, notification, Spin, Table } from "antd"
import { PrinterOutlined } from '@ant-design/icons'
import { useReactToPrint } from "react-to-print"
import { getLabReportLatestValue, getPatient, listDialysis, listDialysisInterval, listDialysisOrder, listDialysisSummary, listHospitalSummary, listMedicalMilestone, listMedication, listPatient } from "../backend/graphql/queries"
import API from "@aws-amplify/api"
import moment from "moment"
import { AppContext } from "../contexts/AppContext"
import { listPatientAndAllergy } from "../backend/graphql/custom_queries"

const PatientRecordExportPatientSummary = (props) => {
    const { appState } = useContext(AppContext)
    const componentRef = useRef()
    const { patientID, patient, setPatient } = props

    const [tableLastLab, setTableLastLab] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableDiaylsisHistorySummary, setTableDialysisHistorySummary] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableCurrentMedication, setTableCurrentMedication] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 24,
            offset: 0
        },
        loading: false,
    })
    const [tableDialysisHistory, setTableDialysisHistory] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 12,
            offset: 0
        },
        loading: false,
    })
    const [tableDialysisHistoryInterval, setTableDialysisHistoryInterval] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableMedicalMilestone, setTableMedicalMilestone] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false,
    })
    const [tableAllergies, setTableAllergies] = useState({
        data: -1,
    })
    const [displayLastLab, setDisplayLastLab] = useState([])
    const [displayDialysisHistorySummary, setDisplayDialysisHistorySummary] = useState([])
    const [displayCurrentMedication, setDisplayCurrentMedication] = useState([])
    const [displayDialysisHistory, setDisplayDialysisHistory] = useState([])
    const [displayMedicalMilestone, setDisplayMedicalMilestone] = useState([])
    const [displayAllergies, setDisplayAllergies] = useState()

    const [latestClinicalNote, setLatestClinicalNote] = useState(-1)
    const [dialysisOrder, setDialysisOrder] = useState(-1)
    // const [patient, setPatient] = useState(-1)
    const [loadingLatestClinicalNote, setLoadingLatestClinicalNote] = useState(false)
    const [loadingDialysisOrder, setLoadingDialysisOrder] = useState(false)
    const [loadingPatient, setLoadingPatient] = useState(false)
    const [enablePrint, setEnablePrint] = useState(false)
    const [printing, setPrinting] = useState(true);
    const [patientClinic, setPatientClinic] = useState(-1);

    const getPatientRecord = async (id) => {
        setLoadingPatient(true)
        try {
            const variables = {
                filter: {
                    id: { eq: parseInt(id) }
                },
                pagination: {
                    limit: 1
                },
                filter2: {
                    patientID: { eq: parseInt(id) },
                    deleted: { eq: 0 }
                }
            }

            // console.log('PatientRecordExportPatientSummary listPatient', variables);

            const result = await API.graphql({
                query: listPatientAndAllergy,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordExportPatientSummary listPatient", result);
            let data = result.data.result
            const array = result?.data?.result?.result[0]
            const array2 = result?.data?.result2?.result

            if (data) {
                setPatient({
                    ...array ?? {},
                });
                setTableAllergies({
                    data: [...array2] ?? [],
                })
                setLoadingPatient(false);
            }
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve Patient"
            })
            setLoadingPatient(false)
        }
    }

    const getLastLabRecord = async (id) => {
        setTableLastLab({
            ...tableLastLab,
            loading: true,
        })
        try {
            const variables = {
                patientID: parseInt(id)
            }

            // console.log('PatientRecordExportPatientSummary getLabReportLatestValue', variables);

            const result = await API.graphql({
                query: getLabReportLatestValue,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordExportPatientSummary getLabReportLatestValue", result);
            let data = result.data.result ?? {}
            setTableLastLab({
                ...tableLastLab,
                data: [data],
                loading: false,
            });
            // const timer = setTimeout(() => {
            //     setTableLastLab({
            //         ...tableLastLab,
            //         loading: true,
            //         data: [
            //             {
            //                 glucose: "10",
            //                 tw: "10",
            //                 Plt: "10",
            //                 Hb: "10",
            //                 MCV: "10",
            //                 K: "10",
            //                 Na: "10",
            //                 ureaPre: "10",
            //                 ureaPost: "10",
            //                 creatinine: "10",
            //                 HCO3: "10",
            //                 chlorine: "10",
            //                 Ca: "10",
            //                 Po4: "10",
            //                 IPTH: "10",
            //                 HepBsAg: "10",
            //                 HepBsAb: "10",
            //                 _two: "10",
            //                 Fe: "10",
            //                 TIBC: "10",
            //                 ferritin: "10",
            //                 protein: "10",
            //                 Alb: "10",
            //                 Ublood: "10",
            //                 Urbc: "10",
            //                 Uprt: "10",
            //                 Uwbc: "10",
            //                 TUP24: "10",
            //                 TUP12: "10",
            //                 Uvol: "10",
            //                 CCT: "10",
            //                 uric: "10",
            //                 HCV: "10",
            //                 HIV: "10",
            //                 ggt: "10",
            //                 images: "10",
            //                 others: "10",
            //                 othersDetails: "10",
            //                 TC: "10",
            //                 HDL: "10",
            //                 LDL: "10",
            //                 TG: "10",
            //                 Ratio: "10",
            //                 Timing: "10",
            //                 Biltotal: "10",
            //                 SAP: "10",
            //                 SGPT_ALT: "10",
            //                 SGOT_AST: "10",
            //                 Hba1c: "10",
            //                 labsNoted: "10",
            //                 SBP: "10",
            //                 DBP: "10",
            //                 Mg: "10",
            //                 Tac: "10",
            //                 urineMA: "10",
            //                 MACr: "10",
            //                 uCreatinine: "10",
            //                 ESR: "10",
            //                 CRP: "10",
            //                 CNI: "10",
            //                 Myco: "10",
            //                 Pred: "10",
            //                 noted: "10",
            //                 t4: "10",
            //                 FT4: "10",
            //                 TSH: "10",
            //                 gis: "10",
            //                 hte: "10",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve last lab"
            })
            setTableLastLab({
                ...tableLastLab,
                loading: false
            })
        }
    }

    const getDialysisHistorySummaryRecords = async (id) => {
        setTableDialysisHistorySummary({
            ...tableDiaylsisHistorySummary,
            loading: true,
        })
        try {
            const variables = {
                patientID: parseInt(id),
                endDate: moment().toISOString().split('.')[0],
                startDate: moment().add(-1, "months").utc().format('yyyy-MM-01T00:00:00')
            }

            // console.log('PatientRecordExportPatientSummary listDialysisSummary', variables);

            const result = await API.graphql({
                query: listDialysisSummary,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordExportPatientSummary listDialysisSummary", result);
            let data = result.data.result
            // if (manageDialysis === true) {
            //     data.result = data.result.filter(item => item.endTime !== null && item.endTime !== undefined && item.endTime !== "")
            // }

            setTableDialysisHistorySummary({
                ...setTableDialysisHistorySummary,
                // loading: _dialysisHistory.length !== 0 ? true : false,
                // data: _dialysisHistory,
                loading: data.length !== 0 ? true : false,
                data: data ?? [],
                pagination: {
                    ...tableDiaylsisHistorySummary.pagination,
                    current: props.current,
                    offset: props.offset,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
            // const timer = setTimeout(() => {
            //     setTableDialysisHistorySummary({
            //         ...tableDiaylsisHistorySummary,
            //         loading: true,
            //         data: [
            //             {
            //                 id: 4,
            //                 month: "4",
            //                 total: "10",
            //                 dw: "10",
            //                 wtLast: "10",
            //                 wtPre: "10",
            //                 wtPost: "10",
            //                 ufTarget: "10",
            //                 wtOffTarget: "10",
            //                 sbpPost: "10",
            //                 dbpPost: "10",
            //                 ktv: "10",
            //             },
            //             {
            //                 id: 3,
            //                 month: "3",
            //                 total: "10",
            //                 dw: "10",
            //                 wtLast: "10",
            //                 wtPre: "10",
            //                 wtPost: "10",
            //                 ufTarget: "10",
            //                 wtOffTarget: "10",
            //                 sbpPost: "10",
            //                 dbpPost: "10",
            //                 ktv: "10",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve dialysis history summary"
            })
            setTableDialysisHistorySummary({
                ...tableDiaylsisHistorySummary,
                loading: false
            })
        }
    }

    const getCurrentMedicationRecords = async (id, props = tableCurrentMedication.pagination) => {
        setTableCurrentMedication({
            ...tableCurrentMedication,
            loading: true,
        })
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: `(status = 'ACTIVE') ASC, createdOn DESC`
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    },
                    end: {
                        attributeExists: false,
                    }
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedication,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordExportPatientSummary listMedication", result);

            let data = result.data.result;
            setTableCurrentMedication({
                ...tableCurrentMedication,
                data: data.result,
            })

            // const timer = setTimeout(() => {
            //     setTableCurrentMedication({
            //         ...tableCurrentMedication,
            //         loading: true,
            //         data: [
            //             {
            //                 dateStart: "DD MMM YY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //             {
            //                 dateStart: "DD MMM YY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //             {
            //                 dateStart: "DD MMM YY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //             {
            //                 dateStart: "DD MMM YY",
            //                 medication: "Medication ABC 10mg",
            //                 route: "po",
            //                 freq: "3x/wk",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            })
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            })
        }
    }

    const getDiaysisHistoryRecords = async (id, props = tableDialysisHistory.pagination) => {
        setTableDialysisHistory({
            ...tableDialysisHistory,
            loading: true,
        })
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: 'startTime DESC'
                }
            }

            if (patientID > 0) {
                variables.filter = {
                    patientID: { eq: parseInt(id) },
                    // endedBy: {attributeExists: false} // not working
                }
            }

            // if (appState.selectedClinicID > 0) {
            // if (appState.selectedClinicID !== "") {
            //     variables.filter = {
            //         ...variables.filter,
            //         clinicID: {
            //             eq: appState.selectedClinicID
            //         }
            //     }
            // }

            // console.log('PatientRecordExportPatientSummary', variables);

            const result = await API.graphql({
                query: listDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordExportPatientSummary listDialysis", result);
            let data = result.data.result
            // if (manageDialysis === true) {
            //     data.result = data.result.filter(item => item.endTime !== null && item.endTime !== undefined && item.endTime !== "")
            // }

            setTableDialysisHistory({
                ...tableDialysisHistory,
                // loading: _dialysisHistory.length !== 0 ? true : false,
                // data: _dialysisHistory,
                loading: data.result.length !== 0 ? true : false,
                data: data.result,
                pagination: {
                    ...tableDialysisHistory.pagination,
                    current: props.current,
                    offset: props.offset,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
            // const timer = setTimeout(() => {
            //     setTableDialysisHistory({
            //         ...tableDialysisHistory,
            //         loading: true,
            //         data: [
            //             {
            //                 id: 3,
            //                 timestampDisplay: "DD MMM YY",
            //                 sessionMonth: "3",
            //                 QB: "20",
            //                 AP: "20",
            //                 VP: "20",
            //                 DFLOW: "20",
            //                 CDT: "20",
            //                 lastWeight: "20",
            //                 preEdema: "20",
            //                 postEdema: "20",
            //                 preWeight: "20",
            //                 dryWeight: "20",
            //                 ufTargetStart: "20",
            //                 postWeight: "20",
            //                 offTarget: "20",
            //                 postSBPDBP: "20",
            //                 SBP: "20",
            //                 DBP: "20",
            //                 HR: "20",
            //                 temp: "20",
            //                 preTemp: "20",
            //                 postTemp: "20",
            //                 MSA: "20",
            //                 dialyser: "20",
            //                 KTVonline: "20",
            //                 notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
            //                 displaySymptoms: "20",
            //             },
            //             {
            //                 id: 2,
            //                 timestampDisplay: "DD MMM YY",
            //                 sessionMonth: "2",
            //                 QB: "20",
            //                 AP: "20",
            //                 VP: "20",
            //                 DFLOW: "20",
            //                 CDT: "20",
            //                 lastWeight: "20",
            //                 preEdema: "20",
            //                 postEdema: "20",
            //                 preWeight: "20",
            //                 dryWeight: "20",
            //                 ufTargetStart: "20",
            //                 postWeight: "20",
            //                 offTarget: "20",
            //                 postSBPDBP: "20",
            //                 SBP: "20",
            //                 DBP: "20",
            //                 HR: "20",
            //                 temp: "20",
            //                 preTemp: "20",
            //                 postTemp: "20",
            //                 MSA: "20",
            //                 dialyser: "20",
            //                 KTVonline: "20",
            //                 notes: "20",
            //                 displaySymptoms: "20",
            //             },
            //             {
            //                 id: 1,
            //                 timestampDisplay: "DD MMM YY",
            //                 sessionMonth: "1",
            //                 QB: "20",
            //                 AP: "20",
            //                 VP: "20",
            //                 DFLOW: "20",
            //                 CDT: "20",
            //                 lastWeight: "20",
            //                 preEdema: "20",
            //                 postEdema: "20",
            //                 preWeight: "20",
            //                 dryWeight: "20",
            //                 ufTargetStart: "20",
            //                 postWeight: "20",
            //                 offTarget: "20",
            //                 postSBPDBP: "20",
            //                 SBP: "20",
            //                 DBP: "20",
            //                 HR: "20",
            //                 temp: "20",
            //                 preTemp: "20",
            //                 postTemp: "20",
            //                 MSA: "20",
            //                 dialyser: "20",
            //                 KTVonline: "20",
            //                 notes: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
            //                 displaySymptoms: "20",
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            })
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            })
        }
    }

    const getDiaysisHistoryIntervalRecords = async (dialysisIDs) => {
        setTableDialysisHistory({
            ...tableDialysisHistory,
            loading: true,
        })
        try {
            const variables = {
                pagination: {
                    // limit: props.pageSize,
                    // offset: props.offset,
                    orderby: "timestamp DESC"
                }
            }

            variables.filter = {
                ...variables.filter,
                or: [],
                deleted: { eq: 0 },
            };
            dialysisIDs.forEach(id => variables.filter.or.push({ dialysisID: { eq: id } }))

            // console.log('listDialysisInterval', variables);

            const result = await API.graphql({
                query: listDialysisInterval,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordExportPatientSummary listDialysisInterval", result);
            let data = result.data.result
            // if (manageDialysis === true) {
            //     data.result = data.result.filter(item => item.endTime !== null && item.endTime !== undefined && item.endTime !== "")
            // }

            setTableDialysisHistoryInterval({
                ...tableDialysisHistoryInterval,
                data: data.result,
                loading: data.result.length !== 0 ? true : false,
                dialysisIDs: dialysisIDs,
            })
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve current medication"
            })
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            })
        }
    }

    const getLatestClinicalNoteRecord = async (id) => {
        setLoadingLatestClinicalNote(true)
        try {
            const variables = {
                pagination: {
                    limit: 1,
                    offset: 0,
                    orderby: `admitTimestamp DESC`
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    },
                    or: [
                        {
                            type: {
                                eq: "CLINIC"
                            }
                        },
                        {
                            type: {
                                eq: "EVENT"
                            }
                        }
                    ]
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listHospitalSummary,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log(result);

            const data = result.data.result
            const array = result.data.result.result
            const clinicalNote = array[0] ?? {};

            setLatestClinicalNote(clinicalNote);

            // const timer = setTimeout(() => {
            //     setLatestClinicalNote("This is the latest clinical note. Blah blah blah, 123, abc, hahahaha.")
            //     clearTimeout(timer)
            // }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve latest clinical note"
            })
            setLoadingLatestClinicalNote(false)
        }
    }

    const getDialysisOrderRecord = async (id) => {
        setLoadingDialysisOrder(true)
        try {
            const variables = {
                pagination: {
                    limit: 1,
                    orderby: 'dOrder DESC',
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    }
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listDialysisOrder,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordDialysisOrder", result);

            const data = result.data.result
            const array = result.data.result.result

            if (array) {
                const v = array[0] ?? {};

                // process data
                setDialysisOrder({
                    id: v.id,
                    msa: v.MSA,
                    dorderDate: v.dOrder ? moment(v.dOrder).format('DD MMM YY') : '',
                    freqmth: v.freqMth,
                    duration: v.duration,
                    hepload: v.hepLoad,
                    hepcont: v.hepCont,
                    antioOthers: v.antio,
                    dialysate: v.dialysate,
                    primaryAccess: v.accessPrim,
                    aNeedle: v.aNeedle,
                    vNeedle: v.vNeedle,
                    bicarbonate: v.bicarbonate,
                    temp: v.temp,
                    qd: v.QD,
                    qb: v.QB,
                    dw: v.dryWeight ? (v.dryWeight / 1000.0)?.toFixed(1) : '',
                    notes: v.notes
                });
            }
            // const timer = setTimeout(() => {
            //     setDialysisOrder({
            //         msa: "2.1",
            //         dorderDate: "21 Apr 2021",
            //         freqmth: "12",
            //         duration: "240",
            //         hepload: "2000",
            //         hepcont: "1000",
            //         antioOthers: "n/a",
            //         dialysate: "LC",
            //         primaryAccess: "AVF LEF",
            //         aNeedle: "16",
            //         vNeedle: "+2",
            //         bicarbonate: "+2",
            //         temp: "35",
            //         qd: "500",
            //         qb: "3",
            //         dw: "16",
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve dilysis order"
            })
            setLoadingDialysisOrder(false)
        }
    }

    const getMedicalMilestoneRecords = async (id, pagination = tableMedicalMilestone.pagination) => {
        setTableMedicalMilestone({
            ...tableMedicalMilestone,
            loading: true,
        })
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `timestamp DESC`
                },
                filter: {
                    patientID: {
                        eq: parseInt(id)
                    }
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listMedicalMilestone,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordMedicalMilestone", result);

            const data = result.data.result
            const array = result.data.result.result

            // process data
            let _medicalMilestone = [];
            for (let v of array) {
                _medicalMilestone.push({
                    id: v.id,
                    patientID: v.patientID,
                    date: v?.timestamp,
                    milestone: v.milestone,
                    note: v.note,
                })
            }

            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                data: _medicalMilestone,
                loading: false,
            })
            // const timer = setTimeout(() => {
            //     setTableMedicalMilestone({
            //         ...tableMedicalMilestone,
            //         loading: true,
            //         data: [
            //             {
            //                 id: "6",
            //                 date: "DD MMM YY",
            //                 milestone: "This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789. This is a milestone 6 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 6."
            //             },
            //             {
            //                 id: "5",
            //                 date: "DD MMM YY",
            //                 milestone: "This is a milestone 5 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 5. This is some additional notes for this milestone 5. This is some additional notes for this milestone 5. This is some additional notes for this milestone 5."
            //             },
            //             {
            //                 id: "4",
            //                 date: "DD MMM YY",
            //                 milestone: "This is a milestone 4 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 4."
            //             },
            //             {
            //                 id: "3",
            //                 date: "DD MMM YY",
            //                 milestone: "This is a milestone 3 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 3."
            //             },
            //             {
            //                 id: "2",
            //                 date: "DD MMM YY",
            //                 milestone: "This is a milestone 2 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 2."
            //             },
            //             {
            //                 id: "1",
            //                 date: "DD MMM YY",
            //                 milestone: "This is a milestone 1 abc 123 xyz 789.",
            //                 note: "This is some additional notes for this milestone 1."
            //             },
            //         ]
            //     })
            //     clearTimeout(timer)
            // }, 1000)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to retrieve medical milestones"
            })
            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                loading: false
            })
        }
    }

    // const columnsLastLabPart1 = [
    //     {
    //         title: "Hb",
    //         dataIndex: "Hb",
    //         render(text, record) {
    //             let outOfRange = parseFloat(text) <= 10;
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         }
    //     },
    //     {
    //         title: "MCV",
    //         dataIndex: "MCV"
    //     },
    //     {
    //         title: "TW",
    //         dataIndex: "tw"
    //     },
    //     {
    //         title: "Plt",
    //         dataIndex: "Plt"
    //     },
    //     {
    //         title: "K",
    //         dataIndex: "K",
    //         render(text, record) {
    //             let outOfRange = parseFloat(text) >= 5.5;
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         }
    //     },
    //     {
    //         title: "HCO3",
    //         dataIndex: "HCO3"
    //     },
    //     {
    //         title: "Na",
    //         dataIndex: "Na"
    //     },
    //     {
    //         title: "Cl",
    //         dataIndex: "chlorine"
    //     },
    //     {
    //         title: "Creatinine",
    //         dataIndex: "creatinine"
    //     },
    //     {
    //         title: "Glucose",
    //         dataIndex: "glucose"
    //     },
    //     {
    //         title: "CCT",
    //         dataIndex: "CCT"
    //     },
    //     {
    //         title: "Ca",
    //         dataIndex: "Ca",
    //         render(text, record) {
    //             let outOfRange = parseFloat(text) <= 2;
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         }
    //     },
    //     {
    //         title: "Po4",
    //         dataIndex: "Po4",
    //         render(text, record) {
    //             let outOfRange = parseFloat(text) >= 1.7;
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         }
    //     },
    //     {
    //         title: "iPTH",
    //         dataIndex: "IPTH"
    //     },
    //     {
    //         title: "Urea Pre",
    //         dataIndex: "ureaPre"
    //     },
    //     {
    //         title: "Urea Post",
    //         dataIndex: "ureaPost"
    //     },
    //     {
    //         title: "HepBsAg",
    //         dataIndex: "HepBsAg",
    //         render(text, record) {
    //             let outOfRange = text?.toLowerCase()?.includes('pos');
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         },
    //     },
    //     {
    //         title: "HepBsAb",
    //         dataIndex: "HepBsAb"
    //     },
    //     {
    //         title: "HCV",
    //         dataIndex: "HCV",
    //         render(text, record) {
    //             let outOfRange = text?.toLowerCase()?.includes('pos');
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         },
    //     },
    //     {
    //         title: "HIV",
    //         dataIndex: "HIV",
    //         render(text, record) {
    //             let outOfRange = text?.toLowerCase()?.includes('pos');
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         },
    //     },
    //     {
    //         title: "Fe",
    //         dataIndex: "Fe"
    //     },
    //     {
    //         title: "TIBC",
    //         dataIndex: "TIBC"
    //     },
    //     {
    //         title: "Ferritin",
    //         dataIndex: "ferritin",
    //         render(text, record) {
    //             let outOfRange = parseFloat(text) >= 700;
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         }
    //     },
    //     {
    //         title: "ESR",
    //         dataIndex: "ESR"
    //     },
    //     {
    //         title: "CRP",
    //         dataIndex: "CRP"
    //     },
    //     {
    //         title: "uricAcid",
    //         dataIndex: "uric"
    //     },
    //     {
    //         title: "TC",
    //         dataIndex: "TC"
    //     },
    //     {
    //         title: "HDL",
    //         dataIndex: "HDL"
    //     },
    //     {
    //         title: "LDL",
    //         dataIndex: "LDL"
    //     },
    //     {
    //         title: "TG",
    //         dataIndex: "TG"
    //     },
    // ]

    // const columnsLastLabPart2 = [
    //     {
    //         title: "Ratio",
    //         dataIndex: "Ratio"
    //     },
    //     {
    //         title: "Hba1c",
    //         dataIndex: "Hba1c",
    //         render(text, record) {
    //             let outOfRange = parseFloat(text) >= 7;
    //             return {
    //                 props: {
    //                     style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
    //                 },
    //                 children: text
    //             };
    //         }
    //     },
    //     {
    //         title: "Protein",
    //         dataIndex: "protein"
    //     },
    //     {
    //         title: "Alb",
    //         dataIndex: "Alb"
    //     },
    //     {
    //         title: "Biltotal",
    //         dataIndex: "Biltotal"
    //     },
    //     {
    //         title: "Sap",
    //         dataIndex: "SAP"
    //     },
    //     {
    //         title: "Ast",
    //         dataIndex: "SGOT_AST"
    //     },
    //     {
    //         title: "Alt",
    //         dataIndex: "SGPT_ALT"
    //     },
    //     {
    //         title: "Ggt",
    //         dataIndex: "ggt"
    //     },
    //     {
    //         title: "Mg",
    //         dataIndex: "Mg"
    //     },
    //     {
    //         title: "Gis",
    //         dataIndex: "gis"
    //     },
    //     {
    //         title: "Hte",
    //         dataIndex: "hte"
    //     },
    //     {
    //         title: "Timing",
    //         dataIndex: "Timing"
    //     },
    //     {
    //         title: "TUP 24 Hrs",
    //         dataIndex: "TUP24"
    //     },
    //     {
    //         title: "TUP 12 Hrs",
    //         dataIndex: "TUP12"
    //     },
    //     {
    //         title: "Uvol",
    //         dataIndex: "Uvol"
    //     },
    //     {
    //         title: "Ublood",
    //         dataIndex: "Ublood"
    //     },
    //     {
    //         title: "Urbc",
    //         dataIndex: "Urbc"
    //     },
    //     {
    //         title: "Uprt",
    //         dataIndex: "Uprt"
    //     },
    //     {
    //         title: "Uwbc",
    //         dataIndex: "Uwbc"
    //     },
    //     {
    //         title: "Urine MA",
    //         dataIndex: "urineMA"
    //     },
    //     {
    //         title: "MACr",
    //         dataIndex: "MACr"
    //     },
    //     {
    //         title: "U creatinine",
    //         dataIndex: "uCreatinine"
    //     },
    //     {
    //         title: "Tac",
    //         dataIndex: "Tac"
    //     },
    //     {
    //         title: "CNI",
    //         dataIndex: "CNI"
    //     },
    //     {
    //         title: "Myco",
    //         dataIndex: "Myco"
    //     },
    //     {
    //         title: "Pred",
    //         dataIndex: "Pred"
    //     },
    // ]

    const columnsLastLabPart1 = [
        {
            title: "Hb",
            dataIndex: "Hb",
            render(text, record, index) {
                let outOfRange = Object.keys(record ?? {}).includes('Hb_timestamp') && parseFloat(text) <= 10;
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        {
            title: "MCV",
            dataIndex: "MCV",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "TW",
            dataIndex: "TW",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Plt",
            dataIndex: "Plt",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "K",
            dataIndex: "K",
            render(text, record, index) {
                let outOfRange = Object.keys(record ?? {}).includes('K_timestamp') && parseFloat(text) >= 5.5;
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        {
            title: "HCO3",
            dataIndex: "HCO3",
            render(text, record, index) {
                let outOfRange = Object.keys(record ?? {}).includes('HCO3_timestamp') && parseFloat(text) < 20;
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Na",
            dataIndex: "Na",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Cl",
            dataIndex: "chlorine",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Creatinine",
            dataIndex: "creatinine",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Glucose",
            dataIndex: "glucose",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        // {
        //     title: "CCT",
        //     dataIndex: "CCT",
        //     render(text, record, index) {
        //         if (text !== 'DD MM YY') {
        //             return {
        //                 props: {
        //                     style: { color: "black", textAlign: "center" }
        //                 },
        //                 children: text
        //             }
        //         } else if (index == 1) {
        //             return {
        //                 props: {
        //                     style: { color: "white" }
        //                 },
        //                 children: "DD MMM YY"
        //             }
        //         }
        //     }
        // },
        {
            title: "Ca",
            dataIndex: "Ca",
            render(text, record, index) {
                let outOfRange = Object.keys(record ?? {}).includes('Ca_timestamp') && parseFloat(text) <= 2;
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        {
            title: "Po4",
            dataIndex: "Po4",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    let outOfRange = Object.keys(record ?? {}).includes('Po4_timestamp') && parseFloat(text) >= 1.7;
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        {
            title: "iPTH",
            dataIndex: "IPTH",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    let outOfRange = Object.keys(record ?? {}).includes('IPTH_timestamp') && parseFloat(text) > 80;
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Urea Pre",
            dataIndex: "ureaPre",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Urea Post",
            dataIndex: "ureaPost",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Hep Bs Ag",
            dataIndex: "HepBsAg",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    let outOfRange = Object.keys(record ?? {}).includes('HepBsAg_timestamp') && (
                        text?.toLowerCase()?.includes('pos') ||
                        text?.toLowerCase() === ('weak reactive') ||
                        text?.toLowerCase() === ('reactive')
                    );
                    let outOfDate = !Object.keys(record ?? {}).includes('HepBsAg_timestamp') && moment().isAfter(moment.utc(text).add(moment.duration(3.5, 'months').asDays(), 'days'));
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" },
                            timestampStyle: { color: outOfDate ? "red" : "", fontWeight: outOfDate ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        {
            title: "Hep Bs Ab",
            dataIndex: "HepBsAb",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "HCV",
            dataIndex: "HCV",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    let outOfRange = Object.keys(record ?? {}).includes('HCV_timestamp') && (text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('reactive'));
                    let outOfDate = !Object.keys(record ?? {}).includes('HCV_timestamp') && moment().isAfter(moment.utc(text).add(moment.duration(3.5, 'months').asDays(), 'days'));
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" },
                            timestampStyle: { color: outOfDate ? "red" : "", fontWeight: outOfDate ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        {
            title: "HIV",
            dataIndex: "HIV",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    let outOfRange = Object.keys(record ?? {}).includes('HIV_timestamp') && (text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('reactive'));
                    let outOfDate = !Object.keys(record ?? {}).includes('HIV_timestamp') && moment().isAfter(moment.utc(text).add(moment.duration(5.5, 'months').asDays(), 'days'));
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" },
                            timestampStyle: { color: outOfDate ? "red" : "", fontWeight: outOfDate ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
    ]

    const columnsLastLabPart2 = [
        // {
        //     title: "Hep Bs Ab",
        //     dataIndex: "HepBsAb"
        // },
        {
            title: "Fe",
            dataIndex: "Fe",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "TIBC",
            dataIndex: "TIBC",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Ferritin",
            dataIndex: "ferritin",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    let outOfRange = Object.keys(record ?? {}).includes('ferritin_timestamp') && parseFloat(text) >= 700;
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        // {
        //     title: "ESR",
        //     dataIndex: "ESR",
        //     render(text, record, index) {
        //         if (text !== 'DD MM YY') {
        //             return {
        //                 props: {
        //                     style: { color: "black", textAlign: "center" }
        //                 },
        //                 children: text
        //             }
        //         } else if (index == 1) {
        //             return {
        //                 props: {
        //                     style: { color: "white" }
        //                 },
        //                 children: "DD MM YYYY"
        //             }
        //         }
        //     }
        // },
        {
            title: "CRP",
            dataIndex: "CRP",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "uricAcid",
            dataIndex: "uric",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "TC",
            dataIndex: "TC",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "HDL",
            dataIndex: "HDL",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "LDL",
            dataIndex: "LDL",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "TG",
            dataIndex: "TG",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Ratio",
            dataIndex: "Ratio",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Hba1c",
            dataIndex: "Hba1c",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    let outOfRange = Object.keys(record ?? {}).includes('Hba1c_timestamp') && parseFloat(text) >= 7;
                    return {
                        props: {
                            style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal", textAlign: "center" }
                        },
                        children: text
                    };
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            },
        },
        {
            title: "Protein",
            dataIndex: "protein",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Alb",
            dataIndex: "Alb",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Biltotal",
            dataIndex: "Biltotal",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },

        {
            title: "Sap",
            dataIndex: "SAP",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Ast",
            dataIndex: "SGOT_AST",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Alt",
            dataIndex: "SGPT_ALT",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Ggt",
            dataIndex: "ggt",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
        {
            title: "Mg",
            dataIndex: "Mg",
            render(text, record, index) {
                if (text !== 'DD MM YY') {
                    return {
                        props: {
                            style: { color: "black", textAlign: "center" }
                        },
                        children: text
                    }
                } else if (index == 1) {
                    return {
                        props: {
                            style: { color: "white" }
                        },
                        children: "DD MM YYYY"
                    }
                }
            }
        },
    ]

    const columnsLastLabPart3 = [
        // {
        //     title: "Sap",
        //     dataIndex: "SAP"
        // },
        // {
        //     title: "Ast",
        //     dataIndex: "SGOT_AST"
        // },
        // {
        //     title: "Alt",
        //     dataIndex: "SGPT_ALT"
        // },
        // {
        //     title: "Ggt",
        //     dataIndex: "ggt"
        // },
        // {
        //     title: "Mg",
        //     dataIndex: "Mg"
        // },

        // {
        //     title: "Gis",
        //     dataIndex: "gis"
        // },
        // {
        //     title: "Hte",
        //     dataIndex: "hte"
        // },
        // {
        //     title: "Timing",
        //     dataIndex: "Timing"
        // },
        // {
        //     title: "TUP 24 Hrs",
        //     dataIndex: "TUP24"
        // },
        // {
        //     title: "TUP 12 Hrs",
        //     dataIndex: "TUP12"
        // },
        // {
        //     title: "Uvol",
        //     dataIndex: "Uvol"
        // },
        // {
        //     title: "Ublood",
        //     dataIndex: "Ublood"
        // },
        // {
        //     title: "Urbc",
        //     dataIndex: "Urbc"
        // },
        // {
        //     title: "Uprt",
        //     dataIndex: "Uprt"
        // },
        // {
        //     title: "Uwbc",
        //     dataIndex: "Uwbc"
        // },
        // {
        //     title: "Urine MA",
        //     dataIndex: "urineMA"
        // },
        // {
        //     title: "MACr",
        //     dataIndex: "MACr"
        // },
        // {
        //     title: "U creatinine",
        //     dataIndex: "uCreatinine"
        // },
        // {
        //     title: "Tac",
        //     dataIndex: "Tac"
        // },
        // {
        //     title: "CNI",
        //     dataIndex: "CNI"
        // },
        // {
        //     title: "Myco",
        //     dataIndex: "Myco"
        // },
        // {
        //     title: "Pred",
        //     dataIndex: "Pred"
        // },
    ]

    const columnsDialysisHistorySummary = [
        {
            title: "MONTH",
            dataIndex: "month",
            flex: "0 0 45px",
            // render(text, record) {
            //     return {
            //         props: {
            //             style: { flex: "0 0 100px" }
            //         },
            //         children: text
            //     };
            // }
        },
        {
            title: "TOTAL",
            dataIndex: "total",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "DW",
            dataIndex: "dw",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "WT Last",
            dataIndex: "wtLast",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "WT (Pre)",
            dataIndex: "wtPre",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "WT (Post)",
            dataIndex: "wtPost",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "UF Target",
            dataIndex: "ufTarget",
            flex: "0 0 70px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "WT off Target",
            dataIndex: "wtOffTarget",
            flex: "0 0 80px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "SBP (Post)",
            dataIndex: "sbpPost",
            flex: "1.2",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "DBP (Post)",
            dataIndex: "dbpPost",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "KTV",
            dataIndex: "ktv",
            flex: "0 0 70px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
    ]

    const columnsCurrentMedication = [
        // {
        //     title: "Start Date",
        //     dataIndex: "dateStart",
        // },
        {
            title: "Medication",
            dataIndex: "medication",
            width: '100%',
        },
        // {
        //     title: "Route",
        //     dataIndex: "route"
        // },
        // {
        //     title: "Frequency",
        //     dataIndex: "freq"
        // },
    ]

    const columnsDialysisHistoryPart1 = [
        {
            title: "Date / Time",
            dataIndex: "timestampDisplay",
            // width: 120,
            flex: "0 0 55px",
        },
        {
            title: "SE",
            dataIndex: "sessionMonth",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "Clinic",
            dataIndex: "clinic",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "Duration",
            dataIndex: "duration",
            flex: "0 0 40px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "QB",
            dataIndex: "QB",
            flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "AP",
            dataIndex: "AP",
            flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "VP",
            dataIndex: "VP",
            flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "FLOW",
            dataIndex: "DFLOW",
            flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "COND",
            dataIndex: "CDT",
            flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "LAST WT",
            dataIndex: "lastWeight",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "Pre Edema",
            dataIndex: "preEdema",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "Post Edema",
            dataIndex: "postEdema",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "PRE WT",
            dataIndex: "preWeight",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "DW",
            dataIndex: "dryWeight",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "UFT",
            flex: "0 0 30px",
            dataIndex: "ufTargetStart",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        // ===
        {
            title: "POST WT",
            dataIndex: "postWeight",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "OFF Target",
            dataIndex: "offTarget",
            flex: "0 0 35px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "SBP",
            dataIndex: "SBP",
            flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "DBP",
            dataIndex: "DBP",
            flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
    ]

    const columnsDialysisHistoryPart2 = [
        {
            title: "Date / Time",
            dataIndex: "timestampDisplay",
            // width: 120,
            flex: "0 0 55px",
        },
        {
            // title: "Post SBP / DBP",
            title: "Post BP",
            dataIndex: "postSBPDBP",
            // flex: 1.5,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "HR",
            dataIndex: "HR",
            flex: 0.8,
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "Temp",
            dataIndex: "temp",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        // {
        //     title: "Temp Admit",
        //     dataIndex: "preTemp",
        //     flex: 0.8,
        //     render(text, record, index) {
        //         if (text !== null)
        //             return {
        //                 props: {
        //                     style: { textAlign: "center" }
        //                 },
        //                 children: text
        //             }
        //     },
        // },
        // {
        //     title: "Temp Discharge",
        //     dataIndex: "postTemp",
        //     flex: 0.8,
        //     render(text, record, index) {
        //         if (text !== null)
        //             return {
        //                 props: {
        //                     style: { textAlign: "center" }
        //                 },
        //                 children: text
        //             }
        //     },
        // },
        {
            title: "MSA",
            dataIndex: "MSA",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "Dialyser",
            dataIndex: "dialyser",
        },
        {
            title: "KTV Online",
            dataIndex: "KTVonline",
            flex: "0 0 30px",
            render(text, record, index) {
                if (text !== null)
                    return {
                        props: {
                            style: { textAlign: "center" }
                        },
                        children: text
                    }
            },
        },
        {
            title: "Dialysis Note",
            dataIndex: "notes",
            // width: 450,
            flex: 4,
            render(text, record) {
                return {
                    props: {
                        style: { display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "400px" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Symptoms",
            dataIndex: "displaySymptoms",
            // width: 150,
            flex: 2,
            render(text, record) {
                return {
                    props: {
                        style: { display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }
                    },
                    children: text
                };
            }
        },
    ]

    const renderHeaderItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            // const width = item.width !== undefined ? item.width : null
            const flex = item.flex !== undefined ? item.flex : null

            let style = {};
            if (item?.render) {
                style = item?.render(null, null)?.props?.style
            }

            return (
                // <div key={item.dataIndex} className={item.dataIndex !== "action" ? "column" : ""} style={width !== null ? { width: width } : {}}>
                // <div key={item.dataIndex} className={"column"} style={width !== null ? { width: width, ...style } : { ...style }}>
                <div key={item.dataIndex} className={"column"} style={flex !== null ? { flex: flex, ...style, fontWeight: 700, textAlign: "center" } : { ...style, fontWeight: 700, textAlign: "center" }}>
                    {item.title}
                </div>
            )
        })
        return items
    }

    const renderCardResultHeader = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderHeaderItems(array)
            )
        )
    }

    const renderItems = (columns, array) => {
        if (Object.keys(array).length > 0) {
            const items = array.map((item, arrIndex) => {
                const renderItemColumns = (columns, rowItem) => {
                    // console.log("columns", columns);
                    // console.log("rowItem", rowItem);
                    const renderRowItems = columns.map((columnItem, index) => {
                        // console.log("columns item", columnItem);
                        const width = columnItem.width !== undefined ? columnItem.width : null
                        const flex = columnItem.flex !== undefined ? columnItem.flex : null
                        let value = " "
                        let style = {};
                        for (const key in rowItem) {
                            // console.log("key", key);
                            // console.log("value", rowItem[key]);
                            if (columnItem.dataIndex === key) {
                                value = rowItem[key]
                                if (columnItem?.render) {
                                    let props = columnItem?.render(value, rowItem, arrIndex)?.props
                                    style = arrIndex === 1 && props?.timestampStyle ? props?.timestampStyle : props?.style
                                }
                                break
                            }
                        }
                        return (
                            // <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : (columnItem.dataIndex !== "action" ? "column" : "")} style={width !== null ? { width: width } : {}}>
                            // <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : "column"} style={width !== null ? { width: width, ...style } : { ...style }}>
                            <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : "column"} style={flex !== null ? { flex: flex, ...style } : { ...style }}>
                                {value}&nbsp;
                            </div>
                        )
                    })
                    return renderRowItems
                }

                return (
                    <div key={item.id} className={arrIndex % 2 === 0 ? "record highlight" : "record"}>
                        {
                            renderItemColumns(columns, item)
                        }
                    </div>
                )
            })
            return items
        } else {
            return null
        }
    }

    const renderCardResult = (columns, array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderItems(columns, array)
            )
        )
    }

    const renderDisplayDescriptionItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div className="item">
                    <div className="item-label">{item.label}</div>
                    <div className="item-value">{item.value}</div>
                </div>
            )
        })
        return items
    }

    const renderDisplayDescriptionItems_ul_li = (array) => {
        const items = array.map((item, index) => {
            return (
                <li className="item">
                    <div className="item-label">{item.label}</div>
                    <div className="item-value">{item.value}</div>
                </li>
            )
        })
        return items
    }

    const renderDisplayValueItems = (array) => {
        const items = array.map((item, index) => {
            return (
                <div className="item-medicalmilestone">
                    <div className="milestone">
                        <span>{item.date}: {item.milestone}</span>
                        <span className="note">{item.note ? ' | ' + item.note : ''}</span>
                    </div>
                </div>
            )
        })
        return items
    }

    const ComponentToPrint = forwardRef((props, ref) => {
        // console.log("props", props);
        // console.log("ref", ref);

        return (
            <div ref={ref} className="export-patientsummary">
                <style type="text/css">
                    {"@media print{@page {size: landscape}}"}
                </style>
                {/* <style type="text/css" media="print">{"\
                @page {\ size: landscape;\ }\
                "}</style> */}
                {printing === true && patientClinic !== -1 ?
                    <div style={{ textAlign: "center" }}>{
                        [patientClinic?.name + ' Center', patientClinic?.address, patientClinic?.phoneNo, patientClinic?.email].filter(s => s && s.length > 0).join(' | ')
                    }</div>
                    : <></>
                }
                <div className="row">
                    <Spin spinning={loadingPatient}>
                        <div className="row" style={{ fontWeight: 700 }}>
                            <div className="title">Patient Detail</div>
                        </div>
                        <div className="container-border row">
                            {/* <div className="column" style={{ paddingRight: "10px", borderRight: "1px solid #000000" }}> */}
                            <div className="column" style={{ gap: "0px" }}>
                                {
                                    renderDisplayDescriptionItems([
                                        {
                                            label: "Name",
                                            value: patient.name,
                                        },
                                        {
                                            label: "NRIC",
                                            value: patient.nric,
                                        },
                                        // {
                                        //     label: "iDpt",
                                        //     value: patient.accessIDPt,
                                        // },
                                        // {
                                        //     label: "Shift",
                                        //     value: "",
                                        // },
                                        {
                                            label: "Print Date",
                                            value: moment().format("DD MMM YYYY, HH:mm"),
                                        },
                                        {
                                            label: "CURRENT DC",
                                            value: appState?.fixedClinicList !== -1 ? appState?.fixedClinicList?.find(s => s.id == patient.existingClinicID)?.name : patient.existingClinicID,
                                        },
                                        // {
                                        //     label: "Relation",
                                        //     value: "1NN",
                                        // },
                                        {
                                            label: "Years ESRD",
                                            value: patient.DESRD ? (moment().diff(moment(patient.DESRD), "years", true)?.toFixed(1)) : "",
                                        },
                                        {
                                            label: "DESRD",
                                            value: patient.DESRD ? moment(patient.DESRD).format("DD MMM YYYY") : '',
                                        },
                                        {
                                            label: "DOB",
                                            value: patient.dob ? moment(patient.dob).format("DD MMM YYYY") : '',
                                        },
                                        {
                                            label: "GENDER",
                                            value: patient.gender,
                                        },
                                        {
                                            label: "Age At Print * yrs",
                                            value: patient.dob ? (moment().diff(moment(patient.dob), "years", true)?.toFixed(1)) : "",
                                        },
                                    ])
                                }
                            </div>
                            {/* <div className="column">
                                {
                                    renderDisplayDescriptionItems([
                                        {
                                            label: "DESRD",
                                            value: patient.DESRD ? moment(patient.DESRD).format("DD MMM YY") : '',
                                        },
                                        {
                                            label: "DOB",
                                            value: patient.dob ? moment(patient.dob).format("DD MMM YY") : '',
                                        },
                                        {
                                            label: "GENDER",
                                            value: patient.gender,
                                        },
                                        {
                                            label: "Age At Print * yrs",
                                            value: patient.dob ? (moment().diff(moment(patient.dob), "years", true)?.toFixed(1)) : "",
                                        },
                                    ])
                                }
                            </div> */}
                        </div>
                    </Spin>
                    <div className="container-table flex" style={{ whiteSpace: "nowrap" }}>
                        <div className="row">
                            <div className="title">Last Individual Lab</div>
                            &nbsp;&nbsp;&nbsp;
                            {/* <div className="subtitle">Date Lab: */}
                            {/* 01 Apr 2022 */}
                            {/* {displayLastLab[0]?.timestamp} */}
                            {/* </div> */}
                        </div>
                        <Spin spinning={tableLastLab.loading}>
                            <div className="card lastlab">
                                <div className="card-header">
                                    {
                                        renderCardResultHeader(columnsLastLabPart1)
                                    }
                                </div>
                                <div className="card-content">
                                    <div className="record-content">
                                        {
                                            renderCardResult(columnsLastLabPart1, displayLastLab)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Spin>
                        <Spin spinning={tableLastLab.loading}>
                            <div className="card lastlab" style={{ marginTop: "5px" }}>
                                <div className="card-header">
                                    {
                                        renderCardResultHeader(columnsLastLabPart2)
                                    }
                                </div>
                                <div className="card-content">
                                    <div className="record-content">
                                        {
                                            renderCardResult(columnsLastLabPart2, displayLastLab)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Spin>
                        {/* <Spin spinning={tableLastLab.loading}>
                            <div className="card lastlab">
                                <div className="card-header">
                                    {
                                        renderCardResultHeader(columnsLastLabPart3)
                                    }
                                </div>
                                <div className="card-content">
                                    <div className="record-content">
                                        {
                                            renderCardResult(columnsLastLabPart3, displayLastLab)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Spin> */}
                        {/* <Table
                            columns={columnsLastLabPart1}
                            rowKey={record => record.id}
                            loading={tableLastLab.loading}
                            rowClassName={(record, index) => {
                                // console.log("index", index);
                                return index % 2 === 0 ? "highlight" : ""
                            }}
                            dataSource={displayLastLab}
                            key={record => record.id}
                            pagination={false}
                        />
                        <Table
                            columns={columnsLastLabPart2}
                            rowKey={record => record.id}
                            loading={tableLastLab.loading}
                            rowClassName={(record, index) => {
                                // console.log("index", index);
                                return index % 2 === 0 ? "highlight" : ""
                            }}
                            dataSource={displayLastLab}
                            key={record => record.id}
                            pagination={false}
                        /> */}
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="column container-table flex">
                        <div className="container-table">
                            <div className="title">Dialysis Summary</div>
                            <Spin spinning={tableDiaylsisHistorySummary.loading}>
                                <div className="card" style={{ whiteSpace: "nowrap" }}>
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsDialysisHistorySummary)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsDialysisHistorySummary, displayDialysisHistorySummary)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <div className="container-table">
                            <div className="title">Dialysis History</div>
                            <Spin spinning={tableDialysisHistory.loading}>
                                <div className="card" style={{ whiteSpace: "nowrap" }}>
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsDialysisHistoryPart1)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsDialysisHistoryPart1, displayDialysisHistory)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <div className="container-table">
                            <div className="title">Dialysis History (Continued)</div>
                            <Spin spinning={tableDiaylsisHistorySummary.loading}>
                                <div className="card" style={{ whiteSpace: "nowrap" }}>
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsDialysisHistoryPart2)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsDialysisHistoryPart2, displayDialysisHistory)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                    </div>
                    <div className="column" style={{ minWidth: "250px", width: "250px" }}>
                        <div className="">
                            <div className="row" style={{ fontWeight: 700 }}>
                                <div className="title">&nbsp;</div>
                            </div>
                            {/* <div className="container-border"> */}
                            <div className="container-border">
                                <div className="item allergy">
                                    <div className="item-label">Allergies</div>
                                    <div className="item-value">
                                        {displayAllergies}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-table">
                            <div className="title">Current Medication</div>
                            <Spin spinning={tableCurrentMedication.loading}>
                                <div className="card currentmed">
                                    <div className="card-header">
                                        {
                                            renderCardResultHeader(columnsCurrentMedication)
                                        }
                                    </div>
                                    <div className="card-content">
                                        <div className="record-content">
                                            {
                                                renderCardResult(columnsCurrentMedication, displayCurrentMedication)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <div className="display-none">
                            <Spin spinning={loadingLatestClinicalNote}>
                                <div style={{ fontWeight: "700" }}>
                                    Last Clinical Note
                                </div>
                                <div className="container-border row">
                                    {/* <div className="container-border"> */}
                                    <div className="column dialysisorder small clinicalnotes" style={{ paddingRight: "5px", borderRight: "1px solid #000000", gap: "0px" }}>
                                        {/* <div className="column" style={{ borderBottom: "1px solid #000000" }}> */}
                                        {
                                            renderDisplayDescriptionItems([
                                                {
                                                    label: "Date",
                                                    value: latestClinicalNote !== -1 && latestClinicalNote?.admitTimestamp ? moment.utc(latestClinicalNote?.admitTimestamp).local().format('DD MMM YY') : "",
                                                },
                                                {
                                                    label: "Name",
                                                    value: patient.name, //"MAYNOTEXIST",
                                                },
                                            ])
                                        }
                                    </div>
                                    <div className="column clinicalnote">
                                        {latestClinicalNote !== -1 ? latestClinicalNote?.plan : ""}
                                    </div>
                                </div>
                            </Spin>
                        </div>
                        <div className="display-none">
                            <Spin spinning={loadingDialysisOrder}>
                                <div className="row" style={{ fontWeight: 700 }}>
                                    <div className="title">Dialysis Order</div>
                                </div>
                                <div className="container-border row">
                                    <div className="column dialysisorder small" style={{ paddingRight: "5px", borderRight: "1px solid #000000", gap: "0px" }}>
                                        {
                                            renderDisplayDescriptionItems([
                                                {
                                                    label: "MSA",
                                                    value: dialysisOrder.msa,
                                                },
                                                {
                                                    label: "DORDER",
                                                    value: dialysisOrder.dorderDate
                                                },
                                                {
                                                    label: "FREQMTH",
                                                    value: dialysisOrder.freqmth,
                                                },
                                                {
                                                    label: "Duration",
                                                    value: dialysisOrder.duration,
                                                },
                                                {
                                                    label: "HEP (load)",
                                                    value: dialysisOrder.hepload
                                                },
                                                {
                                                    label: "HEP (cont)",
                                                    value: dialysisOrder.hepcont
                                                },
                                                {
                                                    label: "COAG (Others)",
                                                    value: dialysisOrder.antioOthers,
                                                },
                                                {
                                                    label: "Dialysate (%)",
                                                    value: dialysisOrder.dialysate,
                                                },
                                            ])
                                        }
                                    </div>
                                    <div className="column dialysisorder small" style={{ gap: "0px" }}>
                                        {
                                            renderDisplayDescriptionItems([
                                                {
                                                    label: "Primary Access",
                                                    value: dialysisOrder.primaryAccess,
                                                },
                                                {
                                                    label: "BICARBONATE",
                                                    value: dialysisOrder.bicarbonate
                                                },
                                                {
                                                    label: "'A' Needle",
                                                    value: dialysisOrder.aNeedle,
                                                },
                                                {
                                                    label: "'V' Needle",
                                                    value: dialysisOrder.vNeedle,
                                                },
                                                {
                                                    label: <>Temp (&#8451;)</>,
                                                    value: dialysisOrder.temp
                                                },
                                                {
                                                    label: "QD",
                                                    value: dialysisOrder.qd
                                                },
                                                {
                                                    label: "QB",
                                                    value: dialysisOrder.qb,
                                                },
                                                {
                                                    label: "DW",
                                                    value: dialysisOrder.dw,
                                                },
                                            ])
                                        }
                                    </div>
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>
                <Spin spinning={loadingLatestClinicalNote}>
                    <div style={{ fontWeight: "700" }}>
                        Last Clinical Note {latestClinicalNote !== -1 && latestClinicalNote?.admitTimestamp ? moment.utc(latestClinicalNote?.admitTimestamp).local().format('(DD MMM YY)') : ""}
                    </div>
                    <div className="container-border row">
                        {/* <div className="container-border"> */}
                        <div className="column clinicalnote big">
                            {latestClinicalNote !== -1 ? latestClinicalNote?.plan : ""}
                        </div>
                    </div>
                </Spin>
                {/* <div className="display-none"> */}
                <Spin spinning={loadingDialysisOrder}>
                    <div className="row" style={{ fontWeight: 700 }}>
                        <div className="title">Dialysis Order {dialysisOrder !== -1 && dialysisOrder?.dorderDate ? moment.utc(dialysisOrder?.dorderDate).local().format('(DD MMM YY)') : ""}</div>
                    </div>
                    <div className="container-border row">
                        <ul className="row dialysisorder individual" >
                            {
                                renderDisplayDescriptionItems_ul_li([
                                    {
                                        label: "MSA",
                                        value: dialysisOrder.msa,
                                    },
                                    // {
                                    //     label: "DORDER",
                                    //     value: dialysisOrder.dorderDate
                                    // },
                                    {
                                        label: "FREQMTH",
                                        value: dialysisOrder.freqmth,
                                    },
                                    {
                                        label: "Duration",
                                        value: dialysisOrder.duration,
                                    },
                                    {
                                        label: "HEP (load)",
                                        value: dialysisOrder.hepload
                                    },
                                    {
                                        label: "HEP (cont)",
                                        value: dialysisOrder.hepcont
                                    },
                                    {
                                        label: "COAG (Others)",
                                        value: dialysisOrder.antioOthers,
                                    },
                                    {
                                        label: "Dialysate (%)",
                                        value: dialysisOrder.dialysate,
                                    },
                                    {
                                        label: "Primary Access",
                                        value: dialysisOrder.primaryAccess,
                                    },
                                    {
                                        label: "BICARBONATE",
                                        value: dialysisOrder.bicarbonate
                                    },
                                    {
                                        label: "'A' Needle",
                                        value: dialysisOrder.aNeedle,
                                    },
                                    {
                                        label: "'V' Needle",
                                        value: dialysisOrder.vNeedle,
                                    },
                                    {
                                        label: <>Temp (&#8451;)</>,
                                        value: dialysisOrder.temp
                                    },
                                    {
                                        label: "QD",
                                        value: dialysisOrder.qd
                                    },
                                    {
                                        label: "QB",
                                        value: dialysisOrder.qb,
                                    },
                                    {
                                        label: "DW",
                                        value: dialysisOrder.dw,
                                    },
                                ])
                            }
                        </ul>
                    </div>
                </Spin>
                {/* </div> */}
                <div>
                    <div className="row" style={{ fontWeight: 700 }}>
                        <div className="title">Medical Milestone</div>
                    </div>
                    <div className="container-border row">
                        <div className="column flex" style={displayMedicalMilestone.length > 3 ? { paddingRight: "10px", borderRight: "1px solid #000000" } : null}>
                            {renderDisplayValueItems(displayMedicalMilestone.filter((item, index) => index < 3))}
                        </div>
                        <div className={displayMedicalMilestone.length > 3 ? "column flex" : ""} style={displayMedicalMilestone.length > 6 ? { paddingRight: "10px", borderRight: "1px solid #000000" } : null}>
                            {renderDisplayValueItems(displayMedicalMilestone.filter((item, index) => index >= 3 && index < 6))}
                        </div>
                        <div className={displayMedicalMilestone.length > 6 ? "column flex" : ""}>
                            {renderDisplayValueItems(displayMedicalMilestone.filter((item, index) => index >= 6 && index < 9))}
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Patient - ${patient.name} - ${moment().format('DD MMM YYYY')}`,
        onBeforeGetContent: () => {
            setPrinting(true);
        },
        onAfterPrint: () => {
            // setPrinting(false);
        },
    })

    useEffect(() => {
        getPatientRecord(patientID)
        getLastLabRecord(patientID)
        getDialysisHistorySummaryRecords(patientID)
        getCurrentMedicationRecords(patientID)
        getDiaysisHistoryRecords(patientID)
        getLatestClinicalNoteRecord(patientID)
        getDialysisOrderRecord(patientID)
        getMedicalMilestoneRecords(patientID)
        // const timer = setTimeout(() => {
        //     clearTimeout(timer)
        // }, 3000)
    }, [])

    useEffect(() => {

        if (tableLastLab.data !== -1 && tableDiaylsisHistorySummary.data !== -1 && tableCurrentMedication.data !== -1 && tableDialysisHistory.data !== -1 && tableDialysisHistoryInterval.data !== -1 && latestClinicalNote !== -1 && dialysisOrder !== -1 && tableMedicalMilestone.data !== -1 && patient !== -1) {
            // console.log('enablePrint', tableLastLab.data, tableDiaylsisHistorySummary.data, tableCurrentMedication.data, tableDialysisHistory.data, tableDialysisHistoryInterval.data, latestClinicalNote, dialysisOrder, tableMedicalMilestone.data, tableLastLab.data && tableDiaylsisHistorySummary.data && tableCurrentMedication.data && tableDialysisHistory.data && tableDialysisHistoryInterval.data && latestClinicalNote && dialysisOrder && tableMedicalMilestone.data);
            setEnablePrint(true)
        }
    }, [tableLastLab.data, tableDiaylsisHistorySummary.data, tableCurrentMedication.data, tableDialysisHistory.data, tableDialysisHistoryInterval.data, latestClinicalNote, dialysisOrder, tableMedicalMilestone.data, patient])


    useEffect(() => {
        if (tableLastLab.data !== -1) {
            let lastLab = [];
            for (let item of tableLastLab.data) {
                lastLab.push({
                    ...item,
                    timestamp: item?.timestamp ? moment.utc(item?.timestamp).local().format('DD MMM YY') : '-',
                })
                let attributeTimestamp = {};
                if (item) {
                    for (let i of Object.keys(item)) {
                        if (i.includes('_timestamp')) {
                            attributeTimestamp[i.split('_timestamp')[0]] = item[i] ? moment.utc(item[i]).local().format('DD MMM YY') : 'DD MM YY';
                        }
                    }
                }
                lastLab.push(attributeTimestamp);
            }
            setDisplayLastLab(lastLab)
            setTableLastLab({
                ...tableLastLab,
                loading: false
            })
        }
    }, [tableLastLab.data])

    useEffect(() => {
        if (tableDiaylsisHistorySummary.data !== -1) {
            let _dialysisHistorySummaries = [];
            for (let item of tableDiaylsisHistorySummary.data) {
                let offTargetAverage = (item.offTargetAverage / 1000.0)?.toFixed(1);
                _dialysisHistorySummaries.push({
                    ...item,
                    month: item.yearMonth,
                    total: item.totalSession,

                    dw: `${(item.dryWeightAverage / 1000.0)?.toFixed(1)} (${(item.dryWeightMin / 1000.0)?.toFixed(1)} - ${(item.dryWeightMax / 1000.0)?.toFixed(1)})`,

                    wtLast: `${(item.lastWeightAverage / 1000.0)?.toFixed(1)} (${(item.lastWeightMin / 1000.0)?.toFixed(1)} - ${(item.lastWeightMax / 1000.0)?.toFixed(1)})`,

                    wtPre: `${(item.preWeightAverage / 1000.0)?.toFixed(1)} (${(item.preWeightMin / 1000.0)?.toFixed(1)} - ${(item.preWeightMax / 1000.0)?.toFixed(1)})`,

                    wtPost: `${(item.postWeightAverage / 1000.0)?.toFixed(1)} (${(item.postWeightMin / 1000.0)?.toFixed(1)} - ${(item.postWeightMax / 1000.0)?.toFixed(1)})`,

                    ufTarget: `${item.ufTargetStartAverage?.toFixed(1)} (${item.ufTargetStartMin?.toFixed(1)} - ${item.ufTargetStartMax?.toFixed(1)})`,

                    wtOffTarget: `${offTargetAverage == '-0.0' ? 0.0 : offTargetAverage} (${(item.offTargetMin / 1000.0)?.toFixed(1)} - ${(item.offTargetMax / 1000.0)?.toFixed(1)})`,

                    sbpPost: item.postSBPAverage ? `${item.postSBPAverage?.toFixed(1)} (${item.postSBPMin?.toFixed(1)} - ${item.postSBPMax?.toFixed(1)})` : '-',

                    dbpPost: item.postDBPAverage ? `${item.postDBPAverage?.toFixed(1)} (${item.postDBPMin?.toFixed(1)} - ${item.postDBPMax?.toFixed(1)})` : '-',

                    ktv: item.KTVonlineAverage ? `${item.KTVonlineAverage?.toFixed(1)} (${item.KTVonlineMin?.toFixed(1)} - ${item.KTVonlineMax?.toFixed(1)})` : '-',
                })
            }
            setDisplayDialysisHistorySummary(_dialysisHistorySummaries)
            setTableDialysisHistorySummary({
                ...tableDiaylsisHistorySummary,
                loading: false
            })
        }
    }, [tableDiaylsisHistorySummary.data])

    useEffect(() => {
        if (tableCurrentMedication.data !== -1) {
            let currentMedications = []
            for (let item of tableCurrentMedication.data) {
                currentMedications.push({
                    id: item.id,
                    // date: item.start,
                    // end: item.end,
                    dateStart: moment.utc(item.start).local().format('DD MMM YY'),
                    dateEnd: item.end,
                    active: item.status == 'ACTIVE',
                    // medication: item.drugName,
                    // medication: item.medication,
                    freq: item.freq,
                    route: item.route,

                    medication: `${moment.utc(item.start).local().format('DD MMM YY')} ${item.medication ?? ''} ${item.dose ?? ''} ${item.route ?? ''} ${item.freq ?? ''}`,
                })
            }
            setDisplayCurrentMedication(currentMedications)
            setTableCurrentMedication({
                ...tableCurrentMedication,
                loading: false
            })
        }
    }, [tableCurrentMedication.data])

    useEffect(() => {
        if (tableDialysisHistory.data !== -1) {
            let dialysisIDs = tableDialysisHistory.data.map(s => s.id);
            // console.log('dialysisIDs', dialysisIDs);
            if (dialysisIDs.length !== 0) {
                getDiaysisHistoryIntervalRecords(dialysisIDs, tableDialysisHistoryInterval.pagination);
            } else {
                setTableDialysisHistoryInterval({
                    ...tableDialysisHistoryInterval,
                    loading: false,
                })
            }
        }

        return () => { }
    }, [tableDialysisHistory.data])

    useEffect(() => {
        if (tableDialysisHistoryInterval.data !== -1 && appState.fixedListLoaded === true) {
            let _dialysisInterval = [];
            for (let r of tableDialysisHistoryInterval.data) {
                r.timestampDisplay = moment.utc(r.timestamp).local().format('DD MMM YY, HH:mm:ss');

                _dialysisInterval.push({
                    ...r,
                    type: 'intraDialysisNoteSymptoms',
                    notes: r.note ?? '',
                    displaySymptoms: r.symptoms ? appState?.symptomList?.filter(s => JSON.parse(r.symptoms).includes(s.id)).map(s => s.name).join(', ') : ''
                });
            }

            let _dialysisHistory = [];

            for (let d of tableDialysisHistory.data) {
                d.type = 'dialysisNoteSymptoms';
                d.clinic = appState.fixedClinicList.find(s => s.id == d.clinicID)?.uid;
                d.lastWeight = d.lastWeight != null && d.lastWeight >= 0 ? (parseInt(d.lastWeight) / 1000.0).toFixed(1) : '';
                d.preWeight = d.preWeight != null && d.preWeight >= 0 ? (parseInt(d.preWeight) / 1000.0).toFixed(1) : '';
                d.postWeight = d.postWeight != null && d.postWeight >= 0 ? (parseInt(d.postWeight) / 1000.0).toFixed(1) : '';
                d.dryWeight = d.dryWeight != null && d.dryWeight >= 0 ? (parseInt(d.dryWeight) / 1000.0).toFixed(1) : '';

                d.preTemp = d.preTemp != null && d.preTemp >= 0 ? (parseFloat(d.preTemp).toFixed(1)) : '';
                d.postTemp = d.postTemp != null && d.postTemp >= 0 ? (parseFloat(d.postTemp).toFixed(1)) : '';

                d.KTVonline = d.KTVonline != null && d.KTVonline >= 0 ? (parseFloat(d.KTVonline).toFixed(1)) : '';

                d.timestampDisplay = d.startTime ? moment.utc(d.startTime).local().format('DD MMM YY') : '';
                if (!d.endedBy) {
                    // d.timestampDisplay += ' (In Progress)';
                }

                d.postSBPDBP = d.postSBP && d.postDBP ? d.postSBP + ' / ' + d.postDBP : '';
                d.offTarget = d.postWeight && d.dryWeight ? (d.postWeight - d.dryWeight)?.toFixed(1) : '';
                if (d.offTarget == '-0.0') {
                    d.offTarget = '0.0'
                }

                d.ufTargetStart = d.ufTargetStart?.toFixed(1) ?? '';

                let filteredDialysisInterval = _dialysisInterval.filter(s => s.dialysisID == d.id);
                // console.log('filteredDialysisInterval', filteredDialysisInterval);

                // if (d.id == dialysisID) {
                let QBs = filteredDialysisInterval.map(s => s.QB);
                d.QB = QBs.length ? Math.min(...QBs) + " to " + Math.max(...QBs) : '';

                let APs = filteredDialysisInterval.map(s => s.AP);
                d.AP = APs.length ? Math.min(...APs) + " to " + Math.max(...APs) : '';

                let VPs = filteredDialysisInterval.map(s => s.VP);
                d.VP = VPs.length ? Math.min(...VPs) + " to " + Math.max(...VPs) : '';

                let DFLOWs = filteredDialysisInterval.map(s => s.DFLOW);
                d.DFLOW = DFLOWs.length ? Math.min(...DFLOWs) + " to " + Math.max(...DFLOWs) : '';

                let CDTs = filteredDialysisInterval.map(s => s.CDT);
                d.CDT = CDTs.length ? Math.min(...CDTs)?.toFixed(1) + " to " + Math.max(...CDTs)?.toFixed(1) : '';

                let SBPs = filteredDialysisInterval.map(s => s.SBP);
                d.SBP = SBPs.length ? Math.min(...SBPs) + " to " + Math.max(...SBPs) : '';

                let DBPs = filteredDialysisInterval.map(s => s.DBP);
                d.DBP = DBPs.length ? Math.min(...DBPs) + " to " + Math.max(...DBPs) : '';

                let HRs = filteredDialysisInterval.map(s => s.HR);
                d.HR = HRs.length ? Math.min(...HRs) + " to " + Math.max(...HRs) : '';

                let temps = filteredDialysisInterval.map(s => s.temp);
                d.temp = temps.length ? Math.min(...temps)?.toFixed(1) + " to " + Math.max(...temps)?.toFixed(1) : '';

                let KTVs = filteredDialysisInterval.map(s => s.KTV);
                d.KTV = KTVs.length ? Math.min(...KTVs) + " to " + Math.max(...KTVs) : '';

                d.KTVonline = d.KTVonline != null && d.KTVonline >= 0 ? (parseFloat(d.KTVonline)?.toFixed(1)) : '';

                let intraNotes = filteredDialysisInterval.map(s => s.notes)?.filter(s => s && s?.trim()?.length > 0)?.join(" | ") ?? '';
                if (intraNotes.length > 0)
                    intraNotes = ' || ' + intraNotes;
                d.notes = d.notes ? d.notes + intraNotes : intraNotes;

                let allSymoptoms = filteredDialysisInterval.reduce((a, b) =>
                    // { 
                    // if (b?.symptoms) console.log(a, JSON.parse(b?.symptoms));
                    // return 
                    (a.concat(b?.symptoms && b?.symptoms?.length > 0 ? JSON.parse(b?.symptoms) : []))
                    // }
                    , []);
                // console.log('allSymoptoms', allSymoptoms);
                d.displaySymptoms = allSymoptoms.length ? appState?.symptomList?.filter(s => allSymoptoms.includes(s.id)).map(s => s.name).join(', ') : ''

                // let duration = filteredDialysisInterval.length > 0 && d.dischargeTime ? (moment(filteredDialysisInterval[0].timestamp).diff(moment(d.startTime), "minutes")) : (d.dischargeTime ? moment(d.dischargeTime).diff(moment(d.startTime), "minutes") : '');
                let duration = (d.dischargeTime ? moment(d.dischargeTime).diff(moment(d.startTime), "minutes") : '');
                d.duration = duration || '';

                _dialysisHistory.push(d);
            }

            // setDisplayListDialysisInterval(_dialysisInterval);
            setDisplayDialysisHistory(_dialysisHistory);
            setTableDialysisHistoryInterval({
                ...tableDialysisHistoryInterval,
                loading: false
            })
            setTableDialysisHistory({
                ...tableDialysisHistory,
                loading: false
            })
        }
    }, [tableDialysisHistoryInterval.data, appState.fixedListLoaded])

    useEffect(() => {
        if (tableAllergies.data !== -1) {
            if (tableAllergies.data.length > 0)
                setDisplayAllergies(tableAllergies.data.map(s => s.name).join(', '));
            else
                setDisplayAllergies('-');
        }
    }, [tableAllergies.data])

    useEffect(() => {
        if (patient?.existingClinicID > 0 && appState?.fixedClinicList !== -1) {
            setPatientClinic(appState?.fixedClinicList?.find(s => s.id == patient?.existingClinicID));
        }
    }, [patient?.existingClinicID, appState.fixedClinicList])

    useEffect(() => {
        if (latestClinicalNote !== -1) {
            setLoadingLatestClinicalNote(false)
        }
    }, [latestClinicalNote])

    useEffect(() => {
        if (dialysisOrder !== -1) {
            setLoadingDialysisOrder(false)
        }
    }, [dialysisOrder])

    useEffect(() => {
        if (tableMedicalMilestone.data !== -1) {
            let medicalMilestones = [];
            for (let item of tableMedicalMilestone.data) {
                medicalMilestones.push({
                    ...item,
                    // milestone: item.milestone + ' | ' + item.note,
                    date: moment.utc(item.date).local().format('DD MMM YY'),
                })
            }
            setDisplayMedicalMilestone(medicalMilestones)
            setTableMedicalMilestone({
                ...tableMedicalMilestone,
                loading: false
            })
        }
    }, [tableMedicalMilestone.data])

    return (
        <div>
            <Button type="primary" onClick={handlePrint} loading={!enablePrint} disabled={!enablePrint}><PrinterOutlined />Print - Patient Summary</Button>
            {/* <Button type="primary" onClick={handlePrint}><PrinterOutlined />Print - Patient Summary</Button> */}
            <br /><br />
            <div style={{ border: "1px solid #000000", backgroundColor: "#FFFFFF" }}>
                <ComponentToPrint ref={componentRef} />
            </div>
        </div>
    )
}

export default PatientRecordExportPatientSummary