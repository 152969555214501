import React, { useContext, useState, useEffect } from "react"
import { Button, DatePicker, Empty, Form, Input, Modal, notification, Pagination, Popconfirm, Select, Spin, Table } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import moment from "moment"

import { DataContext } from '../contexts/DataContext'
import iconAdd from "../media/Icon-Add.png"
import API from "@aws-amplify/api"
import { AppContext } from "../contexts/AppContext"
import { listLabReport } from "../backend/graphql/queries"
import { createLabReport } from "../backend/graphql/mutations"

const { RangePicker } = DatePicker

const PatientRecordLabResults = (props) => {
    const { appState } = useContext(AppContext);
    const { dataState } = useContext(DataContext)
    const { Option } = Select
    const [form, form2] = Form.useForm()
    const dateFormatList = ["DD MMM YYYY"]
    const validateMessages = {
        required: "Required.",
    }
    const [visibilityLabResults, setVisibilityLabResults] = useState(false)
    // const [loading, setLoading] = useState(false);
    // const [labReport, setLabReport] = useState([])
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: props.pageSize ?? 20,
            offset: 0,
        },
        loading: false,
    });
    const [tableViewAll, setTableViewAll] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            showSizeChanger: false,
        },
        loading: false
    });
    const [filter, setFilter] = useState({
        startDate: null,
        endDate: null,
    });

    useEffect(() => {
        setVisibilityLabResults(false);
    }, [])

    useEffect(() => {
        // console.log('PatientRecordMedication', props?.patientID);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            getLabResult(null, table.pagination);
        }
        return () => { }
    }, [appState.cognitoUser])

    useEffect(() => {
        // console.log('table.data', table.data);
        return () => { }
    }, [table])

    const _listLabResult = async (patientID, pagination, filter = null) => {
        // setLoading(true);
        // setTable({
        //     ...table,
        //     loading: true,
        // })
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: 'timestamp DESC',
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    deleted: { eq: 0 }
                },
            };

            if (filter) {
                variables.filter.and = [
                    {
                        timestamp: {
                            ge: filter.startDate.toISOString().split('.')[0]
                        }
                    },
                    {
                        timestamp: {
                            le: filter.endDate.toISOString().split('.')[0]
                        }
                    }
                ]
            }

            // console.log(variables);

            const result = await API.graphql({
                query: listLabReport,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordLabResults", result);

            const data = result.data.result
            const array = result.data.result.result

            // process data
            let _labReport = [];
            for (let v of array) {
                _labReport.push({
                    id: v.id,
                    patientID: v.patientID,
                    clinicID: v.clinicID,
                    timestamp: moment.utc(v.timestamp),
                    timestampString: moment.utc(v.timestamp).local().format(dateFormatList[0]),
                    source: v.source,
                    accessIDlab: v.accessIDlab,
                    name: v.name,
                    glucose: v.glucose,
                    tw: v.TW,
                    Plt: v.Plt,
                    Hb: v.Hb,
                    MCV: v.MCV,
                    K: v.K,
                    Na: v.Na,
                    ureaPre: v.ureaPre,
                    ureaPost: v.ureaPost,
                    creatinine: v.creatinine,
                    HCO3: v.HCO3,
                    chlorine: v.chlorine,
                    Ca: v.Ca,
                    Po4: v.Po4,
                    IPTH: v.IPTH,
                    HepBsAg: v.HepBsAg,
                    HepBsAb: v.HepBsAb,
                    _two: v._two,
                    // Hep: v.Hep,
                    // HepElisa: v.HepElisa,
                    Fe: v.Fe,
                    TIBC: v.TIBC,
                    ferritin: v.ferritin,
                    protein: v.protein,
                    Alb: v.Alb,
                    Ublood: v.Ublood,
                    Urbc: v.Urbc,
                    Uprt: v.Uprt,
                    Uwbc: v.Uwbc,
                    TUP24: v.TUP24,
                    TUP12: v.TUP12,
                    Uvol: v.Uvol,
                    CCT: v.CCT,
                    uric: v.uric,
                    HCV: v.HCV,
                    HIV: v.HIV,
                    ggt: v.ggt,
                    images: v.images,
                    others: v.others,
                    othersDetails: v.othersDetails,
                    TC: v.TC,
                    HDL: v.HDL,
                    LDL: v.LDL,
                    TG: v.TG,
                    Ratio: v.Ratio,
                    Timing: v.Timing,
                    Biltotal: v.Biltotal,
                    SAP: v.SAP,
                    SGPT_ALT: v.SGPT_ALT,
                    SGOT_AST: v.SGOT_AST,
                    Hba1c: v.Hba1c,
                    labsNoted: v.labsNoted,
                    SBP: v.SBP,
                    DBP: v.DBP,
                    Mg: v.Mg,
                    Tac: v.Tac,
                    urineMA: v.urineMA,
                    MACr: v.MACr,
                    uCreatinine: v.uCreatinine,
                    ESR: v.ESR,
                    CRP: v.CRP,
                    CNI: v.CNI,
                    Myco: v.Myco,
                    Pred: v.Pred,
                    noted: v.noted,
                    t4: v.t4,
                    FT4: v.FT4,
                    TSH: v.TSH,

                    gis: v.gis,
                    hte: v.hte,
                })
            }

            _labReport = _labReport.sort((a, b) => b.timestamp - a.timestamp);

            if (filter) {
                setTableViewAll({
                    ...tableViewAll,
                    data: _labReport,
                    loading: false,
                    pagination: {
                        ...tableViewAll.pagination,
                        current: pagination.current,
                        offset: pagination.offset,
                        total: pagination.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                    },
                })
            } else {
                setTable({
                    ...table,
                    data: _labReport,
                    loading: false,
                    pagination: {
                        ...table.pagination,
                        current: pagination.current,
                        offset: pagination.offset,
                        total: pagination.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                    },
                });
            }

            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Lab Results"
            })
        }
        finally { }
    }

    const _createLabReport = async (v) => {
        // setLoading(true);
        setTable({
            ...table,
            loading: true,
        })
        try {
            let variables = {
                patientID: props?.patientID,
                clinicID: appState.selectedClinicID ? appState.selectedClinicID : null,
                timestamp: v.timestamp.toISOString().split('.')[0],
                // name: v.name,
                glucose: v.glucose,
                TW: v.tw,
                Plt: v.Plt,
                Hb: v.Hb,
                MCV: v.MCV,
                K: v.K,
                Na: v.Na,
                ureaPre: v.ureaPre,
                ureaPost: v.ureaPost,
                creatinine: v.creatinine,
                HCO3: v.HCO3,
                chlorine: v.chlorine,
                Ca: v.Ca,
                Po4: v.Po4,
                IPTH: v.IPTH,
                HepBsAg: v.HepBsAg,
                HepBsAb: v.HepBsAb,
                _two: v._two,
                // Hep: v.Hep,
                // HepElisa: v.HepElisa,
                Fe: v.Fe,
                TIBC: v.TIBC,
                ferritin: v.ferritin,
                protein: v.protein,
                Alb: v.Alb,
                Ublood: v.Ublood,
                Urbc: v.Urbc,
                Uprt: v.Uprt,
                Uwbc: v.Uwbc,
                TUP24: v.TUP24,
                TUP12: v.TUP12,
                Uvol: v.Uvol,
                CCT: v.CCT,
                uric: v.uric,
                HCV: v.HCV,
                HIV: v.HIV,
                ggt: v.ggt,
                images: v.images,
                others: v.others,
                othersDetails: v.othersDetails,
                TC: v.TC,
                HDL: v.HDL,
                LDL: v.LDL,
                TG: v.TG,
                Ratio: v.Ratio,
                Timing: v.Timing,
                Biltotal: v.Biltotal,
                SAP: v.SAP,
                SGPT_ALT: v.SGPT_ALT,
                SGOT_AST: v.SGOT_AST,
                Hba1c: v.Hba1c,
                labsNoted: v.labsNoted,
                SBP: v.SBP,
                DBP: v.DBP,
                Mg: v.Mg,
                Tac: v.Tac,
                urineMA: v.urineMA,
                MACr: v.MACr,
                uCreatinine: v.uCreatinine,
                ESR: v.ESR,
                CRP: v.CRP,
                CNI: v.CNI,
                Myco: v.Myco,
                Pred: v.Pred,
                noted: v.noted,
                t4: v.t4,
                FT4: v.FT4,
                TSH: v.TSH,

                gis: v.gis,
                hte: v.hte,
            }

            // process HepBsAb values
            if (variables.HepBsAb) {
                // console.log('HepBsAb')
                if (variables.HepBsAb.match(/[0-9]+/g)) {
                    if (variables.HepBsAb.includes('>')) {
                        // console.log('HepBsAb gt')
                        let HepBsAb = variables.HepBsAb.replace('>', '');
                        if (parseInt(HepBsAb)) {
                            HepBsAb = parseInt(HepBsAb) + 1;
                        }
                        variables.HepBsAb = HepBsAb;
                    } else if (variables.HepBsAb.includes('<')) {
                        // console.log('HepBsAb lt')
                        let HepBsAb = variables.HepBsAb.replace('<', '');
                        if (parseInt(HepBsAb)) {
                            HepBsAb = parseInt(HepBsAb) - 1;
                        }
                        variables.HepBsAb = HepBsAb;
                    }
                }
            }

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log('_createLabReport', v.timestamp, v.timestamp.toISOString(), variables);

            const result = await API.graphql({
                query: createLabReport,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            })
            // console.log("API result PatientRecordLabResults createLabReport", result);

            if (result?.data?.result) {
                getLabResult(null, {
                    ...table.pagination,
                    current: 1,
                    offset: 1 * table.pagination.pageSize - table.pagination.pageSize,
                });
                notification.success({
                    message: "Created successfully"
                })
                form.resetFields()
            }

            setTable({
                ...table,
                loading: false,
            })
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create new record"
            })
        } finally { }
    }

    const getLabResult = (filter, pagination = table.pagination) => {
        if (filter) {
            setTableViewAll({
                ...tableViewAll,
                loading: true,
                data: -1,
            });

            _listLabResult(props?.patientID, pagination, filter);

        } else {
            setTable({
                ...table,
                loading: true,
                data: -1
            });

            _listLabResult(props?.patientID, pagination);
        }

        // console.log("filter", filter);
        // console.log("table", table);

    }

    const handleTableChange = (paginate) => {
        // console.log(paginate);
        getLabResult(null, {
            ...table.pagination,
            current: paginate,
            offset: paginate * table.pagination.pageSize - table.pagination.pageSize,
        })
    }

    const handleTableHistoryChange = (paginate) => {
        // console.log('handleTableHistoryChange', paginate);
        getLabResult(filter, {
            ...tableViewAll.pagination,
            current: paginate.current,
            offset: paginate.current * tableViewAll.pagination.pageSize - tableViewAll.pagination.pageSize,
        })
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
        setFilter({
            startDate: date[0],
            endDate: date[1],
        });
    }

    const handleModalVisibility = (modal) => {
        // console.log("modal", modal);
        switch (modal) {
            case "labResults":
                setVisibilityLabResults(!visibilityLabResults)
                break
            default:
        }
    }

    const columns = [
        {
            title: "Date Lab",
            dataIndex: "timestampString",
            width: 150,
            renderInput: (
                <Form.Item
                    key="timestamp"
                    name="timestamp"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                // style={{ width: 150 }}
                >
                    <DatePicker format={dateFormatList} />
                </Form.Item>
            )
        },
        {
            title: "Hb",
            dataIndex: "Hb",
            render(text, record) {
                let outOfRange = parseFloat(text) <= 10;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "MCV",
            dataIndex: "MCV"
        },
        {
            title: "TW",
            dataIndex: "tw"
        },
        {
            title: "Plt",
            dataIndex: "Plt"
        },
        {
            title: "K",
            dataIndex: "K",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 5.5;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "HCO3",
            dataIndex: "HCO3",
            render(text, record) {
                let outOfRange = parseFloat(text) < 20;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Na",
            dataIndex: "Na"
        },
        {
            title: "Cl",
            dataIndex: "chlorine"
        },
        {
            title: "Creatinine",
            dataIndex: "creatinine"
        },
        {
            title: "Glucose",
            dataIndex: "glucose"
        },
        {
            title: "CCT",
            dataIndex: "CCT"
        },
        {
            title: "Ca",
            dataIndex: "Ca",
            render(text, record) {
                let outOfRange = parseFloat(text) <= 2;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Po4",
            dataIndex: "Po4",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 1.7;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "iPTH",
            dataIndex: "IPTH",
            render(text, record) {
                let outOfRange = parseFloat(text) > 80;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" },
                    },
                    children: text
                };
            }
        },
        {
            title: "Urea Pre",
            dataIndex: "ureaPre"
        },
        {
            title: "Urea Post",
            dataIndex: "ureaPost"
        },
        // {
        //     title: "Un",
        //     dataIndex: "un"
        // },
        {
            title: "HepBsAg",
            dataIndex: "HepBsAg",
            width: 80,
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('weak reactive') || text?.toLowerCase() === ('reactive');
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            },
            renderInput: (
                <Form.Item
                    key={"HepBsAg"}
                    name={"HepBsAg"}
                    style={{ width: 80 }}
                >
                    <Select
                        showSearch
                        placeholder={"HepBsAg"}
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        }
                    // disabled={item.disabled === true ? true : false}
                    // onChange={item.handleSelect !== undefined? item.handleSelect : null}
                    // defaultValue={0}
                    >
                        <Option key={"POS"} value={"POS"}>{"POS"}</Option>
                        <Option key={"NEG"} value={"NEG"}>{"NEG"}</Option>
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "HepBsAb",
            dataIndex: "HepBsAb"
        },
        {
            title: "HCV",
            dataIndex: "HCV",
            width: 80,
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('reactive');
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            },
            renderInput: (
                <Form.Item
                    key={"HCV"}
                    name={"HCV"}
                    style={{ width: 80 }}
                >
                    <Select
                        showSearch
                        placeholder={"HCV"}
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        }
                    // disabled={item.disabled === true ? true : false}
                    // onChange={item.handleSelect !== undefined? item.handleSelect : null}
                    // defaultValue={0}
                    >
                        <Option key={"POS"} value={"POS"}>{"POS"}</Option>
                        <Option key={"NEG"} value={"NEG"}>{"NEG"}</Option>
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "HIV",
            dataIndex: "HIV",
            width: 80,
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('reactive');
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            },
            renderInput: (
                <Form.Item
                    key={"HIV"}
                    name={"HIV"}
                    style={{ width: 80 }}
                >
                    <Select
                        showSearch
                        placeholder={"HIV"}
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        }
                    // disabled={item.disabled === true ? true : false}
                    // onChange={item.handleSelect !== undefined? item.handleSelect : null}
                    // defaultValue={0}
                    >
                        <Option key={"POS"} value={"POS"}>{"POS"}</Option>
                        <Option key={"NEG"} value={"NEG"}>{"NEG"}</Option>
                    </Select>
                </Form.Item>
            )
        },
        {
            title: "Fe",
            dataIndex: "Fe"
        },
        {
            title: "TIBC",
            dataIndex: "TIBC"
        },
        {
            title: "Ferritin",
            dataIndex: "ferritin",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 700;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "ESR",
            dataIndex: "ESR"
        },
        {
            title: "CRP",
            dataIndex: "CRP"
        },
        {
            title: "uricAcid",
            dataIndex: "uric"
        },
        {
            title: "TC",
            dataIndex: "TC"
        },
        {
            title: "HDL",
            dataIndex: "HDL"
        },
        {
            title: "LDL",
            dataIndex: "LDL"
        },
        {
            title: "TG",
            dataIndex: "TG"
        },
        {
            title: "Ratio",
            dataIndex: "Ratio"
        },
        {
            title: "Hba1c",
            dataIndex: "Hba1c",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 7;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        // {
        //     title: "Hep",
        //     dataIndex: "Hep"
        // },
        // {
        //     title: "Hep (Elisa)",
        //     dataIndex: "HepElisa"
        // },
        {
            title: "Protein",
            dataIndex: "protein"
        },
        {
            title: "Alb",
            dataIndex: "Alb"
        },
        {
            title: "Biltotal",
            dataIndex: "Biltotal"
        },
        {
            title: "Sap",
            dataIndex: "SAP"
        },
        {
            title: "Ast",
            dataIndex: "SGOT_AST"
        },
        {
            title: "Alt",
            dataIndex: "SGPT_ALT"
        },
        {
            title: "Ggt",
            dataIndex: "ggt"
        },
        {
            title: "Mg",
            dataIndex: "Mg"
        },
        // {
        //     title: "Gis",
        //     dataIndex: "gis"
        // },
        // {
        //     title: "Hte",
        //     dataIndex: "hte"
        // },
        // {
        //     title: "Timing",
        //     dataIndex: "Timing"
        // },
        {
            title: "TUP 24 Hrs",
            dataIndex: "TUP24"
        },
        // {
        //     title: "TUP 12 Hrs",
        //     dataIndex: "TUP12"
        // },
        {
            title: "Uvol",
            dataIndex: "Uvol"
        },
        {
            title: "Ublood",
            dataIndex: "Ublood"
        },
        {
            title: "Urbc",
            dataIndex: "Urbc"
        },
        {
            title: "Uprt",
            dataIndex: "Uprt"
        },
        {
            title: "Uwbc",
            dataIndex: "Uwbc"
        },
        {
            title: "Urine MA",
            dataIndex: "urineMA"
        },
        {
            title: "MACr",
            dataIndex: "MACr"
        },
        {
            title: "U creatinine",
            dataIndex: "uCreatinine"
        },
        {
            title: "Tac",
            dataIndex: "Tac"
        },
        {
            title: "CNI",
            dataIndex: "CNI"
        },
        {
            title: "Myco",
            dataIndex: "Myco"
        },
        {
            title: "Pred",
            dataIndex: "Pred"
        },
        {
            title: "",
            dataIndex: "action",
            width: 50,
            renderInput: (
                <>
                    <Popconfirm title="Are you sure?" onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No" placement="topRight">
                        {/* <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} /> */}
                        <div className="clickable">
                            <img src={iconAdd} />
                        </div>
                    </Popconfirm>
                </>
            )
        },
    ]

    const onFinish = async (values) => {
        // console.log(onFinish clicked", values);
        const v = await form.validateFields()
        // console.log("onFinish clicked2", v);
        // Check if v has at least one
        let _v = Object.entries(v).reduce(
            (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
            {}
        )
        if (Object.keys(_v).filter(s => s != 'timestamp').length > 0) {
            _createLabReport(v);
        } else {
            // console.log('Lab Result no input.');
            notification.error({ message: 'Lab Result require at least 1 input.' });
        }
    }

    const onFinishLabResults = async (values) => {
        // console.log("onFinishLabResults clicked", values);
        getLabResult({ startDate: values.period[0], endDate: values.period[1] }, tableViewAll.pagination);
    }

    const handleFocus = (event) => {
        event.target.select()
    }

    // render custom table header
    const renderHeaderItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            const width = item.width !== undefined ? item.width : null

            return (
                // <div key={item.dataIndex} className={item.dataIndex !== "action" ? "column" : ""} style={width !== null ? { width: width } : {}}>
                <div key={item.dataIndex} className={"column"} style={width !== null ? { width: width } : {}}>
                    {item.title}
                </div>
            )
        })
        return items
    }

    const renderLabResultsHeader = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderHeaderItems(array)
            )
        )
    }

    // render custom table input row
    const renderInputItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item width", item.width);
            const width = item.width !== undefined ? item.width : null
            if (item.renderInput !== undefined) {
                return item.renderInput
            }
            else {
                if (width !== null) {
                    return (
                        <Form.Item
                            key={item.dataIndex}
                            name={item.dataIndex}
                            style={{ width: width }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder={item.title} onFocus={handleFocus} />
                        </Form.Item>
                    )
                }
                else {
                    if (item.dataIndex === "action") {
                        return (
                            <Form.Item key={item.dataIndex}>
                                <Popconfirm title="Are you sure?" onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No" placement="topRight">
                                    {/* <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} /> */}
                                    <div className="clickable">
                                        <img src={iconAdd} />
                                    </div>
                                </Popconfirm>
                            </Form.Item>
                        )
                    }
                    else {
                        return (
                            <Form.Item
                                key={item.dataIndex}
                                name={item.dataIndex}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder={item.title} onFocus={handleFocus} />
                            </Form.Item>
                        )
                    }
                }
            }
        })
        return items
    }

    const renderLabResultsInputRow = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderInputItems(array)
            )
        )
    }

    const renderItems = (columns, array) => {
        if (Object.keys(array).length > 0) {
            const items = array.map((item, index) => {
                const renderItemColumns = (columns, rowItem) => {
                    // console.log("columns", columns);
                    // console.log("rowItem", rowItem);
                    const renderRowItems = columns.map((columnItem, index) => {
                        // console.log("columns item", columnItem);
                        const width = columnItem.width !== undefined ? columnItem.width : null
                        let value = ""
                        let style = {};
                        for (const key in rowItem) {
                            // console.log("key", key);
                            // console.log("value", rowItem[key]);
                            if (columnItem.dataIndex === key) {
                                value = rowItem[key]
                                if (columnItem?.render) {
                                    style = columnItem?.render(value, rowItem)?.props?.style
                                }
                                break
                            }
                        }
                        return (
                            // <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : (columnItem.dataIndex !== "action" ? "column" : "")} style={width !== null ? { width: width } : {}}>
                            <div key={columnItem.dataIndex} className={columnItem.dataIndex === "datetime" ? "column date" : "column"} style={width !== null ? { width: width, ...style } : { ...style }}>
                                {value}
                            </div>
                        )
                    })
                    return renderRowItems
                }

                return (
                    <div key={item.id} className={index % 2 === 0 ? "record highlight" : "record"}>
                        {
                            renderItemColumns(columns, item)
                        }
                    </div>
                )
            })
            return items
        } else {
            return null
        }
    }

    const renderLabResults = (columns, array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderItems(columns, array)
            )
        )
    }

    function TableWrapper() {
        // https://codesandbox.io/s/so-56619026-tableheader-forked-9lz5ve?file=/src/index.js:729-1042
        // const columnsInput = renderLabResultsInputRow(columns);
        const _columns = columns.map(s => {
            return {
                ...s,
                width: s.width ? s.width + 32 : 100,
            }
        })
        const _columnsInput = columns.map(s => {
            return {
                ...s,
                width: s.width ? s.width + 32 : 100,
                marginBottom: 0,
                render: () => s.renderInput ? s.renderInput :
                    <Form.Item
                        key={s.dataIndex}
                        name={s.dataIndex}
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder={s.title} onFocus={handleFocus} />
                    </Form.Item>,

                // render: () => <Input autoComplete="off" placeholder={s.title} onFocus={handleFocus} />
            }
        })
        // console.log(_columns);

        return (
            <div className="card" style={{ overflowY: "scroll" }}>
                <div className="card-content">
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Table
                            className="record-header"
                            // width={"5500px"}
                            // style={{position: fixed}}
                            tableLayout="fixed"
                            // size="medium"
                            bordered
                            columns={_columnsInput}
                            dataSource={[{}]}
                            pagination={false}
                        />
                    </Form>
                    <div style={{
                        // display: "flex",
                        // flexDirection: "column",
                        width: "fit-content",
                        height: "300px",
                        overflowX: "hidden",
                        overflowY: "scroll",
                    }}
                    >
                        <Table
                            tableLayout="fixed"
                            // size="medium"
                            bordered
                            rowClassName={(record, index) => {
                                // console.log("index", index);
                                return index % 2 === 0 ? "record highlight" : ""
                            }}
                            showHeader={false}
                            columns={_columns}
                            loading={table.loading}
                            dataSource={table.data != -1 ? table?.data : []}
                            // pagination={table.pagination}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="patientrecord-labresults" id="labreport">
            <div className="row">
                <div className="row">
                    <div className="title">
                        Lab Results
                    </div>
                    <Button type="primary" style={{ marginBottom: "10px" }} onClick={() => { setVisibilityLabResults(true) }}>
                        View All Records
                    </Button>
                </div>
                <div className="labresultperiod">
                    {(Object.keys(table.data).length > 0) ?
                        `Showing lab result from ${moment(table.data[table.data.length - 1]?.timestamp).local().format(dateFormatList[0])} to ${moment().local().format(dateFormatList[0])}` :
                        ''}
                </div>
            </div>
            <Spin spinning={table.loading}>
                {/* <div style={{ overflow: "auto", overscrollBehaviorInline: "contain" }}>
                    <div className="card" style={{ width: "4000px" }}>
                        <div className="card-header">
                            {
                                renderLabResultsHeader(columns)
                            }
                        </div>
                        <div className="card-content">
                            <Form
                                form={form}
                                layout="horizontal"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                requiredMark={false}
                            >
                                <div className="record-add">
                                    {
                                        renderLabResultsInputRow(columns)
                                    }
                                </div>
                            </Form>
                            <div className="record-content">
                                {
                                    // renderLabResults(columns, dataState.labResultsRecords)
                                    renderLabResults(columns, table.data)
                                }
                            </div>
                            <Pagination
                                current={table.pagination.current}
                                pageSize={table.pagination.pageSize}
                                total={table.pagination.total}
                                onChange={handleTableChange}
                                showSizeChanger={false}
                            // onShowSizeChange={onShowSizeChange}
                            />
                        </div>
                    </div>
                </div> */}
                <TableWrapper />
                <Pagination
                    current={table.pagination.current}
                    pageSize={table.pagination.pageSize}
                    total={table.pagination.total}
                    onChange={handleTableChange}
                    showSizeChanger={false}
                // onShowSizeChange={onShowSizeChange}
                />
            </Spin>
            <Modal
                title={
                    <div className="modal-title">
                        Lab Results
                    </div>
                }
                visible={visibilityLabResults}
                onCancel={() => handleModalVisibility("labResults")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("labResults")}>
                        Cancel
                    </Button>,
                ]}
                className="modal-patientrecord-labresults"
                width={"100%"}
            >
                <div className="modal-content">
                    <Form
                        form={form2}
                        layout="horizontal"
                        onFinish={onFinishLabResults}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="row">
                            <Form.Item
                                label="Period"
                                name="period"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <RangePicker onChange={handleDatePickerChange} format={dateFormatList} />
                            </Form.Item>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button type="primary" htmlType="submit">Apply</Button>
                        </div>
                    </Form>
                    <div className="container-table">
                        <Table
                            columns={columns}
                            rowKey={record => record.id}
                            loading={tableViewAll.loading}
                            rowClassName={(record, index) => {
                                // console.log("index", index);
                                return index % 2 === 0 ? "highlight" : ""
                            }}
                            scroll={{ x: 4500 }}
                            dataSource={Object.keys(tableViewAll.data).length > 0 ?
                                tableViewAll.data :
                                []}
                            key={record => record.id}
                            pagination={tableViewAll.pagination}
                            onChange={handleTableHistoryChange}
                        // showSizeChanger={false}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PatientRecordLabResults