import React, { useEffect, useState } from "react"
import { Button, Form, Input, Modal, notification, Popconfirm, Space, Spin, Table } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import API from "@aws-amplify/api"
import moment from "moment"

import { listSymptom } from "../backend/graphql/queries"
import { createSymptom, updateSymptom } from "../backend/graphql/mutations"
import renderInputItems from "../utils/renderFormInputItems"
import iconMinus from "../media/Icon-Minus.png"

const ManageSymptom = () => {
    const [formAdd] = Form.useForm()
    const [formSearch] = Form.useForm()
    const [filter, setFilter] = useState({
        search: "",
    })
    const [visibilityAddSymptom, setVisibilityAddSymptom] = useState(false)
    const validateMessages = {
        required: "Required.",
    }
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            showSizeChanger: false
        },
        loading: false
    })
    const [display, setDisplay] = useState([])
    const [loadingModal, setLoadingModal] = useState(false)
    const columns = [
        {
            title: "Date Added",
            dataIndex: "createdOnDisplay",
        },
        {
            title: "Symptoms",
            dataIndex: "name",
        },
        {
            title: "Class",
            dataIndex: "class",
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
                            <div className="table-row-action clickable red">
                                <div className="icon">
                                    <img src={iconMinus} />
                                </div>
                                <div className="text">
                                    Delete
                                </div>
                            </div>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ]

    const getSymptomList = async (props, values) => {
        setTable({
            ...table,
            loading: true,
        })
        try {
            let filter = {
                deleted: { eq: 0 }
            }

            if (values.search != "") {
                filter = {
                    ...filter,
                    // name: { contains: values.search },
                    or: [
                        { name: { contains: values.search } },
                        { class: { contains: values.search } }
                    ]
                }
            }

            const symptomDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "name ASC"
                },
                filter: filter
            }
            // console.log("symptomDetails", symptomDetails);
            const result = await API.graphql({
                query: listSymptom,
                variables: symptomDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);
            const data = result.data.result
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    pageSize: props.pageSize,
                    current: props.current,
                    offset: props.offset,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            notification.error({
                message: "Unable to retrieve records"
            })
            setTable({
                ...table,
                loading: false
            })
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         // loading: dataState.patientSessionRecords.length !== 0 ? true : false,
        //         loading: false, // set false for simulation
        //         data: [
        //             {
        //                 id: 1,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 class: "GIC",
        //             },
        //             {
        //                 id: 2,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 class: "GIC",
        //             },
        //             {
        //                 id: 3,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 class: "GIC",
        //             },
        //             {
        //                 id: 4,
        //                 createdOn: "01 Jan 2022",
        //                 name: "Dizzy",
        //                 class: "GIC",
        //             },
        //         ]
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    const createSymptomRecord = async (values) => {
        setLoadingModal(true)
        try {
            const symptomDetails = {
                create: {
                    name: values.name,
                    class: values.class,
                }
            }
            // console.log("symptomDetails", symptomDetails);
            const result = await API.graphql({
                query: createSymptom,
                variables: symptomDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);
            const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Created successfully"
            })
            getSymptomList(table.pagination, filter)
            formAdd.resetFields()
            handleModalVisibility("addSymptom")
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to create record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const deleteSymptomRecord = async (id) => {
        setLoadingModal(true)
        try {
            const symptomDetails = {
                update: {
                    id: id,
                    deleted: 1,
                }
            }
            // console.log("symptomDetails", symptomDetails);
            const result = await API.graphql({
                query: updateSymptom,
                variables: symptomDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);
            // const data = result.data.result
            // console.log("data", data);
            notification.success({
                message: "Deleted successfully"
            })
            getSymptomList(table.pagination, filter)
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    useEffect(() => {
        getSymptomList(table.pagination, filter)
        formSearch.setFieldsValue(filter)
    }, [])

    useEffect(() => {
        if (table.data !== -1) {
            let array = []
            for (let i = 0; i < table.data.length; i++) {
                let object = {
                    ...table.data[i],
                    createdOnDisplay: moment.utc(table.data[i].createdOn).local().format("DD MMM YYYY")
                }
                array.push(object)
            }
            setDisplay(array)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data])

    const onFinishSearch = (values) => {
        // console.log("submit formSearch", values);
        setFilter(values)
        getSymptomList(table.pagination, values)
    }

    const onFinishAdd = async (values) => {
        // console.log("onFinishAdd", values);
        await formAdd.validateFields()
        createSymptomRecord(values)
        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("addSymptom")
        // }, 1000)
    }

    const handleTableChange = (paginate) => {
        getSymptomList({
            ...table.pagination,
            pageSize: paginate.pageSize,
            current: paginate.current,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        }, filter)
    }

    const handleDelete = async (id) => {
        // console.log("handle delete", id);
        deleteSymptomRecord(id)
        // setTable({
        //     ...table,
        //     loading: true
        // })
        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         loading: false
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    const handleModalVisibility = (modal, id) => {
        // console.log("modal", modal);
        switch (modal) {
            case "addSymptom":
                setVisibilityAddSymptom(!visibilityAddSymptom)
                break
            default:
        }
    }

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="title">
                            Manage Symptoms
                        </div>
                        <Button type="primary" onClick={() => handleModalVisibility("addSymptom")}>
                            Add Symptom
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    <Form.Item
                                        className="search"
                                        name="search"
                                    >
                                        <Input autoComplete="off" prefix={<SearchOutlined />} placeholder="Search symptom / class" value={filter.search} allowClear />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : ""
                    }}
                    dataSource={display}
                    rowKey={record => record.id}
                    loading={table.loading}
                    pagination={table.pagination}
                    onChange={handleTableChange}
                />
            </div>
            <Modal
                title={
                    <div className="modal-title">
                        Add Symptom
                    </div>
                }
                visible={visibilityAddSymptom}
                onCancel={() => handleModalVisibility("addSymptom")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("addSymptom")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure" onConfirm={() => onFinishAdd(formAdd.getFieldsValue())} okText="Yes" cancelText="No">
                        <Button key="submit" type="primary" loading={loadingModal}>
                            Add
                        </Button>
                    </Popconfirm>
                    ,
                ]}
                className="modal-manage"
                width={800}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishAdd}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            {renderInputItems([
                                {
                                    label: "Symptom Name",
                                    name: "name",
                                    type: "input",
                                    placeholder: "Enter symptom name",
                                    required: true,
                                },
                                {
                                    label: "Class",
                                    name: "class",
                                    type: "input",
                                    placeholder: "Enter class",
                                },
                            ])}
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    )
}

export default ManageSymptom