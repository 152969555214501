import React, { useContext, useEffect, useState } from "react"
import { Button, Divider, Form, Empty, Input, Modal, Table, Upload, notification, Spin } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import moment from "moment"
import API from "@aws-amplify/api"
import { createLabReport } from "../backend/graphql/mutations"
import { AppContext } from "../contexts/AppContext"
import path from "../utils/pathSettings"
import { useHistory } from "react-router-dom"

const ImportLabResultFileUploadReview = (props) => {
    const { appState } = useContext(AppContext);
    const [form] = Form.useForm()
    const [filter, setFilter] = useState({
        search: "",
    })
    const history = useHistory();
    const [labReportType, setLabReportType] = useState(props?.location?.state?.provider ?? "Lab ?")
    const [totalPatients, setTotalPatients] = useState(53)
    const [errorMessageDisplay, setErrorMessageDisplay] = useState(false)
    const columns = [
        {
            title: "Date Lab",
            // dataIndex: "date"
            dataIndex: "timestamp"
        },
        {
            title: "Hb",
            dataIndex: "Hb"
        },
        {
            title: "MCV",
            dataIndex: "MCV"
        },
        {
            title: "TW",
            dataIndex: "tw"
        },
        {
            title: "Plt",
            dataIndex: "Plt"
        },
        {
            title: "K",
            dataIndex: "K"
        },
        {
            title: "HCO3",
            dataIndex: "HCO3"
        },
        {
            title: "Na",
            dataIndex: "Na"
        },
        {
            title: "Cl",
            dataIndex: "chlorine"
        },
        {
            title: "Creatinine",
            dataIndex: "creatinine"
        },
        {
            title: "Glucose",
            dataIndex: "glucose"
        },
        {
            title: "CCT",
            dataIndex: "CCT"
        },
        {
            title: "Ca",
            dataIndex: "Ca"
        },
        {
            title: "Po4",
            dataIndex: "Po4"
        },
        {
            title: "iPTH",
            dataIndex: "IPTH"
        },
        {
            title: "Urea Pre",
            dataIndex: "ureaPre"
        },
        {
            title: "Urea Post",
            dataIndex: "ureaPost"
        },
        // {
        //     title: "Un",
        //     dataIndex: "un"
        // },
        {
            title: "HepBsAg",
            dataIndex: "HepBsAg"
        },
        {
            title: "HepBsAb",
            dataIndex: "HepBsAb"
        },
        {
            title: "HCV",
            dataIndex: "HCV"
        },
        {
            title: "HIV",
            dataIndex: "HIV"
        },
        {
            title: "Fe",
            dataIndex: "Fe"
        },
        {
            title: "TIBC",
            dataIndex: "TIBC"
        },
        {
            title: "Ferritin",
            dataIndex: "ferritin"
        },
        {
            title: "ESR",
            dataIndex: "ESR"
        },
        {
            title: "CRP",
            dataIndex: "CRP"
        },
        {
            title: "uricAcid",
            dataIndex: "uric"
        },
        {
            title: "TC",
            dataIndex: "TC"
        },
        {
            title: "HDL",
            dataIndex: "HDL"
        },
        {
            title: "LDL",
            dataIndex: "LDL"
        },
        {
            title: "TG",
            dataIndex: "TG"
        },
        {
            title: "Ratio",
            dataIndex: "Ratio"
        },
        {
            title: "Hba1c",
            dataIndex: "Hba1c"
        },
        // {
        //     title: "Hep",
        //     dataIndex: "Hep"
        // },
        // {
        //     title: "Hep (Elisa)",
        //     dataIndex: "HepElisa"
        // },
        {
            title: "Protein",
            dataIndex: "protein"
        },
        {
            title: "Alb",
            dataIndex: "Alb"
        },
        {
            title: "Biltotal",
            dataIndex: "Biltotal"
        },
        {
            title: "Sap",
            dataIndex: "SAP"
        },
        {
            title: "Ast",
            dataIndex: "SGOT_AST"
        },
        {
            title: "Alt",
            dataIndex: "SGPT_ALT"
        },
        {
            title: "Ggt",
            dataIndex: "ggt"
        },
        {
            title: "Mg",
            dataIndex: "Mg"
        },
        {
            title: "Gis",
            dataIndex: "gis"
        },
        {
            title: "Hte",
            dataIndex: "hte"
        },
        {
            title: "Timing",
            dataIndex: "Timing"
        },
        {
            title: "TUP 24 Hrs",
            dataIndex: "TUP24"
        },
        {
            title: "TUP 12 Hrs",
            dataIndex: "TUP12"
        },
        {
            title: "Uvol",
            dataIndex: "Uvol"
        },
        {
            title: "Ublood",
            dataIndex: "Ublood"
        },
        {
            title: "Urbc",
            dataIndex: "Urbc"
        },
        {
            title: "Uprt",
            dataIndex: "Uprt"
        },
        {
            title: "Uwbc",
            dataIndex: "Uwbc"
        },
        {
            title: "Urine MA",
            dataIndex: "urineMA"
        },
        {
            title: "MACr",
            dataIndex: "MACr"
        },
        {
            title: "U creatinine",
            dataIndex: "uCreatinine"
        },
        {
            title: "Tac",
            dataIndex: "Tac"
        },
        {
            title: "CNI",
            dataIndex: "CNI"
        },
        {
            title: "Myco",
            dataIndex: "Myco"
        },
        {
            title: "Pred",
            dataIndex: "Pred"
        },
    ]
    const [loading, setLoading] = useState(false);
    const [labsWithPatient, setLabsWithPatient] = useState(props?.location?.state?.labsWithPatient ?? []);
    const [labReports, setLabReports] = useState([]);
    const [filteredLabReports, setFilteredLabReports] = useState([]);
    const [displayLabReports, setDisplayLabReports] = useState([]);

    useEffect(() => {
        // console.log('props', props);
        return () => { }
    }, [])

    useEffect(() => {
        if (labsWithPatient.length > 0) {
            switch (labReportType) {
                case 'Innoquest':
                    // Processing RAW lab imports...
                    // console.log('ImportLabResultFileUploadReview labsWithPatient', labsWithPatient);

                    // get unique number of nric
                    let noOfUniqueID = [... new Set(labsWithPatient.map(s => s.NRIC))].length;
                    // set total record
                    setTotalPatients(noOfUniqueID);

                    // Filtering list with known/unknown patient
                    let knownLab = labsWithPatient.filter(s => s?.patient);
                    let unknownLab = labsWithPatient.filter(s => !s?.patient);
                    // console.log(unknownLab);

                    // show error if there's unknow lab
                    setErrorMessageDisplay(unknownLab && unknownLab.length > 0 ? [... new Set(unknownLab.map(s => s.NRIC))].length : false);

                    // Sort all error records to the bottom
                    let sortedLabsWithPatient = [];
                    sortedLabsWithPatient = [
                        ...knownLab.sort((a, b) => moment(a?.Date, 'DD/MM/YYYY') > moment(b?.Date, 'DD/MM/YYYY') ? 1 : -1),
                        ...unknownLab.sort((a, b) => moment(a?.Date, 'DD/MM/YYYY') > moment(b?.Date, 'DD/MM/YYYY') ? 1 : -1),
                    ]

                    // Parse lab obj to labReport
                    let _labReports = [];

                    for (let item of sortedLabsWithPatient) {
                        /**
                         * 
                            24HR Urine T.Protein (g/L): ""
                            24HR Urine T.Protein Excretion (g/24hr): ""
                            24hr Creatinine Excretion (mmol/24hr): ""
                            24hr Urine Vol (ml/24hr): ""
                            ABO: ""
                            AFP (ug/L): ""
                            AG (Ratio): ""
                            ALB (g/L): ""
                            ALP (U/L): ""
                            Age: "74"
                            Age_units: "Y"
                            BACT: ""
                            BASP (x10^9/L): ""
                            BHCG (IU/L): ""
                            BLD: ""
                            BMI: ""
                            CA125 (U/mL): ""
                            CA153 (U/mL): ""
                            CA199 (U/mL): ""
                            CA (mmol/L): ""
                            CAST: ""
                            CEA (ug/L): ""
                            CL (mmol/L): ""
                            CREAT (umol/L): ""
                            CRP (mg/L): ""
                            CRYST: ""
                            Collect: "QI110"
                            Collect_Name: "IMMANUEL DIALYSIS CTR (AMK) PL"
                            Creatinine Clearance (mL/min): ""
                            Date: "1/7/21"
                            E2 (pmol/L): ""
                            EBV (RU): ""
                            EOSP (x10^9/L): ""
                            EPI(/uL): ""
                            ESR (mm/hr): ""
                            FSH (IU/L): ""
                            FT3 (pmol/L): ""
                            FT4 (pmol/L): ""
                            Ferritin (ug/L): ""
                            GGT (U/L): ""
                            GLO (g/L): ""
                            GluF (mmol/L): ""
                            GluR (mmol/L): ""
                            HAEMO_F: ""
                            HAVT: ""
                            HB (g/dL): ""
                            HBA1C (%): ""
                            HBA2: ""
                            HBH: ""
                            HBSAB: ""
                            HBSAB_v (mIU/mL): ""
                            HBSAG: ""
                            HCV: ""
                            HDL (mmol/L): ""
                            H_PYLORI: ""
                            Height (cm): ""
                            IGF-1 (ng/mL): ""
                            Iron (umol/L): ""
                            Iron Saturation (%): ""
                            K (mmol/L): ""
                            KET: ""
                            LDL (mmol/L): ""
                            LH (IU/L: ""
                            LYMP (x10^9/L): ""
                            Lab_No: "21-2700443"
                            MCH (pg): ""
                            MCHC (g/dL): ""
                            MCV (fL): ""
                            MONP (x10^9/L): ""
                            MUCOUS_THREAD: ""
                            NA (mmol/L): ""
                            NEUP (x10^9/L): ""
                            NITRITE: ""
                            OCC: ""
                            OCC(IM): ""
                            OTHERS: ""
                            PBF: ""
                            PCV: ""
                            PLT (x10^9/L): ""
                            PO4 (mmol/L): ""
                            PRL (ug/L): ""
                            PROG (nmol/L): ""
                            PSA (ug/L): ""
                            PTH (pmol/L): ""
                            Profiles: ""
                            RA (IU/mL): ""
                            RBC (x10^12/L): ""
                            RC(/uL): ""
                            RDW (%): ""
                            Rhesus: ""
                            SG: ""
                            SGOT (U/L): ""
                            SGPT (U/L): ""
                            Serial_No: "NA"
                            Sex: "F"
                            TB (umol/L): ""
                            TC (mmol/L): ""
                            TC/HDL (Ratio): ""
                            TESTO (nmol/L): ""
                            TIBC (umol/L): ""
                            TP (g/L): ""
                            TPPA: ""
                            TPPA_Titre: ""
                            TRIG (mmol/L): ""
                            TSH (mIU/L): ""
                            Transferrin (g/L): ""
                            UA (mmol/L): ""
                            UBIL: ""
                            UGLU: ""
                            UPRO: ""
                            UREA (Post)(mmol/L): "2.3"
                            UREA (mmol/L): ""
                            UROBIL: ""
                            U_APPR: ""
                            U_CLARITY: ""
                            U_COLOR: ""
                            Urine Creatine 24hr (mmol/L): ""
                            VDRL: ""
                            VDRL_Titre: ""
                            VD_SYPHILIS_AB: ""
                            VitD3 (ng/mL): ""
                            WBC (x10^9/L): ""
                            WC(/uL): ""
                            Waist Circ (cm): ""
                            Weight (kg): ""
                            YEASTS: ""
                            nric: "A1234567B"
                            pH: ""
                            patient: {id: 1, accessIDPt: -265705606, namecode: null, registeredDate: '2019-11-18 00:00:00', quickbookCustomerID: null, …}
                            patient name: ""
                            uALB (mg/L): ""
                            uALB/uCRT (mg/mmol): ""
                            uCRT (mmol/L): ""
                         */

                        // console.log(item);
                        let _labReport = {
                            patientID: item?.patient?.id,

                            // fields to be removed
                            patient: item?.patient,
                            nric: item?.patient?.nric ?? item.NRIC,
                            patientName: item?.patient?.name ?? item['Patient_Name'],
                            gender: item?.Sex,

                            clinicID: appState.selectedClinicID,
                            timestamp: moment(item['Date'], 'DD/MM/YYYY').local().format('YYYY-MM-DD HH:mm:ss'),
                            source: labReportType,
                            name: item['patient name'],

                            glucose: item['GluF (mmol/L)'],
                            TW: item['WBC (x10^9/L)'],
                            Plt: item['PLT (x10^9/L)'],
                            Hb: item['HB (g/dL)'],
                            MCV: item['MCV (fL)'],
                            K: item['K (mmol/L)'],
                            Na: item['NA (mmol/L)'],
                            ureaPre: item['UREA (mmol/L)'],
                            ureaPost: item['UREA (Post)(mmol/L)'],
                            creatinine: item['CREAT (umol/L)'],
                            // HCO3,
                            chlorine: item['CL (mmol/L)'],
                            Ca: item['CA (mmol/L)'],
                            Po4: item['PO4 (mmol/L)'],
                            IPTH: item['PTH (pmol/L)'],
                            HepBsAg: item['HBSAG'],
                            // HepBsAb: item['HBSAB'],
                            HepBsAb: item['HBSAB_v (mIU/mL)'],
                            _two: item['HBSAB_v (mIU/mL)'],
                            Fe: item['Iron (umol/L)'],
                            TIBC: item['TIBC (umol/L)'],
                            ferritin: item['Ferritin (ug/L)'],
                            protein: item['TP (g/L)'],
                            Alb: item['ALB (g/L)'],

                            Ublood: item['BLD'],
                            // Urbc,
                            Uprt: item['UPRO'],
                            Uwbc: item['WC(/uL)'],
                            TUP24: item['24HR Urine TProtein Excretion (g/24hr)'],
                            // TUP12,
                            // Uvol,
                            CCT: item['Creatinine Clearance (mL/min)'],
                            uric: item['UA (mmol/L)'],
                            HCV: item['HCV'],
                            // HIV,
                            ggt: item['GGT (U/L)'],
                            // images,
                            others: item['OTHERS'],
                            // othersDetails,
                            TC: item['TC (mmol/L)'],
                            HDL: item['HDL (mmol/L)'],
                            LDL: item['LDL (mmol/L)'],
                            TG: item['TRIG (mmol/L)'],
                            Ratio: item['TC/HDL (Ratio)'],
                            // Timing,
                            Biltotal: item['TB (umol/L)'],
                            SAP: item['ALP (U/L)'],
                            SGPT_ALT: item['SGPT (U/L)'],
                            SGOT_AST: item['SGOT (U/L)'],
                            Hba1c: item['HBA1C (%)'],
                            // labsNoted,
                            // SBP,
                            // DBP,
                            // Mg,
                            // Tac,
                            urineMA: item['uALB (mg/L)'],
                            MACr: item['uALB/uCRT (mg/mmol)'],
                            uCreatinine: item['uCRT (mmol/L)'],
                            ESR: item['ESR (mm/hr)'],
                            CRP: item['CRP (mg/L)'],
                            // CNI,
                            // Myco,
                            // Pred,
                            // noted,
                            t4: item['FT4 (pmol/L)'],
                            // FT4,
                            TSH: item['TSH (mIU/L)'],

                            // gis,
                            // hte,
                        }

                        _labReports.push(_labReport);
                    }
                    setLabReports(_labReports);
                    break;
                default:
                    break;
            }
        }

    }, [labsWithPatient]);

    useEffect(() => {
        // console.log(filter, labReports.length);
        if (filter?.search?.length > 0) {
            // let _filteredLabReports = labReports.filter(s => s.nric.toUpperCase().search(filter.search.toUpperCase()) || s.name.toUpperCase().search(filter.search.toUpperCase()));
            let _filteredLabReports = labReports.filter(s =>
                s?.nric?.toUpperCase()?.includes(filter.search.toUpperCase()) ||
                s?.patientName?.toUpperCase()?.includes(filter.search.toUpperCase()) ||
                s?.name?.toUpperCase()?.includes(filter.search.toUpperCase())
            );
            // console.log('_filteredLabReports.length', _filteredLabReports.length);

            setFilteredLabReports(_filteredLabReports);
        } else {
            setFilteredLabReports(labReports);
        }
    }, [filter, labReports]);

    useEffect(() => {
        if (filteredLabReports.length > 0) {
            // get unique patient IC
            let uniqueICs = [... new Set(filteredLabReports.map(s => s.nric?.toUpperCase()))];

            let _displayLabReports = [];
            // build labReports for each patient
            for (let ic of uniqueICs) {
                let findLabReport = filteredLabReports.find(s => s.nric == ic);
                let _displayLabReport = {
                    patientID: findLabReport?.patientID,
                    patient: findLabReport?.patient,
                    name: findLabReport?.patientName,
                    nric: findLabReport?.nric,
                    gender: findLabReport?.gender,

                    labReports: filteredLabReports.filter(s => s.nric == ic),
                }
                _displayLabReports.push(_displayLabReport);
            }
            setDisplayLabReports(_displayLabReports);
        } else {
            setDisplayLabReports([]);
        }
        setLoading(false);
    }, [filteredLabReports])

    const _createLabReport = async () => {
        setLoading(true);
        try {

            // break bulk emails into block of 50
            let labReports_block = [];
            const chunk = 30;
            for (let begin = 0; begin < labReports.length; begin += chunk) {
                let temparray = labReports.slice(begin, begin + chunk);
                labReports_block.push(temparray);
            }

            if (labReports_block.length > 1) {
                notification.info({
                    key: 'multi_labreport_upload',
                    message: `Inserting ${labReports.length} records in ${labReports_block.length} parts`
                })
            }

            let result;
            for (let [blockIndex, block] of labReports_block.entries()) {
                let variables = {
                }

                let query = `mutation CreateLabReport(`;
                let query2 = `mutation CreateLabReport`;
                let param = ``;
                let body = ``;
                let body2 = ``;

                for (let [index, lab] of block.entries()) {
                    let _lab = {
                        ...lab
                    };

                    // process HepBsAb values
                    if (_lab.HepBsAb) {
                        // console.log('HepBsAb')
                        if (_lab.HepBsAb.match(/[0-9]+/g)) {
                            if (_lab.HepBsAb.includes('>')) {
                                // console.log('HepBsAb gt')
                                let HepBsAb = _lab.HepBsAb.replace('>', '');
                                if (parseInt(HepBsAb)) {
                                    HepBsAb = parseInt(HepBsAb) + 1;
                                }
                                _lab.HepBsAb = HepBsAb;
                            } else if (_lab.HepBsAb.includes('<')) {
                                // console.log('HepBsAb lt')
                                let HepBsAb = _lab.HepBsAb.replace('<', '');
                                if (parseInt(HepBsAb)) {
                                    HepBsAb = parseInt(HepBsAb) - 1;
                                }
                                _lab.HepBsAb = HepBsAb;
                            }
                        }
                    }

                    if (_lab.HepBsAg) {
                        if (_lab.HepBsAg.toLowerCase() === 'non-reactive') {
                            _lab.HepBsAg = 'NEG'
                        } else if (_lab.HepBsAg.toLowerCase() === 'reactive') {
                            _lab.HepBsAg = 'POS'
                        }
                    }
                    
                    if (_lab.HCV) {
                        if (_lab.HCV.toLowerCase() === 'non-reactive') {
                            _lab.HCV = 'NEG'
                        } else if (_lab.HCV.toLowerCase() === 'reactive') {
                            _lab.HCV = 'POS'
                        }
                    }
                    
                    if (_lab.HIV) {
                        if (_lab.HIV.toLowerCase() === 'non-reactive') {
                            _lab.HIV = 'NEG'
                        } else if (_lab.HIV.toLowerCase() === 'reactive') {
                            _lab.HIV = 'POS'
                        }
                    }

                    // fields to be removed
                    _lab.patient = null;
                    _lab.nric = null;
                    _lab.patientName = null;
                    _lab.gender = null;
                    _lab.clinicID = parseInt(_lab.clinicID);

                    _lab = Object.entries(_lab).reduce(
                        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                        {}
                    )

                    variables['create' + index] = _lab;

                    param += `
                    $create${index}: CreateLabReportInput!`

                    body += `
                      result${index}: createLabReport(create: $create${index}) {
                        id
                        patientID
                        clinicID
                        timestamp
                        source
                        accessIDlab
                        name
                        glucose
                        TW
                        Plt
                        Hb
                        MCV
                        K
                        Na
                        ureaPre
                        ureaPost
                        creatinine
                        HCO3
                        chlorine
                        Ca
                        Po4
                        IPTH
                        HepBsAg
                        HepBsAb
                        _two
                        Fe
                        TIBC
                        ferritin
                        protein
                        Alb
                        Ublood
                        Urbc
                        Uprt
                        Uwbc
                        TUP24
                        TUP12
                        Uvol
                        CCT
                        uric
                        HCV
                        HIV
                        ggt
                        images
                        others
                        othersDetails
                        TC
                        HDL
                        LDL
                        TG
                        Ratio
                        Timing
                        Biltotal
                        SAP
                        SGPT_ALT
                        SGOT_AST
                        Hba1c
                        labsNoted
                        SBP
                        DBP
                        Mg
                        Tac
                        urineMA
                        MACr
                        uCreatinine
                        ESR
                        CRP
                        CNI
                        Myco
                        Pred
                        noted
                        t4
                        FT4
                        TSH
                        gis
                        hte
                        createdOn
                        updatedOn
                        deleted
                        deletedOn
                      }`;

                    // body2 += `
                    //   result${index}: createLabReport(create: ${JSON.stringify(_lab)}) {
                    //     id
                    //     patientID
                    //     clinicID
                    //     timestamp
                    //     source
                    //     accessIDlab
                    //     name
                    //     glucose
                    //     TW
                    //     Plt
                    //     Hb
                    //     MCV
                    //     K
                    //     Na
                    //     ureaPre
                    //     ureaPost
                    //     creatinine
                    //     HCO3
                    //     chlorine
                    //     Ca
                    //     Po4
                    //     IPTH
                    //     HepBsAg
                    //     HepBsAb
                    //     _two
                    //     Fe
                    //     TIBC
                    //     ferritin
                    //     protein
                    //     Alb
                    //     Ublood
                    //     Urbc
                    //     Uprt
                    //     Uwbc
                    //     TUP24
                    //     TUP12
                    //     Uvol
                    //     CCT
                    //     uric
                    //     HCV
                    //     HIV
                    //     ggt
                    //     images
                    //     others
                    //     othersDetails
                    //     TC
                    //     HDL
                    //     LDL
                    //     TG
                    //     Ratio
                    //     Timing
                    //     Biltotal
                    //     SAP
                    //     SGPT_ALT
                    //     SGOT_AST
                    //     Hba1c
                    //     labsNoted
                    //     SBP
                    //     DBP
                    //     Mg
                    //     Tac
                    //     urineMA
                    //     MACr
                    //     uCreatinine
                    //     ESR
                    //     CRP
                    //     CNI
                    //     Myco
                    //     Pred
                    //     noted
                    //     t4
                    //     FT4
                    //     TSH
                    //     gis
                    //     hte
                    //     createdOn
                    //     updatedOn
                    //     deleted
                    //     deletedOn
                    //   }`;
                }

                query = query + param + `
                ) {` + body + `
                }`;
                // query2 = query2 + `
                // {` + body2 + `
                // }`;

                // console.log('_createLabReport', query, variables);
                // console.log('query2', query2);

                result = await API.graphql({
                    query: query,
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    variables: variables
                })


                if (labReports_block.length > 1) {
                    if (Object.keys(result?.data).length > 0) {
                        notification.success({
                            key: 'multi_labreport_upload',
                            message: `Uploaded Part ${blockIndex + 1} of ${labReports_block.length}`
                        });
                    }
                }
                // console.log("API result PatientRecordLabResults createLabReport", result);
            }

            if (Object.keys(result?.data).length > 0) {
                notification.success({
                    key: 'multi_labreport_upload',
                    message: 'Import Lab Report uploaded successfully'
                });
                history.push(path('dashboard'));
            } else {
                notification.error({
                    message: "Unable to import lab report"
                })
            }

            setLoading(false);
        } catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to import lab report"
            })
        } finally { }
    }

    const onFinish = () => {
        // console.log("submit form");
        if (errorMessageDisplay === false) {
            // check and make sure all got patientID
            let checkLabs = labReports.map(s => s.patientID).filter(s => s.toString().length == 0 || s == 0);

            if (checkLabs.length > 0) {
                notification.error({ message: 'Unable to process with unidentified Patient' });
            } else {
                _createLabReport();
            }
        } else {
            notification.error({ message: 'Unable to process with unidentified Patient' });
        }
    }

    const handleSearchChange = (e) => {
        // console.log("handleSearchChange");
        // console.log(e.target.value);
        setLoading(true);
        setFilter({
            ...filter,
            search: e.target.value
        })
    }

    const renderRecordsByPatientItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            // const error = item.error !== undefined ? item.error : false
            const error = item.patient !== undefined ? false : true;
            return (
                <div className="container-patient-error">
                    <div className="container-patient">
                        <div className="container-patient-header">
                            <div className="patientname">
                                {/* {item?.patient?.name} */}
                                {(item?.patient) ?
                                    (item?.patient?.name) :
                                    (item?.name)
                                }
                            </div>
                            <div>
                                {(item?.nric)}
                            </div>
                        </div>
                        <Divider />
                        <Table
                            dataSource={item.labReports}
                            columns={columns}
                            pagination={false}
                        />
                    </div>
                    {
                        error === true ? (
                            <div>
                                <div className="error-overlay">
                                    {/* <div>
                                        <div className="text">
                                            This patient is not yet in our data
                                        </div>
                                        <Button onClick={() => {}}>
                                            Add New Patient
                                        </Button>
                                    </div> */}
                                </div>
                                <div className="error-content">
                                    <div className="text">
                                        This patient is not yet in our data
                                    </div>
                                    <Button type="primary" onClick={() => {
                                        // history.push(path('patientCreate'))
                                        window.open(path('patientCreate') + `?name=${item?.name}${item?.nric ? '&nric=' + item.nric : ''}${item?.gender ? '&gender=' + item.gender : ''}`, '_blank');
                                    }}>
                                        Add New Patient
                                    </Button>
                                </div>
                            </div>
                        ) : ("")
                    }
                </div>
            )
        })
        return items
    }

    const renderRecordsByPatient = (array) => {
        if (array.length !== 0) {
            return renderRecordsByPatientItems(array)
        }
        else {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    }

    return (
        <div className="content-content">
            <div className="importlabresultreview-header">
                <div className="row1">
                    <div className="column1">
                        <div className="title">
                            Import Lab Result - File Upload
                        </div>
                    </div>
                    <div className="statscard">
                        <div className="column1">Total<br />Patients</div>
                        <div className="column2">{totalPatients}</div>
                    </div>
                    <div className="column3">
                        <Button type="primary" onClick={onFinish} disabled={errorMessageDisplay} loading={loading}>Confirm</Button>
                    </div>
                </div>
                <div className="row2">
                    <Spin spinning={loading}>
                        <Form
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            requiredMark={false}
                        >
                            <div className="container-row">
                                <div className="row">
                                    <Form.Item
                                        className="search"
                                        name="search"
                                    >
                                        <Input autoComplete="off" prefix={<SearchOutlined />} placeholder="Search patient / NRIC" onChange={handleSearchChange} value={filter.search} allowClear />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Spin>
                    <div className="labReportType">
                        {labReportType}
                    </div>
                </div>
            </div>
            {
                errorMessageDisplay !== false ? (
                    <div className="importlabresult-error">
                        {`(${errorMessageDisplay})`} Patient Record(s) Not Found
                    </div>
                ) : ("")
            }
            <Divider />
            <Spin spinning={loading}>
                <div className="importlabresultreview-content">
                    {renderRecordsByPatient(
                        // labsWithPatient
                        displayLabReports
                        // [
                        //     {
                        //         id: 1,
                        //         name: "Patient ABC",
                        //         nric: "*****123A"
                        //     },
                        //     {
                        //         id: 2,
                        //         name: "Patient BCD",
                        //         nric: "*****123B",
                        //         error: true,
                        //     },
                        // ]
                    )}
                </div>
            </Spin>
        </div>
    )
}

export default ImportLabResultFileUploadReview