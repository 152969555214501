import React, { useContext, useEffect, useState } from "react"
import { Button, Form, Input, Modal, notification, Spin } from "antd"
import { useHistory } from "react-router-dom"
import API from "@aws-amplify/api"
import moment from "moment"

import path from "../utils/pathSettings"
import { createDialysis, startDialysis, updateDialysis } from "../backend/graphql/mutations"
import { listDialysis } from "../backend/graphql/queries"
import { AppContext } from "../contexts/AppContext"
import PatientRecordHeader from "./Component-PatientRecord-Header"
import PatientRecordPreweights from "./Component-PatientRecord-Preweights"
import PatientRecordDialysisOrder from "./Component-PatientRecord-DialysisOrder"

const PatientRecordPreweightsDialysisOrder = (props) => {
    const { appState } = useContext(AppContext);
    const {
        page,
        patientID,
        appointmentID,
        dialysisID,
        lastDialysisIntervalNum,
        setLastDialysisIntervalNum,
        lastDialysis,
        setLastDialysis,
        // dialysisCompleted
        manageDialysis = false
    } = props
    // console.log("dialysisCompleted", dialysisCompleted);
    const history = useHistory()
    const [form] = Form.useForm();
    // const [form2] = Form.useForm();
    // const { TextArea } = Input
    const [values, setValues] = useState({
        sessionMonth: "",
        preWt: "",
        lastWt: "",
        dw: "",
        preTemp: "",
        preEdema: "",
        preSBP: "",
        preDBP: "",
        preHRT: "",
        ufTargetAtStart: "",
        // wtGain: "",
        clinicID: '',
        machineID: "",
        dialyser: "",
        msa: "",
        // membraneUse: "",
        membraneUse: "1",
        renalinPrecheck: "",
    });
    const [dialysisOrderID, setDialysisOrderID] = useState(0);
    const [dwFromDialysiOrder, setDwFromDialysiOrder] = useState("")
    const [patientDisplay, setPatientDisplay] = useState({})
    const validateMessages = {
        required: "Required.",
    }
    const [visibilityModal, setVisibilityModal] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0) {
            // console.log('PatientRecordPreweightsDialysisOrder', page, patientID);
            // _listDialysisOrder(patientID)
            // setValues({
            //     ...values,
            //     clinicID: parseInt(appState.selectedClinicID)
            // })
            // console.log(page);

            if (page === 'intra' || page === 'checkinEdit') {
                _listDialysis(parseInt(patientID), parseInt(dialysisID));
            } else {
                _listDialysis(parseInt(patientID));
            }
        }
        return () => { }
    }, [appState.cognitoUser])

    useEffect(() => {
        // console.log('PatientRecordPreweightsDialysisOrder', values);
        if (page === "checkin") {
            // form.validateFields()
            form.setFieldsValue({
                ...values,
                dw: dwFromDialysiOrder
            })
        }
        else if (page === 'checkinEdit') {
            // let tempValues = {}
            // for (let [k, v] of Object.entries(values)) {
            //     // console.log(k, v)
            //     if (v && v != '0' && v != '0.0')
            //         tempValues[k] = v;
            //     else
            //         tempValues[k] = null;
            // }
            // console.log('tempValues', tempValues);
            // form.setFieldsValue(tempValues);
            form.setFieldsValue(values)
        } else {
            form.setFieldsValue(values)
        }
        // if (page === "checkin") {
        //     form.validateFields()
        // }
    }, [values, dwFromDialysiOrder])

    const getWeight = (weight) => {
        if (!weight)
            return null;

        let weightString = weight.toString();
        let weightResult;

        // check if is decimal
        if (weightString.includes('.')) {
            weightResult = Number(weightString) * 1000;
        }
        // check if within 999 (kg)
        else if (weightString.length <= 3) {
            weightResult = Number(weightString) * 1000;
        }
        // otherwise more than 4 character
        else {
            weightResult = Number(weightString);
        }

        // console.log('getWight', weightString, weightResult);

        return parseInt(weightResult);
    }

    const getSessionMonth = (date, sessionMonth) => {
        // console.log(date, sessionMonth);
        if (sessionMonth > 0) {
            let prevMonth = moment(date).local().format('YYYY-MM');
            let currentMonth = moment().local().format('YYYY-MM');
            // console.log(prevMonth, currentMonth);

            if (prevMonth == currentMonth) {
                return parseInt(sessionMonth) + 1;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    }

    const _listDialysis = async (patientID, dialysisID = 0) => {
        setLoading(true);
        try {
            const variables = {
                filter: {
                    patientID: {
                        eq: patientID
                    }
                }
            }

            if (dialysisID > 0) {
                variables.filter.id = {
                    eq: dialysisID
                };
            } else {
                variables.pagination = {
                    limit: 1,
                    orderby: 'startTime DESC',
                };
            }

            // console.log(variables);

            const result = await API.graphql({
                query: listDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log(API result PatientRecordPreweightsDialysisOrder _listDialysis", result);
            const data = result.data.result
            const array = result.data.result.result

            // process data
            if (array.length > 0) {
                let r = {};

                if (page === 'intra' || page === 'checkinEdit') {
                    r = { ...array[0] };
                    r.preWeight = parseInt(r.preWeight) >= 0 ? (parseInt(r.preWeight) / 1000.0).toFixed(1) : null;
                    r.postWeight = parseInt(r.postWeight) >= 0 ? (parseInt(r.postWeight) / 1000.0).toFixed(1) : null;
                    r.dryWeight = parseInt(r.dryWeight) >= 0 ? (parseInt(r.dryWeight) / 1000.0).toFixed(1) : null;
                    r.preTemp = parseFloat(r.preTemp) >= 0 ? (parseFloat(r.preTemp).toFixed(1)) : null;
                    r.lastWeight = (dialysisID > 0) ? (Number(r.lastWeight) / 1000.0).toFixed(1) : (
                        r.postWeight ?
                            (parseInt(r.postWeight) / 1000.0).toFixed(1) :
                            r.postWeight
                    );
                    // r.postTemp = (parseFloat(r.postTemp).toFixed(1));

                    r.arriveTimeLocal = moment.utc(r.arriveTime).local().format('DD MMM YYYY, HH:mm:ss');
                    r.startTimeLocal = moment.utc(r.startTime).local().format('DD MMM YYYY, HH:mm:ss');

                    r.postSBPDBP = r.postSBP + ' / ' + r.postDBP;
                    r.preWt = r.preWeight;
                    r.dw = r.dryWeight;

                    r.ufTargetAtStart = r.ufTargetStart;
                    r.renalinPrecheck = r.preRenalin;

                    r.expectedLWDifference = (r.preWeight - r.ufTargetStart - r.lastWeight).toFixed(1);
                    r.expectedDWDifference = (r.preWeight - r.ufTargetStart - r.dryWeight).toFixed(1);
                    r.sessionMonth = array[0].sessionMonth;

                    r.preEdema = r?.preEdema?.toString();
                    r.preSBP = r?.preSBP?.toString();
                    r.preDBP = r?.preDBP?.toString();
                    r.preHRT = r?.preHRT?.toString();
                } else {
                    r.lastWeight = (dialysisID > 0) ? (parseInt(array[0].lastWeight) / 1000.0).toFixed(1) : (
                        array[0].postWeight ?
                            (parseInt(array[0].postWeight) / 1000.0).toFixed(1) :
                            array[0].postWeight
                    );
                    r.postWeight = array[0].postWeight;
                    r.endTime = array[0].endTime;
                    r.startTime = array[0].startTime;
                    r.sessionMonth = array[0].sessionMonth;
                }

                // console.log('r.postWeight', r);

                let newSessionMonth = getSessionMonth(r.endTime ? r.endTime : r.startTime, r.sessionMonth);
                r.lastWt = r.lastWeight;
                if (page == 'checkin')
                    r.sessionMonth = newSessionMonth;
                r.clinicID = parseInt(appState.selectedClinicID);

                // save result to values
                // setDialysis(r);
                let newResult = {
                    ...values,
                    ...r,
                };
                setValues(newResult);
                if (page != 'record')
                    setLastDialysis(newResult);
                // console.log(newResult", newResult);
            } else {
                let newResult = {
                    ...values,
                    sessionMonth: 1,
                    lastWt: '',
                    clinicID: parseInt(appState.selectedClinicID),
                };
                setValues(newResult)
                if (page != 'record')
                    setLastDialysis(newResult);
            }
            // setLoading(false);
        }
        catch (error) {
            console.log("error: ", error);
        }
        finally { setLoading(false) }
    }

    const _createDialysis = async (patientID, values) => {
        setLoading(true);
        try {
            let locURL = window.location.href.split('//');
            let domainURL = locURL[1].split('/')[0];
            let redirect_uri = locURL[0] + '//' + domainURL + path('manageUnlinkedInvoice') + '/';
            const variables = {
                create: {
                    patientID: parseInt(patientID),
                    sessionMonth: parseInt(values.sessionMonth),
                    arriveTime: moment().toISOString().split('.')[0],
                    startTime: moment().toISOString().split('.')[0],

                    clinicID: parseInt(values.clinicID),
                    preWeight: getWeight(values.preWt),
                    lastWeight: getWeight(values.postWeight),
                    dryWeight: getWeight(values.dw),

                    preTemp: parseFloat(values.preTemp),
                    preEdema: parseInt(values.preEdema),
                    preSBP: parseInt(values.preSBP),
                    preDBP: parseInt(values.preDBP),
                    preHRT: parseInt(values.preHRT),
                    // preRenalin: values.renalinPrecheck,
                    ufTargetStart: parseFloat(values.ufTargetAtStart),

                    machineID: parseInt(values.machineID),
                    dialyser: values.dialyser,
                    MSA: values.MSA ? parseFloat(values.MSA) : null,
                    membraneUse: values.membraneUse,

                    dialysisOrderID: parseInt(dialysisOrderID),
                },
                appointmentID: parseInt(appointmentID),
                redirectURL: redirect_uri,
            };

            variables.create = Object.entries(variables.create).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: startDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log(API result createDialysis", result);

            const data = result.data.result
            const array = result.data.result.result

            // console.log('createDialysis', data, array);
            if (data)
                return data;
            // setLoading(false);

            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            if (error?.errors && error?.errors?.length > 0 && error?.errors[0].message == 'Invalid AppointmentID') {
                notification.error({ message: 'Invalid Appointment.' })
                history.push(path("dashboard"));
            } else if (error?.errors && error?.errors?.length > 0 && error?.errors[0].message == 'Machine already in use in another appointment.') {
                notification.error({ message: 'Machine already in use in another appointment.' })
            } else if (error?.errors && error?.errors?.length > 0 && error?.errors[0].message == 'Dialysis already started.') {
                notification.error({ message: 'Dialysis already started.' })
            } else {
                notification.error({
                    message: "Unable to retrieve Dialysis"
                })
            }
        }
        finally { setLoading(false) }
    }

    const _updateDialysis = async (patientID, dialysisID, values) => {
        setLoading(true);
        try {

            // console.log(alues.preTemp, parseFloat(values.preTemp), parseFloat(values.preTemp) >= 0);
            const variables = {
                update: {
                    id: parseInt(dialysisID),
                    patientID: parseInt(patientID),
                    // sessionMonth: parseInt(values.sessionMonth),
                    // arriveTime: moment().toISOString().split('.')[0],

                    clinicID: parseInt(values.clinicID),
                    preWeight: getWeight(values.preWt) ?? null,
                    lastWeight: getWeight(values.lastWt) ?? null,
                    dryWeight: getWeight(values.dw) ?? null,

                    preTemp: parseFloat(values.preTemp) >= 0 ? parseFloat(values.preTemp) : null,
                    preEdema: parseInt(values.preEdema) >= 0 ? parseInt(values.preEdema) : null,
                    preSBP: parseInt(values.preSBP) >= 0 ? parseInt(values.preSBP) : null,
                    preDBP: parseInt(values.preDBP) >= 0 ? parseInt(values.preDBP) : null,
                    preHRT: parseInt(values.preHRT) >= 0 ? parseInt(values.preHRT) : null,
                    // preRenalin: values.renalinPrecheck,
                    ufTargetStart: parseFloat(values.ufTargetAtStart) < 0 ? null : parseFloat(values.ufTargetAtStart),

                    machineID: parseInt(values.machineID) || null,
                    dialyser: values.dialyser || null,
                    MSA: values.MSA ? parseFloat(values.MSA) : null,
                    membraneUse: values.membraneUse,

                    dialysisOrderID: parseInt(dialysisOrderID),
                },
                // appointmentID: parseInt(appointmentID),
            };

            variables.update = Object.entries(variables.update).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: updateDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result updateDialysis", result);

            const data = result.data.result
            const array = result.data.result.result

            // console.log('createDialysis', data, array);
            if (data)
                history.push({
                    pathname: path('dashboard')
                })
            // setLoading(false);

            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            // if (error?.errors && error?.errors?.length > 0 && error?.errors[0].message == 'Invalid AppointmentID') {
            //     notification.error({ message: 'Invalid Appointment.' })
            //     history.push(path("dashboard"));
            // } else if (error?.errors && error?.errors?.length > 0 && error?.errors[0].message == 'Machine already in use in another appointment.') {
            //     notification.error({ message: 'Machine already in use in another appointment.' })
            // } else {
            notification.error({
                message: "Unable to update Dialysis"
            })
            // }
        }
        finally { setLoading(false) }
    }

    const onFinish = async () => {
        // console.log("Handle check in / finished clicked", page);
        // await form.validateFields()
        if (page === "intra") {

            // if (appState.tokenExpired) {
            //     notification.error({ message: "QuickBooks token expired. Please sign in to QuickBooks and Refresh", duration: null });

            //     let locURL = window.location.href.split('//');
            //     let domainURL = locURL[1].split('/')[0];
            //     // let redirect_uri = (window.location.hostname == 'localhost' ? `http://localhost:3000` : window.location.href.split('//')[0]) + path('redirectQuickbooks');
            //     let redirect_uri = locURL[0] + '//' + domainURL + path('redirectQuickbooks') + '/';
            //     // console.log('redirectURL', redirect_uri);

            //     window.open(`https://appcenter.intuit.com/connect/oauth2?client_id=${process.env.REACT_APP_QUICKBOOKS_CLIENTID}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect_uri}&state=testState`, '_blank');
            // } else {
            //     if interval !== 8
            if (lastDialysisIntervalNum < 8)
                setVisibilityModal(true)
            else {
                history.push({
                    pathname: path('patientDialysisCheckOut', [patientID, dialysisID]),
                    state: { checkOutException: false }
                })
            }
            // }
        }
        else if (page === "checkin") {
            if (dialysisOrderID) {
                // redirect to dialysis
                let v = await form.validateFields();
                // console.log('form', v);
                // console.log('values', values);

                let result = await _createDialysis(patientID, { ...values, ...v });
                // console.log(result);

                // history.push({
                //     pathname: path('patientDialysis', [patientID]),
                //     state: {
                //         dialysisID: result.id
                //     }
                // })
                // history.push(path('patientDialysis', [patientID, result.id]));
                // Redirect to home page
                if (result)
                    history.push(path("dashboard"));
            } else {
                notification.error({ message: "No Dialysis Order" })
            }
        }
        else if (page === "checkinEdit") {
            // console.log("Update check in details");
            let v = await form.validateFields();
            // console.log('form', v);
            // console.log('values', values);
            // == Call update dialysis API here ==
            _updateDialysis(patientID, dialysisID, { ...v });
        }
    }

    const onFinishModal = async () => {
        history.push({
            pathname: path('patientDialysisCheckOut', [patientID, dialysisID]),
            state: { checkOutException: true }
        })
    }
    // const onFinishModal = async () => {
    //     console.log("Handle modal finished clicked");
    //     let v = await form2.validateFields()
    //     console.log(v);
    //     // redirect to check out page with additional notes
    //     // history.push(path('patientDialysis', [patientID, result.id]));
    //     history.push({
    //         pathname: path('patientDialysisCheckOut', [patientID, dialysisID]),
    //         state: { notes: v.additionalNotes }
    //     })
    // }

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                layout="horizontal"
                validateMessages={validateMessages}
                requiredMark={true}
            >
                <PatientRecordHeader onFinish={onFinish} page={page} patientID={patientID} setPatientDisplay={setPatientDisplay} manageDialysis={manageDialysis} />
                {/* <PatientRecordHeader onFinish={onFinish} page={page} patientID={patientID} dialysisCompleted={dialysisCompleted} /> */}
                {
                    manageDialysis === false ? (
                        <div className="patientrecord-checkin">
                            {/* //<PatientRecordPreweights values={values} setValues={setValues} onFinish={onFinish} page={page} patientID={props?.patientID} form={form} />
                    <PatientRecordDialysisOrder patientID={props?.patientID} values={values} setValues={setValues} dialysisOrderID={dialysisOrderID} setDialysisOrderID={setDialysisOrderID} />// */}
                            {
                                page !== "record" ? (
                                    <PatientRecordPreweights values={values} setValues={setValues} onFinish={onFinish} page={page} patientID={props?.patientID} form={form} patientDisplay={patientDisplay} />
                                ) : ""
                            }
                            {/* //{
                        dialysisCompleted === false ? 
                        <PatientRecordDialysisOrder patientID={props?.patientID} values={values} setValues={setValues} dialysisOrderID={dialysisOrderID} setDialysisOrderID={setDialysisOrderID} />
                        : ""
                    }// */}

                            <PatientRecordDialysisOrder patientID={props?.patientID} values={values} setValues={setValues} dialysisOrderID={dialysisOrderID} setDialysisOrderID={setDialysisOrderID} setDwFromDialysiOrder={setDwFromDialysiOrder} />
                        </div>
                    ) : ""
                }
                {/* <div className="patientrecord-checkin">
                    {
                        page !== "record" ? (
                            <PatientRecordPreweights values={values} setValues={setValues} onFinish={onFinish} page={page} patientID={props?.patientID} form={form} patientDisplay={patientDisplay} />
                        ) : ""
                    }
                    <PatientRecordDialysisOrder patientID={props?.patientID} values={values} setValues={setValues} dialysisOrderID={dialysisOrderID} setDialysisOrderID={setDialysisOrderID} />
                </div> */}
                <Modal
                    title={
                        <div className="modal-title">
                            Check Out Exception
                        </div>
                    }
                    visible={visibilityModal}
                    onCancel={() => setVisibilityModal(false)}
                    footer={[
                        <Button key="cancel" className="cancel" onClick={() => setVisibilityModal(false)}>
                            No
                        </Button>,
                        <Button key="checkout" type="primary" className="okay" onClick={onFinishModal}>
                            Yes
                        </Button>,
                    ]}
                    className="modal-patientrecord-checkout"
                >
                    <div style={{ fontWeight: 700 }}>
                        Dialysis session is not yet completed, are you sure you want to proceed with the check out process?
                    </div>
                </Modal>
                {/* <Modal
                    title={
                        <div className="modal-title">
                            Check Out Exception
                        </div>
                    }
                    visible={visibilityModal}
                    onCancel={() => setVisibilityModal(false)}
                    footer={[
                        <Button key="cancel" className="cancel" onClick={() => setVisibilityModal(false)}>
                            Cancel
                        </Button>,
                        <Button key="checkout" type="primary" className="okay" onClick={onFinishModal}>
                            Finish
                        </Button>,
                    ]}
                    className="modal-patientrecord-checkout"
                >
                    <div style={{ fontWeight: 700 }}>
                        Please provide details
                    </div>
                    <br />
                    <Form
                        form={form2}
                        layout="vertical"
                        onFinish={onFinishModal}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Form.Item
                            label="Additional Notes"
                            name="additionalNotes"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            className="additionalnotes"
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                    </Form>
                </Modal> */}
            </Form>
        </Spin>
    )
}

export default PatientRecordPreweightsDialysisOrder