import React, { useContext, useEffect, useState } from "react"
import { notification, Spin } from "antd"
import { useHistory } from "react-router-dom"
import moment from "moment"
import API from "@aws-amplify/api"

import { listAppointmentDetail } from "../backend/graphql/queries"
import { AppContext } from "../contexts/AppContext"
import path from "../utils/pathSettings"
import PatientRecordAdministeredMedication from "./Component-PatientRecord-AdministeredMedication"
import PatientRecordCalculatedLabResults from "./Component-PatientRecord-CalculatedLabResults"
import PatientRecordClinicNHospitalE from "./Component-PatientRecord-ClinicNHospitalE"
import PatientRecordDialysisHistory from "./Component-PatientRecord-DialysisHistory"
import PatientRecordIntraDialysisData from "./Component-PatientRecord-IntraDialysisData"
import PatientRecordIntraDialysisInput from "./Component-PatientRecord-IntraDialysisInput"
import PatientRecordLabResults from "./Component-PatientRecord-LabResults"
import PatientRecordMedLabMilestone from "./Component-PatientRecord-MedLabMilestone"
import PatientRecordPreweightsDialysisOrder from "./Component-PatientRecord-PreweightsDialysisOrder"
import PatientRecordLastNonNullLab from "./Component-PatientRecord-LastNonNullLab"

const PatientDialysisIntraDialysis = (props) => {
    const { appState } = useContext(AppContext)
    const [lastDialysisIntervalNum, setLastDialysisIntervalNum] = useState(0)
    const [lastDialysisInterval, setLastDialysisInterval] = useState({})
    const [lastDialysis, setLastDialysis] = useState({})
    const [dialysisCompleted, setDialysisCompleted] = useState(false)
    const patientID = props?.match?.params?.id;
    let dialysisID = props?.match?.params?.id2;
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    const getAppointmentRecord = async () => {
        setLoading(true)
        try {
            const variables = {
                filter: {
                    date: {
                        eq: moment().format("YYYY-MM-DD")
                    },
                    deleted: {
                        eq: 0
                    },
                    patientID: {
                        eq: patientID
                    },
                    clinicID: {
                        eq: appState.selectedClinicID
                    }
                }
            }
            // console.log('get appointment record variables', variables);

            const result = await API.graphql({
                query: listAppointmentDetail,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("result", result);
            const data = result.data.result.result[0] ?? null
            // console.log("data", data);

            // if start go checkin, if end go intra dialysis
            if (data !== null) {
                if (data.dialysisStartTime === null) {
                    // console.log("get appointment id", data);
                    history.push(path("patientDialysisCheckIn", [patientID, data.id]))
                }
                else if (data.dialysisEndTime === null) {
                    history.push(path("patientDialysis", [patientID, data.dialysisID]))
                }
                else {
                    notification.info({
                        message: "No Active Diaylsis Session"
                    })
                    history.push(path("patientRecord", [patientID]))
                }
            }
            else {
                notification.info({
                    message: "No Active Diaylsis Session"
                })
                history.push(path("patientRecord", [patientID]))
            }
        }
        catch (error) {
            console.log("error", error);
        }
        finally {
            setLoading(false)
        }
    }

    const checkDialysisAppointment = async () => {
        getAppointmentRecord()
    }

    useEffect(() => {
        if (appState.selectedClinicID !== "" && dialysisID === "undefined") {
            // Check if there is appointment made, if yes, go to check in; else, go to patient record
            checkDialysisAppointment()
        }
        return () => { }
    }, [appState.selectedClinicID])

    useEffect(() => {
        if (Object.keys(lastDialysis).length !== 0) {
            // console.log("lastDialysis", lastDialysis);
            if (lastDialysis?.id?.toString() === dialysisID?.toString() && lastDialysis?.endTime !== undefined && lastDialysis?.endTime !== null && lastDialysis?.endTime !== "") {
                setDialysisCompleted(true)
                notification.info({
                    message: "No Active Diaylsis Session"
                })
                history.push(path("patientRecord", [patientID]))
            }
        }
    }, [lastDialysis])

    return (
        <div className="content-content">
            <Spin spinning={loading}>
                <div className="container-row">
                    <div className="view-desktop view-desktop-md">
                        <PatientRecordPreweightsDialysisOrder page={"intra"}
                            lastDialysisIntervalNum={lastDialysisIntervalNum} setLastDialysisIntervalNum={setLastDialysisIntervalNum}
                            lastDialysis={lastDialysis} setLastDialysis={setLastDialysis}
                            patientID={patientID} dialysisID={dialysisID} />
                        {/* patientID={patientID} dialysisID={dialysisID} dialysisCompleted={dialysisCompleted} /> */}
                        <PatientRecordIntraDialysisInput
                            lastDialysisIntervalNum={lastDialysisIntervalNum} setLastDialysisIntervalNum={setLastDialysisIntervalNum}
                            lastDialysisInterval={lastDialysisInterval} setLastDialysisInterval={setLastDialysisInterval}
                            lastDialysis={lastDialysis} setLastDialysis={setLastDialysis}
                            patientID={patientID} dialysisID={dialysisID} />
                        <PatientRecordIntraDialysisData patientID={patientID} dialysisID={dialysisID} />
                        <PatientRecordDialysisHistory patientID={patientID} />
                        <div className="importlabresult-content">
                            <PatientRecordLastNonNullLab patientID={props?.match?.params?.id} />
                            <br />
                        </div>
                        <PatientRecordMedLabMilestone patientID={patientID} />
                        <PatientRecordAdministeredMedication patientID={patientID} />
                        <PatientRecordClinicNHospitalE patientID={patientID} />
                        <PatientRecordCalculatedLabResults patientID={patientID} />
                        <PatientRecordLabResults patientID={patientID} />
                        {/* {
                            dialysisCompleted === false ? (
                                <div>
                                    <PatientRecordIntraDialysisInput
                                        lastDialysisIntervalNum={lastDialysisIntervalNum} setLastDialysisIntervalNum={setLastDialysisIntervalNum}
                                        lastDialysisInterval={lastDialysisInterval} setLastDialysisInterval={setLastDialysisInterval}
                                        lastDialysis={lastDialysis} setLastDialysis={setLastDialysis}
                                        patientID={patientID} dialysisID={dialysisID} />
                                    <PatientRecordIntraDialysisData patientID={patientID} dialysisID={dialysisID} />
                                    <PatientRecordDialysisHistory patientID={patientID} />
                                    <PatientRecordMedLabMilestone patientID={patientID} />
                                    <PatientRecordAdministeredMedication patientID={patientID} />
                                    <PatientRecordClinicNHospitalE patientID={patientID} />
                                    <PatientRecordCalculatedLabResults patientID={patientID} />
                                    <PatientRecordLabResults patientID={patientID} />
                                </div>
                            ) : (
                                <div>
                                    Dialysis session completed:
                                    <ul>
                                        <li>Start: {moment.utc(lastDialysis.startTime).local().format("DD MMM YYYY, HH:mm")}</li>
                                        <li>End: {moment.utc(lastDialysis.endTime).local().format("DD MMM YYYY, HH:mm")}</li>
                                    </ul>
                                </div>
                            )
                        } */}
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default PatientDialysisIntraDialysis