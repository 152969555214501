import React, { useContext, useEffect, useState } from "react"
import { notification, Table } from "antd"
import { AppContext } from "../contexts/AppContext";
import API from "@aws-amplify/api";
import { listDialysisInterval } from "../backend/graphql/queries";
import moment from "moment";

const PatientRecordIntraDialysisData = (props) => {
    const { appState } = useContext(AppContext);
    const { patientID, dialysisID } = props;
    const [table, setTable] = useState({
        data: -1,
        loading: false
    });
    const [dialysisInterval, setDialysisInterval] = useState([]);
    const columns = [
        {
            title: "Date / Time",
            dataIndex: "timestamp"
        },
        // {
        //     title: "SE",
        //     dataIndex: "se"
        // },
        {
            title: "SBP",
            dataIndex: "SBP"
        },
        {
            title: "DBP",
            dataIndex: "DBP"
        },
        {
            title: "HR",
            dataIndex: "HR"
        },
        {
            title: "QB",
            dataIndex: "QB"
        },
        {
            title: "AP",
            dataIndex: "AP"
        },
        {
            title: "VP",
            dataIndex: "VP"
        },
        {
            title: "TMP",
            dataIndex: "tmp"
        },
        {
            title: "UFR (ℓ)",
            dataIndex: "UFR"
        },
        {
            title: "UFV (ℓ)",
            dataIndex: "cUF"
        },
        {
            title: "FLOW",
            dataIndex: "DFLOW"
        },
        {
            title: "COND",
            dataIndex: "CDT"
        },
        {
            title: "Temp",
            dataIndex: "temp"
        },
        {
            title: "HepRate",
            dataIndex: "hepRate"
        },
        {
            title: "Dialysis Note",
            dataIndex: "note"
        },
        {
            title: "Symptoms",
            dataIndex: "symptoms"
        },
    ]

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0 && dialysisID !== "undefined") {
            // console.log("dialysisID", dialysisID);
            _listDialysisInterval(dialysisID);
        }
    }, [appState.cognitoUser])
    
    useEffect(() => {
        if (table.data !== -1 && appState.fixedListLoaded === true) {
            let array = []
            for (let v of table.data) {
                array.push({
                    ...v,
                    symptoms: v.symptoms ? appState?.symptomList?.filter(s => JSON.parse(v.symptoms).includes(s.id)).map(s => s.name).join(', ') : '',
                    UFR: v.UFR/1000,
                    cUF: v.cUF/1000
                })
            }

            setDialysisInterval(array)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data, appState.fixedListLoaded])

    const _listDialysisInterval = async (dialysisID) => {
        setTable({
            ...table,
            loading: true,
        })
        try {
            const variables = {
                filter: {
                    dialysisID: {
                        eq: dialysisID
                    },
                    deleted: { eq: 0 },
                },
                pagination: {
                    // limit: 20,
                    // offset: 0,
                    orderby: "timestamp DESC"
                }
            };

            // console.log('PatientRecordIntraDialysisData _listDialysisInterval', variables);

            const result = await API.graphql({
                query: listDialysisInterval,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordIntraDialysisData _listDialysisInterval ", result);

            const data = result.data.result
            const array = data.result

            let _dialysisIntervals = []

            for (let v of array) {
                _dialysisIntervals.push({
                    ...v,
                    timestamp: moment.utc(v.timestamp).local().format('DD MMM yyyy HH:mm:ss'),
                })
            }

            setTable({
                data: _dialysisIntervals,
                loading: array.length === 0 ? false : true
            })
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Dialysis Interval"
            })
        }
    }

    return (
        <div className="patientrecord-dialysishistory">
            <div className="title">
                Intra Dialysis Data
            </div>
            <div>
                <Table
                    loading={table.loading}
                    columns={columns}
                    // dataSource={table.data}
                    dataSource={dialysisInterval}
                    scroll={{ x: 1500, y: 500 }}
                />
            </div>
        </div>
    )
}

export default PatientRecordIntraDialysisData