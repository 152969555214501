import React, { useContext, useEffect, useState } from "react"
import { Avatar, Button } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import Storage from "@aws-amplify/storage"
import moment from "moment"

import path from "../utils/pathSettings"
import IconDoctor from "../media/Icon-PatientCard-Doctor.png"
import IconIncidentReport from "../media/Icon-PatientCard-IncidentReport.png"
import IconNurse from "../media/Icon-PatientCard-Nurse.png"
import IconPatientInfo from "../media/Icon-PatientCard-PatientInfo.png"
import { AppContext } from '../contexts/AppContext'

const CardPatient = (props) => {
    const { appState, actionSetQuickBookRedirect } = useContext(AppContext);
    const { item, filterSearchTypeApplied, manageDialysis = false, manageInvoice = false, quickbook, onFinishLinkQB } = props
    // console.log("item", item);
    // console.log("filterSearchTypeApplied", filterSearchTypeApplied);
    const history = useHistory()
    let nricDisplay = item.nric ?? ""
    nricDisplay = nricDisplay?.substring(0, nricDisplay.length - 4).replace(/\d|\w/g, "*") + nricDisplay?.substring(nricDisplay.length - 4)
    const [profilePic, setProfilePic] = useState("")
    const [clinicName, setClinicName] = useState(item.clinicID)
    const [alarmCountdown, setAlarmCountdown] = useState("...")
    // console.log("item.dialysisEndTime", item.dialysisEndTime);
    let lastDatetime = item.dialysisEndTime === null && item.dialysisStartTime !== null ? (item.lastDialysisIntervalTimestamp ?? (item.dialysisStartTime ?? "")) : ""
    // console.log("lastDatetime", lastDatetime);
    let alarmDatetime = lastDatetime !== "" ? moment.utc(lastDatetime).add(process.env.REACT_APP_DIALYSIS_INTERVAL_PERIOD, "minutes").local() : ""
    // console.log("alarmDatetime", alarmDatetime);
    let [classTimer, setClassTimer] = useState("timer")
    const [updateCheckInOut, setUpdateCheckInOut] = useState(false);

    const getProfilePicture = async (key) => {
        setProfilePic(await Storage.get(key))
    }

    const updateLinkInvoice = async () => {

    }

    const onFinishCreateInvoice = async () => {
        if (!appState.useRefreshToken) {
            // console.log(item);
            actionSetQuickBookRedirect(item.patientID, item.dialysisID, {
                // ...form2.getFieldValue(),
                // ...form.getFieldValue(),
            });

            let locURL = window.location.href.split('//');
            let domainURL = locURL[1].split('/')[0];
            let redirect_uri = locURL[0] + '//' + domainURL + path('manageUnlinkedInvoice') + '/';

            window.location.assign(`https://appcenter.intuit.com/connect/oauth2?client_id=${process.env.REACT_APP_QUICKBOOKS_CLIENTID}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect_uri}&state=testState`);
        } else {
            onFinishLinkQB(null, item.patientID, item.dialysisID, clinicName);
        }
    }

    useEffect(() => {
        let imageKey = item.key ?? ""
        if (imageKey !== "") {
            getProfilePicture(imageKey)
        }
    }, [item.key])

    useEffect(() => {
        if (appState?.fixedClinicList?.length > 0) {
            // console.log(item);
            let clinic = appState.fixedClinicList.find(s => s.id == item.clinicID);
            setClinicName(clinic?.name + ' (' + clinic?.uid + ')');
        }
    }, [appState.fixedClinicList])


    const [datetime, setDatetime] = useState(new Date())

    useEffect(() => {
        let timer
        if (alarmDatetime !== "") {
            timer = setTimeout(() => {
                // console.log("hi hi alarm", alarmDatetime);
                // console.log("alarmDatetime", alarmDatetime);
                let duration = alarmDatetime.fromNow()
                duration = duration.replace(/minute/, 'min');
                duration = duration.replace(/second/, 'sec');

                // console.log("duration", duration);
                if (duration.includes("ago")) {
                    setAlarmCountdown("Scheduled Reading " + duration)
                    setClassTimer("timer red")
                }
                else {
                    setAlarmCountdown("Next Reading " + duration)
                    setClassTimer("timer")
                }
                setDatetime(new Date())
                clearTimeout(timer)
            }, 1000)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [datetime])

    useEffect(() => {
        if (item.dialysisID && item.dialysis) {
            if (!(item?.dialysis?.preEdema?.toString() && item?.dialysis?.preWeight?.toString() && item?.dialysis?.preTemp?.toString() && item?.dialysis?.preSBP?.toString() && item?.dialysis?.preDBP?.toString() && item?.dialysis?.preHRT?.toString() && item?.dialysis?.ufTargetStart?.toString() && item?.dialysis?.dryWeight?.toString() && item?.dialysis?.machineID?.toString() && item?.dialysis?.dialyser?.toString() && item?.dialysis?.MSA?.toString())) {
                setUpdateCheckInOut('CHECKIN');
            } else if (!(item?.dialysis?.postWeight?.toString() && item?.dialysis?.postTemp?.toString() && item?.dialysis?.postSBP?.toString() && item?.dialysis?.postDBP?.toString() && item?.dialysis?.postHRT?.toString() && item?.dialysis?.KTVonline?.toString()) && item?.dialysis?.endedBy?.toString()) {
                setUpdateCheckInOut('CHECKOUT');
            }
        }
    }, [item.dialysis])


    useEffect(() => {
        // console.log("item dialysis started, item", item);
        lastDatetime = item.dialysisEndTime === null && item.dialysisStartTime !== null ? (item.lastDialysisIntervalTimestamp ?? (item.dialysisStartTime ?? "")) : ""
        alarmDatetime = lastDatetime !== "" ? moment.utc(lastDatetime).add(process.env.REACT_APP_DIALYSIS_INTERVAL_PERIOD, "minutes").local() : ""
        setDatetime(new Date())
    }, [item.dialysisStartedBy])

    return (
        <div className="card">
            {
                filterSearchTypeApplied === "daily" ? (
                    <div className="card-header">
                        <div className={"checkedin" + (item.dialysisStartedBy && !item.dialysisEndedBy ? " green" : "")}>
                            Checked In
                        </div>
                        <div className={classTimer}>
                            {/* {item.dialysisStartedBy && item.dialysisEndedBy === null ? "(Next Reading " + alarmCountdown + ")" : ""} */}
                            {item.dialysisStartedBy !== null && item.dialysisEndedBy === null ? "(" + alarmCountdown + ")" : ""}
                        </div>
                        <div className={"checkedout" + (item.dialysisEndedBy ? " green" : "")}>
                            Checked Out
                        </div>
                    </div>
                ) : (
                    <div style={{ marginTop: "16px" }} />
                )
            }
            <div className="card-content">
                {/* <Avatar className="avatar" icon={<UserOutlined />} shape="square" src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/c9b00de6-cec9-4fe8-ad78-461708e81a4a/de6573p-f6e97ff5-97c2-4fd0-b3b9-f10a604272b2.png/v1/fill/w_1024,h_768,q_80,strp/sleeping_mudkip_by_jomamon_de6573p-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzY4IiwicGF0aCI6IlwvZlwvYzliMDBkZTYtY2VjOS00ZmU4LWFkNzgtNDYxNzA4ZTgxYTRhXC9kZTY1NzNwLWY2ZTk3ZmY1LTk3YzItNGZkMC1iM2I5LWYxMGE2MDQyNzJiMi5wbmciLCJ3aWR0aCI6Ijw9MTAyNCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.L9liUv-bhk8rOUu3Hr1h1xOAQLDGT6DZE5nDBTcFzvI" /> */}
                {/* <Avatar className="avatar" icon={<UserOutlined />} shape="square" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUz5ApidRFGD2JfJo0PSZr_-GHRkpAZ8pXsQ&usqp=CAU" /> */}
                {/* <Avatar className="avatar" icon={<UserOutlined />} shape="square" src="" /> */}
                <Avatar className="avatar" icon={<UserOutlined />} shape="square" src={profilePic} />
                <div className="card-content-details">
                    <div>
                        <div className="uid">{item.uid}</div>
                        <div className="name">{item.name}</div>
                        {/* <div className="nric">{item.nric.substring(0, item.nric.length - 4).replace(/\d|\w/g, "*") + item.nric.substring(item.nric.length - 4)}</div> */}
                        <div className="nric">{nricDisplay}</div>
                        {manageInvoice === true ? (
                            <div className="clinic">{clinicName}</div>
                        ) : ""}
                    </div>
                    {item?.dialysisStartedBy && manageDialysis === false && manageInvoice === false ? (
                        !item?.quickbookInvoiceID ? (
                            <Button onClick={() => {
                                // console.log("Open in new tab to link to Quickbooks");
                                onFinishCreateInvoice()
                            }} style={{ height: "25px", lineHeight: "10px", margin: "5px 0" }} className="linkinvoice">
                                Create Invoice
                            </Button>
                        ) : (
                            <Button onClick={() => {
                                window.open(`https://app.${process.env.REACT_APP_ENV === 'prod' ? '' : 'sandbox.'}qbo.intuit.com/app/invoice?txnId=${item.quickbookInvoiceID}`);
                            }} style={{ height: "25px", lineHeight: "10px", margin: "5px 0" }} className="viewinvoice">
                                View Invoice
                            </Button>
                        )
                    ) : ""
                    }
                    {
                        updateCheckInOut ?
                            updateCheckInOut == 'CHECKIN' ?
                                <Button onClick={() => {
                                    // console.log("Update Check In");
                                    history.push(path("patientDialysisCheckInEdit", [item.id, item.dialysisID]))
                                }} style={{ height: "25px", lineHeight: "10px", margin: "5px 0" }} className="linkinvoice">Update Check In</Button>
                                : updateCheckInOut == 'CHECKOUT' ?
                                    <Button onClick={() => {
                                        // console.log("Update Check Out");
                                        history.push(path("patientDialysisCheckOutEdit", [item.id, item.dialysisID]))
                                    }} style={{ height: "25px", lineHeight: "10px", margin: "5px 0" }} className="linkinvoice">Update Check Out</Button>
                                    : <></>
                            : <></>
                    }
                    {
                        manageDialysis === false && manageInvoice === false ? (
                            <div className="nav">
                                <div className="icon" onClick={() => { history.push(path("patientInformation", [item.id])) }}><img src={IconPatientInfo} /></div>
                                {
                                    filterSearchTypeApplied === "daily" && appState?.loginType != 'Doctor' ? (
                                        <div className="icon" onClick={() => {
                                            // console.log("ON CLICK", item);
                                            if (item.dialysisID !== null) {
                                                // if (item.dialysisEndedBy == null)
                                                history.push(path("patientDialysis", [item.id, item.dialysisID]))

                                            }
                                            else {
                                                // history.push({
                                                //     pathname: path("patientDialysisCheckIn", [item.id]),
                                                //     state: { appointmentID: item.appointmentID }
                                                // })
                                                history.push(path("patientDialysisCheckIn", [item.id, item.appointmentID]))
                                            }
                                        }}><img src={IconNurse} /></div>
                                    ) : (
                                        <div className="icon" onClick={() => { history.push(path("patientRecord", [item.id])) }}><img src={IconNurse} /></div>
                                    )
                                }
                                <div className="icon" onClick={() => { history.push(path("patientIncidentReport", [item.id])) }}><img src={IconIncidentReport} /></div>
                            </div>
                        ) : (
                            manageDialysis === true ? (
                                <Button onClick={() => { history.push(path("manageDialysisSessionPatientRecord", [item.id])) }}>
                                    View Dialysis Sessions
                                </Button>
                            ) : (
                                manageInvoice === true ? (
                                    <Button onClick={() => {
                                        // console.log("Open in new tab to link to Quickbooks");
                                        onFinishCreateInvoice()
                                    }} className="linkinvoice">
                                        Create Invoice
                                    </Button>
                                ) : ""
                            )
                        )
                    }
                </div>
            </div>
        </div >
    )
}

export default CardPatient