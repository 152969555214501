import React, { useContext, useEffect, useState } from "react"
import { Avatar, Dropdown, Menu, notification, Spin } from "antd"
import { DownOutlined, UserOutlined } from "@ant-design/icons"
import { Link, NavLink } from 'react-router-dom'
import { AppContext } from "../contexts/AppContext"
import { Auth } from "@aws-amplify/auth"

import path from "../utils/pathSettings"
import API from "@aws-amplify/api"
import { listPatient } from "../backend/graphql/queries"

const PatientCreateDuplicated = (props) => {
    const { appState, actionLogout, actionSetClinicID } = useContext(AppContext)
    const [displayClinic, setDisplayClinic] = useState("")
    const { search } = props;
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    })
    const [display, setDisplay] = useState([])

    const getPatientRecord = async (search) => {
        setLoading(true);
        try {
            const variables = {
                filter: {
                    // name: { contains: search }
                },
                pagination: {
                    limit: 10,
                    orderby: "(`status` = 'ACTIVE') DESC, `name` ASC"
                },
            }

            for (let s of search.split(' ')) {
                if (!variables.filter.and)
                    variables.filter.and = [];

                variables.filter.and.push({
                    name: { contains: s }
                })
            }

            // console.log('PatientCreateDuplicated', variables);

            const result = await API.graphql({
                query: listPatient,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result", result);
            const data = result.data.result
            const array = data.result

            // process data
            setTable({
                ...table,
                data: array ?? [],
                pagination: {
                    ...table.pagination,
                    total: data.count,
                }
            })

            setLoading(false);
        }
        catch (error) {
            console.log("error: ", error);
        }
    }

    useEffect(() => {
        if (search && search.length > 0) {
            getPatientRecord(search);
        }
    }, [search])

    useEffect(() => {
        if (table.data !== -1) {
            let array = [];
            for (let item of table.data) {
                array.push({
                    id: item.id,
                    name: item.name,
                })
            }
            // console.log(array);
            setDisplay(array);
        }
    }, [table.data])

    const renderDuplicatedPatient = (array) => {
        const items = array.map((item) => {
            // console.log(item);
            return <li><Link to={path('patientInformation', [item.id])}>{item.name}&nbsp;&nbsp;&nbsp;</Link></li>
        })
        // console.log(array, items);
        return items
    }

    return (
        (search && search?.length > 0 ?
            <div className={"patientcreate-duplicated"}>
                <Spin spinning={loading}>
                    <div>
                        ({table.pagination.total}) Patients with similar names.
                    </div>
                    <ul>
                        {renderDuplicatedPatient(display)}
                    </ul>
                </Spin>
            </div>
            : "")
    )
}

export default PatientCreateDuplicated