import React, { useContext, useEffect, useState } from "react"
import { Button, Form, DatePicker, Input, Modal, Popconfirm, Select, Space, Spin, Table, TreeSelect, Upload, notification, Descriptions } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { AppContext } from "../contexts/AppContext"

import renderInputItems from "../utils/renderFormInputItems"
import iconCalendar from "../media/Icon-Calendar.png"
import iconMinus from "../media/Icon-Minus.png"
import iconDownload from "../media/Icon-Download.png"
import { listMachineStatus } from "../backend/graphql/queries"
import API from "@aws-amplify/api"
import Storage from "@aws-amplify/storage"
import moment from "moment"

import imgFileUpload from "../media/Image-FileUpload.png"
import { createMachineStatus, updateMachineStatus } from "../backend/graphql/mutations"
import { listMachineAndMachineStatus } from "../backend/graphql/custom_queries"
import TextArea from "antd/lib/input/TextArea"

const ManageMachineStatus = (props) => {
    const { appState, getS3 } = useContext(AppContext);
    const [formAdd] = Form.useForm()
    const [formUpdate] = Form.useForm()
    const [formSearch] = Form.useForm()
    const dateFormatList = ["DD MMM YYYY"]
    const { Option } = Select
    const { TreeNode, SHOW_PARENT } = TreeSelect
    const [filter, setFilter] = useState({
        search: "",
        clinicIDs: [0],
    })
    const validateMessages = {
        required: "Required.",
    }
    const [totalMachine, setTotalMachine] = useState('')
    const [actionNeeded, setActionNeeded] = useState(0)
    const [table, setTable] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            showSizeChanger: true,
        },
        loading: false
    })
    const [visibilityUpdateMachineStatus, setVisibilityUpdateMachineStatus] = useState(false)
    const [visibilitySign, setVisibilitySign] = useState(false)
    const [visibilityAddMachineStatus, setVisibilityAddMachineStatus] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false)
    const [editMachineStatus, setEditMachineStatus] = useState({})
    const [selectedExpiryDate, setSelectedExpiryDate] = useState(null)

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0 && Object.keys(appState.clinicList).length !== 0) {
            // console.log('ManageMachineStatus', props);
            getMachineStatus(null, {
                ...table.pagination,
                offset: table.pagination.current * table.pagination.pageSize - table.pagination.pageSize,
            });

            formSearch.setFieldsValue({
                clinicIDs: [0]
            });
        }
        return () => { }
    }, [appState.cognitoUser, appState.clinicList])

    useEffect(() => {
        getMachineStatus(filter, table.pagination)
    }, [filter])

    useEffect(() => {
        if (Object.keys(editMachineStatus).length !== 0) {
            // console.log("editMachineStatus", editMachineStatus);
            formUpdate.setFieldsValue({
                ...editMachineStatus,
                // clinicID: editMachineStatus.clinicID ? editMachineStatus.clinicID : "",
                // name: editMachineStatus.name ? editMachineStatus.name : "",
                // serialNo: editMachineStatus.serialNo ? editMachineStatus.serialNo : "",
                // manufacturerName: editMachineStatus.manufacturer ? editMachineStatus.manufacturer : "",


                expiryDate: editMachineStatus.expiryDate ? moment.utc(editMachineStatus.expiryDate).local() : null,

                nextAudit: editMachineStatus.nextAudit ? moment.utc(editMachineStatus.nextAudit).local() : null,
            });
            setState({
                fileList: []
            });
        }
    }, [editMachineStatus])

    useEffect(() => {
        if (visibilityAddMachineStatus == false && visibilityUpdateMachineStatus == false) {
            setSelectedExpiryDate(null);
        }
    }, [visibilityUpdateMachineStatus, visibilityAddMachineStatus])

    const uploadFile = async (file) => {
        // console.log(file);

        const fileName = "m_" + editMachineStatus.machineID + '_' + editMachineStatus.name + '_' + moment().utc().format("YYYY-MM-DD_HHmmss") + "_" + file.name
        const fileType = file.type

        try {
            const result = await Storage.put("machine/" + fileName, file, {
                contentType: fileType
            })
            // console.log(result, result.key);
            return result.key
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: error
            })
        }
    }

    const _listMachineStatus = async (filter, props) => {
        setTable({
            ...table,
            loading: true,
        });
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: "FIELD(`status`, 'EXPIRED', 'TO_SCHEDULE', 'SCHEDULED', 'PENDING_SIGN', 'PASS') ASC, machineClinicName ASC, `name` ASC "
                },
                filter: {
                },
                groupByName: 1
            }

            if (filter) {
                if (filter?.clinicIDs) {
                    variables.filter.or = [];

                    for (let clinicID of filter.clinicIDs) {
                        variables.filter.or.push({
                            machineClinicID: { eq: clinicID }
                        })
                    }
                }

                if (filter?.search) {
                    variables.filter.name = {
                        contains: filter.search
                    }
                }
            }

            variables.filter2 = {
                status: {
                    ne: 'INACTIVE'
                },
                deleted: {
                    eq: 0
                }
            }

            variables.groupByName3 = 1;
            variables.filter3 = {
                status: {
                    ne: "PASS"
                }
            }

            // console.log('ManageMachineStatus', variables);

            const result = await API.graphql({
                query: listMachineAndMachineStatus,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result ManageMachineStatus listMachineStatus", result);
            const data = result.data.result

            if (data?.result && data.result.length > 0) {
                let _machineStatus = [];

                for (let v of data.result) {
                    _machineStatus.push({
                        ...v,
                        centreName: appState?.clinicList?.find(s => s.id == v.machineClinicID).name,
                        status: v.status,
                    })
                }

                // console.log(_machineStatus);

                setTable({
                    ...table,
                    loading: data.result.length == 0 ? true : false,
                    data: _machineStatus,
                    pagination: {
                        ...table.pagination,
                        pageSize: props.pageSize,
                        current: props.current,
                        offset: props.offset,
                        total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                    }
                })
            } else {
                setTable({
                    ...table,
                    loading: false,
                    data: [],
                    pagination: {
                        ...table.pagination,
                        current: 1,
                        offset: props.offset,
                        total: 0
                    }
                })
            }

            if (result?.data?.result2) {
                // console.log(result.data.result2);
                setTotalMachine(result.data.result2.count);
            }

            if (result?.data?.result3) {
                setActionNeeded(result.data.result3.count);
            }
        }
        catch (error) {
            console.log("error: ", error);
        }
        finally { }
    }

    const _updateMachineStatus = async (values, id, _state = null) => {
        setLoadingModal(true)
        try {
            const variable = {
                update: {
                    id: id,
                    // clinicID: values.clinicID,
                    // name: values.name,
                    // serialNo: values.serialNo,
                    // manufacturer: values.manufacturerName,
                    remarks: values.remarks,
                }
            }

            if (values.nextAudit) {
                variable.update.nextAudit = values.nextAudit.format('yyyy-MM-DD');
            }
            if (values.expiryDate) {
                variable.update.expiryDate = values.expiryDate.format('yyyy-MM-DD');
            }

            switch (values.status) {
                case 'TO_SCHEDULE':
                case 'EXPIRED':
                    variable.state = 'SCHEDULE';
                    break;
                case 'SCHEDULED':
                    variable.state = 'PASS_AUDIT'
                    break;
                case 'PENDING_SIGNATURE':
                    variable.state = _state ? _state : '' // APPROVE
                    break;
            }

            if (state?.fileList) {
                // console.log(state.fileList);
                let _f = [];
                for (let file of state.fileList) {
                    _f.push(await uploadFile(file.originFileObj));
                }
                // console.log(_f);

                if (_f && _f.length > 0) {
                    variable.update.key = _f[0];
                    let s3 = getS3();
                    variable.update.bucket = s3?.bucket;
                    variable.update.region = s3?.region;
                }
            }

            // console.log("_updateMachineStatus", variable);
            const result = await API.graphql({
                query: updateMachineStatus,
                variables: variable,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);

            notification.success({
                message: "Updated successfully"
            })
            getMachineStatus(filter, table.pagination)
            handleModalVisibility(variable.state == 'APPROVE' ? 'sign' : "updateMachineStatus")
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const _createMachineStatus = async (values) => {
        setLoadingModal(true)
        try {
            const variable = {
                create: {
                    machineID: values.machine,
                    name: values.name,
                }
            }

            if (values.nextAudit) {
                variable.create.nextAudit = values.nextAudit.format('yyyy-MM-DD');
            }
            if (values.expiryDate) {
                variable.create.expiryDate = values.expiryDate.format('yyyy-MM-DD');
            }

            if (state?.fileList) {
                // console.log(state.fileList);
                let _f = [];
                for (let file of state.fileList) {
                    _f.push(await uploadFile(file.originFileObj));
                }
                // console.log(_f);

                if (_f && _f.length > 0) {
                    variable.create.key = _f[0];
                    let s3 = getS3();
                    variable.create.bucket = s3?.bucket;
                    variable.create.region = s3?.region;
                }
            }

            // console.log("_createMachineStatus", variable);
            const result = await API.graphql({
                query: createMachineStatus,
                variables: variable,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);

            notification.success({
                message: "Updated successfully"
            })
            getMachineStatus(filter, {
                ...table.pagination,
                current: 1,
                offset: 0,
            })
            handleModalVisibility("addMachineStatus");
        }
        catch (error) {
            console.log("error", error);
            notification.error({
                message: "Unable to update record"
            })
        }
        finally {
            setLoadingModal(false)
        }
    }

    const getMachineStatus = async (filter, pagination) => {
        if (filter?.clinicIDs?.length == 1 && filter?.clinicIDs[0] == 0) {
            _listMachineStatus({
                ...filter,
                clinicIDs: null
            }, pagination);
        } else {
            _listMachineStatus(filter, pagination);
        }
    }

    const onFinishSearch = () => {
        // console.log("submit formSearch");
        // console.log(formSearch.getFieldsValue());
        setFilter(formSearch.getFieldsValue())
    }

    const onFinishUpdateMachineStatus = async (values) => {
        // console.log("onFinishUpdateMachineStatus", values, state);
        await formUpdate.validateFields();

        _updateMachineStatus(values, values.id);

        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateMachineStatus")
        // }, 1000)

    }

    const onFinishSign = async (values) => {
        // console.log("onFinishSign", values);
        await formUpdate.validateFields()

        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateMachineStatus")
        // }, 1000)

        _updateMachineStatus({
            id: values.id,
            status: values.status,
        }, values.id, 'APPROVE');
    }

    const onFinishAddMachineStatus = async (values) => {
        // console.log("onFinishAddMachineStatus", values, state);
        await formAdd.validateFields();

        _createMachineStatus(values);

        // setLoadingModal(true)
        // const timer = setTimeout(() => {
        //     setLoadingModal(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("updateMachineStatus")
        // }, 1000)

    }

    const handleModalVisibility = (modal, id) => {
        // console.log("modal", modal);
        switch (modal) {
            case "updateMachineStatus":
                if (id) {
                    // console.log(table.data.find(s => s.id == id));
                    let record = {
                        ...table.data.find(s => s.id == id)
                    };
                    if (record.status == 'SCHEDULED') {
                        record.expiryDate = null;
                    }
                    if (['TO_SCHEDULE', 'EXPIRED'].includes(record.status)) {
                        record.nextAudit = null;
                    }
                    setEditMachineStatus(record);
                    setSelectedExpiryDate(record.expiryDate)
                }
                setVisibilityUpdateMachineStatus(!visibilityUpdateMachineStatus)
                break;
            case "sign":
                setEditMachineStatus({
                    ...table.data.find(s => s.id == id)
                });
                setVisibilitySign(!visibilitySign);
                setState({
                    fileList: []
                });
                break;
            case "addMachineStatus":
                formAdd.resetFields();
                setVisibilityAddMachineStatus(!visibilityAddMachineStatus);
                setState({
                    fileList: []
                });
                break;
            default:
                break;
        }
    }

    const handleDownload = async (key) => {
        let result = await Storage.get(key
            // , {
            //     download: true
            // }
        );
        // console.log(result);
        window.open(result);
    }

    const handleExpiryDatePickerChange = (date, dateString) => {
        // console.log("handleExpiryDatePickerChange");
        // console.log(date, dateString);
        if (visibilityAddMachineStatus) {
            let expiryDate = formAdd.getFieldValue('expiryDate');
            // console.log('expiryDate', expiryDate);
            setSelectedExpiryDate(expiryDate)
        } else if (visibilityUpdateMachineStatus) {
            let expiryDate = formUpdate.getFieldValue('expiryDate');
            // console.log('expiryDate', expiryDate);
            setSelectedExpiryDate(expiryDate)
        } else {
            setSelectedExpiryDate(null);
        }
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    }

    const handleTreeSelectChange = (value, label, extra) => {
        // console.log("value", value);
        // console.log("label", label);
        // console.log("extra", extra);
    }

    const renderTreeSelectOptions = (array) => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const renderListOptions = (array, key = "id", value = "id", name = "name") => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    /**
     * Start of FileUpload
     */

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const uploadButton = (
        <div>
            <img src={imgFileUpload} />
            <div className="upload-text">
                <div>
                    Drag and Drop file here
                </div>
                <div>
                    or
                </div>
                <div>
                    Click here to browse
                </div>
            </div>
        </div>
    )
    const [state, setState] = useState({
        fileList: []
    })

    const handleCancel = () => {
        setState({ ...state, previewVisible: false })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url?.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    const handleChange = (file, status = null) => {
        // console.log('handleChange', file);
        // console.log(optionNum);
        let { fileList } = file
        // fileList = fileList.slice(-1)
        if (status == 'create') {
            if (fileList.length > 0) {
                formAdd.setFieldsValue({ 'key': fileList });
            } else {
                formAdd.setFieldsValue({ 'key': null });
            }
        } else {
            if (editMachineStatus.status == 'SCHEDULED') {
                if (fileList.length > 0) {
                    formUpdate.setFieldsValue({ 'key': fileList });
                } else {
                    formUpdate.setFieldsValue({ 'key': null });
                }
            }
        }
        setState({ ...state, fileList: fileList })
    }

    const handleTableChange = (paginate) => {
        // console.log("handleTableChange", paginate)
        getMachineStatus(filter, {
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * table.pagination.pageSize - table.pagination.pageSize,
        })
    }

    /**
     * End of FileUpload
     */

    const columns = [
        {
            title: "Centre",
            dataIndex: "centreName"
        },
        {
            title: "Machine Name",
            dataIndex: "machineName"
        },
        {
            title: "Machine ID",
            dataIndex: "machineSerialNo"
        },
        {
            title: "Test Case",
            dataIndex: "name"
        },
        {
            title: "Expiry Date",
            dataIndex: "expiryDate",
            render: (text, record) => {
                return text && text.length > 0 ? moment(text).format('DD MMM YYYY') : ''
            }
        },
        {
            title: "Next Audit",
            dataIndex: "nextAudit",
            render: (text, record) => {
                return text && text.length > 0 ? moment(text).format('DD MMM YYYY') : ''
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => {
                return {
                    props: {
                        style: {color: text == 'EXPIRED' ? "red" : ""}  
                    },
                    children: text
                }
            }
        },
        {
            title: "Remarks",
            dataIndex: "remarks"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <Space size="large">
                        {(record.status && ['TO_SCHEDULE', 'EXPIRED', 'SCHEDULED'].includes(record.status)) ?
                            (
                                <div className="table-row-action clickable" onClick={() => handleModalVisibility("updateMachineStatus", record.id)}>
                                    <div className="icon">
                                        <img src={iconCalendar} />
                                    </div>
                                    <div className="text">
                                        Update Status
                                    </div>
                                </div>
                            ) :
                            <></>
                        }
                        {(record.status && record.status == 'PENDING_SIGNATURE') ?
                            (
                                <div className="table-row-action clickable" onClick={() => handleModalVisibility("sign", record.id)}>
                                    <div className="icon">
                                        {/* <img src={iconCalendar} /> */}
                                    </div>
                                    <div className="text">
                                        Sign
                                    </div>
                                </div>
                            ) :
                            <></>
                        }
                        {(record.key && record.key.length > 0) ?
                            (
                                <div className="table-row-action clickable" onClick={() => handleDownload(record.key)}>
                                    <div className="icon">
                                        <img src={iconDownload} />
                                    </div>
                                    <div className="text">
                                        Download Report
                                    </div>
                                </div>
                            ) :
                            (
                                <></>
                            )
                        }
                    </Space >
                )
            }
        },
    ]

    return (
        <div className="content-content">
            <div className="manage-header">
                <div className="container-rows">
                    <div className="row1">
                        <div className="container-title">
                            <div className="title">
                                Machine Status
                            </div>
                            <Space className="row">
                                <div className="statscard">
                                    <div className="column1">
                                        Total<br />Machines
                                    </div>
                                    <div className="column2">
                                        {totalMachine}
                                    </div>
                                </div>
                                {
                                    actionNeeded !== 0 ? (
                                        <div className="statscard red">
                                            <div className="column1">
                                                Action<br />Needed
                                            </div>
                                            <div className="column2">
                                                {actionNeeded}
                                            </div>
                                        </div>
                                    ) : ""
                                }
                            </Space>
                        </div>
                        <Button type="primary" onClick={() => handleModalVisibility("addMachineStatus")}>
                            Add Machine Status
                        </Button>
                    </div>
                    <Form
                        form={formSearch}
                        layout="horizontal"
                        onFinish={onFinishSearch}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="container-row">
                            <div className="row2">
                                <div className="row">
                                    <Form.Item
                                        key="clinicIDs"
                                        name="clinicIDs"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        style={{ minWidth: 430, width: "50%" }}
                                    >
                                        <TreeSelect
                                            showSearch
                                            allowClear
                                            multiple
                                            treeDefaultExpandAll
                                            treeCheckable
                                            placeholder="Select clinics"
                                            showCheckedStrategy={SHOW_PARENT}
                                            filterTreeNode={(input, treenode) =>
                                                treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            defaultValue={0}
                                            onChange={handleTreeSelectChange}
                                        >
                                            <TreeNode value={0} title="All">
                                                {appState.clinicList !== -1 ? renderTreeSelectOptions(appState.clinicList) : ""}
                                                {/* {renderTreeSelectOptions(
                                                    [
                                                        {
                                                            id: 1,
                                                            name: "AMC Centre (AMC)"
                                                        },
                                                        {
                                                            id: 2,
                                                            name: "Thomson Clinic (TMC)"
                                                        },
                                                        {
                                                            id: 3,
                                                            name: "Yishun Clinic (YSC)"
                                                        },
                                                        {
                                                            id: 4,
                                                            name: "Hougang Clinic (HGC)"
                                                        },
                                                        {
                                                            id: 5,
                                                            name: "Mayflower Clinic (MFC)"
                                                        },
                                                    ]
                                                )} */}
                                            </TreeNode>
                                        </TreeSelect>
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    {/* <Form.Item
                                        className="display-none"
                                        name="searchTypeDaily"
                                    >
                                        <Input autoComplete="off" />
                                    </Form.Item> */}
                                    <Form.Item
                                        className="search"
                                        name="search"
                                    >
                                        <Input autoComplete="off" prefix={<SearchOutlined />} placeholder="Search Test Case" value={filter.search} allowClear />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className="manage-content">
                <Table
                    columns={columns}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : ""
                    }}
                    loading={table.loading}
                    pagination={table.pagination}
                    onChange={handleTableChange}
                    // dataSource={display}
                    dataSource={
                        table.data
                        // [
                        //     {
                        //         id: "1",
                        //         centreName: "Clinic ABC",
                        //         machineName: "Machine ABC",
                        //         machineId: "rts123123123",
                        //         testCase: "Test Case 123",
                        //         expiryDate: "2021-04-10 06:00:00",
                        //         nextAuditDate: "2021-04-10 06:00:00",
                        //         status: "Pass",
                        //         remarks: "Remarks one two three",
                        //     },
                        //     {
                        //         id: "2",
                        //         centreName: "Clinic ABC",
                        //         machineName: "Machine ABC",
                        //         machineId: "rts123123123",
                        //         testCase: "Test Case 123",
                        //         expiryDate: "2021-04-10 06:00:00",
                        //         nextAuditDate: "2021-04-10 06:00:00",
                        //         status: "Pass",
                        //         remarks: "Remarks one two three",
                        //     },
                        //     {
                        //         id: "3",
                        //         centreName: "Clinic ABC",
                        //         machineName: "Machine ABC",
                        //         machineId: "rts123123123",
                        //         testCase: "Test Case 123",
                        //         expiryDate: "2021-04-10 06:00:00",
                        //         nextAuditDate: "2021-04-10 06:00:00",
                        //         status: "Pass",
                        //         remarks: "Remarks one two three",
                        //     },
                        // ]
                    }
                />
            </div>
            <Modal
                title={
                    <div className="modal-title">
                        Update Status
                    </div>
                }
                visible={visibilityUpdateMachineStatus}
                onCancel={() => handleModalVisibility("updateMachineStatus")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("updateMachineStatus")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure?" onConfirm={() => onFinishUpdateMachineStatus(formUpdate.getFieldsValue())} okText="Yes" cancelText="No">
                        <Button key="submit" type="primary" loading={loadingModal}>
                            Update
                        </Button>
                    </Popconfirm>
                    ,
                ]}
                className="modal-manageaccount-updatecertification"
                width={600}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            className="updatecertification"
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishUpdateMachineStatus}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="machineStatusID"
                                name="id"
                                hidden={true}
                            >
                                <Input autoComplete="off" disabled allowClear />
                            </Form.Item>
                            <Form.Item
                                label="machineStatusStatus"
                                name="status"
                                hidden={true}
                            >
                                <Input autoComplete="off" disabled allowClear />
                            </Form.Item>
                            <Form.Item
                                label="Machine"
                                name="machineName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" disabled allowClear />
                            </Form.Item>
                            <Form.Item
                                label="Machine ID"
                                name="machineSerialNo"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" disabled allowClear />
                            </Form.Item>
                            <Form.Item
                                label="Test Case"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" disabled allowClear />
                            </Form.Item>
                            <Form.Item
                                label="Expiry Date"
                                name="expiryDate"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker onChange={handleExpiryDatePickerChange} format={dateFormatList} disabled={(editMachineStatus.status == 'EXPIRED')} disabledDate={(d) => d < moment()} />
                            </Form.Item>
                            <Form.Item
                                label="  "
                                name="_1"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <div style={{}}>
                                    <div>{"- 3 months before expiry date,"}</div><div>&nbsp;&nbsp;&nbsp;{"the status will be changed to TO_SCHEDULE."}</div><div>{"- On the day of expiry, status will be changed to EXPIRED,"}</div><div>&nbsp;&nbsp;&nbsp;{"Machine and Seat will be not available."}</div>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="Next Audit Date"
                                name="nextAudit"
                                rules={[
                                    {
                                        required: ['TO_SCHEDULE', 'EXPIRED'].includes(editMachineStatus.status),
                                    },
                                ]}
                            >
                                <DatePicker onChange={handleDatePickerChange} format={dateFormatList} disabledDate={(d) =>
                                    (editMachineStatus.status != 'EXPIRED') &&
                                    selectedExpiryDate &&
                                    (d.isAfter(selectedExpiryDate))} />
                            </Form.Item>
                            {(editMachineStatus.status == 'SCHEDULED') ?
                                <Form.Item
                                    className="managemachinestatus"
                                    label="Attach Document"
                                    name="key"
                                    rules={[
                                        {
                                            required: editMachineStatus.status == 'SCHEDULED',
                                        },
                                    ]}
                                // getValueProps={state.fileList}
                                // getValueFromEvent={getFile}
                                >
                                    <Upload
                                        className="fileupload"
                                        action={getBase64}
                                        listType="picture-card"
                                        fileList={state.fileList}
                                        onPreview={handlePreview}
                                        onChange={(file) => handleChange(file)}
                                    >
                                        {state.fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                    <Modal
                                        visible={state.previewVisible}
                                        title={state.previewTitle}
                                        footer={null}
                                        onCancel={handleCancel}
                                    >
                                        <img alt="upload preview" style={{ width: '100%' }} src={state.previewImage} />
                                    </Modal>
                                </Form.Item> :
                                <></>
                            }
                            <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={[
                                    {
                                        required: false,
                                        max: 1000,
                                    },
                                ]}
                            >
                                <TextArea rows={3} autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">
                        Approve Maintenance
                    </div>
                }
                visible={visibilitySign}
                onCancel={() => handleModalVisibility("sign")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("sign")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure?" onConfirm={() => onFinishSign(formUpdate.getFieldsValue())} okText="Yes" cancelText="No">
                        <Button key="submit" type="primary" loading={loadingModal}>
                            Sign
                        </Button>
                    </Popconfirm>
                    ,
                ]}
                className="modal-manageaccount-updatecertification"
                width={600}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            className="updatecertification"
                            form={formUpdate}
                            layout="horizontal"
                            onFinish={onFinishSign}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="machineStatusID"
                                name="id"
                                hidden={true}
                            >
                                <Input autoComplete="off" disabled allowClear />
                            </Form.Item>
                            <Form.Item
                                label="machineStatusStatus"
                                name="status"
                                hidden={true}
                            >
                                <Input autoComplete="off" disabled allowClear />
                            </Form.Item>
                            <Form.Item
                                // label="Machine"
                                name="machineName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <div>{editMachineStatus.machineName}</div>
                                <div>{editMachineStatus.name}</div>
                                <div>Sign here</div>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
            <Modal
                title={
                    <div className="modal-title">
                        Add Status
                    </div>
                }
                visible={visibilityAddMachineStatus}
                onCancel={() => handleModalVisibility("addMachineStatus")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("addMachineStatus")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure?" onConfirm={() => onFinishAddMachineStatus(formAdd.getFieldsValue())} okText="Yes" cancelText="No">
                        <Button key="submit" type="primary" loading={loadingModal}>
                            Add
                        </Button>
                    </Popconfirm>
                    ,
                ]}
                className="modal-manageaccount-updatecertification"
                width={600}
            >
                <div className="modal-content">
                    <Spin spinning={loadingModal}>
                        <Form
                            className="updatecertification"
                            form={formAdd}
                            layout="horizontal"
                            onFinish={onFinishAddMachineStatus}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <Form.Item
                                label="Machine"
                                name="machine"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch={true}
                                    placeholder={"Select machine"}
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                    }
                                >
                                    {renderListOptions(appState.machineList)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Test Case"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input autoComplete="off" allowClear />
                            </Form.Item>
                            <Form.Item
                                label="Expiry Date"
                                name="expiryDate"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker onChange={handleExpiryDatePickerChange} format={dateFormatList} disabledDate={(d) => d < moment()} />
                            </Form.Item>
                            <Form.Item
                                label="  "
                                name="_1"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <div style={{}}>
                                    <div>{"- 3 months before expiry date,"}</div><div>&nbsp;&nbsp;&nbsp;{"the status will be changed to TO_SCHEDULE."}</div><div>{"- On the day of expiry, status will be changed to EXPIRED,"}</div><div>&nbsp;&nbsp;&nbsp;{"Machine and Seat will be not available."}</div>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="Next Audit Date"
                                name="nextAudit"
                                dependencies={['expiryDate']}
                                rules={[
                                    {
                                        required: ['TO_SCHEDULE', 'EXPIRED'].includes(editMachineStatus.status),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            // console.log(getFieldValue('expiryDate'));
                                            if (!value || getFieldValue('expiryDate') > value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The next audit date cannot be after expiry date.'));
                                        },
                                    }),
                                ]}
                            >
                                <DatePicker onChange={handleDatePickerChange} format={dateFormatList} disabledDate={(d) =>
                                    selectedExpiryDate &&
                                    (d.isAfter(selectedExpiryDate))} />
                            </Form.Item>
                            <Form.Item
                                className="managemachinestatus"
                                label="Attach Document"
                                name="key"
                            >
                                <Upload
                                    className="fileupload"
                                    action={getBase64}
                                    listType="picture-card"
                                    fileList={state.fileList}
                                    onPreview={handlePreview}
                                    onChange={(file) => handleChange(file, 'create')}
                                >
                                    {state.fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                <Modal
                                    visible={state.previewVisible}
                                    title={state.previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                >
                                    <img alt="upload preview" style={{ width: '100%' }} src={state.previewImage} />
                                </Modal>
                            </Form.Item>
                            <Form.Item
                                label="Remarks"
                                name="remarks"
                                rules={[
                                    {
                                        required: false,
                                        max: 1000,
                                    },
                                ]}
                            >
                                <TextArea rows={3} autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </div>
    )
}

export default ManageMachineStatus