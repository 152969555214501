import React, { useEffect, useState, useContext } from "react"
import { Avatar, Button, Checkbox, DatePicker, Descriptions, Divider, Form, Input, Modal, notification, Radio, Select, Spin, TreeSelect, Upload } from "antd"
import { UploadOutlined, UserOutlined, } from "@ant-design/icons"
import { Link, useHistory } from "react-router-dom"
import API from "@aws-amplify/api"
import { Storage } from "@aws-amplify/storage"
import moment from "moment"

import { listPatientAndAllergyAndDialysis_PatientClinic } from "../backend/graphql/custom_queries"
import { AppContext } from "../contexts/AppContext"
import path from "../utils/pathSettings"
import PatientDetailsSummary from "./Component-PatientDetailsSummary"

const PatientInformation = (props) => {
    const [form] = Form.useForm()
    const { appState } = useContext(AppContext);
    const patientID = props?.match?.params?.id;
    const { Option } = Select
    const { TextArea } = Input
    const { TreeNode } = TreeSelect
    const [values, setValues] = useState({})
    const dateFormatList = ['DD MMM YYYY HH:mm']
    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf('day');
    }
    const validateMessages = {
        required: "Required.",
        types: {
            email: 'This is not a valid email.',
        },
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const uploadButton = (
        <div>
            <Button icon={<UploadOutlined />}>Upload</Button>
        </div>
    )
    const [state, setState] = useState({
        fileList: []
    })
    const [loading, setLoading] = useState(false);
    const history = useHistory()

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0 && appState.fixedListLoaded === true) {
            _listPatientAndAllergyAndDialysis(patientID);
        }
        return () => { }
    }, [appState.cognitoUser, appState.fixedListLoaded])

    useEffect(() => {
        form.setFieldsValue(values)
    }, [values])

    const _listPatientAndAllergyAndDialysis = async (patientID) => {
        setLoading(true);
        try {
            const variables = {
                filter: {
                    id: {
                        eq: patientID
                    }
                },
                filter2: {
                    patientID: { eq: patientID },
                    deleted: { eq: 0 }
                },
                filter3: {
                    patientID: { eq: patientID },
                    postWeight: { attributeExists: true },
                    deleted: { eq: 0 }
                },
                pagination: {
                    limit: 1,
                },
                pagination2: {},
                pagination3: {
                    limit: 1,
                    orderby: 'endTime DESC',
                },
                unmasked: 1
            }

            // console.log('PatientInformation', variables);

            const result = await API.graphql({
                query: listPatientAndAllergyAndDialysis_PatientClinic,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result", result);
            const data = result.data.result
            const array = data.result

            // process data
            if (array && array.length > 0) {
                let v = array[0];
                let _patient = {
                    ...v,
                    existingClinic: v.existingClinicID ? (appState.fixedClinicList.filter(s => s.id == v.existingClinicID))[0].name : '',
                    // existingClinic: v.existingClinicID,
                    // active: v.status == 'ACTIVE',
                    status: v.status,
                    amputeeYes: v.amputee,
                    amputee: v.amputee?.length > 0 ? 1 : 0,
                    vwoSupportYes: v.vwoSupport,
                    vwoSupport: v?.vwoSupport?.length > 0 ? 1 : 0,
                    firstDialysisDisplay: v.firstDialysis ? moment(v.firstDialysis).local().format("DD MMM YYYY") : null,
                    dobDisplay: v.dob ? moment(v.dob).local().format("DD MMM YYYY") : null,
                    dob: v.dob ? moment(v.dob).local().format("DD MMM YYYY") : null,
                    // firstDialysis: v.firstDialysis ? moment(v.firstDialysis).local().format("DD MMM YYYY") : null,
                    firstDialysisHospitalDate: v.firstDialysisHospitalDate ? moment(v.firstDialysisHospitalDate).format('DD MMM YYYY') : null,
                    referringSourceEnd: v.referringSourceEnd ? moment.utc(v.referringSourceEnd).local().format('DD MMM YYYY') : null,
                    referringSourceStart: v.referringSourceStart ? moment.utc(v.referringSourceStart).local().format('DD MMM YYYY') : null,
                    DESRD: v.DESRD ? moment(v.DESRD).format('DD MMM YYYY') : null,
                    height: v.height ? v.height / 100.0 : '',
                    weight: v.weight ? v.weight / 1000.0 : '',
                    wheelchairWeight: v.wheelchairWeight ? v.wheelchairWeight / 1000.0 : '',
                    gender: v.gender == 'M' ? 'Male' : (v.gender == 'F' ? 'Female' : 'Others'),
                }

                let imageKey = v.key ?? ""
                if (imageKey !== "") {
                    _patient.profilePic = await Storage.get(imageKey)
                }
                else {
                    _patient.profilePic = ""
                }

                if (result?.data?.result2?.result && result?.data?.result2?.result?.length > 0) {
                    let allergies = result?.data?.result2?.result;
                    // console.log(allergies)
                    _patient.allergies = allergies.map(s => s.name).join(', ');
                }

                if (result?.data?.result3?.result && result?.data?.result3?.result?.length > 0) {
                    _patient.weight = result.data.result3.result[0].postWeight / 1000.0 ?? '';
                }
                _patient.bmi = _patient.height && _patient.weight ? (_patient.weight / (_patient.height * _patient.height)).toFixed(2) : '';

                setValues(_patient);
            } else {
                notification.error({ message: "Invalid Patient" });
                history.push(path("dashboard"))
            }

            setLoading(false);
        }
        catch (error) {
            console.log("error: ", error);
        }
        finally { }
    }

    const handleCancel = () => {
        setState({ ...state, previewVisible: false })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url?.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    const handleChange = (file) => {
        // console.log(file);
        // console.log(optionNum);
        let { fileList } = file
        // fileList = fileList.slice(-1)
        setState({ ...state, fileList: fileList })
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
    }

    const renderListOptions = (array, key = 'id', value = 'id', name = 'name') => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const renderTreeSelectOptions = (array) => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <TreeNode title={item.name} value={item.id} key={item.id} />
                )
            })

            return listOptions
        }
        else {
            return (
                ""
            )
        }
    }

    const renderInputItems = (array) => {
        const items = array.map((item, index) => {
            // console.log("item", item);
            switch (item.type) {
                case "displayText":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required: item.required === true ? true : false
                                }
                            ]}
                            style={item.width !== undefined ? { width: item.width } : {}}
                        >
                            {values[item.name] || '-'}
                        </Form.Item>
                    )
                case "select":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required: item.required === true ? true : false
                                }
                            ]}
                            style={item.width !== undefined ? { width: item.width } : {}}
                        >
                            <Select
                                showSearch
                                placeholder={item.placeholder}
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                                disabled={item.disabled === true ? true : false}
                            // defaultValue={0}
                            >
                                {renderListOptions(item.optionList)}
                            </Select>
                        </Form.Item>
                    )
                case "treeSelect":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required: item.required === true ? true : false
                                }
                            ]}
                            style={item.width !== undefined ? { width: item.width } : {}}
                        >
                            <TreeSelect
                                showSearch
                                allowClear
                                multiple
                                treeDefaultExpandAll
                                treeCheckable
                                placeholder="Select symptoms"
                                filterTreeNode={(input, treenode) =>
                                    treenode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderTreeSelectOptions(item.optionList)}
                            </TreeSelect>
                        </Form.Item>
                    )
                case "input":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required: item.required === true ? true : false
                                }
                            ]}
                            style={item.width !== undefined ? { width: item.width } : {}}
                        >
                            <Input placeholder={item.placeholder} disabled={item.disabled === true ? true : false} autoComplete="off" />
                        </Form.Item>
                    )
                case "email":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    type: "email",
                                    required: item.required === true ? true : false
                                }
                            ]}
                            style={item.width !== undefined ? { width: item.width } : {}}
                        >
                            <Input placeholder={item.placeholder} disabled={item.disabled === true ? true : false} autoComplete="off" />
                        </Form.Item>
                    )
                case "text":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required: item.required === true ? true : false
                                }
                            ]}
                            style={{ width: "100%" }}
                        >
                            <TextArea rows={item.rows} placeholder={item.placeholder} disabled={item.disabled === true ? true : false} />
                        </Form.Item>
                    )
                case "datepicker":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required: item.required === true ? true : false
                                }
                            ]}
                        >
                            <DatePicker onChange={handleDatePickerChange} format={dateFormatList} disabledDate={disabledDate} disabled={item.disabled === true ? true : false} />
                        </Form.Item>
                    )
                case "checkbox":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                        >
                            <Form.Item
                                name={item.name}
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox disabled={item.disabled === true ? true : false} />
                            </Form.Item>
                        </Form.Item>
                    )
                case "radio":
                    return (
                        <Form.Item
                            key={index}
                            label={item.label}
                            name={item.name}
                            rules={[
                                {
                                    required: item.required === true ? true : false
                                }
                            ]}
                        >
                            <Radio.Group
                                options={item.optionList}
                                defaultValue={item.defaultValue}
                                disabled={item.disabled === true ? true : false}
                            />
                        </Form.Item>
                    )
                case "space":
                    return (
                        <Form.Item key={index}>&nbsp;</Form.Item>
                    )
                case "none":
                    return (
                        <Form.Item key={index} style={{ display: "none" }}></Form.Item>
                    )
                default:
            }
        })
        return items
    }

    return (
        <div className="content-content">
            <div className="container-row">
                <Spin spinning={loading}>
                    <div className="patientinformation-header">
                        <div className="container-column1">
                            <div className="column1 title">
                                {/* Patient Information - <span className="patient">{values.name} ({values.nric})</span> */}
                                Patient Information
                            </div>
                            <PatientDetailsSummary patientID={patientID} />
                        </div>
                        <div className="column3">
                            {(appState.loginType != 'Doctor') ?
                                <Button className="btn-bookappointment">
                                    <Link to={path("patientAppointment", [props.match.params.id])}>
                                        Book Appointment
                                    </Link>
                                </Button> :
                                <></>}
                            <Button type="primary">
                                <Link to={path("patientInformationEdit", [props.match.params.id])}>Edit</Link>
                            </Button>
                        </div>
                    </div>
                    <Form
                        form={form}
                        layout="horizontal"
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="patientinformation-content view">
                            <div className="column">
                                <div className="row1">
                                    <div className="container-profilepic">
                                        <div>
                                            <Avatar className="avatar" icon={<UserOutlined />} shape="square" src={values.profilePic} />
                                        </div>
                                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop: "16px" }}>
                                            <Form.Item>
                                                <Upload
                                                    action={getBase64}
                                                    listType="picture"
                                                    fileList={state.fileList}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => handleChange(file)}
                                                >
                                                    {state.fileList.length >= 1 ? null : uploadButton}
                                                </Upload>
                                                <Modal
                                                    visible={state.previewVisible}
                                                    title={state.previewTitle}
                                                    footer={null}
                                                    onCancel={handleCancel}
                                                >
                                                    <img alt="upload preview" style={{ width: '100%' }} src={state.previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </div> */}
                                    </div>
                                    <div className="container-ddl">
                                        <Descriptions title={"Dialysis Details"} />
                                        {renderInputItems([
                                            // {
                                            //     label: "Dialysis Start Date",
                                            //     name: "firstDialysisDisplay",
                                            //     type: "displayText",
                                            //     value: "Data",
                                            // },
                                            {
                                                label: "Referring Doctor",
                                                name: "referrer",
                                                type: "displayText",
                                                value: "Option 1",
                                            },
                                            {
                                                label: "Referring Source",
                                                name: "referringSource",
                                                type: "displayText",
                                                value: "Option 1",
                                            },
                                            {
                                                label: "Referring Source Start",
                                                name: "referringSourceStart",
                                                type: "displayText",
                                                value: "Data",
                                            },
                                            {
                                                label: "Referring Source End",
                                                name: "referringSourceEnd",
                                                type: "displayText",
                                                value: "Data",
                                            },
                                            {
                                                label: "Dialysis Centre",
                                                name: "existingClinic",
                                                type: "displayText",
                                                value: "Option 1",
                                            },
                                            // {
                                            //     label: "Current Dialysis Centre Start",
                                            //     name: "firstDialysis",
                                            //     type: "displayText",
                                            //     value: "Data",
                                            // },
                                            // {
                                            //     label: "Active",
                                            //     name: "active",
                                            //     type: "checkbox",
                                            //     disabled: true,
                                            // },
                                            {
                                                label: "Status",
                                                name: "status",
                                                type: "displayText",
                                            },
                                            {
                                                label: "Travel Type",
                                                name: "travelType",
                                                type: "displayText",
                                                value: "Option 1",
                                            },
                                        ])}
                                    </div>
                                </div>
                                <Divider />
                                <div className="row2">
                                    <Descriptions title={"Personal Particulars"} />
                                    {renderInputItems([
                                        {
                                            label: "Full Name",
                                            name: "name",
                                            type: "displayText",
                                            value: "Full Name",
                                            width: "100%",
                                        },
                                        {
                                            type: "none"
                                        },
                                        // {
                                        //     label: "First Name",
                                        //     name: "firstName",
                                        //     type: "input",
                                        //     placeholder: "Enter first name",
                                        //     required: true,
                                        // },
                                        // {
                                        //     label: "Last Name",
                                        //     name: "lastName",
                                        //     type: "input",
                                        //     placeholder: "Enter last name",
                                        //     required: true,
                                        // },
                                        {
                                            label: "Identification Type",
                                            name: "idType",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "NRIC",
                                            name: "nric",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Gender",
                                            name: "gender",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Date of Birth",
                                            name: "dob",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Race",
                                            name: "race",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Religion",
                                            name: "religion",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Marital Status",
                                            name: "maritalStatus",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Accommodation",
                                            name: "accommodation",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                    ])}
                                </div>
                                <Divider />
                                <div className="row2">
                                    <Descriptions title={"Contact Details"} />
                                    {renderInputItems([
                                        {
                                            label: "Mobile Number",
                                            name: "phoneNo",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Home Number",
                                            name: "homeNo",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Email",
                                            name: "email",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            type: "space"
                                        },
                                        {
                                            label: "Address",
                                            name: "address",
                                            type: "displayText",
                                            value: "Data",
                                            width: "100%",
                                        },
                                        {
                                            type: "none"
                                        },
                                        {
                                            label: "Name of Next of Kin",
                                            name: "nokName",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "NOK's relationshp with Patient",
                                            name: "nokRelation",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "NOK's Mobile Number",
                                            name: "nokPhoneNo",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            type: "space"
                                        },
                                        {
                                            label: "NOK's Address",
                                            name: "nokAddress",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                    ])}
                                </div>
                            </div>
                            <div className="column">
                                <div className="row2">
                                    <Descriptions title={"Medical Details"} />
                                    {renderInputItems([
                                        {
                                            label: "Height (m)",
                                            name: "height",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Weight (kg)",
                                            name: "weight",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "BMI",
                                            name: "bmi",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Blood Group",
                                            name: "bloodGroup",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Mobility Status",
                                            name: "mobilityStatus",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Diabetic Status",
                                            name: "diabeticStatus",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Smoker Status",
                                            name: "smoker",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Alcohol",
                                            name: "alcohol",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "Amputee",
                                            name: "amputeeYes",
                                            type: "displayText",
                                            disabled: true,
                                        },
                                        {
                                            type: "space"
                                        },
                                        // {
                                        //     label: "Amputee",
                                        //     name: "amputee",
                                        //     type: "radio",
                                        //     required: true,
                                        //     defaultValue: 0,
                                        //     optionList: [
                                        //         { label: "No", value: 0 },
                                        //         { label: "Yes", value: 1 },
                                        //     ],
                                        //     disabled: true,
                                        // },
                                        // {
                                        //     label: "If yes (Amputee), specify",
                                        //     name: "amputeeYes",
                                        //     type: "displayText",
                                        //     value: "Data",
                                        // },
                                        {
                                            label: "Date ESRD",
                                            name: "DESRD",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Date of First Dialysis",
                                            name: "firstDialysisHospitalDate",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            label: "Wheelchair's weight (kg)",
                                            name: "wheelchairWeight",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            type: "space"
                                        },
                                        // {
                                        //     label: "Patient's History",
                                        //     name: "history",
                                        //     type: "displayText",
                                        //     value: "Data",
                                        //     width: "100%",
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                        // {
                                        //     label: "Cause of kidney failure",
                                        //     name: "causeOfKidneyFailure",
                                        //     type: "displayText",
                                        //     value: "Data",
                                        //     width: "100%",
                                        // },
                                        // {
                                        //     type: "none"
                                        // },
                                        {
                                            label: "Allergies",
                                            name: "allergies",
                                            type: "displayText",
                                            value: "Option 1",
                                            width: "100%",
                                        },
                                        {
                                            type: "none"
                                        },
                                    ])}
                                </div>
                                {/* <Divider />
                                <div className="row2">
                                    <Descriptions title={"Dialysis Schedule"} />
                                    {renderInputItems([
                                        // {
                                        //     label: "Pattern",
                                        //     name: "pattern",
                                        //     type: "displayText",
                                        //     value: "Option 1",
                                        // },
                                        {
                                            label: "Session",
                                            name: "session",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                        {
                                            label: "HD Device No",
                                            name: "hdDeviceNo",
                                            type: "displayText",
                                            value: "Option 1",
                                        },
                                    ])}
                                </div> */}
                                <Divider />
                                <div className="row2">
                                    <Descriptions title={"Support"} />
                                    {renderInputItems([
                                        {
                                            label: "Med Social Worker",
                                            name: "socialWorker",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                        {
                                            type: "space"
                                        },
                                        // {
                                        //     label: "Other VWO Support",
                                        //     name: "vwoSupport",
                                        //     type: "radio",
                                        //     required: true,
                                        //     defaultValue: 0,
                                        //     optionList: [
                                        //         { label: "No", value: 0 },
                                        //         { label: "Yes", value: 1 },
                                        //     ],
                                        //     disabled: true,
                                        // },
                                        {
                                            label: "Funded",
                                            name: "vwoSupportYes",
                                            type: "displayText",
                                            value: "Data",
                                        },
                                    ])}
                                </div>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    )
}

export default PatientInformation