import React, { useEffect, useState } from "react"
import { Button, Descriptions, Form, Input, notification, Space, Spin, Tooltip } from "antd"
import { InfoCircleOutlined, LockOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { Auth } from "@aws-amplify/auth"

import path from "../utils/pathSettings"

const SettingsChangePassword = () => {
    const [form] = Form.useForm()
    const validateMessages = {
        required: "This field is required.",
        string: {
            min: "This field must be at least ${min} characters.",
        },
    }
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        return () => { }
    }, [])

    const changePassword = async (values) => {
        await form.validateFields()
        setLoading(true)
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
            const result = await Auth.changePassword(user, values.password, values.newPassword)
            // console.log(result);
            notification.success({
                message: "Password has been updated successfully"
            })
            form.resetFields()
        }
        catch (error) {
            console.log("error : ", error);
            if (error.code === "NotAuthorizedException") {
                notification.error({
                    message: "Incorrect current password"
                })
            }
            else {
                notification.error({
                    message: error.message
                })
            }
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <div className="content-content">
            <div className="settingsmanageaccount-header">
                <div className="title">
                    Manage Password - Change Password
                </div>
            </div>
            <div className="settingsmanageaccount-account">
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        layout="vertical"
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <div className="row">
                            <div className="container-accountdetails">
                                <Descriptions
                                    extra={
                                        <Space>
                                            <Button>
                                                <Link to={path("settingsManageAccount")}>Cancel</Link>
                                            </Button>
                                            <Button type="primary" onClick={() => { changePassword(form.getFieldsValue()) }}>
                                                Change Password
                                            </Button>
                                        </Space>
                                    }
                                />
                                <Form.Item
                                    label="Current Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    extra={
                                        <Tooltip placement="bottomLeft" title={"Kindly log out of your account and click on \"Forgot password\" at the login page."} trigger={["click"]}>
                                            <div className="hint">
                                                <InfoCircleOutlined /> Forget your password?
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="Current Password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="New Password"
                                    name="newPassword"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 8,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="New Password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Confirm New Password"
                                    name="confirmNewPassword"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("newPassword") === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error("The two passwords that you entered do not match."));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        placeholder="Confirm New Password"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    )
}

export default SettingsChangePassword