import React, { useContext, useState, useEffect } from "react"
import { Button, DatePicker, Form, Input, Modal, Table, notification, Popconfirm, Select, Space, Descriptions, message } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { PlusOutlined } from "@ant-design/icons"
import API from "@aws-amplify/api"
import moment from "moment"

import { listHospitalSummary, listPatient } from "../backend/graphql/queries"
import { createHospitalSummary } from "../backend/graphql/mutations"
import { AppContext } from "../contexts/AppContext"
import MyWindowPortal from "../utils/MyWindowPortal"
import iconEye from "../media/Icon-Eye2.png"
import iconFile from "../media/Icon-File2.png"
import { listHospitalSummary_Patient_DialysisOrder } from "../backend/graphql/custom_queries"

const { TextArea } = Input

const PatientRecordClinicNotes = (props) => {
    const { appState } = useContext(AppContext);
    const { Option } = Select
    const [form] = Form.useForm()
    const dateFormatList = ["DD MMM YYYY, HH:mm"]
    const validateMessages = {
        required: "This field is required.",
    }
    const [visibilityAddClinicalNotes, setVisibilityAddClinicalNotes] = useState(false)
    const [visibilityClinicalNotes, setVisibilityClinicalNotes] = useState(false)
    const [clinicalNotes, setClinicalNotes] = useState({})
    let columns = [
        {
            title: "Date",
            dataIndex: "admitTimestamp",
            width: 125
        },
        {
            title: "Author",
            dataIndex: "staffName", //staffAccountID
            width: 120
        },
        {
            title: "Clinic / Event",
            dataIndex: "type",
            width: 125,
        },
        {
            title: "Observation",
            dataIndex: "observation",
            ellipsis: true,
        },
        {
            title: "Assessment",
            dataIndex: "assessment",
            ellipsis: true,
        },
        {
            title: "Plans",
            dataIndex: "plan",
            ellipsis: true,
        },
        {
            title: "Intra Dialysis Notes",
            dataIndex: "notes",
            width: 180,
            ellipsis: true,
        },
        {
            title: "",
            dataIndex: "action",
            width: 50,
            render: (_, record) => {
                return (
                    <div className="clickable" onClick={() => { handleModalVisibility("clinicalNotes", record) }}>
                        <img src={iconEye} />
                    </div>
                )
            }
        }
    ]
    const [table, setTable] = useState({
        data: [],
        pagination: {
            current: 1,
            pageSize: 5,
            offset: 0,
            showSizeChanger: false,
        },
        loading: false
    });
    const [patient, setPatient] = useState(-1)
    const [dialysisOrder, setDialysisOrder] = useState(-1)
    const hideInterDialysisNote = true;
    columns = columns.filter(s => !(hideInterDialysisNote && s?.dataIndex === 'notes'));

    useEffect(() => {
    }, [])


    useEffect(() => {
        // console.log("PatientRecordAdministeredMedication", props?.patientID);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            getHospitalSummary(null, table.pagination);
        }
        return () => { }
    }, [appState.cognitoUser])

    useEffect(() => {
        if (visibilityAddClinicalNotes === true) {
            form.setFieldsValue({
                admitTimestamp: moment()
            })
        }
    }, [visibilityAddClinicalNotes])

    const _listHospitalSummary = async (patientID, pagination = table.pagination, filter = null) => {
        try {
            const variables = {
                pagination: {
                    limit: pagination.pageSize,
                    offset: pagination.offset,
                    orderby: `admitTimestamp DESC`
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    // or: [
                    //     {
                    //         type: {
                    //             eq: "CLINIC"
                    //         }
                    //     },
                    //     {
                    //         type: {
                    //             eq: "EVENT"
                    //         }
                    //     }
                    // ]
                    type: {
                        ne: "HOSPITAL"
                    },
                    deleted: { eq: 0 }
                },
                filter2: {
                    id: {
                        eq: patientID
                    }
                },
                pagination2: {
                    limit: 1,
                },
                filter3: {
                    patientID: {
                        eq: patientID
                    },
                    deleted: { eq: 0 },
                },
                pagination3: {
                    limit: 1,
                    orderby: 'dOrder DESC',
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listHospitalSummary_Patient_DialysisOrder,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordClinicNotes", result);

            const data = result.data.result
            const array = result.data.result.result

            // process data
            let _hopitalSummary = [];
            for (let v of array) {
                _hopitalSummary.push({
                    id: v.id,
                    accessIDHosp: v.accessIDHosp,
                    patientID: v.patientID,
                    clinicID: v.clinicID,
                    staffAccountID: v.staffAccountID,
                    staffName: v?.staffName ? v?.staffName : (appState.staffList !== -1 && appState.staffList.length > 0 ? (appState.staffList.filter(s => s.accountID == v.staffAccountID)[0])?.name : v.staffAccountID),

                    admitTimestamp: moment.utc(v.admitTimestamp).local().format("DD MMM yyyy, HH:mm"),
                    type: v.type,
                    observation: v.observation,
                    assessment: v.assessment,
                    plan: v.plan,
                    notes: v.notes,
                    noOfDays: v.noOfDays,
                    location: v.location,
                    reason: v.reason,
                    diagnosis: v.diagnosis,
                    outcome: v.outcome,
                })
            }

            // process patient
            const array2 = result?.data?.result2?.result;
            if (array2 && array2.length > 0) {
                let v = array2[0];
                let _patient = {
                    ...v,
                    name: v.name,
                    namecode: v.namecode,
                    nric: v.nric ? v.nric?.substring(0, v.nric.length - 4).replace(/\d|\w/g, "*") + v.nric?.substring(v.nric.length - 4) : '****',
                    dob: v.dob,
                    dobDisplay: v.dob ? moment(v.dob).format("DD MMM YYYY") : '',
                    // age: values.dob ? (moment(values.dob).fromNow()).replace("ago", "old") : "",
                    age: v.dob ? (moment().diff(moment(v.dob), "years", true).toFixed(1) + " years old") : "",
                    gender: v.gender,
                    genderDisplay: v.gender === "M" ? "Male" : (v.gender === "F" ? "Female" : "Others"),
                    // nok: values.nokName,
                    // nokMobile: values.nokPhoneNo,
                    // dialysisPeriodDuration: dialysisPeriodDuration, //"18 years, 4 months",
                    referralSource: v.referringSource,
                    // currentClinicDialysisDuration: firstDialysisPeriodDuration, //"1 year, 3 months",
                    // allergyDisplay: result?.data?.result2?.result?.map(s => s.name) || [],
                    // allergy: _allergy,
                    // quickbookCustomerID,
                    esrd: v.DESRD,
                    esrdAge: v.DESRD ? (moment().diff(moment(v.DESRD), "years", true).toFixed(1) + " years") : "",
                    esrdDisplay: v.DESRD ? moment(v.DESRD).format("DD MMM YYYY") : '',
                }

                setPatient(_patient);
            }

            // process dialysisOrder
            const array3 = result?.data?.result3?.result;
            if (array3 && array3.length > 0) {
                let v = array3[0];
                setDialysisOrder({
                    id: v.id,
                    msa: v.MSA,
                    dorderDate: moment(v.dOrder).format('DD MMM yyyy'),
                    freqmth: v.freqMth,
                    duration: v.duration,
                    hepload: v.hepLoad,
                    hepcont: v.hepCont,
                    antioOthers: v.antio,
                    dialysate: v.dialysate,
                    primaryAccess: v.accessPrim,
                    aNeedle: v.aNeedle,
                    vNeedle: v.vNeedle,
                    bicarbonate: v.bicarbonate,
                    temp: v.temp,
                    qd: v.QD,
                    qb: v.QB,
                    dw: v.dryWeight ? (v.dryWeight / 1000.0).toFixed(1) : '',
                    notes: v.notes
                });
            }

            // setMedicationAdministered(_medicationAdministered);
            setTable({
                ...table,
                data: _hopitalSummary,
                loading: false,
                pagination: {
                    ...table.pagination,
                    current: pagination.current,
                    offset: pagination.offset,
                    total: pagination.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                },
            })

            // console.log(_medicationAdministered);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Clinical Notes"
            })
        }
        finally { }
    }

    const _createHospitalSummary = async (values) => {
        // setLoading(true);
        setTable({
            ...table,
            loading: true,
        })
        try {
            let variables = {
                patientID: props?.patientID,
                clinicID: appState?.selectedClinicID,
                // staffAccountID: values.staffAccountID,
                staffAccountID: appState?.cognitoUser.username,
                admitTimestamp: moment(values.admitTimestamp).toISOString().split(".")[0],
                type: values.type,
                observation: values.observation,
                assessment: values.assessment,
                plan: values.plan,
                notes: values.notes,
            }

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: createHospitalSummary,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            })
            // console.log("API result PatientRecordClinicNotes createHospitalSummary", result);

            if (result?.data?.result) {
                getHospitalSummary(props?.patientID, {
                    ...table.pagination,
                    current: 1,
                    offset: 1 * table.pagination.pageSize - table.pagination.pageSize,
                });
                notification.success({
                    message: "Created successfully"
                })
                form.resetFields();
                setVisibilityAddClinicalNotes(false);
            }

            setTable({
                ...table,
                loading: false,
            })
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create new record"
            })
        } finally { }
    }

    const getHospitalSummary = (filter, pagination) => {
        setTable({
            ...table,
            loading: true
        });

        _listHospitalSummary(props?.patientID, pagination, filter);
    };

    const onFinish = async (values) => {
        // console.log("onFinish clicked", values);
        const v = await form.validateFields()
        // console.log("onFinish clicked2", v);
        _createHospitalSummary(v);
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log("handleDatePickerChange");
        // console.log(date, dateString);
    }

    const handleModalVisibility = (modal, record) => {
        // console.log("modal", modal);
        // console.log("record", record);
        switch (modal) {
            case "addClinicNotes":
                form.setFieldsValue({ type: "EVENT" })
                setVisibilityAddClinicalNotes(!visibilityAddClinicalNotes)
                break
            case "clinicalNotes":
                setClinicalNotes(record ? record : {})
                setVisibilityClinicalNotes(!visibilityClinicalNotes)
                break
            default:
        }
    }

    const handleTableChange = (paginate) => {
        // console.log("handleTableChange", paginate)
        getHospitalSummary(null, {
            ...table.pagination,
            current: paginate.current,
            offset: paginate.current * table.pagination.pageSize - table.pagination.pageSize,
        })
    }

    const renderListOptions = (array, key = 'id', value = 'id', name = 'name') => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="patientrecord-clinicnotes">
            <Button className="side-button" type="primary" onClick={() => handleModalVisibility("addClinicNotes")}>
                <PlusOutlined /> Add Clinical Notes
            </Button>
            <div className="container-title">
                <div className="title">
                    Clinical Notes
                </div>
                <Button type="primary" onClick={() => handleModalVisibility("addClinicNotes")}>
                    <PlusOutlined /> Add Notes
                </Button>
            </div>
            <div>
                <Table
                    columns={columns}
                    // scroll={{ x: 1500, y: 500 }}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : ""
                    }}
                    dataSource={table.data}
                    loading={table.loading}
                    pagination={table.data.length > 0 ? { ...table.pagination, position: ["bottomLeft"] } : false}
                    onChange={handleTableChange}
                    ellipsis={true}
                />
            </div>
            {
                visibilityAddClinicalNotes === true && (
                    <MyWindowPortal closeWindowPortal={() => setVisibilityAddClinicalNotes(false)}>
                        <div style={{ padding: "16px" }}>
                            <Form
                                className="addclinicalnotes"
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                requiredMark={false}
                            >
                                <div className="row">
                                    <Descriptions title="User Info" bordered>
                                        <Descriptions.Item span={3} label="Name">{patient.name}</Descriptions.Item>
                                        {/* <span className="clickable">
                                            <CopyToClipboard
                                                text={"ESRD: " + patient.esrdAge + ", Age: " + patient.age + ", DW: " + dialysisOrder.dw}
                                                onCopy={(text, result) => {
                                                    console.log(text, result)
                                                    if (result)
                                                        message.success('Copied!')
                                                }}
                                                options={{
                                                    debug: false
                                                }}
                                            >
                                                <img src={iconFile} />
                                            </CopyToClipboard>
                                        </span> */}
                                        <Descriptions.Item label="ESRD">{patient.esrdAge}</Descriptions.Item>
                                        <Descriptions.Item label="AGE">{patient.age}</Descriptions.Item>
                                        <Descriptions.Item label="DW">{dialysisOrder.dw}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                                <br />
                                <div className="row">
                                    <Space>
                                        <Form.Item
                                            label="Date"
                                            name="admitTimestamp"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={handleDatePickerChange} format={dateFormatList} showTime/>
                                        </Form.Item>
                                        {/* <Form.Item
                                            label="Author"
                                            name="staffAccountID"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select style={{ width: 180 }}
                                                showSearch={true}
                                                placeholder="Select a staff"
                                                optionFilterProp="children"
                                                // onChange={onChange}
                                                // onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                }>
                                                {appState.staffList !== -1 ? renderListOptions(appState.staffList, 'accountID', 'accountID', 'name') : null}
                                            </Select>
                                        </Form.Item> */}
                                        <Form.Item
                                            label="Clinic / Event"
                                            name="type"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select style={{ width: 180 }}
                                                showSearch={true}
                                                placeholder="Clinic / Event"
                                                optionFilterProp="children"
                                                // onChange={onChange}
                                                // onSearch={onSearch}
                                                // defaultValue={"EVENT"}
                                                filterOption={(input, option) =>
                                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                }>
                                                {renderListOptions([{ id: 'CLINIC', name: 'Clinic' }, { id: 'EVENT', name: 'Event' }], 'id', 'id', 'name')}
                                            </Select>
                                        </Form.Item>
                                    </Space>
                                </div>
                                <div className="row">
                                    <Space>
                                        <Form.Item
                                            className="row2"
                                            label="Observation"
                                            name="observation"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <TextArea rows={6} />
                                        </Form.Item>
                                        <Form.Item
                                            className="row2"
                                            label="Assessment"
                                            name="assessment"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <TextArea rows={6} />
                                        </Form.Item>
                                        <Form.Item
                                            className="row2"
                                            label="Plans"
                                            name="plan"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <TextArea rows={6} />
                                        </Form.Item>
                                        {hideInterDialysisNote === false ?
                                            <Form.Item
                                                className="row2"
                                                label="Intra Dialysis Notes"
                                                name="notes"
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={6} />
                                            </Form.Item> :
                                            <></>
                                        }
                                    </Space>
                                </div>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </MyWindowPortal>
                )
            }
            {/* <Modal
                title={
                    <div className="modal-title">
                        Add Clinical Notes
                    </div>
                }
                visible={visibilityAddClinicalNotes}
                onCancel={() => handleModalVisibility("addClinicNotes")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("addClinicNotes")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure?" onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No">
                        <Button key="submit" type="primary">
                            Submit
                        </Button>
                    </Popconfirm>,
                ]}
                className="modal-patientrecord-clinicnotes"
                width={800}
            >
                <div className="modal-content">
                    <Form
                        className="addclinicalnotes"
                        form={form}
                        layout="horizontal"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Form.Item
                            label="Admission Date"
                            name="admitTimestamp"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                        </Form.Item>
                        <Form.Item
                            label="Author"
                            name="staffAccountID"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select style={{ width: 180 }}
                                showSearch={true}
                                placeholder="Select a staff"
                                optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }>
                                {appState.staffList !== -1 ? renderListOptions(appState.staffList, 'accountID', 'accountID', 'name') : null}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Clinic / Event"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select style={{ width: 180 }}
                                showSearch={true}
                                placeholder="Clinic / Event"
                                optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }>
                                {renderListOptions([{ id: 'CLINIC', name: 'Clinic' }, { id: 'EVENT', name: 'Event' }], 'id', 'id', 'name')}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Observation"
                            name="observation"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Assessment"
                            name="assessment"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Plans"
                            name="plan"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                        <Form.Item
                            className="row2"
                            label="Intra Dialysis Notes"
                            name="notes"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                    </Form>
                </div>
            </Modal> */}
            <Modal
                title={
                    <div className="modal-title">
                        Clinical Notes
                    </div>
                }
                visible={visibilityClinicalNotes}
                onCancel={() => handleModalVisibility("clinicalNotes")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("clinicalNotes")}>
                        Cancel
                    </Button>,
                ]}
                className="modal-patientrecord-clinicnotes"
                width={800}
            >
                <div className="modal-content">
                    <div className="clinicnotes">
                        <div className="row1">
                            <div className="label">Date:</div>
                            {/* <div className="value">{moment(clinicalNotes.admitTimestamp).format("DD MMM YYYY, HH:mm")}</div> */}
                            <div className="value">{clinicalNotes.admitTimestamp}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Author:</div>
                            <div className="value">{clinicalNotes.staffName}</div>
                        </div>
                        <div className="row1">
                            <div className="label">Clinic / Event:</div>
                            <div className="value">{clinicalNotes.type}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Observation:</div>
                            <div className="value">{clinicalNotes.observation}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Assessment:</div>
                            <div className="value">{clinicalNotes.assessment}</div>
                        </div>
                        <div className="row2">
                            <div className="label">Plans:</div>
                            <div className="value">{clinicalNotes.plan}</div>
                        </div>
                        {hideInterDialysisNote === false ?
                            <div className="row2">
                                <div className="label">Intra Dialysis Notes:</div>
                                <div className="value">{clinicalNotes.notes}</div>
                            </div> :
                            <></>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PatientRecordClinicNotes