import React, { useEffect, useState, useContext } from "react"
import { Button, Descriptions, Form, Input, Modal, notification, Popconfirm, Space, Spin } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import API from "@aws-amplify/api";
import moment, { duration } from "moment";

import renderInputItems from "../utils/renderFormInputItems"
import { listDialysisOrder } from "../backend/graphql/queries";
import { AppContext } from "../contexts/AppContext";
import { createDialysisOrder } from "../backend/graphql/mutations";

const { TextArea } = Input

const PatientRecordDialysisOrder = (props) => {
    const { appState } = useContext(AppContext);
    const { modal, values, setValues, dialysisOrderID, setDialysisOrderID, setDwFromDialysiOrder = null } = props
    const [form] = Form.useForm()
    const validateMessages = {
        required: "Required.",
    }
    const [visibilityCreateDialysisOrder, setVisibilityCreateDialysisOrder] = useState(false);
    const [dialysisOrder, setDialysisOrder] = useState({
        // msa: "2.1",
        // // if: "468",
        // dorderDate: "21 Apr 2021",
        // // nameCode: "TUIERF",
        // freqmth: "12",
        // duration: "240",
        // hepload: "2000",
        // hepcont: "1000",
        // antioOthers: "n/a",
        // dialysate: "LC",
        // // accessprmm: "AVF LEF",
        // primaryAccess: "AVF LEF",
        // aNeedle: "16",
        // vNeedle: "+2",
        // // anlldll: "16",
        // bicarbonate: "+2",
        // temp: "35",
        // // target: "35",
        // qd: "500",
        // qb: "3",
        // dw: "16",
        // note: "machine bicarbonate setting +2; up +3, 18: up +4, 17 p+5. 16 up +6 but must check the bicarbbonate and K and Calcium in 2w",
    });
    const [loading, setLoading] = useState(false);

    const _listDialysisOrder = async (patientID) => {
        // console.log("patientID", patientID);
        setLoading(true);
        try {
            const variables = {
                pagination: {
                    limit: 1,
                    orderby: 'dOrder DESC',
                },
                filter: {
                    patientID: {
                        eq: patientID
                    },
                    deleted: { eq: 0 }
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listDialysisOrder,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordDialysisOrder", result);

            const data = result.data.result
            const array = result.data.result.result

            if (array.length > 0) {
                const v = array[0];

                // process data
                setDialysisOrder({
                    id: v.id,
                    msa: v.MSA,
                    dorderDate: moment(v.dOrder).format('DD MMM yyyy'),
                    freqmth: v.freqMth,
                    duration: v.duration,
                    hepload: v.hepLoad,
                    hepcont: v.hepCont,
                    antioOthers: v.antio,
                    dialysate: v.dialysate,
                    primaryAccess: v.accessPrim,
                    aNeedle: v.aNeedle,
                    vNeedle: v.vNeedle,
                    bicarbonate: v.bicarbonate,
                    temp: v.temp,
                    qd: v.QD,
                    qb: v.QB,
                    dw: v.dryWeight ? (v.dryWeight / 1000.0).toFixed(1) : '',
                    notes: v.notes
                });

                // setValues({
                //     ...values,
                //     dialysisOrderID: v.id,
                // })
                if (setDialysisOrderID !== undefined) {
                    setDialysisOrderID(v.id);
                    if (setDwFromDialysiOrder !== null) {
                        setDwFromDialysiOrder(v.dryWeight ? (v.dryWeight / 1000.0).toFixed(1) : "")
                    }
                }
            }

            // setLoading(false);
            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Dialysis Order"
            })
        }
        finally {
            setLoading(false);
        }
    }

    const _createDialysisOrder = async (patientID, values) => {
        setLoading(true);
        try {
            let create = {
                patientID: parseInt(patientID),
                notes: values.notes,
                MSA: parseFloat(values.msa),
                dOrder: values.dorder ? values.dorder.toISOString().split('.')[0] : moment().toISOString().split('.')[0],
                freqMth: parseInt(values.freqmth),
                duration: parseInt(values.duration),
                hepLoad: parseInt(values.hepload),
                hepCont: parseInt(values.hepcont),
                antio: parseInt(values.antioOthers),
                dialysate: values.dialysate,
                accessPrim: values.primaryAccess,
                bicarbonate: parseInt(values.bicarbonate),
                temp: parseFloat(values.temp),
                QD: parseInt(values.qd),
                QB: parseInt(values.qb),
                dryWeight: Number.parseInt(parseFloat(values.dw) * 1000),
                staffAccountID: appState.cognitoUser.username,
            }

            if (values.aNeedle !== " ") {
                create = {
                    ...create,
                    aNeedle: parseInt(values.aNeedle)
                }
            }
            if (values.vNeedle !== " ") {
                create = {
                    ...create,
                    vNeedle: parseInt(values.vNeedle),
                }
            }

            const variables = {
                create: create
            }

            if (appState.cognitoUser.username)

                variables.create = Object.entries(variables.create).reduce(
                    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                    {}
                )

            console.log(variables);

            const result = await API.graphql({
                query: createDialysisOrder,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result createDialysisOrder", result);

            const data = result?.data?.result
            const array = result?.data?.result?.result

            // console.log('createDialysisOrder', data, array);

            if (data) {
                setVisibilityCreateDialysisOrder(false);
                form.resetFields();
                notification.success({
                    message: "Dialysis Order created."
                })

                await _listDialysisOrder(props?.patientID);
            } else {
                throw Error(`failed`);
            }
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to create Dialysis Order"
            })
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0) {
            _listDialysisOrder(props?.patientID);
        }
        return () => { }
    }, [appState.cognitoUser])

    useEffect(() => {
        if (Object.keys(dialysisOrder).length > 0) {
            form.setFieldsValue({
                ...dialysisOrder,
                aNeedle: dialysisOrder.aNeedle ?? ' ',
                vNeedle: dialysisOrder.vNeedle ?? ' ',
            })
        }
        else {
            form.setFieldsValue({
                freqmth: "13",
                duration: "240",
                hepload: "1000",
                hepcont: "500",
                dialysate: "-1.25",
                bicarbonate: "31",
                temp: "36.9",
                qd: "500",
                qb: "250"
            })
        }
        form.setFieldsValue({
            dorder: moment()
        })
    }, [dialysisOrder])

    const onFinish = async (values) => {
        setLoading(true);
        // const timer = setTimeout(() => {
        //     setLoading(false)
        //     clearTimeout(timer)
        //     handleModalVisibility("createDialysisOrder")
        // }, 1000)

        let v = await form.validateFields();
        _createDialysisOrder(props?.patientID, v);
    }

    const handleModalVisibility = (modal, id = 0) => {
        // console.log("modal", modal, id);
        switch (modal) {
            case "createDialysisOrder":
                setVisibilityCreateDialysisOrder(!visibilityCreateDialysisOrder);
                break
            default:
        }
    }

    return (
        <div className={modal === true ? "modal-patientrecord-dialysisorder" : "patientrecord-dialysisorder above-md"}>
            <Spin spinning={loading}>
                <div>
                    {"(Please fill in the date of the Anti Hep Bcore total and result as well as HBV and HCV RNA if any into the Dialysis Order note; as well any of the medication to be given at dialysis)"}
                </div>
                <div className="container-row1">
                    {dialysisOrder.notes ?
                        <div className="row1">
                            DO Notes:
                            <br />
                            {dialysisOrder.notes}
                        </div>
                        : <></>
                    }
                    <Button type="primary" onClick={() => handleModalVisibility("createDialysisOrder")}>
                        <PlusOutlined /> New Order
                    </Button>
                </div>
                <div className="row2">
                    <Descriptions
                        className="column"
                        column={{ xs: 1, sm: 1 }}
                    >
                        <Descriptions.Item label="MSA">{dialysisOrder.msa}</Descriptions.Item>
                        {/* <Descriptions.Item label="IF">{dialysisOrder.if}</Descriptions.Item> */}
                        <Descriptions.Item label="DORDER">{dialysisOrder.dorderDate}</Descriptions.Item>
                        {/* <Descriptions.Item label="Name Code">{dialysisOrder.nameCode}</Descriptions.Item> */}
                        <Descriptions.Item label="FREQMTH">{dialysisOrder.freqmth}</Descriptions.Item>
                        <Descriptions.Item label="Duration">{dialysisOrder.duration}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        className="column"
                        column={{ xs: 1, sm: 1 }}
                    >
                        {/* <Descriptions.Item label="Duration">{dialysisOrder.duration}</Descriptions.Item> */}
                        <Descriptions.Item label="HEP (load)">{dialysisOrder.hepload}</Descriptions.Item>
                        <Descriptions.Item label="HEP (cont)">{dialysisOrder.hepcont}</Descriptions.Item>
                        <Descriptions.Item label="COAG (Others)">{dialysisOrder.antioOthers}</Descriptions.Item>
                        {/* <Descriptions.Item label="Accessprmm">{dialysisOrder.accessprmm}</Descriptions.Item> */}
                        <Descriptions.Item label="Dialysate (%)">{dialysisOrder.dialysate}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        className="column"
                        column={{ xs: 1, sm: 1 }}
                    >
                        {/* <Descriptions.Item label="ANLLDLL">{dialysisOrder.anlldll}</Descriptions.Item> */}
                        <Descriptions.Item label="Primary Access">{dialysisOrder.primaryAccess}</Descriptions.Item>
                        <Descriptions.Item label="BICARBONATE">{dialysisOrder.bicarbonate}</Descriptions.Item>
                        <Descriptions.Item label="'A' Needle">{dialysisOrder.aNeedle === null ? "None" : dialysisOrder.aNeedle}</Descriptions.Item>
                        <Descriptions.Item label="'V' Needle">{dialysisOrder.vNeedle === null ? "None" : dialysisOrder.vNeedle}</Descriptions.Item>
                        {/* <Descriptions.Item label="Temp">{dialysisOrder.temp}</Descriptions.Item>
                    <Descriptions.Item label="Target">{dialysisOrder.target}</Descriptions.Item>
                    <Descriptions.Item label="QB">{dialysisOrder.qb}</Descriptions.Item> */}
                    </Descriptions>
                    <Descriptions
                        className="column"
                        column={{ xs: 1, sm: 1 }}
                    >
                        {/* <Descriptions.Item label="ANLLDLL">{dialysisOrder.anlldll}</Descriptions.Item>
                    <Descriptions.Item label="bICARBONATE">{dialysisOrder.bicarbonate}</Descriptions.Item> */}
                        <Descriptions.Item label={<>Temp (&#8451;)</>}>{dialysisOrder.temp}</Descriptions.Item>
                        {/* <Descriptions.Item label="Target">{dialysisOrder.target}</Descriptions.Item> */}
                        <Descriptions.Item label="QD">{dialysisOrder.qd}</Descriptions.Item>
                        <Descriptions.Item label="QB">{dialysisOrder.qb}</Descriptions.Item>
                        <Descriptions.Item label="DW">{dialysisOrder.dw}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Spin>
            <Modal
                title={
                    <div className="modal-title">
                        Create Dialysis Order
                    </div>
                }
                visible={visibilityCreateDialysisOrder}
                onCancel={() => handleModalVisibility("createDialysisOrder")}
                footer={[
                    <Button key="back" onClick={() => handleModalVisibility("createDialysisOrder")}>
                        Cancel
                    </Button>,
                    <Popconfirm title="Are you sure?" onConfirm={() => {
                        form.submit()
                    }}>
                        <Button key="submit" type="primary">
                            Submit
                        </Button>
                    </Popconfirm>,
                ]}
                className="modal-patientrecord-dialysisorder"
                width={800}
            >
                <Spin spinning={loading}>
                    <div className="modal-content">
                        <Form
                            className="createdialysisorder"
                            form={form}
                            layout="horizontal"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                            requiredMark={false}
                        >
                            <div className="row">
                                <Space>
                                    <div>
                                        {renderInputItems([
                                            {
                                                className: "row2",
                                                label: "DO",
                                                name: "notes",
                                                type: "text",
                                                rows: 12,
                                                placeholder: "Enter DO Notes",
                                                required: true,
                                            },
                                        ])}
                                    </div>
                                    <div className="row">
                                        <Space>
                                            <div>
                                                {renderInputItems([
                                                    {
                                                        className: "row2",
                                                        label: "DW",
                                                        name: "dw",
                                                        type: "input",
                                                        placeholder: "Enter DW",
                                                        required: true,
                                                        regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "MSA",
                                                        name: "msa",
                                                        type: "input",
                                                        placeholder: "Enter MSA",
                                                        required: true,
                                                        regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    // {
                                                    //     label: "IF",
                                                    //     name: "if",
                                                    //     type: "input",
                                                    //     placeholder: "Enter IF",
                                                    //     required: false,
                                                    // },
                                                    {
                                                        className: "row2",
                                                        label: "DORDER",
                                                        name: "dorder",
                                                        type: "datepicker",
                                                        placeholder: "Enter DORDER",
                                                        required: true,
                                                    },
                                                    // {
                                                    //     label: "Name Code",
                                                    //     name: "nameCode",
                                                    //     type: "input",
                                                    //     placeholder: "Enter Name Code",
                                                    //     required: false,
                                                    // },
                                                    {
                                                        className: "row2",
                                                        label: "FREQMTH",
                                                        name: "freqmth",
                                                        type: "input",
                                                        placeholder: "Enter FREQMTH",
                                                        required: true,
                                                        regex: new RegExp('^[0-9]*$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                ])}
                                            </div>
                                            <div>
                                                {renderInputItems([
                                                    {
                                                        className: "row2",
                                                        label: "Duration (mins)",
                                                        name: "duration",
                                                        type: "input",
                                                        placeholder: "Enter Duration",
                                                        required: true,
                                                        regex: new RegExp('^[0-9]*$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "HEP (load)",
                                                        name: "hepload",
                                                        type: "input",
                                                        placeholder: "Enter HEPLOAD",
                                                        required: false,
                                                        regex: new RegExp('^[0-9]*$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "HEP (cont)",
                                                        name: "hepcont",
                                                        type: "input",
                                                        placeholder: "Enter HEPCONT",
                                                        required: false,
                                                        regex: new RegExp('^[0-9]*$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "COAG (Others)",
                                                        name: "antioOthers",
                                                        type: "input",
                                                        placeholder: "Enter COAG (Others)",
                                                        required: false,
                                                        // regex: new RegExp('^[0-9]*$'),
                                                        // regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                ])}
                                            </div>
                                            <div>
                                                {renderInputItems([
                                                    {
                                                        className: "row2",
                                                        label: "Dialysate (%)",
                                                        name: "dialysate",
                                                        type: "input",
                                                        placeholder: "Enter Dialysate",
                                                        required: true,
                                                        onFocusSelect: true,
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "Primary Access",
                                                        name: "primaryAccess",
                                                        type: "select",
                                                        placeholder: "Enter Primary Access",
                                                        required: true,
                                                        optionList: [
                                                            { id: "AVF LEFT WRIST", name: "AVF LEFT WRIST" },
                                                            { id: "AVF RIGHT WRIST", name: "AVF RIGHT WRIST" },
                                                            { id: "AVF LEFT BCF", name: "AVF LEFT BCF" },
                                                            { id: "AVF RIGHT BCF", name: "AVF RIGHT BCF" },
                                                            { id: "PC LEFT", name: "PC LEFT" },
                                                            { id: "PC RIGHT", name: "PC RIGHT" },
                                                            { id: "FEMORAL TRIALYSIS LEFT", name: "FEMORAL TRIALYSIS LEFT" },
                                                            { id: "FEMORAL TRIALYSIS RIGHT", name: "FEMORAL TRIALYSIS RIGHT" },
                                                            { id: "FEMORAL PERM CATH LEFT", name: "FEMORAL PERM CATH LEFT" },
                                                            { id: "FEMORAL PERM CATH RIGHT", name: "FEMORAL PERM CATH RIGHT" },
                                                        ],
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "Bicarbonate",
                                                        name: "bicarbonate",
                                                        type: "input",
                                                        placeholder: "Enter Bicarbonate",
                                                        required: true,
                                                        regex: new RegExp('^[0-9]*$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "'A' Needle",
                                                        name: "aNeedle",
                                                        type: "select",
                                                        placeholder: "Enter 'A' Needle",
                                                        required: true,
                                                        optionList: [
                                                            { id: " ", name: "None" },
                                                            { id: "15", name: "15" },
                                                            { id: "16", name: "16" },
                                                            { id: "17", name: "17" },
                                                        ],
                                                    },
                                                ])}
                                            </div>
                                            <div>
                                                {renderInputItems([
                                                    {
                                                        className: "row2",
                                                        label: "'V' Needle",
                                                        name: "vNeedle",
                                                        type: "select",
                                                        placeholder: "Enter 'A' Needle",
                                                        required: true,
                                                        optionList: [
                                                            { id: " ", name: "None" },
                                                            { id: "15", name: "15" },
                                                            { id: "16", name: "16" },
                                                            { id: "17", name: "17" },
                                                        ],
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: <>Temp (&#8451;)</>,
                                                        name: "temp",
                                                        type: "input",
                                                        placeholder: "Enter Temp",
                                                        required: true,
                                                        regex: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    // {
                                                    //     label: "Target",
                                                    //     name: "target",
                                                    //     type: "input",
                                                    //     placeholder: "Enter Target",
                                                    //     required: false,
                                                    // },
                                                    {
                                                        className: "row2",
                                                        label: "QD",
                                                        name: "qd",
                                                        type: "input",
                                                        placeholder: "Enter QD",
                                                        required: true,
                                                        regex: new RegExp('^[0-9]*$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                    {
                                                        className: "row2",
                                                        label: "QB",
                                                        name: "qb",
                                                        type: "input",
                                                        placeholder: "Enter QB",
                                                        required: true,
                                                        regex: new RegExp('^[0-9]*$'),
                                                        regexMessage: 'Digits only',
                                                        onFocusSelect: true,
                                                    },
                                                ])}
                                            </div>
                                        </Space>
                                    </div>
                                </Space>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </Modal>
        </div>
    )
}

export default PatientRecordDialysisOrder