import API from "@aws-amplify/api"
import { Auth } from "@aws-amplify/auth"
import { notification } from "antd"
import React, { createContext, useEffect, useReducer, useState } from "react"
import { listClinicRelatedList, listFixedList } from "../backend/graphql/custom_queries"
import { useHistory } from 'react-router-dom'
import path from "./../utils/pathSettings"
import awsmobile from '.././aws-exports';
import { Storage } from "@aws-amplify/storage"
import moment from "moment"

export const AppContext = createContext()

const initialState = {
    cognitoUser: {},
    validLogin: false,
    loginType: null,
    menu: [],
    clinicList: -1,
    fixedClinicList: -1,
    drugList: -1,
    machineList: -1,
    seatList: -1,
    symptomList: -1,
    staffList: -1,
    hospitalReasonList: -1,
    // clinicList: [],
    // drugList: [],
    // machineList: [],
    // seatList: [],
    // symptomList: [],
    // staffList: [],
    selectedClinicID: "",
    fixedListLoaded: false,
    useRefreshToken: true,
    tokenExpired: false,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_MENU":
            return {
                ...state,
                menu: action.payload,
            }
        case "SET_COGNITOUSER":
            return {
                ...state,
                cognitoUser: action.payload,
            }
        case "SET_LOGINTYPE":
            return {
                ...state,
                loginType: action.payload,
            }
        case "SET_FIXEDLIST":
            return {
                ...state,
                ...action.payload,
            }
        case "SET_VALIDLOGIN":
            return {
                ...state,
                validLogin: action.payload,
            }
        case "SET_CLINICID":
            return {
                ...state,
                selectedClinicID: action.payload,
            }
        case "SET_PROFILEPICTURE":
            return {
                ...state,
                profilePicture: action.payload,
            }
        default:
    }
}

const AppContextProvider = (props) => {
    const [appState, dispatch] = useReducer(reducer, initialState);
    const [profilePic, setProfilePic] = useState("")
    const history = useHistory();

    // Actions
    const actionLogin = (user, loginType = null) => {
        localStorage.setItem("ircs_cognitouser", JSON.stringify(user))
        dispatch({ type: "SET_VALIDLOGIN", payload: true })
        dispatch({ type: "SET_COGNITOUSER", payload: user })
        if (loginType)
            dispatch({ type: "SET_LOGINTYPE", payload: loginType })
    }

    const actionLogout = () => {
        dispatch({ type: "SET_VALIDLOGIN", payload: false })
        dispatch({ type: "SET_COGNITOUSER", payload: {} })
        dispatch({ type: "SET_CLINICID", payload: "" })
        dispatch({ type: "SET_PROFILEPICTURE", payload: "" })
        localStorage.removeItem("ircs_cognitouser")
        localStorage.removeItem("ircs_clinicid")
        localStorage.removeItem("ircs_stayloggedin")
    }

    const actionSetMenu = (menu) => {
        dispatch({ type: "SET_MENU", payload: menu })
    }

    const actionSetClinicID = (clinicID) => {
        localStorage.setItem("ircs_clinicid", clinicID)
        dispatch({ type: "SET_CLINICID", payload: clinicID })
    }

    const actionSetQuickBookRedirect = async (patientID, dialysisID, values) => {
        sessionStorage.setItem("ircs_quickbook", JSON.stringify({
            ...values,
            patientID,
            dialysisID,
        }));
    }

    const getS3 = () => {
        // console.log('getS3');
        return {
            bucket: awsmobile.aws_user_files_s3_bucket,
            region: awsmobile.aws_user_files_s3_bucket_region,
        };
    }

    const getQuickBookRedirect = async () => {
        return JSON.parse(sessionStorage.getItem("ircs_quickbook"));
    }

    const getFixedList = async () => {
        try {
            const variables = {
                // filter: {
                //     status: { eq: 'ACTIVE' }
                // }
                filter2: {
                    deleted: { eq: 0 }
                },
                filter4: {
                    deleted: { eq: 0 }
                },
                pagination2: {
                    orderby: '`name` ASC'
                },
                pagination4: {
                    orderby: '`name` ASC'
                },
                pagination6: {
                    orderby: '`hospDetail` ASC'
                },
            };

            const result = await API.graphql({
                query: listFixedList,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables,
                // authMode: "AWS_IAM",
            })
            // console.log("fixed list", result);

            let drugList = result?.data?.listDrug?.result ?? [];
            // drugList = drugList.sort((a, b) => a.name > b.name ? 1 : -1);
            let symptomList = result?.data?.listSymptom?.result || [];
            // symptomList = symptomList.sort((a, b) => a.name > b.name ? 1 : -1);
            // let qbAccess = result?.data?.listQBAccesses?.items[0] ?? {};
            // let tokenExpired = moment(qbAccess.date).add(qbAccess.expiresIn, 'second') < moment().add(-3, 'days');
            // console.log('tokenExpired', tokenExpired);

            dispatch({
                type: "SET_FIXEDLIST", payload: {
                    clinicList: result?.data?.listClinic?.result || [],
                    fixedClinicList: result?.data?.listClinic?.result || [],
                    drugList: drugList,
                    // machineList: result?.data?.listMachine?.result || [],
                    symptomList: symptomList,
                    staffList: result?.data?.listStaff?.result || [],
                    hospitalReasonList: result?.data?.listHospitalReason?.result || [],
                    // fixedListLoaded: true,
                    // tokenExpired,
                }
            })
        }
        catch (error) {
            // console.log("error: ", error.key, error.message, JSON.stringify(error));
            if (error.message === "No current user") {
                notification.error({
                    message: "Token has expired, please re-login."
                })
                await Auth.signOut()
                actionLogout()
            }
        }
        finally { }
    }

    const getClinicRelatedList = async () => {
        try {
            const variables = {
                filter: {
                    clinicID: {
                        eq: parseInt(appState.selectedClinicID)
                    },
                    deleted: { eq: 0 },
                    status: { eq: 'AVAILABLE' }
                },
                filter2: {
                    clinicID: {
                        eq: parseInt(appState.selectedClinicID)
                    }
                }
            };

            if (initialState.useRefreshToken) {
                variables.filter3 = {
                    id: { eq: `${appState.selectedClinicID}` }
                }
            }

            // console.log('variable', variables);
            const result = await API.graphql({
                query: listClinicRelatedList,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables,
                // authMode: "AWS_IAM",
            })
            // console.log("clinic fixed list", result);

            let qbAccess = result?.data?.listQBAccesses?.items[0] ?? {};
            let tokenExpired = moment(qbAccess.date).add(qbAccess.expiresIn, 'second') < moment().add(-3, 'days');
            // console.log('tokenExpired', tokenExpired);

            dispatch({
                type: "SET_FIXEDLIST", payload: {
                    machineList: result?.data?.listMachine?.result || [],
                    seatList: result?.data?.listSeat?.result || [],
                    // fixedListLoaded: true,
                    tokenExpired,
                }
            })
        }
        catch (error) {
            console.log("error: ", error);
        }
        finally { }
    }

    const getCognitoFromLocalStorage = async () => {
        const user = JSON.parse(localStorage.getItem("ircs_cognitouser"))
        const cognitoGroups = user.signInUserSession.idToken.payload["cognito:groups"]
        // console.log("cognitoGroups", cognitoGroups);

        let platformPermission = false
        let loginType = ""

        for (let i = 0; i < cognitoGroups.length; i++) {
            if (cognitoGroups[i] === "Nurse" || cognitoGroups[i] === "ManagingNurse" || cognitoGroups[i] === "Doctor" || cognitoGroups[i] === "MedicalDirector") {
                platformPermission = true
                loginType = cognitoGroups[i]
                break
            }
        }

        if (platformPermission) {
            actionLogin(user, loginType)
            if (localStorage.getItem("ircs_clinicid") !== null) {
                actionSetClinicID(localStorage.getItem("ircs_clinicid"))
            }
        }
        else {
            try {
                await Auth.signOut();
            } catch (error) {
                console.log("error signing out: ", error);
            }
            notification.error({
                message: "Not authorised account"
            })
        }
    }

    const initProfilePicture = async () => {
        if (appState?.cognitoUser?.attributes?.picture) {
            let pic = await Storage.get(JSON.parse(appState?.cognitoUser?.attributes?.picture).key);
            setProfilePic(pic);
            dispatch({ type: "SET_PROFILEPICTURE", payload: pic })
        }
    }

    useEffect(() => {
        if (localStorage.getItem("ircs_cognitouser") !== null) {
            // Logout if stayloggedin is false && not at qb redirect page.

            let isQuickbooksRedirect = sessionStorage.getItem('ircs_quickbook') ?? false;

            if (localStorage.getItem("ircs_stayloggedin") !== "true" && !isQuickbooksRedirect) {
                actionLogout()
            }
            else {
                getCognitoFromLocalStorage()
            }
        }

        return () => { }
    }, [])

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0 && appState.validLogin === true) {
            getFixedList();

            initProfilePicture();
        }
    }, [appState.cognitoUser, appState.validLogin])

    useEffect(() => {
        if (appState.selectedClinicID > 0) {
            getClinicRelatedList();
        }
    }, [appState.selectedClinicID])

    // useEffect(() => {
    //     // check for clinicid
    //     // console.log("check clinic id", appState);
    //     if (appState.fixedListLoaded === true && appState.selectedClinicID === null) {
    //         console.log("REDIRECT TO SELECT CENTRE");
    //         history.push(path("settingsCentreSelection"))
    //     }
    // }, [appState.fixedListLoaded, appState.selectedClinicID])

    useEffect(() => {
        if (appState.fixedListLoaded === false && appState.clinicList !== -1 && appState.drugList !== -1 && appState.machineList !== -1 && appState.seatList !== -1 && appState.symptomList !== -1 && appState.staffList !== -1) {
            dispatch({
                type: "SET_FIXEDLIST", payload: {
                    fixedListLoaded: true,
                }
            })
        }
    }, [appState])

    useEffect(() => {
        // console.log('Manage Staff', appState.cognitoUser, appState.staffList);
        if (Object.keys(appState.cognitoUser).length > 0 && appState.staffList && appState.staffList.length > 0 && appState.clinicList && appState.clinicList.length > 0) {
            let staff = appState.staffList.find(s => s.accountID === appState.cognitoUser.username);
            // console.log('Manage Staff 2', staff, staff.clinicIDs);

            // if (staff && staff?.clinicIDs) {
            if (staff && (['Nurse', 'Doctor'].includes(staff?.designation) || staff?.clinicIDs)) {
                let _clinicIDs = staff.clinicIDs ? JSON.parse(staff.clinicIDs) : [];
                let _clinicList = appState.clinicList.filter(s => (_clinicIDs.length == 0 || _clinicIDs?.includes(s.id)) && s.status == 'ACTIVE');
                // console.log('Manage Staff 3', _clinicList);

                if (_clinicList.length < appState.clinicList.length) {
                    dispatch({
                        type: "SET_FIXEDLIST",
                        payload: {
                            clinicList: _clinicList
                        }
                    });
                }
            }
        }
    }, [appState.cognitoUser, appState.staffList, appState.clinicList])

    return (
        // <AppContext.Provider value={{ appState, actionSetMenu, actionLogin, actionLogout, actionSetClinicID, getLocalStorageUser, getLocalStorageClinicID, getCurrentAuthenticatedUser }}>
        <AppContext.Provider value={{ appState, actionSetMenu, actionLogin, actionLogout, actionSetClinicID, actionSetQuickBookRedirect, getS3 }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContextProvider