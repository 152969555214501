import React, { useContext, useEffect, useState } from "react"
import { Empty, notification, Spin, Table } from "antd"
import moment from "moment"
import { AppContext } from "../contexts/AppContext"
import { listLastNonNullLab } from "../backend/graphql/custom_queries"
import API from "@aws-amplify/api"
import { SWR, fetcher } from "../utils/useSWR"
import useSWR from "swr"

const PatientRecordLastNonNullLab = (props) => {
    const { appState } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [labData, setLabData] = useState({
        electrolytes: [],
        LFTData: [],
        HbCaData: [],
        virologyLIPDS: []
    });

    useEffect(() => {
        // console.log('PatientRecordMedication', props?.patientID);
        // console.log(appState.cognitoUser);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            _listLastNonNullLab(props?.patientID)
        }
        return () => { }
    }, [appState.cognitoUser])

    const [queryOption, setQueryOption] = useState(null)

    const { data, error } = useSWR(queryOption, fetcher, {
        // dedupingInterval: 60000
    });

    useEffect(() => {
        if (data && queryOption) {
            // console.log(data);
            const result = data;

            if (queryOption?.query === listLastNonNullLab) {
                // console.log("API result PatientRecordLastNonNullLab", result);

                // process data
                let _electrolytes = result?.data?.result?.result.length ? result?.data?.result?.result[0] : [];
                let _LFTData = result?.data?.result2?.result.length ? result?.data?.result2?.result[0] : [];
                let _HbCaData = result?.data?.result3?.result.length ? result?.data?.result3?.result[0] : [];
                let _virologyLIPDS = result?.data?.result4?.result.length ? result?.data?.result4?.result[0] : [];

                let _data = {
                    electrolytes: [],
                    LFTData: [],
                    HbCaData: [],
                    virologyLIPDS: []
                }

                for (let [k, v] of Object.entries(_electrolytes)) {
                    if (k.includes('_timestamp')) {
                        let key = k.split('_timestamp')[0];

                        _data.electrolytes.push({
                            date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                            item: key,
                            value: v ? _electrolytes[key] : '-',
                        });
                    }
                }

                for (let [k, v] of Object.entries(_LFTData)) {
                    if (k.includes('_timestamp')) {
                        let key = k.split('_timestamp')[0];

                        _data.LFTData.push({
                            date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                            item: key,
                            value: v ? _LFTData[key] : '-',
                        });
                    }
                }

                for (let [k, v] of Object.entries(_HbCaData)) {
                    if (k.includes('_timestamp')) {
                        let key = k.split('_timestamp')[0];

                        _data.HbCaData.push({
                            date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                            item: key,
                            value: v ? _HbCaData[key] : '-',
                        });
                    }
                }

                for (let [k, v] of Object.entries(_virologyLIPDS)) {
                    if (k.includes('_timestamp')) {
                        let key = k.split('_timestamp')[0];

                        _data.virologyLIPDS.push({
                            date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                            item: key,
                            value: v ? _virologyLIPDS[key] : '-',
                        });
                    }
                }

                setLabData(_data);
            }
        }
    }, [data, queryOption])

    const _listLastNonNullLab = async (patientID) => {
        setLoading(true)
        try {
            const variables = {
                pagination: {
                    limit: 1,
                    orderby: `timestamp DESC, id DESC`
                },
                filter: {
                    patientID: {
                        eq: parseInt(patientID)
                    }
                },
                filter2: {
                    patientID: {
                        eq: parseInt(patientID)
                    }
                },
                filter3: {
                    patientID: {
                        eq: parseInt(patientID)
                    }
                },
                filter4: {
                    patientID: {
                        eq: parseInt(patientID)
                    }
                }
            };

            // console.log('_listLastNonNullLab', variables);
            // setQueryOption({
            //     query: listLastNonNullLab,
            //     authMode: "AMAZON_COGNITO_USER_POOLS",
            //     variables
            // })

            const result = await API.graphql({
                query: listLastNonNullLab,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            });
            // console.log("API result PatientRecordLastNonNullLab", result);

            // process data
            let _electrolytes = result?.data?.result?.result.length ? result?.data?.result?.result[0] : [];
            let _LFTData = result?.data?.result2?.result.length ? result?.data?.result2?.result[0] : [];
            let _HbCaData = result?.data?.result3?.result.length ? result?.data?.result3?.result[0] : [];
            let _virologyLIPDS = result?.data?.result4?.result.length ? result?.data?.result4?.result[0] : [];

            let _data = {
                electrolytes: [],
                LFTData: [],
                HbCaData: [],
                virologyLIPDS: []
            }

            for (let [k, v] of Object.entries(_electrolytes)) {
                if (k.includes('_timestamp')) {
                    let key = k.split('_timestamp')[0];

                    _data.electrolytes.push({
                        date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                        item: key,
                        value: v ? _electrolytes[key] : '-',
                    });
                }
            }

            for (let [k, v] of Object.entries(_LFTData)) {
                if (k.includes('_timestamp')) {
                    let key = k.split('_timestamp')[0];

                    _data.LFTData.push({
                        date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                        item: key,
                        value: v ? _LFTData[key] : '-',
                    });
                }
            }

            for (let [k, v] of Object.entries(_HbCaData)) {
                if (k.includes('_timestamp')) {
                    let key = k.split('_timestamp')[0];

                    _data.HbCaData.push({
                        date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                        item: key,
                        value: v ? _HbCaData[key] : '-',
                    });
                }
            }

            for (let [k, v] of Object.entries(_virologyLIPDS)) {
                if (k.includes('_timestamp')) {
                    let key = k.split('_timestamp')[0];

                    _data.virologyLIPDS.push({
                        date: v ? moment.utc(v).local().format('DD MMM YYYY') : '-',
                        item: key,
                        value: v ? _virologyLIPDS[key] : '-',
                    });
                }
            }

            setLabData(_data);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Last Non Null Lab"
            })
        }
        finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            title: "Date Lab",
            dataIndex: "timestampString",
            width: 150,
            // renderInput: (
            //     <Form.Item
            //         key="timestamp"
            //         name="timestamp"
            //         rules={[
            //             {
            //                 required: true,
            //             },
            //         ]}
            //         style={{ width: 150 }}
            //     >
            //         <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
            //     </Form.Item>
            // )
        },
        {
            title: "Hb",
            dataIndex: "Hb",
            render(text, record) {
                let outOfRange = parseFloat(text) <= 10;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "MCV",
            dataIndex: "MCV"
        },
        {
            title: "TW",
            dataIndex: "tw"
        },
        {
            title: "Plt",
            dataIndex: "Plt"
        },
        {
            title: "K",
            dataIndex: "K",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 5.5;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "HCO3",
            dataIndex: "HCO3",
            render(text, record) {
                let outOfRange = parseFloat(text) < 20;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Na",
            dataIndex: "Na"
        },
        {
            title: "Cl",
            dataIndex: "chlorine"
        },
        {
            title: "Creatinine",
            dataIndex: "creatinine"
        },
        {
            title: "Glucose",
            dataIndex: "glucose"
        },
        {
            title: "CCT",
            dataIndex: "CCT"
        },
        {
            title: "Ca",
            dataIndex: "Ca",
            render(text, record) {
                let outOfRange = parseFloat(text) <= 2;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Po4",
            dataIndex: "Po4",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 1.7;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "iPTH",
            dataIndex: "IPTH",
            render(text, record) {
                let outOfRange = parseFloat(text) > 80;
                // let outOfDate = moment().isAfter(moment.utc(record['date']).add(moment.duration(3.5, 'months').asDays(), 'days'));
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" },
                        // timestampStyle: { color: outOfDate ? "red" : "", fontWeight: outOfDate ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Urea Pre",
            dataIndex: "ureaPre"
        },
        {
            title: "Urea Post",
            dataIndex: "ureaPost"
        },
        // {
        //     title: "Un",
        //     dataIndex: "un"
        // },
        {
            title: "HepBsAg",
            dataIndex: "HepBsAg",
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === ('weak reactive') || text?.toLowerCase() === 'reactive';
                let outOfDate = moment().isAfter(moment.utc(record['date']).add(moment.duration(3.5, 'months').asDays(), 'days'));
                // console.log(record['date']);
                // console.log(moment(record['date'], 'DD MMM YYYY').toLocaleString());
                // console.log(moment(record['date'], 'DD MMM YYYY').add(moment.duration(3.5, 'months').asDays(), 'days').toLocaleString());
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" },
                        timestampStyle: { color: outOfDate ? "red" : "", fontWeight: outOfDate ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "HepBsAb",
            dataIndex: "HepBsAb"
        },
        {
            title: "HCV",
            dataIndex: "HCV",
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === 'reactive';
                let outOfDate = moment().isAfter(moment.utc(record['date']).add(moment.duration(3.5, 'months').asDays(), 'days'));
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" },
                        timestampStyle: { color: outOfDate ? "red" : "", fontWeight: outOfDate ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "HIV",
            dataIndex: "HIV",
            render(text, record) {
                let outOfRange = text?.toLowerCase()?.includes('pos') || text?.toLowerCase() === 'reactive';
                let outOfDate = moment().isAfter(moment.utc(record['date']).add(moment.duration(5.5, 'months').asDays(), 'days'));
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" },
                        timestampStyle: { color: outOfDate ? "red" : "", fontWeight: outOfDate ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "Fe",
            dataIndex: "Fe"
        },
        {
            title: "TIBC",
            dataIndex: "TIBC"
        },
        {
            title: "Ferritin",
            dataIndex: "ferritin",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 700;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        {
            title: "ESR",
            dataIndex: "ESR"
        },
        {
            title: "CRP",
            dataIndex: "CRP"
        },
        {
            title: "uricAcid",
            dataIndex: "uric"
        },
        {
            title: "TC",
            dataIndex: "TC"
        },
        {
            title: "HDL",
            dataIndex: "HDL"
        },
        {
            title: "LDL",
            dataIndex: "LDL"
        },
        {
            title: "TG",
            dataIndex: "TG"
        },
        {
            title: "Ratio",
            dataIndex: "Ratio"
        },
        {
            title: "Hba1c",
            dataIndex: "Hba1c",
            render(text, record) {
                let outOfRange = parseFloat(text) >= 7;
                return {
                    props: {
                        style: { color: outOfRange ? "red" : "", fontWeight: outOfRange ? "bold" : "normal" }
                    },
                    children: text
                };
            }
        },
        // {
        //     title: "Hep",
        //     dataIndex: "Hep"
        // },
        // {
        //     title: "Hep (Elisa)",
        //     dataIndex: "HepElisa"
        // },
        {
            title: "Protein",
            dataIndex: "protein"
        },
        {
            title: "Alb",
            dataIndex: "Alb"
        },
        {
            title: "Biltotal",
            dataIndex: "Biltotal"
        },
        {
            title: "Sap",
            dataIndex: "SAP"
        },
        {
            title: "Ast",
            dataIndex: "SGOT_AST"
        },
        {
            title: "Alt",
            dataIndex: "SGPT_ALT"
        },
        {
            title: "Ggt",
            dataIndex: "ggt"
        },
        {
            title: "Mg",
            dataIndex: "Mg"
        },
        {
            title: "Gis",
            dataIndex: "gis"
        },
        {
            title: "Hte",
            dataIndex: "hte"
        },
        {
            title: "Timing",
            dataIndex: "Timing"
        },
        {
            title: "TUP 24 Hrs",
            dataIndex: "TUP24"
        },
        {
            title: "TUP 12 Hrs",
            dataIndex: "TUP12"
        },
        {
            title: "Uvol",
            dataIndex: "Uvol"
        },
        {
            title: "Ublood",
            dataIndex: "Ublood"
        },
        {
            title: "Urbc",
            dataIndex: "Urbc"
        },
        {
            title: "Uprt",
            dataIndex: "Uprt"
        },
        {
            title: "Uwbc",
            dataIndex: "Uwbc"
        },
        {
            title: "Urine MA",
            dataIndex: "urineMA"
        },
        {
            title: "MACr",
            dataIndex: "MACr"
        },
        {
            title: "U creatinine",
            dataIndex: "uCreatinine"
        },
        {
            title: "Tac",
            dataIndex: "Tac"
        },
        {
            title: "CNI",
            dataIndex: "CNI"
        },
        {
            title: "Myco",
            dataIndex: "Myco"
        },
        {
            title: "Pred",
            dataIndex: "Pred"
        },
        {
            title: "",
            dataIndex: "action"
        },
    ]

    const renderItems = (array) => {
        const items = array.map((item, index) => {
            let _column = columns.find(s => s.dataIndex === item.item && s.render);
            let style = _column?.render(item.value, item).props.style ?? {};
            let timestampStyle = _column?.render(item.value, item).props.timestampStyle ?? {};

            return (
                <div key={item.id} className="record">
                    <div className="record-column" style={{ ...timestampStyle }}>
                        {/* {moment(new Date(item.date.replace(/-/g, "/") + " UTC")).format("DD MMM YYYY")} */}
                        {item.date}
                    </div>
                    <div className="record-column">
                        {item.item}
                    </div>
                    <div className="record-column" style={{ ...style }}>
                        {item.value}
                    </div>
                </div>
            )
        })
        return items
    }

    const renderLabResult = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderItems(array)
            )
        )
    }

    return (
        <div className="patientrecord-lastnonnulllab">
            <div className="title">
                Last Non Null Lab
            </div>
            <div className="arrangement">
                <div className="row">
                    <div className="column">
                        {/* <Table
                            columns={columns}
                        /> */}
                        <Spin spinning={loading}>
                            <div className="card">
                                <div className="card-header">
                                    Electrolytes
                                </div>
                                <div className="card-content">
                                    {renderLabResult(
                                        labData.electrolytes
                                        // [
                                        //     { id: 1, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 2, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 3, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 4, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 5, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 6, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 7, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 8, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        // ]
                                    )}
                                </div>
                            </div>
                        </Spin>
                    </div>
                    <div className="column">
                        {/* <Table
                            columns={columns2}
                        /> */}
                        <Spin spinning={loading}>
                            <div className="card">
                                <div className="card-header">
                                    LFT Data
                                </div>
                                <div className="card-content">
                                    {renderLabResult(
                                        labData.LFTData
                                        // [
                                        //     { id: 1, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 2, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 3, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 4, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 5, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 6, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 7, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 8, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        // ]
                                    )}
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        {/* <Table
                            columns={columns3}
                        /> */}
                        <Spin spinning={loading}>
                            <div className="card">
                                <div className="card-header">
                                    Hb Ca Data
                                </div>
                                <div className="card-content">
                                    {renderLabResult(
                                        labData.HbCaData
                                        // [
                                        //     { id: 1, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 2, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 3, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 4, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 5, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 6, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 7, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 8, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        // ]
                                    )}
                                </div>
                            </div>
                        </Spin>
                    </div>
                    <div className="column">
                        {/* <Table
                            columns={columns4}
                        /> */}
                        <Spin spinning={loading}>
                            <div className="card">
                                <div className="card-header">
                                    Virology &amp; LIPDS
                                </div>
                                <div className="card-content">
                                    {renderLabResult(
                                        labData.virologyLIPDS
                                        // [
                                        //     { id: 1, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 2, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 3, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 4, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 5, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 6, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 7, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        //     { id: 8, date: "2021-04-21 05:59:13", item: "item", value: "value" },
                                        // ]
                                    )}
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientRecordLastNonNullLab