import React, { useEffect, useState } from "react"
import { Button, Form, Input, notification, Table } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

import path from "../utils/pathSettings"
import iconAdd from "../media/Icon-Add-Black.png"
import { listPatient } from "../backend/graphql/queries"
import API from "@aws-amplify/api"

const ImportLabResultManualInput = (props) => {
    const [form] = Form.useForm()
    const [filter, setFilter] = useState({
        search: "",
    })
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0
        },
        loading: false
    })
    const [display, setDisplay] = useState([])
    const columns = [
        {
            title: "Patient Name",
            dataIndex: "name",
        },
        {
            title: "NRIC",
            dataIndex: "nric",
        },
        {
            title: "Mobile Number",
            dataIndex: "phoneNo",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return (
                    <div className="action">
                        <Link to={path("importLabResultManualInputPatient", [record.id])} className="action-icon"><img src={iconAdd} />Input Lab Result</Link>
                    </div>
                )
            }
        },
    ]

    useEffect(() => {
        // console.log(props);
        if (props?.location?.state?.search && props?.location?.state?.search.length > 0) {
            form.setFieldsValue({ search: props?.location?.state?.search ?? '' })
            setFilter({ search: props?.location?.state?.search ?? '' })

            onFinish(props?.location?.state?.search)
        }
    }, [])

    useEffect(() => {
        if (table.data != -1)
            setDisplay(table.data);

    }, [table.data])


    const getPatientRecord = async (search = filter.search, props = table.pagination) => {
        setTable({
            ...table,
            loading: true,
        })
        try {
            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                }
            }

            if (search?.length > 0) {
                variables.filter = {
                    or: [{
                        name: {
                            contains: search
                        }
                    }, {
                        nric: {
                            contains: search
                        }
                    }]
                }
            }

            // console.log('variables', variables);
            const result = await API.graphql({
                query: listPatient,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables,
            })
            // console.log("certification", result);
            const data = result.data.result
            const array = result.data.result?.result
            // console.log("data", data);

            if (array.length > 0) {
                array.map(s => s.nric = s.nric?.substring(0, s.nric.length - 4).replace(/\d|\w/g, "*") + s.nric?.substring(s.nric.length - 4));
            }

            setTable({
                ...table,
                loading: false,
                data: array,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            notification.error({
                message: "Unable to retrieve records"
            })
            setTable({
                ...table,
                loading: false
            })
        }
    }

    const onFinish = (value) => {
        // console.log("value", value);
        // console.log("submit form");
        // console.log(value, form.getFieldsValue());
        if (value)
            getPatientRecord(value);
        else
            getPatientRecord(filter?.search);
    }

    const handleSearchChange = (e) => {
        // console.log("handleSearchChange");
        // console.log(e.target.value);
        setFilter({
            ...filter,
            search: e.target.value
        })
    }

    return (
        <div className="content-content">
            <div className="importlabresult-header">
                <div className="row1 title">
                    Import Lab Result - Manual Input
                </div>
                <Form
                    form={form}
                    layout="horizontal"
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <div className="container-row">
                        <div className="row">
                            <Form.Item
                                className="search"
                                name="search"
                            >
                                <Input autoComplete="off" prefix={<SearchOutlined />} placeholder="Search patient / NRIC" onChange={handleSearchChange} value={filter.search} allowClear />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                Search
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="importlabresult-content">
                <Table
                    columns={columns}
                    rowKey={record => record.id}
                    loading={table.loading}
                    rowClassName={(record, index) => {
                        // console.log("index", index);
                        return index % 2 === 0 ? "highlight" : ""
                    }}
                    dataSource={
                        display
                        // [
                        //     {
                        //         id: 1,
                        //         patientName: "Name",
                        //         nric: "*****111A",
                        //         mobileNumber: "+6598765432",
                        //         email: "abc@email.com",
                        //     },
                        //     {
                        //         id: 2,
                        //         patientName: "Name 2",
                        //         nric: "*****111B",
                        //         mobileNumber: "+6598765111",
                        //         email: "cde@email.com",
                        //     },
                        //     {
                        //         id: 3,
                        //         patientName: "Name 3",
                        //         nric: "*****111C",
                        //         mobileNumber: "+6598765222",
                        //         email: "fgh@email.com",
                        //     },
                        // ]
                    }
                />
            </div>
        </div>
    )
}

export default ImportLabResultManualInput