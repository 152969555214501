import React, { useContext, useEffect, useState } from "react"
import { notification, Spin } from "antd"
import { useHistory } from "react-router-dom"
import path from "../utils/pathSettings";
import { AppContext } from "../contexts/AppContext";
import { loginQuickbooks } from "../backend/graphql/mutations";
import API from "@aws-amplify/api";

const RedirectQuickbooks = (props) => {
    const { appState, useRefreshToken } = useContext(AppContext);
    const history = useHistory();

    const loginQB = async (quickbookURL) => {
        try {
            let locURL = window.location.href.split('//');
            let domainURL = locURL[1].split('/')[0];
            let redirect_uri = locURL[0] + '//' + domainURL + path('redirectQuickbooks') + '/';

            const variables = {
                quickbookURL: quickbookURL ?? '',
                redirectURL: redirect_uri,
                clinicID: parseInt(appState.selectedClinicID)
            };

            // console.log('loginQuickbooks variables', variables);

            const result = await API.graphql({
                query: loginQuickbooks,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result loginQuickbooks", result);


            const data = result.data.result
            // const array = result.data.result.result
            const parseData = JSON.parse(data);
            if (data === '{\"success\":true}') {
                window.close();
            }

            // console.log('checkoutDialysis', data);
            // console.log(parseData, invoiceURL);


            // setLoading(false);
            // return data;

            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to establish Quickbooks connection."
            })
        }
    }

    useEffect(() => {
        // console.log('redirectQuickbooks', props);

        const init = async () => {
            let checkout = JSON.parse(sessionStorage.getItem("ircs_quickbook"));
            // console.log(checkout);

            if (checkout) {
                let patientID = checkout.patientID;
                let dialysisID = checkout.dialysisID;
                let values = checkout;

                sessionStorage.removeItem("ircs_quickbook")
                history.push({
                    pathname: path('patientDialysisCheckOut', [patientID, dialysisID]),
                    state: {
                        ...values,
                        quickbook: props.location.search
                    }
                });
            } else {
                notification.error({ message: "Invalid checkout request" });
                history.push(path('dashboard'));
            }
        }

        if (!appState.useRefreshToken)
            init();
        else {
            loginQB(props.location.search);
        }
    }, [])

    return (
        <div className="content-content" style={{ textAlign: "center" }}>
            <br /><br /><br /><br /><br />
            <Spin spinning={true} />
            <div>
                Redirecting to QuickBooks...
            </div>
        </div>
    )
}

export default RedirectQuickbooks