import React, { useContext, useEffect, useState } from "react"
import { Button, Empty, Form, Input, message, Modal, notification, Popconfirm, Spin, Tooltip } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import API from "@aws-amplify/api"
import moment from "moment"

import { listPatient } from "../backend/graphql/queries"
import { listPatientAndAllergyAndDialysis_PatientClinic } from "../backend/graphql/custom_queries"
import { createAllergy, updateAllergy } from "../backend/graphql/mutations"
import { AppContext } from "../contexts/AppContext"

import iconAdd from "../media/Icon-Add.png"
import iconMinus from "../media/Icon-Minus.png"
import iconFile from "../media/Icon-File2.png"
import useSWR from "swr"
import { fetcher } from "../utils/useSWR"
import { useHistory } from "react-router-dom"
import path from "../utils/pathSettings"

const PatientDetailsSummary = (props) => {
    const { appState } = useContext(AppContext)
    const { patientID, setPatientDisplay } = props;
    const history = useHistory()
    const [form] = Form.useForm()
    const validateMessages = {
        required: "Required.",
    }
    const [patient, setPatient] = useState({})
    // const [patient, setPatient] = useState({
    //     name: "Diego Mclaughlin",
    //     nric: "*****567A",
    //     dob: "13 Jun 1982 (39 years old)",
    //     nok: "John Mclaughlin",
    //     nokMobile: "+6598765432",
    //     dialysisPeriodDuration: "18 years, 4 months",
    //     referralSource: "Singapore General Hosplital",
    //     currentClinicDialysisDuration: "1 year, 3 months",
    //     allergyDisplay: ['Drug 1', 'Drug 2', 'Drug 3']
    // });
    const [visibilityAllergy, setVisibilityAllergy] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false);
    const quickBooksURL = `https://app.${process.env.REACT_APP_ENV === 'prod' ? '' : 'sandbox.'}qbo.intuit.com/app/customerdetail?nameId=`

    const [queryOption, setQueryOption] = useState(null)
    const { data, error, mutate, isValidating } = useSWR(queryOption, fetcher, {
        dedupingInterval: 60000,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    });

    useEffect(() => {
        if (data && queryOption) {
            // console.log(data);
            const result = data;

            if (queryOption?.query === listPatientAndAllergyAndDialysis_PatientClinic) {
                // console.log("API result PatientDetailsSummary", result);

                if (result.data.result.result.length == 0) {
                    notification.error({ message: 'Invalid Patient' });
                    history.push(path('/'));
                } else {

                    // process data
                    let values = result.data.result.result[0]
                    let dialysisPeriodDuration
                    let quickbookCustomerID;

                    if (values != null) {
                        // let inDialysisTime = moment() - moment(values.firstDialysisHospitalDate)
                        // let inDialysisYear = moment(inDialysisTime).year() - 1970
                        // let inDialysisMonth = moment(inDialysisTime).month()
                        // dialysisPeriodDuration = (inDialysisYear > 0 ? inDialysisYear + ' years' : '') + (inDialysisMonth > 0 ? ' ' + inDialysisMonth + ' months' : '')
                        dialysisPeriodDuration = values?.firstDialysisHospitalDate ? (moment().diff(moment(values.firstDialysisHospitalDate), "years", true).toFixed(1) + " years") : '';
                    }

                    let values4 = result?.data?.result4?.result[0];
                    quickbookCustomerID = values4?.quickbookCustomerID;

                    let values3 = result.data.result3.result[0]
                    let firstDialysisPeriodDuration

                    if (values3 != null) {
                        // let firstDialysisTimeInThisClinic = moment() - moment(values3.startTime)
                        // let firstDialysisYearInThisClinic = moment(firstDialysisTimeInThisClinic).year() - 1970
                        // let firstDialysisMonthInThisClinic = moment(firstDialysisTimeInThisClinic).month()
                        // firstDialysisPeriodDuration = (firstDialysisYearInThisClinic > 0 ? firstDialysisYearInThisClinic + ' years' : '') + (firstDialysisMonthInThisClinic > 0 ? ' ' + firstDialysisMonthInThisClinic + ' months' : '')
                        firstDialysisPeriodDuration = values3.startTime ? (moment().diff(moment(values3.startTime), "years", true).toFixed(1) + " years") : '';
                    }

                    let _allergy = [];
                    for (let v of result?.data?.result2?.result) {
                        _allergy.push({
                            id: v.id,
                            patientID: v.patientID,
                            allergy: v.name,
                            date: v.knownDate,
                        })
                    }
                    let _patient = {
                        ...values,
                        name: values.name,
                        namecode: values.namecode,
                        nric: values.nric ? values.nric?.substring(0, values.nric.length - 4).replace(/\d|\w/g, "*") + values.nric?.substring(values.nric.length - 4) : '****',
                        dob: values.dob,
                        dobDisplay: values.dob ? moment(values.dob).format("DD MMM YYYY") : '',
                        // age: values.dob ? (moment(values.dob).fromNow()).replace("ago", "old") : "",
                        age: values.dob ? (moment().diff(moment(values.dob), "years", true).toFixed(1) + " years old") : "",
                        gender: values.gender,
                        genderDisplay: values.gender === "M" ? "Male" : (values.gender === "F" ? "Female" : "Others"),
                        // nok: values.nokName,
                        // nokMobile: values.nokPhoneNo,
                        dialysisPeriodDuration: dialysisPeriodDuration, //"18 years, 4 months",
                        referralSource: values.referringSource,
                        currentClinicDialysisDuration: firstDialysisPeriodDuration, //"1 year, 3 months",
                        allergyDisplay: result?.data?.result2?.result?.map(s => s.name) || [],
                        allergy: _allergy,
                        quickbookCustomerID,
                        esrd: values.DESRD,
                        esrdAge: values.DESRD ? (moment().diff(moment(values.DESRD), "years", true).toFixed(1) + " years") : "",
                        esrdDisplay: values.DESRD ? moment(values.DESRD).format("DD MMM YYYY") : '',
                    };

                    // console.log(result?.data?.result3);

                    // console.log("_patient", _patient);
                    setPatient(_patient)
                    if (setPatientDisplay !== undefined) {
                        setPatientDisplay({
                            name: _patient.name,
                            nric: _patient.nric,
                            namecode: _patient.namecode
                        })
                    }
                }
                setLoading(false)
            }
        }
    }, [data]);

    const _createAllergy = async (values, patientID) => {
        setLoadingModal(true)
        try {
            let variables = {
                name: values.allergy,
                patientID: patientID,
                knownDate: moment().toISOString().split('.')[0]
            }

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: createAllergy,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { create: variables }
            })
            // console.log("API result PatientDetailsSummary createAllergy", result);

            if (result?.data?.result) {
                // _listPatient(props?.patientID);
                mutate();
                notification.success({
                    message: "Created successfully"
                })
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to create record"
            })
        } finally {
            setLoadingModal(false)
            form.resetFields()
        }
    }

    const _updateAllergy = async (allergyID) => {
        setLoadingModal(true)
        try {
            let variables = {
                id: allergyID,
                deleted: 1,
            }

            variables = Object.entries(variables).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log(variables);

            const result = await API.graphql({
                query: updateAllergy,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: { update: variables }
            })
            // console.log("API result PatientDetailsSummary updateAllergy", result);

            if (result?.data?.result) {
                // _listPatient(props?.patientID);
                mutate();
                notification.success({
                    message: "Deleted successfully"
                })
            }
        } catch (error) {
            // console.log(error);
            notification.error({
                message: "Unable to update record"
            })
        } finally {
            setLoadingModal(false)
        }
    }

    const _listPatient = async (patientID) => {
        if (patientID == 0) {
            return;
        }

        try {
            setLoading(true)
            const variables = {
                filter: {
                    id: { eq: patientID }
                },
                filter2: {
                    patientID: { eq: patientID },
                    deleted: { eq: 0 }
                },
                filter3: {
                    patientID: { eq: parseInt(patientID) },
                    startTime: { attributeExists: true },
                },
                filter4: {
                    patientID: { eq: parseInt(patientID) },
                    clinicID: { eq: parseInt(appState.selectedClinicID) },
                },
                pagination: {
                    limit: 1,
                    // orderby: 'startTime ASC'
                },
                pagination2: {},
                pagination3: {
                    limit: 1,
                    orderby: 'startTime ASC'
                },
            }

            // if (appState.selectedClinicID > 0) {
            if (appState.selectedClinicID !== "") {
                variables.filter3.clinicID = {
                    eq: parseInt(appState.selectedClinicID)
                }
            }

            // console.log('listPatientAndAllergyAndDialysis', variables);
            let queryOption = {
                query: listPatientAndAllergyAndDialysis_PatientClinic,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            };
            setQueryOption(queryOption);

            // const result = await API.graphql({
            //     query: listPatientAndAllergyAndDialysis,
            //     authMode: "AMAZON_COGNITO_USER_POOLS",
            //     variables
            // })
            // console.log("API result PatientDetailsSummary", result);

            // // process data
            // let values = result.data.result.result[0]
            // let dialysisPeriodDuration
            // let quickbookCustomerID;

            // if (values != null) {
            //     let inDialysisTime = moment() - moment(values.firstDialysisHospitalDate)
            //     let inDialysisYear = moment(inDialysisTime).year() - 1970
            //     let inDialysisMonth = moment(inDialysisTime).month()
            //     dialysisPeriodDuration = (inDialysisYear > 0 ? inDialysisYear + ' years' : '') + (inDialysisMonth > 0 ? ' ' + inDialysisMonth + ' months' : '')
            //     quickbookCustomerID = values.quickbookCustomerID;
            // }

            // let values3 = result.data.result3.result[0]
            // let firstDialysisPeriodDuration

            // if (values3 != null) {
            //     let firstDialysisTimeInThisClinic = moment() - moment(values3.startTime)
            //     let firstDialysisYearInThisClinic = moment(firstDialysisTimeInThisClinic).year() - 1970
            //     let firstDialysisMonthInThisClinic = moment(firstDialysisTimeInThisClinic).month()
            //     firstDialysisPeriodDuration = (firstDialysisYearInThisClinic > 0 ? firstDialysisYearInThisClinic + ' years' : '') + (firstDialysisMonthInThisClinic > 0 ? ' ' + firstDialysisMonthInThisClinic + ' months' : '')
            // }

            // let _allergy = [];
            // for (let v of result?.data?.result2?.result) {
            //     _allergy.push({
            //         id: v.id,
            //         patientID: v.patientID,
            //         allergy: v.name,
            //         date: v.knownDate,
            //     })
            // }
            // let _patient = {
            //     name: values.name,
            //     namecode: values.namecode,
            //     nric: values.nric.substring(0, values.nric.length - 4).replace(/\d|\w/g, "*") + values.nric.substring(values.nric.length - 4),
            //     dob: values.dob,
            //     dobDisplay: moment(values.dob).format("DD MMM YYYY"),
            //     // age: values.dob ? (moment(values.dob).fromNow()).replace("ago", "old") : "",
            //     age: values.dob ? (moment().diff(moment(values.dob), "years", true).toFixed(1) + " years old") : "",
            //     gender: values.gender,
            //     genderDisplay: values.gender === "M" ? "Male" : (values.gender === "F" ? "Female" : "Others"),
            //     // nok: values.nokName,
            //     // nokMobile: values.nokPhoneNo,
            //     dialysisPeriodDuration: dialysisPeriodDuration, //"18 years, 4 months",
            //     referralSource: values.referringSource,
            //     currentClinicDialysisDuration: firstDialysisPeriodDuration, //"1 year, 3 months",
            //     allergyDisplay: result?.data?.result2?.result?.map(s => s.name) || [],
            //     allergy: _allergy,
            //     quickbookCustomerID,
            //     esrd: values.DESRD,
            //     esrdAge: values.DESRD ? (moment().diff(moment(values.DESRD), "years", true).toFixed(1) + " years") : "",
            //     esrdDisplay: moment(values.DESRD).format("DD MMM YYYY"),
            // };

            // // console.log(result?.data?.result3);

            // // console.log("_patient", _patient);
            // setPatient(_patient)
            // if (setPatientDisplay !== undefined) {
            //     setPatientDisplay(_patient.namecode)
            // }
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Patient Details"
            })
        }
        finally {
        }
    }

    const onFinish = async (values) => {
        // console.log("values", values);
        await form.validateFields()
        _createAllergy(values, patientID)
    }

    const handleModalVisibility = (modal) => {
        // console.log("modal", modal);
        switch (modal) {
            case "allergy":
                setVisibilityAllergy(!visibilityAllergy)
                break
            default:
        }
    }

    useEffect(() => {
        // console.log('PatientDetailsSummary', patientID);
        if (Object.keys(appState.cognitoUser).length !== 0) {
            _listPatient(patientID);
        }
        return () => { }
    }, [appState.cognitoUser])

    const renderAllergyItems = (array) => {
        // console.log("array", array);
        const items = array.map((item, index) => {
            return (
                <div key={item.id} className={index % 2 === 0 ? "record highlight" : "record"}>
                    <div className="value">
                        <div className="date">
                            <span className="clickable">
                                <CopyToClipboard
                                    text={moment(item.date).format("DD MMM YYYY") + ": " + item.allergy}
                                    onCopy={() => { message.success("Copied!") }}
                                >
                                    <img src={iconFile} />
                                </CopyToClipboard>
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            {item.date ? moment(item.date).format("DD MMM YYYY") : '-'}
                        </div>
                        <div className="name">
                            {item.allergy}
                        </div>
                    </div>
                    <Popconfirm title="Are you sure?" onConfirm={() => {
                        // console.log("handle delete", item.id);
                        _updateAllergy(item.id);
                    }} okText="Yes" cancelText="No">
                        <div className="clickable">
                            <img src={iconMinus} />
                        </div>
                    </Popconfirm>
                </div>
            )
        })
        return items
    }

    const renderAllergy = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderAllergyItems(array)
            )
        )
    }

    return (
        <Spin spinning={loading && isValidating}>
            <div className="patientdetailssummary">
                <div className="patient">
                    <div className="row1">
                        {patient.name}
                    </div>
                    <div className="row2">
                        <div>
                            {patient.nric}
                        </div>
                        <div>
                            {patient.genderDisplay}
                        </div>
                        {
                            patient.dob ?
                                <div>
                                    {patient.dobDisplay} ({patient.age})
                                </div> : <></>
                        }
                    </div>
                </div>
                {/* <div className="details">
                    <div className="row1">
                        {"NOK: " + patient.nok}
                    </div>
                    <div>
                        {patient.nokMobile}
                    </div>
                </div> */}
                {
                    patient.esrd ? (
                        <div className="details">
                            <div className="row1">
                                ESRD
                            </div>
                            <div>
                                {patient.esrdDisplay} ({patient.esrdAge})
                            </div>
                        </div>
                    ) : ""
                }
                <div className="details">
                    <div className="row1">
                        Diabetic: <span style={{ fontWeight: "normal" }}>{patient.diabeticStatus && patient.diabeticStatus !== "No" ? <span style={{ color: "red" }}>Yes</span> : "No"}</span>
                    </div>
                    <div className="row1 row">
                        Allergies: <span style={{ fontWeight: "normal" }}>{patient.allergyDisplay?.length > 0 ? <span style={{ color: "red" }}>Yes</span> : "No"}</span>
                        &nbsp;&nbsp;&nbsp;
                        <div className="clickable" onClick={() => { handleModalVisibility("allergy") }}>
                            <img src={iconAdd} />
                        </div>
                    </div>
                    {/* <Tooltip placement="top" title={
                        patient.allergyDisplay.join(', ')
                        // "Allergy 1, allergy 2, allergy 3"
                    } trigger={["hover", "click"]}>
                        <div>
                            {patient.allergyDisplay.length} Drug Allergy
                        </div>
                    </Tooltip> */}
                </div>
                {
                    patient.dialysisPeriodDuration ? (
                        <div className="details">
                            <div className="row1">
                                In Dialysis
                            </div>
                            <div>
                                {patient.dialysisPeriodDuration}
                            </div>
                        </div>
                    ) : ""
                }
                {
                    patient.referralSource ? (
                        <div className="details">
                            <div className="row1">
                                {patient.referralSource}
                            </div>
                        </div>
                    ) : ""
                }
                {
                    patient.currentClinicDialysisDuration ? (
                        <div className="details">
                            <div className="row1">
                                Current Clinic Dialysis Duration
                            </div>
                            <div>
                                {patient.currentClinicDialysisDuration}
                            </div>
                        </div>
                    ) : ""
                }
                &nbsp;&nbsp;&nbsp;
                {(patient.quickbookCustomerID > 0) ?
                    (
                        <Button type="primary" onClick={() => {
                            window.open(quickBooksURL + patient.quickbookCustomerID, "_blank")
                        }}>
                            View Invoices
                        </Button>
                    ) : (<></>)
                }
                <div className="side-display-patient">
                    Patient: <b>{patient.name}</b>
                </div>
                <Modal
                    title={
                        <div className="modal-title">
                            Allergy
                        </div>
                    }
                    visible={visibilityAllergy}
                    onCancel={() => handleModalVisibility("allergy")}
                    footer={[
                        <Button key="back" onClick={() => handleModalVisibility("allergy")}>
                            Cancel
                        </Button>,
                    ]}
                    className="modal-patientdetailssummary-allergy"
                    width={600}
                >
                    <Spin spinning={loadingModal}>
                        <div className="modal-content">
                            <Form
                                form={form}
                                layout="horizontal"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                                requiredMark={false}
                            >
                                <div className="record-add">
                                    {/* <Form.Item
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker onChange={handleDatePickerChange} format={dateFormatList} />
                                </Form.Item> */}
                                    <Form.Item
                                        name="allergy"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input autoComplete="off" placeholder="Enter allergy" />
                                    </Form.Item>
                                    <Popconfirm title="Are you sure?"
                                        onConfirm={() => onFinish(form.getFieldsValue())}
                                        okText="Yes" cancelText="No"
                                        onCancel={() => { form.resetFields() }}>
                                        {/* <Button className="circle" htmlType="submit" shape="circle" icon={<PlusOutlined />} /> */}
                                        <div className="clickable">
                                            <img src={iconAdd} />
                                        </div>
                                    </Popconfirm>
                                </div>
                            </Form>
                            {renderAllergy(patient.allergy ? patient.allergy : [])}
                        </div>
                    </Spin>
                </Modal>
            </div>
        </Spin>
    )
}

export default PatientDetailsSummary