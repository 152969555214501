import React, { useContext, useEffect, useState } from "react"
import { AutoComplete, Button, Collapse, Divider, Form, Input, InputNumber, Modal, notification, Select } from "antd"

import { listDialysis } from "../backend/graphql/queries"
import API from "@aws-amplify/api"
import { AppContext } from "../contexts/AppContext"

import PatientRecordDialysisOrder from "./Component-PatientRecord-DialysisOrder"
import SummaryPrePostDialysis from "./Component-Summary-PrePostDialysis"
import { dialyserList } from "../utils/constants"

const PatientRecordPreweights = (props) => {
    const { appState } = useContext(AppContext);
    const { values, setValues, onFinish, onFinishReview, page, patientID, form, patientDisplay } = props
    const { Option } = Select
    const { Panel } = Collapse
    // console.log("page", page);
    // const [patient, setPatient] = useState({
    //     nameCode: "DMLGH;7585",
    //     sessionMonth: 4,
    //     lastWt: "63 KG",
    //     wtGain: "5.1",
    //     nextOfKin: "John Mclaaughlin",
    //     nextOfKinNum: "+6598765432",
    //     expectedDWDifference: "0.0 KG",
    //     expectedLWDifference: "0.1 KG"
    // })
    const [visibilityDialysisOrder, setVisibilityDialysisOrder] = useState(false)
    const [visibilityMobilePreDialysisSummary, setVisibilityMobilePreDialysisSummary] = useState(false)
    const [loading, setLoading] = useState(false);
    const [currentClinicMachine, setCurrentClinicMachine] = useState([])
    const [collapseKey, setCollapseKey] = useState([])

    useEffect(() => {
        if (page === "checkin") {
            // console.log("page", page);
            setCollapseKey(["1"])
        }
    }, [])

    useEffect(() => {
        if (Object.keys(appState.cognitoUser).length !== 0) {
            // _listDialysis(props?.patientID);
        }
        return () => { }
    }, [appState.cognitoUser])

    useEffect(() => {
        // console.log('PatientRecordPreweights', page, values);
    }, [values])

    useEffect(() => {
        if (appState.machineList !== -1 && appState.selectedClinicID !== "") {
            // console.log("appState.machineList", appState.machineList);
            const array = appState.machineList.filter(value => value.clinicID.toString() === appState.selectedClinicID.toString() && value.status !== "EXPIRED")
            setCurrentClinicMachine(array)
        }
    }, [appState.machineList, appState.selectedClinicID])

    const handleChange = (key, type) => {
        // console.log("key", key);
        switch (type) {
            case "collapse":
                setCollapseKey(key)
                break
            default:
        }
    }

    const handleInputOnBlur = (value) => {
        // console.log("on blur", value);
        let formValues = form.getFieldValue();
        // console.log('formValues', formValues)
        if (formValues.preWt && formValues.ufTargetAtStart) {
            // console.log(formValues.preWt, formValues.ufTargetAtStart);


            let expectedLWDifference = '-';
            if (values.lastWt)
                expectedLWDifference = parseFloat(formValues.preWt) - parseFloat(formValues.ufTargetAtStart) - parseFloat(values.lastWt);

            if (formValues.dw) {
                setValues({
                    ...values,
                    ...formValues,
                    expectedDWDifference: parseFloat(formValues.preWt) - parseFloat(formValues.ufTargetAtStart) - parseFloat(formValues.dw),
                    expectedLWDifference,
                })
            } else {
                setValues({
                    ...values,
                    ...formValues,
                    expectedLWDifference,
                })
            }
        }
    }

    const handleFocus = (event) => {
        event.target.select()
    }

    const _listDialysis = async (patientID) => {
        setLoading(true);
        try {
            const variables = {
                pagination: {
                    limit: 1,
                    orderby: 'createdOn DESC',
                },
                filter: {
                    patientID: {
                        eq: patientID
                    }
                },
            };

            // console.log(variables);

            const result = await API.graphql({
                query: listDialysis,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables
            })
            // console.log("API result PatientRecordPreweights", result);

            const data = result.data.result
            const array = result.data.result.result

            // if (array.length > 0) {
            //     const v = array[0];
            //     console.log(v);
            //     let newSessionMonth = getSessionMonth(v.endTime ? v.endTime : v.startTime, v.sessionMonth);

            //     // process data
            //     setPatient({
            //         lastWt: v.postWeight ? (v.postWeight / 1000.0).toFixed(1) : '',
            //         sessionMonth: newSessionMonth,//v.sessionMonth + 1,
            //     });
            //     form.setFieldsValue({
            //         sessionMonth: newSessionMonth,
            //         lastWt: v.postWeight ? (v.postWeight / 1000.0).toFixed(1) : '',
            //         clinicID: parseInt(appState.selectedClinicID)
            //     });
            // } else {
            //     setPatient({
            //         lastWt: '',
            //         sessionMonth: 1,
            //     });
            //     form.setFieldsValue({
            //         sessionMonth: 1,
            //         lastWt: '',
            //         clinicID: parseInt(appState.selectedClinicID)
            //     });
            // }

            setLoading(false);
            // console.log(_labReport);
        }
        catch (error) {
            console.log("error: ", error);
            notification.error({
                message: "Unable to retrieve Dialysis"
            })
        }
        finally { }
    }

    const renderListOptions = (array, key = 'id', value = 'id', name = 'name') => {
        if (typeof (array) == 'object' && array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item[key]} value={item[value]}>{item[name]}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="patientrecord-preweights">
            <div className="container-button below-md" style={{ marginBottom: 0 }}>
                <Button onClick={() => { setVisibilityDialysisOrder(!visibilityDialysisOrder) }}>Dialysis Order</Button>
                {/* {
                    page !== "intra" ? (
                        <Button type="primary" onClick={onFinish} loading={loading}>
                            Check In
                        </Button>
                    ) : (
                        <Button className="btn-finishcheckout" onClick={onFinish}>
                            Check Out
                            Finish &amp; Check Out
                        </Button>
                    )
                } */}
                {
                    page !== "intra" ? (
                        page === "checkinEdit" ? (
                            <Button type="primary" onClick={() => {
                                setVisibilityMobilePreDialysisSummary(true)
                                // onFinish
                            }} loading={loading}>
                                Update
                            </Button>
                        ) : (
                            <Button type="primary" onClick={() => {
                                // console.log("display summary", form.getFieldValue());
                                setVisibilityMobilePreDialysisSummary(true)
                                // onFinish()
                            }} loading={loading}>
                                Check In
                            </Button>
                        )
                    ) : (
                        <Button className="btn-finishcheckout" onClick={onFinish}>
                            Check Out
                            {/* Finish &amp; Check Out */}
                        </Button>
                    )
                }
            </div>
            <div className="patient below-md" style={{ textAlign: "center", marginBottom: 0 }}>
                <div className="row1">
                    {patientDisplay?.name}
                </div>
                <div className="row2">
                    {patientDisplay?.nric}
                </div>
                <div className="side-display-patient">
                    Patient: <b>{patientDisplay.name}</b>
                </div>
            </div>
            <Collapse ghost className="below-md" activeKey={collapseKey} onChange={(key) => handleChange(key, "collapse")}>
                <Panel header="More" key="1">
                    <div className="column1">
                        {
                            patientDisplay?.namecode ? (
                                <Form.Item
                                    label="Name Code"
                                >
                                    {patientDisplay?.namecode}
                                </Form.Item>
                            ) : ""
                        }
                        <Form.Item
                            label="Session Month"
                            name="sessionMonth"
                        >
                            {values.sessionMonth}
                        </Form.Item>
                        <Form.Item
                            label="LAST-WT (KG)"
                            name="lastWt"
                        >
                            {values.lastWt}
                        </Form.Item>
                        <Form.Item
                            label="PRE-WT (KG)"
                            name="preWt"
                            rules={[
                                // {
                                //     required: true,
                                // },
                                {
                                    message: "Digits only",
                                    pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="" allowClear onBlur={handleInputOnBlur} disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                        </Form.Item>
                    </div>
                    <div className="column2">
                        <div className="column">
                            <Form.Item
                                label="Pre-Temp (&#8451;)"
                                name="preTemp"
                                rules={[
                                    // {
                                    //     required: true,
                                    // },
                                    {
                                        message: "Digits only",
                                        pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                            </Form.Item>
                            <Form.Item
                                label="Pre Edema"
                                name="preEdema"
                                rules={[
                                    // {
                                    //     required: true,
                                    // },
                                    {
                                        message: "Digits only",
                                        pattern: new RegExp('^[0-9]*$'),
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                            </Form.Item>
                            <Form.Item
                                label="Pre SBP"
                                name="preSBP"
                                rules={[
                                    // {
                                    //     required: true,
                                    // },
                                    {
                                        message: "Digits only",
                                        pattern: new RegExp('^[0-9]*$'),
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                            </Form.Item>
                            <Form.Item
                                label="Pre DBP"
                                name="preDBP"
                                rules={[
                                    // {
                                    //     required: true,
                                    // },
                                    {
                                        message: "Digits only",
                                        pattern: new RegExp('^[0-9]*$'),
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                            </Form.Item>
                            <Form.Item
                                label="Pre HRT"
                                name="preHRT"
                                rules={[
                                    // {
                                    //     required: true,
                                    // },
                                    {
                                        message: "Digits only",
                                        pattern: new RegExp('^[0-9]*$'),
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                            </Form.Item>
                            <Form.Item
                                label="DW (KG)"
                                name="dw"
                                rules={[
                                    // {
                                    //     required: true,
                                    // },
                                    {
                                        message: "Digits only",
                                        pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="" allowClear onBlur={handleInputOnBlur} disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                            </Form.Item>
                            <Form.Item
                                label="UF Target At Start (&#8467;)"
                                name="ufTargetAtStart"
                                rules={[
                                    // {
                                    //     required: true,
                                    // },
                                    {
                                        message: "Digits only",
                                        pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="" allowClear onBlur={handleInputOnBlur} disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                            </Form.Item>
                            <Form.Item
                                label="Expected DW Difference"
                                className="red"
                            >
                                {values.expectedDWDifference}
                            </Form.Item>
                            <Form.Item
                                label="Expected LW Difference"
                                className="red"
                            >
                                {values.expectedLWDifference}
                            </Form.Item>
                        </div>
                        <div className="column">
                            {/* <Form.Item
                        label="WT Gain (KG)"
                    >
                        {values.wtGain}
                    </Form.Item> */}
                            {/* <Form.Item
                        label="Next of kin"
                    >
                        {values.nextOfKin}
                    </Form.Item>
                    <Form.Item
                        label="Next of kin number"
                    >
                        {values.nextOfKinNum}
                    </Form.Item> */}
                        </div>
                    </div>
                    <div className="column3">
                        <Form.Item
                            label="Clinic"
                            name="clinicID"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {/* <Select onChange={handleChange} disabled = {page === "intra" ? true : false}> */}
                            <Select onChange={handleChange}
                                // defaultValue={{ value: parseInt(appState.selectedClinicID) }}
                                value={values.clinicID}
                                disabled={true}
                            >
                                {renderListOptions(appState.clinicList !== -1 ? appState.clinicList : [])}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Machine"
                            name="machineID"
                            rules={[
                                // {
                                //     required: true,
                                // },
                            ]}
                        >
                            <Select onChange={handleChange} disabled={page === "intra" ? true : false}
                                showSearch={true}
                                placeholder="Select a machine"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions(currentClinicMachine, 'id', 'id', 'serialNo')}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                    label="Machine"
                    name="machineID"
                >
                    DDL
                </Form.Item> */}
                        <Form.Item
                            label="Dialyser"
                            name="dialyser"
                            rules={[
                                // {
                                //     required: true,
                                // },
                            ]}
                        >
                            <AutoComplete onChange={handleChange} disabled={page === "intra" ? true : false}
                                showSearch={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions(dialyserList , 'id', 'value', 'value')}
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item
                            label="MSA"
                            name="MSA"
                            rules={[
                                // {
                                //     required: true,
                                // },
                                {
                                    message: "Digits only",
                                    pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                        </Form.Item>
                        <Form.Item
                            label="Membrane Use"
                            name="membraneUse"
                            rules={[
                                // {
                                //     required: true,
                                // },
                            ]}
                        >
                            <InputNumber autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} min={0} onFocus={handleFocus} />
                            {/* <InputNumber autoComplete="off" placeholder="" allowClear disabled={true} min={0} /> */}
                        </Form.Item>
                        {/* <Form.Item
                            label="Renalin Precheck"
                            name="renalinPrecheck"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} />
                        </Form.Item> */}
                    </div>
                    {/* mobile */}
                    {
                        page !== "intra" ? (
                            page === "checkinEdit" ? (
                                <Button type="primary" onClick={() => {
                                    setVisibilityMobilePreDialysisSummary(true)
                                    // onFinish
                                }} loading={loading}>
                                    Update
                                </Button>
                            ) : (
                                <Button type="primary" onClick={() => {
                                    // console.log("display summary", form.getFieldValue());
                                    setVisibilityMobilePreDialysisSummary(true)
                                    // onFinish()
                                }} loading={loading}>
                                    Check In
                                </Button>
                            )
                        ) : (
                            <Button className="btn-finishcheckout" onClick={onFinish}>
                                Check Out
                                {/* Finish &amp; Check Out */}
                            </Button>
                        )
                    }
                </Panel>
            </Collapse>
            <div className="column1 above-md-inlineblock">
                {
                    patientDisplay?.namecode ? (
                        <Form.Item
                            label="Name Code"
                        >
                            {patientDisplay?.namecode}
                        </Form.Item>
                    ) : ""
                }
                <Form.Item
                    label="Session Month"
                    name="sessionMonth"
                >
                    {values.sessionMonth}
                </Form.Item>
                <Form.Item
                    label="LAST-WT (KG)"
                    name="lastWt"
                >
                    {values.lastWt}
                </Form.Item>
                <Form.Item
                    label="PRE-WT (KG)"
                    name="preWt"
                    rules={[
                        // {
                        //     required: true,
                        // },
                        {
                            message: "Digits only",
                            pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                        },
                    ]}
                    style={{ color: "red" }}
                >
                    <Input autoComplete="off" placeholder="" allowClear onBlur={handleInputOnBlur} disabled={page === "intra" ? true : false} className={page === "intra" ? "color-black" : ""} onFocus={handleFocus} />
                </Form.Item>
            </div>
            <div className="column2 above-md-inlineblock">
                <div className="column">
                    <Form.Item
                        label="Pre-Temp (&#8451;)"
                        name="preTemp"
                        rules={[
                            // {
                            //     required: true,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                    </Form.Item>
                    <Form.Item
                        label="Pre Edema"
                        name="preEdema"
                        rules={[
                            // {
                            //     required: true,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp('^[0-9]*$'),
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                    </Form.Item>
                    <Form.Item
                        label="Pre SBP"
                        name="preSBP"
                        rules={[
                            // {
                            //     required: true,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp('^[0-9]*$'),
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                    </Form.Item>
                    <Form.Item
                        label="Pre DBP"
                        name="preDBP"
                        rules={[
                            // {
                            //     required: true,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp('^[0-9]*$'),
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                    </Form.Item>
                    <Form.Item
                        label="Pre HRT"
                        name="preHRT"
                        rules={[
                            // {
                            //     required: true,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp('^[0-9]*$'),
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                    </Form.Item>
                    <Form.Item
                        label="DW (KG)"
                        name="dw"
                        rules={[
                            // {
                            //     required: true,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="" allowClear onBlur={handleInputOnBlur} disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                    </Form.Item>
                    <Form.Item
                        label="UF Target At Start (&#8467;)"
                        name="ufTargetAtStart"
                        rules={[
                            // {
                            //     required: true,
                            // },
                            {
                                message: "Digits only",
                                pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="" allowClear onBlur={handleInputOnBlur} disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                    </Form.Item>
                    <Form.Item
                        label="Expected DW Difference"
                        className="red"
                    >
                        {values.expectedDWDifference}
                    </Form.Item>
                    <Form.Item
                        label="Expected LW Difference"
                        className="red"
                    >
                        {values.expectedLWDifference}
                    </Form.Item>
                </div>
                <div className="column">
                    {/* <Form.Item
                        label="WT Gain (KG)"
                    >
                        {values.wtGain}
                    </Form.Item> */}
                    {/* <Form.Item
                        label="Next of kin"
                    >
                        {values.nextOfKin}
                    </Form.Item>
                    <Form.Item
                        label="Next of kin number"
                    >
                        {values.nextOfKinNum}
                    </Form.Item> */}
                </div>
            </div>
            <div className="column3 above-md-inlineblock">
                <Form.Item
                    label="Clinic"
                    name="clinicID"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    {/* <Select onChange={handleChange} disabled = {page === "intra" ? true : false}> */}
                    <Select onChange={handleChange}
                        // defaultValue={{ value: parseInt(appState.selectedClinicID) }}
                        value={values.clinicID}
                        disabled={true}
                    >
                        {renderListOptions(appState.clinicList !== -1 ? appState.clinicList : [])}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Machine"
                    name="machineID"
                    rules={[
                        // {
                        //     required: true,
                        // },
                    ]}
                >
                    <Select onChange={handleChange} disabled={page === "intra" ? true : false}
                        showSearch={true}
                        placeholder="Select a machine"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        }
                    >
                        {renderListOptions(currentClinicMachine, 'id', 'id', 'serialNo')}
                    </Select>
                </Form.Item>
                {/* <Form.Item
                    label="Machine"
                    name="machineID"
                >
                    DDL
                </Form.Item> */}
                <Form.Item
                    label="Dialyser"
                    name="dialyser"
                    rules={[
                        // {
                        //     required: true,
                        // },
                    ]}
                >
                    <AutoComplete onChange={handleChange} disabled={page === "intra" ? true : false}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        }
                    >
                        {renderListOptions(dialyserList, 'id', 'value', 'value')}
                    </AutoComplete>
                </Form.Item>
                <Form.Item
                    label="MSA"
                    name="MSA"
                    rules={[
                        // {
                        //     required: true,
                        // },
                        {
                            message: "Digits only",
                            pattern: new RegExp('^[+-]?([0-9]*[.])?[0-9]+$'),
                        },
                    ]}
                >
                    <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} onFocus={handleFocus} />
                </Form.Item>
                <Form.Item
                    label="Membrane Use"
                    name="membraneUse"
                    rules={[
                        // {
                        //     required: true,
                        // },
                    ]}
                >
                    <InputNumber autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} min={0} onFocus={handleFocus} />
                    {/* <InputNumber autoComplete="off" placeholder="" allowClear disabled={true} min={0} /> */}
                </Form.Item>
                {/* <Form.Item
                    label="Renalin Precheck"
                    name="renalinPrecheck"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input autoComplete="off" placeholder="" allowClear disabled={page === "intra" ? true : false} />
                </Form.Item> */}
            </div>
            <Modal
                visible={visibilityDialysisOrder}
                onCancel={() => { setVisibilityDialysisOrder(false) }}
                footer={[
                    <Button key="back" onClick={() => setVisibilityDialysisOrder(false)}>
                        Cancel
                    </Button>,
                ]}
            >
                <PatientRecordDialysisOrder modal={true} patientID={props?.patientID} />
            </Modal>
            <Modal
                title="Check In"
                visible={visibilityMobilePreDialysisSummary}
                onCancel={() => { setVisibilityMobilePreDialysisSummary(false) }}
                footer={[
                    <Button key="back" onClick={() => setVisibilityMobilePreDialysisSummary(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        setVisibilityMobilePreDialysisSummary(false)
                        onFinish()
                    }}>
                        Submit
                    </Button>
                ]}
            >
                <SummaryPrePostDialysis page="checkin" values={form.getFieldValue()} />
            </Modal>
        </div>
    )
}

export default PatientRecordPreweights